import classNames from "classnames/bind";
import styles from "./pricing-table.module.scss";
import {addComma, calcPerPrice, checkAuthType} from "../../../util/str-util";
import Check from "./check";
import {useState} from "react";
import {Goods} from "../../../model/goods/dto/goods";
import {CommonCode} from "../../../model/common/dto/common-code";

const cx = classNames.bind(styles);

const PricingTable = ({goods, codes, uiView}: {
    goods: Goods[],
    codes: CommonCode[],
    uiView: { [key: string]: boolean };
}) => {
    const [mob, setMob] = useState(false);

    window.addEventListener('resize', () => setMob(window.innerWidth <= 500));

    return (
        <div className={cx('pricing-table')}>
            <div className={cx('head')}>
                <p></p>
                {
                    goods.map((it, i) => <p key={i} style={{color: it.info.goodsColor}}>{it.name}</p>)
                }
            </div>
            <div className={cx('body')}>
                <p>상품종류</p>
                {
                    goods.map((it, i) => <p key={i}>{it.type === 'MEMBERSHIP' ? '연간 멤버십 상품' : '단건 구매 상품'}</p>)
                }
            </div>
            <div className={cx('body')}>
                <p>이용권 수</p>
                {
                    goods.map((it, i) =>
                        <p key={i}>
                            <span className={cx('bold')}>{it.meta.val2}건</span>/
                            {+(it.meta.val3 ?? "0") >= 365 ? `${+(it.meta.val3 ?? "0") / 365}년` : `${it.meta.val3}일`}
                        </p>
                    )
                }
            </div>
            <div className={cx('body')}>
                <p>요금</p>
                {
                    goods.map((it, i) => <p key={i}>{addComma(it.price)}원</p>)
                }
            </div>
            <div className={cx('body')}>
                <p>건당 환산 금액</p>
                {
                    goods.map((it, i) => it.type === 'MEMBERSHIP' ?
                        <p key={i}>{addComma(calcPerPrice(it.price, +(it.meta.val2 ?? "0")))}원</p>
                        : <p key={i}>{addComma(it.price)}원</p>
                    )
                }
            </div>
            <div className={cx('blank')}>
            </div>
            {
                codes
                    .filter((it, i) => uiView[it.code])
                    .map((code) => {
                        return <div className={cx('body')}>
                            <p>{code.codeVal}</p>
                            {
                                goods
                                    .map((it, i) => <p key={code.code}>{checkAuthType(code.code, it.goodsAuth) ?
                                        <Check size={mob ? 15 : 24} color={it.info.goodsColor ?? '#000'}/> :
                                        <i></i>}</p>)
                            }
                        </div>
                    })
            }
            <div className={cx('body')}>
                <p>이용기간</p>
                {
                    goods.map((it, i) => <pre
                        key={i}>구매일로부터 {+(it.meta.val3 ?? "0") >= 365 ? `${+(it.meta.val3 ?? "0") / 365}년` : `${it.meta.val3}일`}간 이용</pre>)
                }
            </div>
            <div className={cx('body')}>
                <p>환불정책</p>
                {
                    goods.map((it, i) => <pre
                        key={i}>{it.content.refundPolicy ? '14일 이내 환불 (전 메뉴 미사용시)' : '환불 불가'}</pre>)
                }
            </div>
            <div className={cx('body')}>
                <p>잔여이용권<br/>이월정책</p>
                {
                    goods.map((it, i) => <pre key={i}>{it.type === 'MEMBERSHIP' ?
                        (checkAuthType('CARRY_OVER', it.goodsAuth) ? '멤버십 이용기한 만료일\n전후 30일 이내 재연장시\n(단, 만료일 기준 90일 이내 사용 조건)' : '이월 불가') : ''}</pre>)
                }
            </div>
        </div>
    );
}

export default PricingTable;