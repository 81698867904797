import React from 'react';
import styles from './title-box.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface TitleBoxProps {
    title: string;
    children?: React.ReactNode;
    paddingTop?: string;
    desc?: string;
    desc2?: string;
}

const TitleBox = ({title, children, paddingTop, desc, desc2}: TitleBoxProps) => {

    return (
        <>
            <div className={cx('title-box')} style={{paddingTop: paddingTop ?? '5.0rem'}}>
                <p className={cx('title')}>{title}</p>
                <div className={cx('btn')}>{children}</div>
            </div>
            {desc != null &&
                <p className={cx('desc-line', desc2 === undefined ? 'desc2' : '')}>{desc}</p>}
            {desc2 != null &&
                <p className={cx('desc-line', 'desc2')}>{desc2}</p>}
        </>

    );
};

export default TitleBox;