import {useInjection} from 'inversify-react';
import {DialogManager} from '../../components/dialog-manager';
import {AuthStore} from '../auth/auth-store';
import {CustomerApi} from './customer-api';
import {useOpenPaymentDialog} from "../payment/open-payment-hook";

export const useCredit = () => {
    const authStore = useInjection(AuthStore);
    const dialogManager = useInjection(DialogManager);

    const [openRegularPayment, openAddCreditPayment] = useOpenPaymentDialog();

    const buyCredit = () => {
        if (authStore.isRegularSubscription) {
            openAddCreditPayment();
        } else {
            openRegularPayment();
        }
    }
    const check = (fun?: (includeMakeCorpReg: boolean) => void) => {
        if (!fun || authStore.getCredit() <= 0) {
            dialogManager.confirm(authStore.isRegularSubscription ? '이용권이 부족합니다.' : '스마트 구독형 상품 결제를 진행해 주세요.', '',
                buyCredit,
                '이용권 구매하기');
        } else {
            fun?.(authStore.isIncludeCorpMake);
        }
    }

    return [check, authStore.getCredit];
}

export const useRefreshCredit = () => {
    const authStore = useInjection(AuthStore);
    const customerApi = useInjection(CustomerApi);

    const refreshCredit = async () => {
        const creditResult = await customerApi.getCredit();

        if (creditResult.success) {
            authStore.setCredit(creditResult.val!);
        }
    }

    return [refreshCredit];
}