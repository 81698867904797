import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {PrVersion} from "./dto/pr-version";
import {PrGroupDto} from "./dto/pr-group-dto";
import {SystemPopup} from "../popup/dto/system-popup";
import {PrSearchMemberDto} from "./dto/pr-search-member-dto";

@injectable()
export class PrEtcApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    getVer = async () => {
        return await this.apiCall.fetch<PrVersion>(`/api/etc/ver`);
    }

    getGroup = async (groupId: string) => {
        return await this.apiCall.fetch<PrGroupDto>(`/api/etc/group/${groupId}`);
    }

    getPopup = async () => {
        return await this.apiCall.fetch<SystemPopup[]>(`/api/etc/popup`);
    }

    getPopupByCode = async (code: string) => {
        return await this.apiCall.fetch<SystemPopup>(`/api/etc/popup/${code}`);
    }

    /**
     * 회원을 검색한다.
     */
    searchMember = async (name: string) => {
        return await this.apiCall.fetch<PrSearchMemberDto[]>(`/api/etc/member/search?name=${encodeURIComponent(name)}`);
    }
}
