import {Chart} from 'chart.js';

type LabelWithImagePluginOptions = {
    width: number
    height: number
    // data?: number[]
    offset: number
}

const image = new Image();
image.src = '/images/report/orange_arrow_down.png';

function handler(chart: Chart<'bar'>, args: any, options: LabelWithImagePluginOptions) {
    const {ctx} = chart;

    if (image.complete) {
        ctx.save();
        const meta = chart.getDatasetMeta(0);
        meta.data.forEach((it, i) => {
            const pos = it.tooltipPosition();
            if(chart.data.datasets[0].data[i] !== null) {
                ctx.drawImage(image, pos.x - (options.width / 2), pos.y - options.offset, options.width, options.height);
            }
        });
        ctx.restore();
    } else {
        image.onload = () => {
            chart.render();
        }
    }
}

const LabelWithImage = {
    id: 'labelWithImage',
    afterDatasetDraw: handler
}

export default LabelWithImage