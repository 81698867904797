import { Field, Form, Formik } from "formik";
import styles from './add-list-pop.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface DirectInputPopProps {
    title: string;
    width: string;
    placeholder?: string;
    hasUnit?: boolean;
    fieldName: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    onClose: VoidFunction;
}

const DirectInputPop = ({title, width, placeholder, fieldName, setFieldValue, onClose}:DirectInputPopProps) => {
    return (
        <div className='full-background' onClick={onClose}>
            <div className={`pop-inner ${cx('edit-stage-list-pop')}`} onClick={(e:any) => e.stopPropagation()}>
                <Formik
                    initialValues={{
                        item: ''
                    }}
                    onSubmit={values => {
                        setFieldValue(fieldName, values.item);
                        onClose();
                    }}>
                    {({values}) => (
                        <Form>
                            <p className={cx('stage-list-title')}>{title}</p>
                            <div className={cx('input-only')}><Field name='item' placeholder={placeholder}/></div>
                            <div className={cx('btn-area')}>
                                <button className={cx('add-btn')} type='button' onClick={onClose}>닫기</button>
                                <button className={cx('save-btn')} type='submit'><i></i>적용하기</button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default DirectInputPop;