import {useInjection} from 'inversify-react';
import {ChartManager} from './chart-manager';
import {observer} from 'mobx-react-lite';
import {toJS} from 'mobx';

export const ChartContainer = observer(({document}: { document?: JSX.Element }) => {
    const chartManager = useInjection(ChartManager);
    return <div style={{display: 'none'}}>
        {document}
        {toJS(chartManager.charts)/*변환하지 않으면 렌더링 안 됨*/}
    </div>
});

