import styled from 'styled-components';
import colors from '../../scss/themes/colors.module.scss';

interface PrimaryButtonProps {
    width?: string;
}

const PrimaryButton = styled.button<PrimaryButtonProps>`
    display: block;
    width: 100%;
    height: 4.6rem;
    border-radius: .6rem;
    background-color: ${colors.main};
    font-size: 1.5rem;
    color: #fff;

    :disabled {
        background-color: #b1c6ff;
    }
`

export default PrimaryButton;