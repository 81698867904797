import styles from './corporate-regi-copy.module.scss';
import classnames from 'classnames/bind';
import CorporateRegiCopyTable, {OthersInfo} from './corporate-regi-copy-table';
import {PrCorpRegRoot} from "../../../model/customer/dto/pr-corp-reg-root";
import {SummaryReport} from "../../../model/inquiry/dto/summary-report";
import {usePdfComponents} from "../pdf-hooks";
import {getMultiplyValue} from "../../../styles/styleSheet";
import {StyleSheet} from "@react-pdf/renderer";
import {CorporateRegStyleContext} from "./corporate-regi-context";
import React, {useEffect, useState} from "react";
import {Customer} from "../../../model/customer/dto/customer";
import CorporateRegiCopyAll from "./corporate-regi-copy-all";
import {PrCorpRegAll} from "../../../model/customer/dto/pr-corp-reg-all";
import {useInjection} from "inversify-react";
import {CustomerApi} from "../../../model/customer/customer-api";
import PdfDownload from "../pdf-download";
import {PdfCorpRegRoot} from "./pdf-corp-reg-root";
import LoadingDialog from "../../../components/loading-dialog";
import {asyncCallNonMount} from "../../../util/util";
import {toast} from "react-toastify";
import {CustomerDetailTab} from "../../../data/const_list";
import PaymentWindowPop from "../../../components/payment-window-pop";
import {DialogManager} from "../../../components/dialog-manager";
import {AuthStore} from "../../../model/auth/auth-store";
import {useNavigate} from "react-router-dom";
import {PaymentApi} from "../../../model/payment/payment-api";
import NoInfo from "../../../components/no-info";

const cx = classnames.bind(styles);

const CorporateRegiNotExist = () => {
    return (
        <div>말소사항 없음</div>
    );
};
export const CorporateRegiCopy = ({corpReg, summary, customer, corpRegAll}: {
    summary: SummaryReport,
    customer: Customer,
    corpReg?: PrCorpRegRoot,
    corpRegAll?: PrCorpRegAll
}) => {
    const {isPdf} = usePdfComponents();
    const num = getMultiplyValue(isPdf);
    const navigate = !isPdf ? useNavigate!() : null;

    const customerApi = useInjection(CustomerApi);
    const paymentApi = useInjection(PaymentApi);
    const dialogManager = useInjection(DialogManager);
    const authStore = useInjection(AuthStore);
    const [loadCorpRegAll, setLoadCorpRegAll] = useState(corpRegAll);
    const [loading, setLoading] = useState(false);
    const [noCorpRegAll, setNoCorpRegAll] = useState(false);

    const loadData = async () => {
        setLoading(true);
        const result = await customerApi.corpRegAll(customer.id);
        setLoading(false);

        if (result.success) {
            setLoadCorpRegAll(result.val!);
        }
        else {
            setNoCorpRegAll(true);
        }
    }

    const updateWork = (payId: string) => {
        const key = dialogManager.open(LoadingDialog, {aniW: '10.0rem', aniH: '10.0rem', jsonFile: 'loading', title: '잠시만 기다려 주세요.'});

        asyncCallNonMount(customerApi.refreshCorp, [customer.id, payId], result => {
            authStore.setCredit(result.credit!);
            dialogManager.close(key);
            toast.success("업데이트가 완료되었습니다.");

            // 고객 이동
            // navigate!('/customer/customerState', {state: {customerId: result.customerId!, tab: CustomerDetailTab.RegCorpCopy}});

            navigate!(`/customer/customerState/${result.customerId!}/${CustomerDetailTab.RegCorpCopy}`);
        }, (e) => {
            dialogManager.close(key);
        });
    }

    const update = () => {
        const coRegNo = summary.KED5002.CONO_PID ?? "";

        if (coRegNo.replaceAll("-", "").length !== 13) {
            return dialogManager.alert("법인번호 13자리를 확인해주세요.");
        }

        dialogManager.confirm(
            '법인등기부등본을 다시 조회하시겠어요?',
             '(카드간편결제 등록시 해당 정보로 결제 진행)',
            async  () => {
                const key = dialogManager.open(LoadingDialog, {aniW: '10.0rem', aniH: '10.0rem', jsonFile: 'loading', title: '잠시만 기다려 주세요.'});
                const result = await paymentApi.autoPay('CORP_REG');

                dialogManager.close(key);

                if (result.success) {
                    dialogManager.alert("결제완료", "법인등기부등본을 조회합니다.", () => {
                        updateWork(result.data!);
                    });
                }
                else {
                    dialogManager.open(PaymentWindowPop, {
                        id: 'CORP_REG', completePayment: (payId: string) => {
                            updateWork(payId);
                        }, hideCash: true
                    });
                }
            },'확인',null,'추가 결제가 필요합니다.'
        );
    }

    // const [loading, data] = usePromise(() => customerApi.corpRegAll(customer.id), [customerApi, customer]);
    useEffect(() => {
        if (!isPdf && corpRegAll == null) {
            loadData();
        }
    }, [corpRegAll, isPdf]);

    const [select, setSelect] = useState(corpRegAll ? 1 : 0);

    const depStyles = StyleSheet.create({
        titleCover: {
            width: 156 * num,
            alignItems: 'flex-start',
            lineHeight: 1.7 * num,
            paddingTop: 8 * num
        },
        dep1: {
            textIndent: 13 * num
        },
        dep2: {
            textIndent: 26 * num
        },
        dep3: {
            textIndent: 39 * num
        },
        dep4: {
            textIndent: 52 * num
        },
        txtCenter: {
            textAlign: 'center'
        },
        cancelLine: {
            textDecoration: 'line-through'
        }
    });

    if (!isPdf && (loading || (corpReg && corpReg!.existExp && !loadCorpRegAll))) {
        // return <LottieComp width='10.0rem' height='10.0rem' jsonFile='loading'/>
        return <div></div>
    }

    return (
        <CorporateRegStyleContext.Provider value={depStyles}>
            <div className={cx('corporate-regi-copy')}>
                <div className={cx('title-area')}>
                    <p className={cx('title')}>
                        법인등기부등본
                    </p>
                    {!isPdf && <button className={cx('update-btn')} onClick={update}>
                        <i></i>다시 조회하기
                    </button>}
                </div>
                {
                    customer.incCorp ?
                        <>
                            <div className={cx('flex-box')}>
                                <div className={cx('tab-area')}>
                                    <button onClick={() => setSelect(0)} className={cx(select === 0 ? 'on' : '')}>현재
                                        유효사항
                                    </button>
                                    {corpReg && corpReg!.existExp &&
                                        <button onClick={() => setSelect(1)}
                                                className={cx(select === 1 ? 'on' : '')}>말소사항
                                            포함</button>}
                                </div>

                                {!isPdf && select === 0 && <PdfDownload
                                    title='현재 유효사항 인쇄'
                                    document={<PdfCorpRegRoot corpReg={corpReg} summary={summary}
                                                              customer={customer}/>}
                                    fileName={'법인등기부등본(' + (summary.KED5002.BZNO) + ').pdf'}
                                    className={cx('download-btn')}/>}

                                {!isPdf && select === 1 && <PdfDownload
                                    title='말소사항 포함 인쇄'
                                    document={<PdfCorpRegRoot corpRegAll={loadCorpRegAll} summary={summary}
                                                              customer={customer}/>}
                                    fileName={'법인등기부등본(' + (summary.KED5002.BZNO) + ')(말소).pdf'}
                                    className={cx('download-btn')}/>}
                            </div>
                            {
                                corpRegAll ? <CorporateRegiCopyAll customer={customer} summary={summary}
                                                                   corpReg={corpRegAll}/> : <>
                                    {(select === 0) ? <>
                                            <CorporateRegiCopyTable corpReg={corpReg!} summary={summary}/>
                                            <OthersInfo corpReg={corpReg!}/>
                                        </> :
                                        <>
                                            {corpReg!.existExp && !noCorpRegAll ?
                                                <CorporateRegiCopyAll customer={customer} summary={summary}
                                                                      corpReg={loadCorpRegAll!}/> :
                                                <CorporateRegiNotExist/>}
                                        </>}
                                </>
                            }
                        </>
                        :
                        <div className={cx('no-data')}>
                            <NoInfo text={"법인등기부등본 조회 이력이 없습니다."}/>
                        </div>
                }
            </div>
        </CorporateRegStyleContext.Provider>
    );
};
