import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {ApiResult} from '../api-result';
import {Group} from './dto/group';
import {CustomerList} from './dto/customer-list';
import {CustomerDetail} from './dto/customer-detail';
import {Keyman} from './dto/keyman';
import {ShareholderList} from './dto/shareholder-list';
import {RepInfo} from './dto/rep-info';
import {Customer, CustomerType, PrCustomerListDto} from './dto/customer';
import {CustomerMainDto} from "./dto/customer-main-dto";
import {CounselingLogDto} from './dto/counseling-log-dto';
import {CounselingStage} from './dto/counseling-stage';
import {CounselingLog} from './dto/counseling-log';
import {MngInfoList} from './dto/mng-info-list';
import {PrFrStat} from './dto/financial-statement';
import {CreditDto} from './dto/credit-dto';
import {IndustryCls} from './dto/industry-cls';
import {CorpRegSelectDto} from './dto/corpReg/corp-reg-select-dto';
import {FsCodeInputDto} from "./dto/fs-code-dto";
import {ModifyCompanyInfoForm} from "./form/modify-company-info-form";
import {CustomerRegisterResult} from "./dto/customer-register-result";
import {Page} from "../common/dto/page";
import {makeSearchDataQs} from "../../util/str-util";
import {PrCorpRegAll} from "./dto/pr-corp-reg-all";
import {PrCustomerRawData} from "./dto/customer-raw-data";
import {CustomerSearchItem} from "./dto/customer-search-item";
import {FsLoadResult} from "./dto/fs_load_result";

@injectable()
export class CustomerApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    /**
     * 고객 등록
     * @param coid 회사아이디
     * @param cono 법인번호
     * @param payId 결제 아이디
     */
    registerV2 = async (coid?: string, cono?: string, incCorp?: string, payId?: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerRegisterResult>>('/customer/v2/register', {
            coid,
            cono,
            payId,
            incCorp
        });
    }

    /**
     * 수동 등록 - 법인
     * @param cono 법인번호
     * @param payId 결제 아이디
     */
    registerManual = async (cono: string, payId?: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerRegisterResult>>('/customer/v2/register/manual', {
            cono,
            payId
        });
    }

    /**
     * 수동 등록 - 개인
     * @param enpNm 기업명
     * @param repNm 대포명
     * @param birth 생년월일
     * @param bno 사업자등록번호
     */
    registerIndividual = async (enpNm: string, repNm: string, birth: string, bno: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerRegisterResult>>('/customer/v2/register/individual', {
            enpNm,
            repNm,
            birth,
            bno
        });
    }

    /**
     * 개인 사업자 등록
     */
    registerInd = async (coid?: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerRegisterResult>>('/customer/v2/register/ind', {
            coid,
        });
    }

    getCredit = async (): Promise<ApiResult<CreditDto>> => {
        return await this.apiCall.fetch<ApiResult<CreditDto>>('/customer/credit');
    }

    corpRegSelect = async (): Promise<ApiResult<CorpRegSelectDto>> => {
        return await this.apiCall.fetch<ApiResult<CorpRegSelectDto>>('/customer/corpRegSelect');
    }

    buyCredit = async (): Promise<ApiResult<CreditDto>> => {
        return await this.apiCall.fetch<ApiResult<CreditDto>>('/customer/credit/buy');
    }

    list = async () => {
        return await this.apiCall.fetch<ApiResult<CustomerList>>('/customer/list');
    }

    search = async (keyword: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerSearchItem[]>>(`/customer/search?keyword=${keyword}`);
    }

    recent = async (enpTyp: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerSearchItem[]>>(`/customer/recent/${enpTyp}`);
    }

    recentByMonth = async() => {
        return await this.apiCall.fetch<ApiResult<CustomerSearchItem[]>>(`/customer/recent/month`);
    }

    getMain = async () => {
        return await this.apiCall.fetch<ApiResult<CustomerMainDto>>('/customer/main')
    }

    delCustomer = async (customer: string[]) => {
        return await this.apiCall.fetch<ApiResult<CustomerList>>('/customer/del', {customer: customer});
    }

    delCustomerV2 = async (customer: string[]) => {
        return await this.apiCall.fetch<ApiResult<boolean>>('/customer/v2/del', {customer: customer});
    }

    setGroup = async (group: string, customer: string[]) => {
        return await this.apiCall.fetch<ApiResult<CustomerList>>('/customer/group', {
            group: group,
            customer: customer
        });
    }

    setGroupV2 = async (group: string, customer: string[]) => {
        return await this.apiCall.fetch<ApiResult<boolean>>('/customer/v2/group', {
            group: group,
            customer: customer
        });
    }

    detailV2 = async (customerId: string, loadType?: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerDetail>>(`/customer/v2/detail/${customerId}?loadType=${loadType}`);
    }

    fsLoadResult = async (customerId: string) => {
        return await this.apiCall.fetch<ApiResult<FsLoadResult>>(`/customer/v2/loadFs/${customerId}`);
    }

    rawData = async (customerId: string) => {
        return await this.apiCall.fetch<ApiResult<PrCustomerRawData>>(`/customer/v2/rawData/${customerId}`);
    }

    corpRegAll = async (customerId: string) => {
        return await this.apiCall.fetch<ApiResult<PrCorpRegAll>>(`/customer/v2/corpRegAll/${customerId}`);
    }

    saveRep = async (customerId: string, data: RepInfo & { id?: string }) => {
        return await this.apiCall.json<ApiResult<RepInfo[]>>('/customer/rep?id=' + customerId, data);
    }

    saveKeyman = async (customerId: string, data: Keyman & { id?: string }) => {
        return await this.apiCall.json<ApiResult<Keyman>>('/customer/keyman?id=' + customerId, data);
    }

    getIndustryCls = async () => {
        return await this.apiCall.fetch<ApiResult<IndustryCls[]>>('/customer/industryCls',);
    }

    /**
     * 기업 정보 수정
     */
    saveCompanyInfo = async (customerId: string, data: ModifyCompanyInfoForm) => {
        return await this.apiCall.json<ApiResult<CustomerDetail>>('/customer/info?id=' + customerId, data);
    }

    saveShareholderList = async (customerId: string, data: ShareholderList) => {
        return await this.apiCall.json<ApiResult<ShareholderList>>('/customer/shareholder?id=' + customerId, data);
    }

    saveMngInfoList = async (customerId: string, data: MngInfoList) => {
        return await this.apiCall.json<ApiResult<CustomerDetail>>('/customer/management?id=' + customerId, data);
    }


    getEditFinancialStmtV2 = async (customerId: string) => {
        return await this.apiCall.fetch<ApiResult<PrFrStat[]>>(`/customer/v2/financialStmt/${customerId}/editor`);
    }

    // saveFinancialStmt = async (customerId: string, data: FinancialStatement) => {
    //     return await this.apiCall.json<ApiResult<FinancialStatement[]>>('/customer/financialStmt?id=' + customerId, data);
    // }

    /**
     * 재무 재표 저장 V2
     */
    saveFinancialStmtV2 = async (customerId: string, data: PrFrStat) => {
        return await this.apiCall.json<ApiResult<PrFrStat[]>>(`/customer/v2/financialStmt/${customerId}`, data);
    }

    makeFrStatV2 = async (customerId: string, year: number) => {
        return await this.apiCall.json<ApiResult<string>>(`/customer/v2/financialStmt/${customerId}/${year}`, {});
    }

    /**
     * 수동 재무 제표를 삭제한다.
     * @param customerId
     * @param year
     */
    deleteManualFinancialStmt = async (customerId: string, year: number) => {
        return await this.apiCall.delete<ApiResult<PrFrStat[]>>(`/customer/v2/financialStmt/${customerId}/${year}`);
    }

    deleteFinancialStmt = async (customerId: string, year: number) => {
        return await this.apiCall.delete<ApiResult<PrFrStat[]>>(`/customer/v2/financialStmt2/${customerId}/${year}`);
    }

    /**
     * 입력용 코드를 리턴한다.
     */
    getInputFsCode = async () => {
        return await this.apiCall.fetch<ApiResult<{[key: string]: FsCodeInputDto[]}>>(`/customer/v2/fsCode/input`);
    }

    // updateFinancialStmt = async (customerId: string, year: number) => {
    //     return await this.apiCall.fetch<ApiResult<FinancialStmtListDto>>('/customer/financialStmt/update', {
    //         id: customerId,
    //         year: year
    //     });
    // }

    updateFinancialStmtV3 = async (customerId: string, payId?: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerRegisterResult>>('/customer/v3/financialStmt/update', {
            id: customerId,
            payId
        });
    }

    updateFsHometax = async (customerId: string, ownerHometaxData: string, workYear: number) => {
        return await this.apiCall.fetch<ApiResult<PrFrStat>>(`/customer/financialStmt/${customerId}/hometax`, {
            ceoMemberNo: ownerHometaxData,
            workYear: workYear
        });
    }

    excelFsStat = async (customerId: string) => {
        return await this.apiCall.fetch<ApiResult<string>>(`/customer/fsStat/excel/${customerId}`);
    }

    getHometaxState = async (connectId: string) => {
        return await this.apiCall.fetch<ApiResult<string>>(`/customer/hometax/state/${connectId}`);
    }

    hometaxFrStatExcel = async (connectId: string, type: string) => {
        return await this.apiCall.fetch<ApiResult<string>>(`/customer/hometax/excel/frStat/${connectId}?type=${type}`);
    }

    hometaxStockTableExcel = async (connectId: string) => {
        return await this.apiCall.fetch<ApiResult<string>>(`/customer/hometax/excel/stock/${connectId}`);
    }

    getSettleYear = async (connectId: string, type: string) => {
        return await this.apiCall.fetch<ApiResult<string[]>>(`/customer/hometax/settleYear/${connectId}?type=${type}`);
    }

    renewalCustomer = async (customerId: string, payId?: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerRegisterResult>>('/customer/v3/renewalCustomer', {
            id: customerId,
            payId
        });
    }

    refreshCorp = async (customerId: string, payId?: string) => {
        return await this.apiCall.fetch<ApiResult<CustomerRegisterResult>>(`/customer/v2/refreshCorp/${customerId}`, {
            id: customerId,
            payId
        });
    }

    initGroup = async () => { //customerApi.initGroup을 매개변수로 줄 때 this가 동작하기 위해 arrow function 사용
        return await this.apiCall.fetch<ApiResult<Group[]>>('/group/init');
    }

    saveGroup = async (groupId: string | undefined | null, name: string, customer?: string[]) => {
        return await this.apiCall.fetch<ApiResult<CustomerList>>('/group/save', {
            id: groupId,
            name: name,
            customer: customer
        });
    }

    deleteGroup = async (groupId: string) => {
        return await this.apiCall.fetch<ApiResult<Group[]>>('/group/delete', {id: groupId});
    }

    listCounselingLog = async (pageNo: number, customerId: string) => {
        return await this.apiCall.fetch<ApiResult<CounselingLogDto>>(`/customer/counselingLog/${customerId}?page=${pageNo}&size=5`);
    }

    pagingCounselingLog = async (pageNo: number, customerId: string)=> {
        return await this.apiCall.fetch<ApiResult<Page<CounselingLog>>>(`/customer/counselingLog/${customerId}/paging?page=${pageNo}&size=5`);
    }

     saveCounselingStage = async (stages: CounselingStage[]) => {
        return await this.apiCall.json<ApiResult<CounselingStage[]>>('/customer/counselingLog/stage', stages);
    }

    saveCounselingLog = async (customerId: string, counselingLog: CounselingLog) => {
        return await this.apiCall.json<ApiResult<CounselingLog[]>>(`/customer/counselingLog/${customerId}`, counselingLog);
    }

    deleteCounselingLog = async (customerId: string, counselingLogId: string) => {
        return await this.apiCall.delete<ApiResult<CounselingLog[]>>(`/customer/counselingLog/${customerId}/${counselingLogId}`);
    }

    saveCounselingData = async (customerId: string, counselingLogId: string, fileId: string, documentName: string) => {
        return await this.apiCall.fetch<ApiResult<CounselingLog[]>>('/customer/counselingLog/attach/save',
            {logId: counselingLogId, fileId, customerId, documentName});

    }

    deleteCounselingData = async (customerId: string, counselingLogId: string, fileId: string) => {
        return await this.apiCall.delete<ApiResult<CounselingLog[]>>(`/customer/counselingLog/${customerId}/${counselingLogId}/${fileId}`);
    }

    getCustomerMain = async (pageNo: number, size: number, searchData: {[key: string]: string|undefined}) => {
        return await this.apiCall.fetch<ApiResult<CustomerMainDto>>(`/customer/main?page=${pageNo}&size=${size}${makeSearchDataQs(searchData)}`);
    }

    getCustomerPaging = async (pageNo: number, size: number, searchData: {[key: string]: string|undefined}) => {
        return await this.apiCall.fetch<ApiResult<Page<PrCustomerListDto>>>(`/customer/paging?page=${pageNo}&size=${size}${makeSearchDataQs(searchData)}`);
    }

    /**
     * 
     * 고객구분 체크 시, 실시간 업데이트
     * @param registerCus ( 기장고객 )
     * @param consultingCus ( 컨설팅고객 )
     * @param insuranceCus  ( 보험고객 )
     * 
     */
    updateCustomerType = async (customerId: string, customerType: CustomerType) => {
        return await this.apiCall.fetch<ApiResult<Boolean>>(`/customer/type/save/${customerId}`, customerType);
    }

    
    /**
     * 북마크 버튼 클릭 시, 실시간 업데이트
     * @param customerId
     * @param reportId
     */
    updateBookmark = async (customerId: string, reportId: string) => {
        return await this.apiCall.put<ApiResult<Customer>>(`/customer/bookmark`, { customerId: customerId, reportId: reportId });
    }

    makeDownloadFile = (fileId: string) => {
        return this.apiCall.getApiUrl() + `/temp/download?fileId=${fileId}`;
    }
}