import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {ApiResult} from "../api-result";
import {LectureProgressDto} from "./dto/lecture-progress-dto";

@injectable()
export class LectureProgressApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    registerAndUpdateProgress = async (lectureNo:number, partNo:number, memberNo:string, name: string, groupId:string, partLen:number, classCnt:number, totalTime:number, saveTime:number): Promise<ApiResult<string>> => {
        return await this.apiCall.json<ApiResult<string>>("/api/lectureProgress/register", {
            lectureNo: lectureNo,
            partNo: partNo,
            memberNo: memberNo,
            memberName: name,
            groupId: groupId,
            partLen: partLen,
            classCnt: classCnt,
            totalTime: totalTime,
            saveTime: saveTime
        });
    }

    checkProgress = async (memberNo:string):Promise<ApiResult<LectureProgressDto[]>> => {
        return await this.apiCall.fetch<ApiResult<LectureProgressDto[]>>(`/api/lectureProgress/checkProgress/${memberNo}`);
    }
}