import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {ApiResult} from '../model/api-result';
import {useMountedState} from 'react-use';

type GetClassT<C extends Promise<ApiResult<any>>> = C extends Promise<ApiResult<infer T>> ? T : unknown;

export const asyncCall = <F extends (...args: any[]) => any>(api: F, args: Parameters<F>, onResult: (result: GetClassT<ReturnType<F>>) => void, isMounted: () => boolean, onFail?: (msg: string) => void) => {
    (async () => {
        try {
            const result = await api(...args);
            if (isMounted()) {
                // console.log(result);

                if (result.success && result.val != null) {
                    onResult(result.val);
                } else if (!result.success) {
                    onFail?.(result.message);
                }
            }
        } catch (e) {
            console.log(e);
        }
    })();
}

export const asyncCallNonMount = <F extends (...args: any[]) => any>(api: F, args: Parameters<F>, onResult: (result: GetClassT<ReturnType<F>>) => void, onFail?: (msg: string) => void) => {
    (async () => {
        try {
            const result = await api(...args);
            if (result.success && result.val != null) {
                onResult(result.val);
            } else if (!result.success) {
                onFail?.(result.message);
            }
        } catch (e) {
            console.log(e);
        }
    })();
}

export const asyncCallPromise = <P extends ApiResult<T>, T>(promiseCreator: () => Promise<P>,
                                                                    onResult: (result: T) => void,
                                                                    isMounted: () => boolean,
                                                                    onFail?: (msg: string) => void) => {
    (async () => {
        try {
            const result = await promiseCreator();
            if (isMounted()) {
                if (result.success && result.val != null) {
                    onResult(result.val);
                } else if (!result.success) {
                    onFail?.(result.message);
                }
            }
        } catch (e) {
            console.log(e);
        }
    })();
}

export const useInit = <T>(api: (...args: any) => Promise<ApiResult<T>>, args: string[], initialState: T, onResult?: (result: T) => void)
    : [T, Dispatch<SetStateAction<T>>] => {
    const [data, setData] = useState<T>(initialState);
    const isMounted = useMountedState();
    useEffect(() => {
            (async () => {
                try {
                    const result = await api(...args);
                    if (isMounted()) {
                        if (result.success && result.val != null) {
                            onResult?.(result.val);
                            setData(result.val);
                        }
                    }
                } catch (e) {
                    console.log(e);
                }
            })();
        },
        // eslint-disable-next-line
        [isMounted, api]);
    return [data, setData];
}

export const usePagination: (startNum?: number, perPage?: number) => [number, Dispatch<SetStateAction<number>>, number, (n: number) => void] = (startNum: number = 0, perPage: number = 8) => {
    const [currentPage, setCurrentPage] = useState(startNum);
    const [listsPerPage, setListPerPage] = useState(perPage);

    const setListPerPage2 = (n: number) => {//현재 페이지 최상단 항목이 계속 보이도록 페이지 조정
        const start = currentPage * listsPerPage;
        setListPerPage(n);
        setCurrentPage(Math.floor(start / n));
    };

    return [currentPage, setCurrentPage, listsPerPage, setListPerPage2];
}

//앞에서 배열 페이징하기
export const  splitIntoChunk = (arr: any[], chunk: number) => {
    const result = [];

    for(let index=0; index < arr.length; index += chunk) {
        let tempArray;
        tempArray = arr.slice(index, index + chunk);
        result.push(tempArray);
    }

    return result;
}

export const currentLists = <T>(list: T[] | undefined, page: number | undefined, listsPerPage: number) => {
    if (!list) return null
    const start = (page ?? 0) * listsPerPage;
    const end = start + listsPerPage;
    return end > 50 ? null : list.slice(start, end);
};

export const useOutsideAlerter = (ref: React.RefObject<HTMLElement | null>, showSelectBox?: Dispatch<SetStateAction<boolean>>, showListBox?: React.Dispatch<React.SetStateAction<string>>) => {

    useEffect(() => {
        const handleClickOutside = (e: Event) => {
            if (ref.current && !ref.current!.contains(e.target as Node)) {
                showSelectBox?.(false);
                showListBox?.('');
            }
        };

        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [ref, showSelectBox, showListBox]);
}

export const mergeObject = (obj1: any, obj2: any) => {
    const isObject = (obj: any) => obj != null && typeof obj === 'object';
    const isObj1 = isObject(obj1);
    if (isObj1 && isObject(obj2)) {
        if (Array.isArray(obj1) && Array.isArray(obj2)) {
            return [...obj1, ...obj2];
        }
        const obj = Object.assign({}, obj1);
        for (let key in obj2) {
            if (obj[key] === undefined) obj[key] = obj2[key];
            else obj[key] = mergeObject(obj[key], obj2[key]);
        }
        return obj;
    } else return isObj1 ? obj1 : obj2;
}

export const arr = <T, R>(ar: T[] | undefined, fun: (ar: T[]) => R) => ar && ar.length > 0 ? fun(ar) : undefined;

export const isEmpty = (obj: any) => !obj || Object.keys(obj).length === 0;