import {SummaryReport} from '../model/inquiry/dto/summary-report';
import React, {FunctionComponent, useEffect, useMemo, useRef, useState} from 'react';
import {Argument} from 'classnames';
import PdfDownload from './report/pdf-download';
import {useInjection} from 'inversify-react';
import {DialogManager} from '../components/dialog-manager';
import {useNavigate} from 'react-router-dom';
import {PrFrStat} from "../model/customer/dto/financial-statement";
import {FsCodeDto} from "../model/customer/dto/fs-code-dto";
import {PdfFsRoot} from "./report/summary/pdf-fs-root";
import {Customer, FsLoadType} from "../model/customer/dto/customer";
import {RepInfo} from "../model/customer/dto/rep-info";
import {CustomerDetailTab} from "../data/const_list";

export interface TabItemProp {
    id: string;
    name: string;
    comp?: FunctionComponent<any>;
    props?: { [k: string]: any };
    onClick?: () => void | boolean;
    isShow?: () => boolean;
}

export const useTab = (list: TabItemProp[], isLoaded: any, cx: (...args: Argument[]) => string, initialTab?: any, edit?: boolean) => {
    const scrollElm = useRef<HTMLDivElement>(null);
    const [tab, setTab] = useState(initialTab ?? list[0].id);
    const editRef = useRef(edit);

    const setTab2 = (clsName: string) => {
        editRef.current = false;
        setTab(clsName);
        if (scrollElm.current) scrollElm.current.scrollTop = 0;
    };

    const menu = <ul className={cx('menu-area')}>
        {list
            .filter((it) => !it.isShow || (it.isShow()))
            .map((it, i) =>
            <li key={i} className={cx(it.id, {'on': tab === it.id})}
                onClick={() => {
                    if (!it.onClick || it.onClick()) setTab2(it.id)
                }}>
                <i></i><span>{it.name}</span></li>)}
    </ul>

    const content = useMemo(() => {
        const loaded = isLoaded && Object.keys(isLoaded).length > 0;
        const item = list.find(it => it.id === tab);
        return <div className={cx('scroll-area')} ref={scrollElm}>
            {loaded && item && item.comp && <item.comp {...item.props} edit={editRef.current}/>}
        </div>
    }, [cx, isLoaded, list, tab]);
    return [menu, content];
}

export const useCustomerState = () => {
    const dialogManager = useInjection(DialogManager);
    const navigate = useNavigate();
    return (msg: string, customerId: string, tab: string, edit?: boolean) => {
        dialogManager.confirm(msg, '',
            // () => navigate('/customer/customerState', {state: {customerId, tab, edit}}))
            () => navigate(`/customer/customerState/${customerId}/${tab}/${edit}`))
    };
}

// export const SummaryPdfDownloadV2 = ({
//                                        data,
//                                          corpReg,
//                                          fs, isTbl, bsTbl,
//                                        className
//                                    }: { data?: SummaryReport, corpReg: PrCorpRegRoot, fs?: PrFrStat[], bsTbl: FsCodeDto[], isTbl: FsCodeDto[] , className: string }) => {
//     return <PdfDownload className={className}
//                         title='상세재무정보 인쇄'
//                         document={data && <PdfReportRoot data={data} corpReg={corpReg} fs={fs} isTbl={isTbl} bsTbl={bsTbl}/>}
//                         fileName={'상세재무정보(' + (data?.KED5002.BZNO) + ').pdf'}/>
// }

export const FsPdfDownload = ({
                                   data,
                                   fs, isTbl, bsTbl,
                                   className,
                                  fsLoadType,
                                  customer
                               }: {data?: SummaryReport, fs?: PrFrStat[], bsTbl: FsCodeDto[], isTbl: FsCodeDto[] , className: string, fsLoadType: FsLoadType, customer: Customer }) => {

    return <PdfDownload className={className}
                        title='재무제표 인쇄'
                        document={data && <PdfFsRoot summary={data} fs={fs} isTbl={isTbl} bsTbl={bsTbl} fsLoadType={fsLoadType} customer={customer}/>}
                        fileName={'재무제표(' + (data?.KED5002.BZNO) + ').pdf'}/>
}

// export const CorpPdfDownload = ({
//                                     data,
//                                     corpReg,
//                                     customer,
//                                     className
//                                 }: {
//     data?: SummaryReport,
//     corpReg: PrCorpRegRoot,
//     customer: Customer,
//     className: string
// }) => {
//     return <PdfDownload className={className}
//                         title='법인등기부등본'
//                         document={data && <PdfCorpRegRoot corpReg={corpReg} summary={data} customer={customer}/>}
//                         fileName={'법인등기부등본(' + (data?.KED5002.BZNO) + ').pdf'}/>
// }