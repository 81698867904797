import React, {Dispatch, SetStateAction, useMemo} from 'react';
import classNames from 'classnames/bind';
import styles from './report-add-info-pop.module.scss';
import CompanyValueInfo from './company-value-info';
import SalaryInfo from './salary-info';
import InheritanceTaxInfo from './inheritance-tax-info';
import {useCustomerState, useTab} from './detail-common';
import {CoReportDto} from '../model/co-report/dto/co-report-dto';
import {CustomerDetailTab, ReportAddInfoTab} from '../data/const_list';
import FamilySharePlanV2 from "./family-share-plan-v2";
import StockTransferPlan from "./stock-transfer-plan";
import DividendPlan from "./dividend-plan";
import EmergencyFundsDesign from "./emergency-funds-design";
import LaborerNumInfo from "./laborer-num-info";
import EditResultOverviewChkbox from "./edit-result-overview-chkbox";
import ProvisionalPayment from "./provisional-payment";
import MainIssueList from "./main-issue-list";
import {isSoleReport} from "./report/report-util";

const cx = classNames.bind(styles);

export interface ReportAddInfoPopProps {
    data: CoReportDto;
    tab?: string;
    secondTab?: string;
    setData: Dispatch<SetStateAction<CoReportDto | undefined>>;
    onClose: VoidFunction;
}

const ReportAddInfoPop = ({data, tab, secondTab, setData, onClose}: ReportAddInfoPopProps) => {
    const customerState = useCustomerState();
    const tabList = useMemo(() => [
        {
            id: ReportAddInfoTab.Value,
            name: '기업가치 평가 정보',
            comp: CompanyValueInfo,
            props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        {
            id: ReportAddInfoTab.StockTransfer,
            name: '주식이동설계',
            comp: StockTransferPlan,
            props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        {
            id: ReportAddInfoTab.FamShare, name: '가족지분설계',
            onClick: () => {
                if (!data.customer.companyInfo.issStkCn) {
                    customerState('주식정보가 없습니다.\n기업개요 편집으로 이동할까요?',
                        data.customer.id, CustomerDetailTab.Summary, true);
                    return false;
                }
                if (!data.customer.shareholder.list) {
                    customerState('주주정보가 없습니다.\n주주현황 편집으로 이동할까요?',
                        data.customer.id, CustomerDetailTab.Member, true);
                    return false;
                }
                if (!data.fss || !data.report.ci.corpVal?.[0]?.marketValue) {
                    customerState('재무정보가 없습니다.\n재무정보 편집으로 이동할까요?',
                        data.customer.id, CustomerDetailTab.Financial, true);
                    return false;
                }
                return true;
            },
            comp: FamilySharePlanV2, props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        {
            id: ReportAddInfoTab.Dividend,
            name: '배당설계',
            comp: DividendPlan,
            props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        {
            id: ReportAddInfoTab.Salary,
            name: '급여 및 퇴직금 정보',
            comp: SalaryInfo,
            props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        // {id: ReportAddInfoTab.Risk, name: 'CEO유고시 리스크', comp: SalaryInfo, props: {data: data, setData: setData, onClose}},
        {
            id: ReportAddInfoTab.Emergency,
            name: '비상운영자금 설계',
            comp: EmergencyFundsDesign,
            props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        {
            id: ReportAddInfoTab.Provisional,
            name: '가지급금 정보',
            comp: ProvisionalPayment,
            props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        {
            id: ReportAddInfoTab.Inheritance,
            name: '상속세 계산 정보',
            comp: InheritanceTaxInfo,
            props: {data: data, setData: setData, onClose, secondTab: secondTab},
            isShow: () => {
                return !isSoleReport(data);
            }
        },
        {
            id: ReportAddInfoTab.LaborerNum,
            name: '근로자수 정보',
            comp: LaborerNumInfo,
            props: {data: data, setData: setData, onClose}
        },
        {
            id: ReportAddInfoTab.MainIssue,
            name: '사업경영 주요이슈',
            comp: MainIssueList,
            props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !(data.customer?.enpTyp && data.customer?.enpTyp === "1")
            }
        },
        {
            id: ReportAddInfoTab.editResultChk,
            name: '경영진단결과 체크박스',
            comp: EditResultOverviewChkbox,
            props: {data: data, setData: setData, onClose},
            isShow: () => {
                return !isSoleReport(data);
            }
        }
    ], [data, setData, onClose, customerState]);

    const [menu, content] = useTab(tabList, data, cx, tab);

    return (
        <div className='full-background'>
            <div className={`pop-inner ${cx('pop-wrap')}`}>
                <div className={cx('left-menu')}>
                    <div className={cx('company-logo')}>
                        <img src='/images/empty_logo.png' alt='로고 없을 경우'/>
                        <p className={cx('name')}>{data.customer?.enpNm}</p>
                        <p className={cx('add-info')}>추가입력정보</p>
                    </div>
                    <div className={cx('scroll-area')}>
                        {menu}
                    </div>
                </div>
                <div className={cx('right-tab')}>
                    {content}
                </div>
            </div>
        </div>
    );
};

export default ReportAddInfoPop;