import React, {useState} from 'react';
import {SummaryReport} from '../../../model/inquiry/dto/summary-report';
import MajorShareholderStatus from './major-shareholder-status';
import ManagementStatus from './management-status';
import TitleBox from '../../../components/title-box';
import EditShareholderInfo from '../../customer/edit-shareholder-info';
import {ShareholderList} from '../../../model/customer/dto/shareholder-list';
import {CustomerDetail} from '../../../model/customer/dto/customer-detail';
import EditManagementInfo from '../../customer/edit-management-info';
import {MngInfoList} from '../../../model/customer/dto/mng-info-list';
import {Customer} from '../../../model/customer/dto/customer';
import {isEmpty} from '../../../util/util';
import {useCustomerState} from '../../detail-common';
import {CustomerDetailTab} from '../../../data/const_list';
import {PrCorpRegRoot} from "../../../model/customer/dto/pr-corp-reg-root";


interface MemberStatProps {
    data: SummaryReport;
    customer?: Customer;
    shareholder?: ShareholderList;
    mng?: MngInfoList;
    setData?: React.Dispatch<React.SetStateAction<CustomerDetail>>;
    edit?: boolean;

    corpReg?: PrCorpRegRoot;
}

const MemberState = ({data, customer, setData, corpReg, edit: editMode = false}: MemberStatProps) => {
    const customerState = useCustomerState();
    const [edit, setEdit] = useState(editMode);
    const [edit2, setEdit2] = useState(false);

    const shareholder = customer?.shareholder;

    const list = shareholder ? shareholder.list : data.KED5013;

    const refDate = shareholder ? shareholder.refDt : data.KED5013?.[0]?.STD_DT;
    const mng = customer?.mng;
    const refDate2 = mng ? mng.refDt : data.KED5008?.[0]?.STD_DT;

    return edit && customer && setData ?
        <EditShareholderInfo customerId={customer.id}
                             data={shareholder && !isEmpty(shareholder) ? shareholder : {} as ShareholderList}
                             issStkCn={customer.companyInfo.issStkCn}
                             summary={data}
                             isConnectedCorpReg={!!corpReg}
                             setData={setData}
                             onClose={() => setEdit(false)}/> :
        (edit2 && customer && setData ?
            <EditManagementInfo customerId={customer.id}
                                data={mng ?? {} as MngInfoList}
                                isConnectedCorpReg={!!corpReg}
                                setData={setData}
                                onClose={() => setEdit2(false)}/> :
            <>
                <TitleBox title='주주 현황' paddingTop='4.0rem'>
                    <button className='edit-btn' onClick={() => {
                        if (!customer?.companyInfo.issStkCn) {
                            customerState('주식정보가 없습니다.\n기업개요 편집으로 이동할까요?',
                                customer?.id ?? '', CustomerDetailTab.Summary, true);
                        } else
                            setEdit(true)
                    }}><i></i><span>수정</span></button>
                </TitleBox>
                <MajorShareholderStatus data={list} refDate={refDate} report={data}/>
                <TitleBox title='임원 현황'>
                    <button className='edit-btn' onClick={() => setEdit2(true)}><i></i><span>수정</span></button>
                </TitleBox>
                <ManagementStatus data={data} ked5008={mng?.list} refDate={refDate2} detail={true} corpReg={corpReg}/>
            </>);
};

export default MemberState;
