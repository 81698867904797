import { inject, injectable } from "inversify";
import { ApiCall } from "../api-call";
import { ApiResult } from '../api-result';
import { makeSearchDataQs } from "../../util/str-util";
import { FaqDto } from "./dto/pr-faq-dto";
import { Page } from "../common/dto/page";
import { CommonCode } from "../common/dto/common-code";

@injectable()
export class FaqApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;


    /**
     * 
     * FAQ 리스트
     * @param codeNo 카테고리 넘버
     * @param title 제목
     * 
     */
    pagingFaq = async (pageNo: number, searchData: {[key: string]: string | undefined}) => {
        return await this.apiCall.fetch<Page<FaqDto>>(`/api/faq/faqs?page=${pageNo}&${makeSearchDataQs(searchData)}`);
    }

    getFaq = async (no: number) => {
        return await this.apiCall.fetch<FaqDto>(`/api/faq/${no}`);
    }

    getFaqCategoryList = async() => {
        return await this.apiCall.fetch<CommonCode[]>(`/api/faq/categoryList`);
    }
}