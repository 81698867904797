import styles from './policy-fund-tab.module.scss';
import classnames from 'classnames/bind';
import TitleBox from "../../components/title-box";
import { Customer } from '../../model/customer/dto/customer';

const cx = classnames.bind(styles);

const PolicyFundTab = ({customer} : {customer: Customer}) => {
    return(
        <div className={cx('policy-fund-tab')}>
            <TitleBox title='맞춤형 정책자금' paddingTop='4.0rem'></TitleBox>
            <div className={cx('content')}>
                <p className={cx('illust')}></p>
                <p className={cx('sub-title')}>CEO고객에게 <span className={cx('color')}>씨오톡 연결</span>을 요청해 보세요</p>
                <p className={cx('text')}>
                    CEO고객이 <span className={cx('color')}>씨오톡 앱 회원가입 후 [전문가파트너 연결]</span>을 승인하면 알림봇이 제공하는<br/>해당 기업의 맞춤형 정부지원사업 및 고용지원금 추천 내용을 공유 받을 수 있습니다.
                </p>
            </div>
        </div>
    );
}

export default PolicyFundTab;