import ReactPDF, {StyleSheet} from '@react-pdf/renderer';
import {summaryColT2Font, summaryFont} from './report-font';

export const getMultiplyValue = (isPdf: boolean) => isPdf || !window.matchMedia("(min-width: 1320px)").matches ? 1 : 1.2;

export const getRowT = (isPdf: boolean): ReactPDF.Styles => {
    let n = getMultiplyValue(isPdf);

    return StyleSheet.create({
        flexWrap: {
            width: '100%',
            borderTop: `${n}px solid #999`,
            fontSize: summaryFont * n,
        },
        flexLine: {
            display: 'flex',
            flexDirection: 'row',
            borderBottom: `${0.5 * n}px solid #ccc`,
            minHeight: 36 * n,
        },
        flexNoLine: {
            display: 'flex',
            flexDirection: 'row',
            minHeight: 36 * n,
        },
        titleCover: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: '#f2f2f2',
            width: 137 * n,
        },
        title2Cover: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'flex-start',
            backgroundColor: '#f2f2f2',
            width: 162 * n,
        },
        title: {
            textIndent: 22 * n,
            color: '#000',
        },
        descCover: {
            flex: 1,
            padding: `${8 * n}px 0`,
        },
        desc2Cover: {
            flex: 0.8,
            padding: `${8 * n}px 0`,
        },
        desc: {
            padding: `0 ${30 * n}px 0 ${33 * n}px`,
            lineHeight: 1.7 * n
        },
        borderB: {
            borderBottom: `${0.5 * n}px solid #ccc`,
        },
        borderR: {
            borderRight: `${0.5 * n}px solid #ccc`,
        },
        borderT: {
            borderTop: `${0.5 * n}px solid #ccc`,
        },
    });
}

export const getColT = (isPdf: boolean): ReactPDF.Styles => {
    let n = getMultiplyValue(isPdf);

    return StyleSheet.create({
        flexWrap: {
            width: '100%',
            borderTop: `${n}px solid #999`,
            fontSize: summaryFont * n
        },
        flexLine: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            minHeight: 36 * n,
            textAlign: 'center',
            borderBottom: `${0.5 * n}px solid #ccc`,
        },
        headBox: {
            height: 46 * n,
            backgroundColor: '#f2f2f2',
            border: 'none'
        },
        flexHead: {
            flex: 1,
            color: '#000000',
            alignSelf: 'center'
        },
        flexBody: {
            flex: 1,
            padding: `${8 * n}px 0`,
            lineHeight: 1.7 * n
        },
        grayBody: {
            backgroundColor: '#f2f2f2',
        },
        footNote: {
            fontSize: 12 * n,
            lineHeight: 1.9 * n,
            color: "#666"
        },
        txtLeft: {
            textAlign: 'left',
            paddingLeft: 22 * n
        },
        txtRight: {
            textAlign: 'right',
            paddingRight: 22 * n
        },
        /// 리포트 연혁 테이블 커스텀
        flex02: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 170 * n,
            borderRight: `${0.5 * n}px solid #ccc`
        },
    });
}

export const getColT2 = (isPdf: boolean): ReactPDF.Styles => {
    let n = getMultiplyValue(isPdf);

    return StyleSheet.create({
        table: {
            fontSize: summaryColT2Font * n,
        },
        flexWrap: {
            width: '100%',
            border: `${n}px solid #ccc`,
        },
        flexLine: {
            display: 'flex',
            flexDirection: 'row',
        },
        flexHead: {
            display: 'flex',
            alignItems: 'center',
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            height: 30 * n,
            textAlign: 'right',
            borderRight: `${0.5 * n}px solid #ccc`,
            borderBottom: `${0.5 * n}px solid #ccc`,
            backgroundColor: '#999',
            color: '#fff',
            fontWeight: 700,
            fontSize: 14 * n
        },
        head1st: {
            justifyContent: 'center',
            textAlign: 'center',
        },
        col1st: {
            flex: 1.3
        },
        flexBody: {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            minHeight: 30 * n,
            textAlign: 'right',
            flex: 1,
            borderRight: `${0.5 * n}px solid #ccc`,
            borderBottom: `${0.5 * n}px solid #ccc`,
        },
        body1st: {
            justifyContent: 'flex-start',
            alignContent: 'center',
            textAlign: 'left'
        },
        txtPadRight: {
            paddingRight: 22 * n
        },
        txtPadLeft: {
            paddingLeft: 22 * n
        },
        dep1: {
            backgroundColor: '#d0cece',
            fontWeight: 700,
        },
        dep2: {
            backgroundColor: '#f2f2f2',
        },
        dep3: {},
        dep4: {},
        dep5: {},
        dep1Txt: {textIndent: 22 * n},
        dep2Txt: {
            textIndent: 22 * n,
            fontWeight: 700
        },
        dep3Txt: {
            textIndent: 30 * n,
            fontWeight: 500
        },
        dep4Txt: {
            textIndent: 38 * n,
        },
        dep5Txt: {
            textIndent: 46 * n,
            color: '#404040'
        },
    });
}

export const getColT2_1 = (isPdf: boolean): ReactPDF.Styles => {
    let n = getMultiplyValue(isPdf);

    return StyleSheet.create({
        dep1: {
            backgroundColor: '#f2f2f2',
        },
        dep2: {},
        dep3: {},
        dep4: {},
        dep5: {},
        dep1Txt: {
            textIndent: 22 * n,
            fontWeight: 700
        },
        dep2Txt: {
            textIndent: 30 * n,
        },
        dep3Txt: {
            textIndent: 38 * n,
            color: '#404040'
        },
        dep4Txt: {},
        dep5Txt: {},
    });
}
