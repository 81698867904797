import React from 'react';
import styles from './pagination.module.scss';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);

interface PaginationProps {
    listLen: number;
    listsPerPage: number;
    currentPage: number;
    setCurrentPage: (page: number) => void;
    showPages?: number;
    zeroStart?: boolean;
}

const Pagination = ({listLen, listsPerPage, currentPage, setCurrentPage, showPages, zeroStart = true}: PaginationProps) => {
    const showPagesPer = showPages ?? 5;
    const currentPg = zeroStart ? currentPage + 1 : currentPage;
    const startPage = currentPg - ((currentPg - 1) % showPagesPer);
    const lastPage = Math.max(Math.ceil(listLen / listsPerPage), 1);
    const pageNumbers: number[] = [];

    for (let i = startPage; i < (startPage + showPagesPer); i++) {
        pageNumbers.push(i);
        if (i === lastPage) break;
    }

    const setPage = (num: number) => {
        setCurrentPage(num - (zeroStart ? 1 : 0));
    };

    const prev = (num: number) => {
        if (currentPg - num > 0) {
            setPage(currentPg - num);
        } else {
            setPage(1);
        }
    }

    const next = (num: number) => {
        if (currentPg + num <= lastPage) {
            setPage(currentPg + num);
        } else {
            setPage(lastPage);
        }
    }

    return (
        <div className={cx('pagination')}>
            <button onClick={() => prev(showPagesPer)} type='button'>
                <img src='/images/arrow2_left.png' alt='왼쪽 화살표'/></button>
            <button onClick={() => prev(1)} type='button'>
                <img src='/images/arrow_left.png' alt='왼쪽 화살표'/></button>
            <ul className={cx('numbering')}>
            {   pageNumbers.length > 0 ?
                    pageNumbers.map((num) =>
                        <li key={num} onClick={() => setPage(num)}
                            className={cx(((num) === currentPg) && 'on')}>{num}</li>)
                        : <li className={cx('on')}>1</li>
            }
            </ul>
            <button onClick={() => next(1)} type='button'>
                <img src='/images/arrow_right.png' alt='오른쪽 화살표'/></button>
            <button onClick={() => next(showPagesPer)} type='button'>
                <img src='/images/arrow2_right.png' alt='오른쪽 화살표'/></button>
        </div>
    );
};

export default Pagination;