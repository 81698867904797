import {MemberType} from "../price-policy-page";
import classNames from "classnames/bind";
import styles from "./gray-box.module.scss";

const cx = classNames.bind(styles);

const GrayBox = ({type, descList, img, btn}: MemberType) => {
    return(
        <div className={cx('gray-box', img ? img : '')}>
            <p className={cx('type')}>{type}</p>
            {
                <ul className={cx('desc-list')}>
                    {
                        descList.map((it, i) =>
                            <li key={i}>{descList.length > 1 && <i></i>}{it}</li>
                        )
                    }
                </ul>
            }
            <button className={cx('channel-talk-btn')} onClick={() => {
                window.open('https://copartner.channel.io/home', '_blank');
            }} type='button'>{btn}<i></i></button>
        </div>
    );
}

export default GrayBox;