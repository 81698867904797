export type MemberConnectionState = "READY" | "REJECTED" | "CONNECTED" | "DISCONNECTED" | "DISCONNECTED_PARTNER";
export interface CotalkStateInfo {
    cn: string;
    name: string;
    name2: string;
}
export const coTalkConnectInfo: {[key: string]: CotalkStateInfo} = {
    "NONE": {cn: 'request', name: '요청', name2: '씨오톡 연결요청'},
    "READY": {cn: 'wait', name: '대기중', name2: '씨오톡 연결대기'},
    "CONNECTED": {cn: 'join', name: '연결', name2: '씨오톡 연결완료'},
    "REJECTED": {cn: 'request', name: '요청', name2: '씨오톡 연결요청'},
    "DISCONNECTED":  {cn: 'disconnect', name: '해제', name2: '씨오톡 연결해제'},
    "DISCONNECTED_PARTNER": {cn: 'disconnect', name: '해제', name2: '씨오톡 연결해제'},
}
export const cotalkStateList = [
    {
        id: "",
        title: '전체'
    },
    {
        id: "NONE",
        title: '미연결'
    },
    {
        id: "READY",
        title: '연결대기'
    },
    {
        id: "CONNECTED",
        title: '연결완료'
    },
    {
        id: "DISCONNECTED",
        title: '연결해제'
    }
];

export const customerType = [
    {id: 'all', title: '전체'},
    {id: 'registerCus', title: '기장고객'},
    {id: 'consultingCus', title: '컨설팅고객'},
    {id: 'insuranceCus', title: '보험고객'}
];