import React, {useRef} from 'react';
import classNames from 'classnames/bind';
import {Field, FieldArray, Form, Formik} from 'formik';
import styles from './edit-shareholder-info.module.scss'
import {ShareholderList} from '../../model/customer/dto/shareholder-list';
import {ShareholderInfo} from '../../model/customer/dto/shareholder-info';
import * as Yup from 'yup';
import {asyncCall} from '../../util/util';
import {toast} from 'react-toastify';
import {useMountedState} from 'react-use';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../../model/customer/customer-api';
import {CustomerDetail} from '../../model/customer/dto/customer-detail';
import {ApiResult} from '../../model/api-result';
import {numberOnly, round} from '../../util/str-util';
import {DialogManager} from '../../components/dialog-manager';
import {CustomInput, DatePicker, InputBox, mdmRelList, NumberInputBox, SelectInputBox} from './edit-common';
import {SummaryReport} from '../../model/inquiry/dto/summary-report';
import {stkRtPrecision} from '../../data/const';

const cx = classNames.bind(styles);


interface ShareholderBoxProps {
    data: ShareholderInfo;
    total: number;
    setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => void;
    index: number;
    remove: (index: number) => ShareholderInfo | undefined
}

const ShareholderBox = ({data, total, setFieldValue, index, remove}: ShareholderBoxProps) => {
    const relList2 = ['본인', '대표이사', '경영실권자', '임원', '관계회사', '관계회사임원', '타인', '기타']; //회사와의 관계
    const field = `list.${index}.`;
    const dialogManager = useInjection(DialogManager);

    return (
        <div className={cx('shareholder-box')}>
            <div className={cx('line')}>
                <InputBox title='주요 주주' name={field + 'STH_NM'}/>
                <SelectInputBox title='경영실권자와의 관계' name={field + 'MDM_REL_CD'} selectList={mdmRelList}/>
                <SelectInputBox title='주식종류' name={field + 'TYPE'}
                                selectList={[{id: '', title: '-'}, {id: '보통주', title: '보통주'}, {
                                    id: '우선주',
                                    title: '우선주'
                                }, {id: '상환전환우선주', title: '상환전환우선주'}]}/>
            </div>
            <div className={cx('line')}>
                <NumberInputBox name={field + 'STK_CN'} title='주식 수' unit='주'
                                onChange={(e: any) => {
                                    const cnt = Number(numberOnly(e.target.value));
                                    //if (cnt && total && cnt > total) return;
                                    setFieldValue(field + 'STK_CN', cnt);
                                    setFieldValue(field + 'STK_RT', cnt && total ? round(cnt * 100 / total, stkRtPrecision).toFixed(1) : '');
                                }}/>
                <InputBox title='지분율' unit='%' value={data.STK_RT}/>
                <SelectInputBox title='회사와의 관계' name={field + 'ENP_REL_CD'} selectList={relList2}/>
            </div>
            <button className={cx('delete-btn')} type='button' onClick={() => dialogManager.confirm('해당 주주정보를 삭제하시겠어요?\n삭제 후에는 복구가 불가능합니다.', '', () => {
                remove(index);
            })}>
            </button>
            <div className={cx('check-box-area', data.BRRW_NM ? 'add-trust-input' : '')}>
                {data.BRRW_NM &&
                    <InputBox title='명의신탁일' name={field + 'TRUSTDATE'} inputboxCn={`date-box ${cx('calendar-box')}`}>
                        <DatePicker
                            name={field + 'TRUSTDATE'}
                            allowNull={false}
                        />
                    </InputBox>
                }
                <label className={cx('check-box')}>
                    <Field type='checkbox' name={field + 'BRRW_NM'} component={CustomInput}/>
                    <span>차명주주</span></label>
            </div>
        </div>
    );
}

interface EditShareholderInfoProps {
    customerId: string;
    data: ShareholderList;
    issStkCn: string;
    summary: SummaryReport;
    isConnectedCorpReg: boolean;
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>;
    onClose: () => void;
}

const EditShareholderInfo = ({customerId, data, issStkCn, summary, isConnectedCorpReg, setData, onClose}: EditShareholderInfoProps) => {
    const isMounted = useMountedState();
    const customerApi = useInjection(CustomerApi);
    const dialogManager = useInjection(DialogManager);
    const scrollRef = useRef<HTMLDivElement>(null);

    const initInfo: ShareholderInfo = {
        BRRW_NM: false,
        CONO_PID: "",
        CSTK_CN: "",
        CSTK_RT: "",
        ENP_REL_CD: "",
        EQRT: "",
        KEDCD: "",
        KEDCD_PCD: "",
        MDM_REL_CD: "",
        OWN_STK_AM: "",
        OWN_STK_CN: "",
        PSTK_CN: "",
        PSTK_RT: "",
        RMK: "",
        STD_DT: "",
        STH_CCD: "",
        STH_STT_SEQ: "",
        STK_CN: "",
        STK_RT: "",
        TYPE: "",
        STH_NM: '',
        TRUSTDATE: summary.KED5002['ESTB_DT']
    };

    const initData = {
        ...data, list: (data.list && data.list.length > 0 ? data.list.map((d) => {
            if (!d.TRUSTDATE) d.TRUSTDATE = summary.KED5002['ESTB_DT'];
            return d;
        }) : [initInfo])
    };

    const doAction = (api: (...args: any) => Promise<ApiResult<ShareholderList>>, args: any[], action: string) => {
        asyncCall(api, args, result => {
            setData(data => {
                if (data.customer) return {...data, customer: {...data.customer, shareholder: result}}
                else return data;
            });
            onClose();
            toast.success(action + ' 완료되었습니다.');
        }, isMounted);
    }

    const onAddRow = (insertAction: VoidFunction) => {
        insertAction();
        setTimeout(() => {
            scrollRef?.current?.scrollTo({top: scrollRef?.current?.scrollHeight, behavior: 'smooth'});
        }, 1)
    }

    const checkReadOnly = (field: string | number | undefined) => {
        if (!isConnectedCorpReg) return false;

        return !!field;
    }

    return (
        <div className={cx('edit-info')}>
            <Formik
                initialValues={initData ?? {}}
                validationSchema={Yup.object({
                    list: Yup.array().of(
                        Yup.object({
                            STH_NM: Yup.string().required('이름을 입력해주세요.'),
                            STK_CN: Yup.number().required('주식 수를 입력해주세요.')
                        }))
                })}
                onSubmit={(value) => {
                    const getSum = (list?: ShareholderInfo[]) => list?.reduce((a, b) => a + Number(b.STK_CN), 0) ?? 0;
                    const sum = getSum(value.list);
                    const sumC = getSum(value.list?.filter(it => it.TYPE === '보통주'));
                    const sumP = getSum(value.list?.filter(it => it.TYPE === '우선주'));

                    if (sum > Number(issStkCn) || sumC > Number(value.cstkCn) || sumP > Number(value.pstkCn)) {
                        dialogManager.alert('주식 수의 합은 총 발행주식 수를\n초과할 수 없습니다.');
                    } else {
                        doAction(customerApi.saveShareholderList, [customerId, value], '저장');
                    }
                }}>
                {({values, setFieldValue}) =>
                    <Form>
                        <FieldArray name='list'>
                            {({push, remove}) => (<>
                                <div className={cx('button-area')}>
                                    <button
                                        className={cx('back')}
                                        type='button'
                                        onClick={() => {
                                            onClose();
                                        }}>
                                        <i/>돌아가기
                                    </button>
                                    <div>
                                        <button className={cx('add-btn')} type='button'
                                                onClick={() => onAddRow(() => push({STH_NM: '', TRUSTDATE: summary.KED5002['ESTB_DT']}))}><i/>주주 추가
                                        </button>
                                        <button className={cx('save')} type='submit'><i/>저장하기</button>
                                    </div>
                                </div>
                                <div className={cx('input-area')} ref={scrollRef}>
                                    <div className={cx('scroll')}>
                                        <p className={cx('title')}>주요 주주 현황</p>
                                        <div className={cx('total-box')}>
                                            {/* <DateInputBox title='기준일' name='refDt'/> */}
                                            <InputBox title='기준일' name='refDt' inputboxCn='date-box'>
                                                <DatePicker
                                                    name='refDt'
                                                    allowNull={false}/>
                                            </InputBox>
                                            <NumberInputBox value={issStkCn} title='총 발행주식 수' unit='주' readOnly
                                                            autoSize/>
                                            <p/> {/* grid 공백처리 */}
                                            <NumberInputBox name='cstkCn' title='보통주' unit='주'
                                                            autoSize
                                                            readOnly={checkReadOnly(summary.KED5012?.['CSTK_CN'])}
                                                            onChange={e => {
                                                                const val = numberOnly(e.target.value);
                                                                if (Number(val) <= Number(issStkCn)) setFieldValue('cstkCn', val);
                                                                //setFieldValue('issStkCn', Number(val) + Number(values.pstkCn));
                                                            }}/>
                                            <NumberInputBox name='pstkCn' title='우선주' unit='주'
                                                            autoSize
                                                            readOnly={checkReadOnly(summary.KED5012?.['PSTK_CN'])}
                                                            onChange={e => {
                                                                const val = numberOnly(e.target.value);
                                                                setFieldValue('pstkCn', val);
                                                                //setFieldValue('issStkCn', Number(values.cstkCn) + Number(val));
                                                            }}/>
                                            <NumberInputBox name='convPstkCn' title='상환전환우선주' unit='주'
                                                            autoSize
                                                            readOnly={checkReadOnly(data.convPstkCn)}
                                                            onChange={e => {
                                                                const val = numberOnly(e.target.value);
                                                                setFieldValue('convPstkCn', val);
                                                            }}/>
                                        </div>
                                        {values.list?.map((it, i) =>
                                            <ShareholderBox key={i} index={i} data={it}
                                                            total={Number(issStkCn)}
                                                            setFieldValue={setFieldValue}
                                                            remove={remove}/>)}
                                    </div>
                                </div>
                            </>)}
                        </FieldArray>
                    </Form>}
            </Formik>
        </div>
    );
};

export default EditShareholderInfo;