import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {ApiResult} from "../api-result";
import {LectureDto} from "./dto/lecture-dto";
import {CreditDto} from "../customer/dto/credit-dto";

@injectable()
export class LectureApi {
    @inject(ApiCall)
    private  apiCall!: ApiCall;

    getLecture = async (): Promise<ApiResult<LectureDto[]>> => {
        return await this.apiCall.fetch<ApiResult<LectureDto[]>>('/api/lecture');
    }
}