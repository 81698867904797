import {useInjection} from 'inversify-react';
import {AuthStore} from './auth-store';
import {observer} from 'mobx-react-lite';
import {Login} from '../../pages/intro/login';

export const RequireAuth = observer(({children}: { children: JSX.Element }) => {
    const authStore = useInjection(AuthStore);
    if (!authStore.isLoggedIn()) {
        return <Login/>;
    }
    return children;
});