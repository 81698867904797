import {SummaryReport} from '../../model/inquiry/dto/summary-report';
import {
    getAge0,
    hyphen,
    ratio,
    toDateString,
    toHundredMillion,
    ymdToDate
} from '../../util/str-util';
import {Customer} from '../../model/customer/dto/customer';
import {CoReportDto} from '../../model/co-report/dto/co-report-dto';
import {PrFrStatCont} from "../../model/customer/dto/financial-statement";
import {add, isAfter} from "date-fns";
import {Donee} from "../../model/co-report/dto/donee";
import {SelectItem} from "../../model/types";
import {ShareholderInfo} from "../../model/customer/dto/shareholder-info";
import {CorpValue} from "../../model/co-report/dto/corp-value";
import {CustomerDetail} from "../../model/customer/dto/customer-detail";

export type IdWithTitle = {id: string, title: string};

export const certStatusList: IdWithTitle[] = [
    {id: 'lapatenpYn', title: '기업연구소 보유'},
    {id: 'rndExrsDrptYn', title: '연구 전담 부서 보유'},
    {id: 'venpYn', title: '벤처인증'},
    {id: 'ibizCertYn', title: '이노비즈 인증'},
    {id: 'mbizCertYn', title: '메인비즈 인증'},
    {id: 'isoYn', title: 'ISO 인증'},
    {id: 'tipsYn', title: 'TIPS 선정'},
    {id: 'haccpYn', title: 'HACCP 인증'},
    {id: 'militaryExceptCompYn', title: '병역특례업체'}
];

// 등록제한 기업코드
export const restrictionFcd: IdWithTitle[] = [
    {id: '19', title: '유한책임회사'},
    {id: '13', title: '사회복지법인'},
    {id: '09', title: '의료법인'},
    {id: '10', title: '학교법인'},
    {id: '05', title: '비영리재단법인'},
    {id: '06', title: '비영리사단법인'},
    {id: '11', title: '종교법인'},
    {id: '12', title: '학술장학법인'},
    {id: '14', title: '기타공익법인'},
    {id: '15', title: '개인기업'},
    {id: '18', title: '법인격없는단체'},
]

export const certToStr = (values: any) => certStatusList.filter(it => values[it.id] === 'Y').map(it => it.title).join(', ');

export const getCompanyInfo = (list1: string[][] | null, addrIndex: number, summary: SummaryReport, simple: boolean = false) => {
    if (list1) {

        list1.forEach((value) => {
            if (summary.KED5002[value[1]])
                value[1] = summary.KED5002[value[1]];
            else {
                value[1] = "-";
            }

            if (value[2]) // 우선 출력할 값
                value[1] = value[2];
        })

        //임시 분기
        if(simple) {
            list1[1][1] = summary.COMPANYATTR;
            list1[2][1] = summary.IND_PROP_RGHT;
        } else {

            if (list1[addrIndex][1]) list1[addrIndex][1] += ' ' + summary.KED5002['LOC_RDNM_ADDRB'];

            list1[addrIndex + 1][1] = summary.COMPANYATTR;
            list1[addrIndex + 2][1] = summary.IND_PROP_RGHT;

            list1.forEach((value) => {
                value[1] = hyphen(value[1]);
            });

            list1[0][1] = summary.BZC_CD ? '(' + summary.BZC_CD + ') ' + summary.KED5002['BZC_CD'] : '';
        }
    }

    return [
        summary.PAYMENTFUND,
        summary.KED5012?.['ISS_STK_CN'] ?? '',
        summary.KED5012?.['CSTK_CN'] ?? '',
        summary.KED5012?.['PSTK_CN'] ?? '',
        summary.KED5012?.['CV_PSTK_CN'] ?? '',
        summary.KED5012?.['STK_FVAL_AM'] ?? ''
    ];
}

const procYear = 5;

export const getFinancialStatus = (summary?: SummaryReport): [any[][] | null, number] => {
    let fnData: any[][] | null = null;
    let accDt: number;

    const ked5003 = summary?.KED5003?.slice(0, 5);

    if (ked5003 && ked5003.length > 0) {

        fnData = [['자산', '부채', '자본', '매출액', '영업이익(손실)', '당기순이익(순손실)',
            '매출액증가율%', '영업이익률%', '순이익률%', 'ROE%']];
        
        accDt = +ked5003[0].ACCT_DT.substring(0, 4);

        // 5년치
        for (let i = 0; i < procYear; i++) {
            let proc = false;
            if (ked5003.length - procYear + i >= 0) {
                const ar = [];
                const d = ked5003[4 - i];
                const currentAccDt = accDt - procYear + i + 1;

                if (d.ACCT_DT && d.ACCT_DT.length > 4 && (+d.ACCT_DT.substring(0, 4) === currentAccDt)) {
                    ar[0] = d.SUMASSET;
                    ar[1] = Number(d.SUMASSET) - Number(d.FUNDTOTAL);
                    ar[2] = d.FUNDTOTAL;

                    ar[3] = d.SALES;
                    ar[4] = d.PROFIT;
                    ar[5] = d.TERMNETPROFIT;

                    ar[6] = undefined;
                    ar[7] = ratio(d.PROFIT, d.SALES);
                    ar[8] = ratio(d.TERMNETPROFIT, d.SALES);

                    // ROE
                    if (i === 0)
                        ar[9] = ratio(d.TERMNETPROFIT, d.FUNDTOTAL);
                    else {
                        const d_1 = ked5003[4 - i + 1];
                        if (d_1) {
                            ar[6] = (ratio(d.SALES, d_1.SALES) ?? 1) - 1;
                            ar[9] = ratio(d.TERMNETPROFIT, (((+d.FUNDTOTAL) + (+d_1.FUNDTOTAL)) / 2));
                        } else {
                            ar[9] = ratio(d.TERMNETPROFIT, (((+d.FUNDTOTAL))));
                        }
                    }

                    fnData.push(ar);
                    proc = true;
                }
            }

            if (!proc) {
                fnData.push([...Array(10)]);
            }

        }
    } else {
        accDt = new Date().getFullYear() - 1;
    }
    return [fnData, accDt];
}

export const getFinancialStatusWithBss = (summary?: SummaryReport, bss?: PrFrStatCont[]): [any[][] | null, number] => {
    let fnData: any[][] | null = null;
    let accDt: number;

    const ked5003 = summary?.KED5003?.slice(0, 5);

    if (ked5003 && ked5003.length > 0) {

        fnData = [['자산', '부채', '자본', '미처분이익잉여금', '매출액', '영업이익(손실)', '당기순이익(순손실)',
            '매출액증가율%', '영업이익률%', '순이익률%', 'ROE%']];
        
        accDt = +ked5003[0].ACCT_DT.substring(0, 4);
        
        // 5년치
        for (let i = 0; i < procYear; i++) {
            let proc = false;
            if (ked5003.length - procYear + i >= 0) {
                const ar = [];
                const d = ked5003[4 - i];
                const currentAccDt = accDt - procYear + i + 1;

                if (d.ACCT_DT && d.ACCT_DT.length > 4 && (+d.ACCT_DT.substring(0, 4) === currentAccDt)) {
                    ar[0] = d.SUMASSET;
                    ar[1] = Number(d.SUMASSET) - Number(d.FUNDTOTAL);
                    ar[2] = d.FUNDTOTAL;

                    const bssData = bss?.filter((b, idx) => {
                        const stDate = Number(b.stDate.substring(0, 4));

                        if (currentAccDt === stDate) {
                            return b.data;
                        }
                    })

                    if (bssData && bssData.length > 0 && bssData[0].data) {
                        ar[3] = bssData[0].data.unapprtdEarning;
                    } else {
                        ar[3] = "-";
                    }

                    ar[4] = d.SALES;
                    ar[5] = d.PROFIT;
                    ar[6] = d.TERMNETPROFIT;

                    ar[7] = undefined;
                    ar[8] = ratio(d.PROFIT, d.SALES);
                    ar[9] = ratio(d.TERMNETPROFIT, d.SALES);

                    // ROE
                    if (i === 0)
                        ar[10] = ratio(d.TERMNETPROFIT, d.FUNDTOTAL);
                    else {
                        const d_1 = ked5003[4 - i + 1];
                        if (d_1) {
                            ar[7] = (ratio(d.SALES, d_1.SALES) ?? 1) - 1;
                            ar[10] = ratio(d.TERMNETPROFIT, (((+d.FUNDTOTAL) + (+d_1.FUNDTOTAL)) / 2));
                        } else {
                            ar[10] = ratio(d.TERMNETPROFIT, (((+d.FUNDTOTAL))));
                        }
                    }

                    fnData.push(ar);
                    proc = true;
                }
            }

            if (!proc) {
                fnData.push([...Array(10)]);
            }

        }
    } else {
        accDt = new Date().getFullYear() - 1;
    }
    return [fnData, accDt];
}

export const getFsVal = (fs: PrFrStatCont[] | undefined, key: string) => {
    return [...fs ?? []].reverse().map((fss) => fss.data[key] ?? 0).map(it => Number(it) * 1000) ?? []
}

export const getFsValAsc = (fs: PrFrStatCont[] | undefined, key: string) => {
    return [...fs ?? []].map((fss) => fss.data[key] ?? 0).map(it => Number(it) * 1000) ?? []
}

export const getRepAge = (customer?: Customer, refDate = new Date()) => {
    if ((customer?.rep.length ?? 0) > 0) {
        const idx = customer?.salary.repIdx ?? 0;
        const birth = ymdToDate(customer?.rep[idx].birthday ?? '');
        if (birth) return getAge0(birth, refDate);
    }
}

export const getRepName = (customer?: Customer) => {
    if ((customer?.rep.length ?? 0) > 0) {
        const idx = customer?.salary.repIdx ?? 0;
        return customer?.rep[idx].name ?? '';
    }
    return '';
}

export const hundredMillionFormatter = (value: number) => toHundredMillion({num: value, unit: '억', precision: 1, type: 'graph'})
export const hundredMillionFormatter2 = (value: number) => toHundredMillion({num :value, unit: '억원', precision: 2, type: 'graph'})

export const getShareholderName = (name: any, relation: any, relation2?: any) => {
    const r = relation === "대표이사" || !relation2 ? relation : relation2;

    return (name ? name : '') + (r ? ' (' + r + ')' : '');
};
export const getIncomeTaxRel = (sr: boolean) => sr ? '소득세법 특수관계' : '소득세법 비특수관계';
export const getInheritTaxRel = (sr: boolean) => sr ? '상증세법 특수관계' : '상증세법 비특수관계';
// export const getFsRefYear = (data: CoReportDto) => data.fs?.refDt ? Number(data.fs?.refDt) : (new Date()).getFullYear() - 1;
export const getFsRefYearV2 = (data: CoReportDto) => data.fss?.refDt ? Number(data.fss?.refDt) : (new Date()).getFullYear() - 1;

export const getSelectedCorpValue = (cvList?: CorpValue[], year?: number) => {
    const idx = cvList?.findIndex(cv => cv.year == year) ?? 0;
    return (cvList?.[idx === -1 ? 0 : idx] ?? {} as CorpValue);
}

/**
 * 날짜 경과 체크
 * @param dateStr 기준일
 */
export const checkOver = (dateStr?: string): boolean => {
    if (!dateStr) return false;

    const checkDate = ymdToDate(dateStr);
    if (!checkDate) return false;

    const checkDate2 = add(checkDate, {days: 14});

    return isAfter(new Date(), checkDate2)
}

export const checkOverRange = (dateStr?: string): boolean => {
    if (!dateStr) return false;

    const checkDate = ymdToDate(dateStr);
    if (!checkDate) return false;

    const now = new Date();

    const checkDateLimit = add(checkDate, {days: 14});
    if (isAfter(now, checkDateLimit))
        return false;

    const checkDateStart = add(checkDate, {days: -30});
    return (isAfter(now, checkDateStart))
}

export const addDate = (dateStr?: string, day?: number): string => {
    if (!dateStr) return "";

    const checkDate = ymdToDate(dateStr);
    if (!checkDate) return "";

    return toDateString(add(checkDate, {days: day}));
}

export function calcAf (donee: Donee): number {

    return (!donee.rtAf ? 0 : donee.rtAf) - (!donee.rtBf ? 0 : donee.rtBf);
}

export function getGiverList(shareholder: ShareholderInfo[], list: { name: string }[]) : SelectItem[] {
    const find = (sh: ShareholderInfo) => list.find(it => it.name === sh.STH_NM);
    const ar: SelectItem[] = [];

    for (let i = 0; i < shareholder.length; i++) {
        const o = shareholder[i];
        if (find(o) == null) ar.push({id: o.STH_NM, title: o.STH_NM});
    }
    return ar;
}

/**
 * 상세 재무 수정 정보
 */
export function calcFsStatEditYear(): number[] {
    const nowDate = new Date();
    const month = nowDate.getMonth() + 1;
    const year = nowDate.getFullYear();

    return month >= 4 ? [year, year - 1] : [year - 1, year - 2];
}

export function isSoleReport(data: CoReportDto | CustomerDetail): boolean {
    return data.customer?.enpTyp === "2";
}