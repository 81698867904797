import React, {useRef, useState} from 'react';
import styles from './select-box.module.scss';
import classNames from 'classnames/bind';
import {useOutsideAlerter} from '../util/util';
import {SelectItem} from '../model/types';

const cx = classNames.bind(styles);

const SelectList = ({selectList, selected, selectBoxCn, onChange, openAbove}: SelectBoxProps) => {

    const positionTop = selectBoxCn?.find((li) => li === 'exception') ? 'calc(100% + 1.0rem)' : 'calc(100% - .3rem)';
    const selectedId = selectList.findIndex(it => it.id === selected);//id가 중복인 경우 하나만 선택하기 위해서 필요

    const position = openAbove ? {bottom: '100%'} : {top: positionTop};

    return (
        <div className={cx('select-list')} style={position}>
            <ul>
                {selectList.map((li, i) =>
                    <li key={i} className={cx(i === selectedId && 'selected')}
                        onClick={() => {
                            if (i !== selectedId) onChange?.(li)
                        }}><span>{i === selectedId && '✓'}</span>{li.title}</li>
                )}
            </ul>
        </div>
    );
}

interface SelectBoxProps {
    selectList: SelectItem[];
    selectedTitle?: string;
    selected?: any;
    labelCn?: string;
    selectBoxCn?: string[];
    width?: string;
    inputBoxCn?: string;
    placeholder?: string;
    onChange?: (id: any) => void;
    externalValue?: boolean; //true일 경우 외부에서 값을 처리하므로 setTitle로 값을 저장하지 않음
    openAbove?: boolean; // selectList가 위로 떠야하는 경우
}

const SelectBox = (props: SelectBoxProps) => {
    const wrapperRef = useRef(null);
    const [showSelectBox, setShowSelectBox] = useState(false);
    const [selected, setSelected] = useState(props.selected ?? props.selectList[0]?.id);
    const title0 = props.selectedTitle ?? props.selectList.find(it => it.id === selected)?.title ?? props.selectList[0]?.title;
    const [title, setTitle] = useState(title0);

    useOutsideAlerter(wrapperRef, setShowSelectBox);

    const onChange = (item: SelectItem) => {
        const itemTitle = !!(item.id === '' && props.selectedTitle) ? props.selectedTitle : item.title;
        setTitle(itemTitle);
        setSelected(item.id);
        props.onChange?.(item.id);
    };

    return (
        <div className={cx('select-box', props.selectBoxCn?.map((li) => li))} style={{width: props.width ?? 'auto'}}
             onClick={() => setShowSelectBox(!showSelectBox)} ref={wrapperRef}>
            {
                props.placeholder ?
                    <input className={cx('input-txt', props.inputBoxCn)}
                           value={(props.externalValue ? props.selectedTitle : title) ?? ''}
                           placeholder={props.placeholder}
                           readOnly/>
                    : <span className={cx('input-txt', props.inputBoxCn)}>{props.externalValue ? props.selectedTitle : title}</span>
            }
            <i><label className={cx(props.labelCn)}></label></i>
            {showSelectBox &&
                <SelectList {...props} onChange={onChange} selected={props.externalValue ? props.selected : selected}/>
            }
        </div>
    );
};

export default SelectBox;