import React from 'react';
import styled from 'styled-components';

const NoInfoStyle = styled.div`
    width: 100%;
    padding-bottom: 10.0rem;
    text-align: center;

    i {
        display: inline-block;
        width: 1.6rem;
        height: 1.6rem;
        margin-right: .9rem;
        background-image: url('../../images/alert.png');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        vertical-align: -10%;
    }

    span {
        font-size: 1.4rem;
        letter-spacing: -.07rem;
        color: #999;
    }
`;

const NoInfo = ({text}: {text?: string}) => {
    return (
        <NoInfoStyle>
            <i></i><span>{text ?? "등록된 정보가 없습니다."}</span>
        </NoInfoStyle>
    );
};

export default NoInfo;