import React, {useEffect} from 'react';
import LottieComp from './lottie-comp';

export interface LoadingDialogProps {
    aniW:string;
    aniH:string;
    jsonFile:string;
    title?:string;
    children?:React.ReactNode; //줄바꿈으로 인한 추가
    onBtn?: () => void;
    autoOff?: boolean;
}

interface LoadingDialogProp extends LoadingDialogProps {
    onClose: VoidFunction;
}

const LoadingDialog = ({
                aniW,
                aniH,
                jsonFile,
                title,
                children,
                onBtn,
                onClose,
                autoOff = false
                }: LoadingDialogProp) => {

    useEffect(() => {
        if(autoOff) {
            setTimeout(() => {
                onClose();
            },3000);
        }
    },[]);

    return (
        <div className='full-background' onClick={onClose}>
            <div className='loading-dialog pop-inner'>
                <div className='text'>
                    <LottieComp width={aniW} height={aniH} jsonFile={jsonFile}/>
                    <p className='sub-title'>{title || children}</p>
                </div>
                { onBtn &&
                    <div className='btn-area'>
                        <button className='close-btn' onClick={onBtn}>닫기</button>
                    </div>}
            </div>
        </div>
    );
};

export default LoadingDialog;