import styles from '../intro/home/co-report-section.module.scss';
import classNames from 'classnames/bind';
import React, {forwardRef} from 'react';
import Vimeo from '@u-wave/react-vimeo';

const cx = classNames.bind(styles);

const KatalkServiceGuide = forwardRef<HTMLDivElement>((props, ref) => {
    // const [backgroundW, setBackgroundW] = useState(window.innerWidth);
    //
    // window.addEventListener("resize", function() {
    //     setBackgroundW(window.innerWidth);
    // });

    return (
        <div className={cx('co-report-section')} ref={ref}>
            <div className={`partners-section-inner gap3 ${cx('inner-gap3')}`}>
                <p className='partners-category ver3'>서비스 가이드</p>
                <h3 className='partners-section-title gap1'>
                    1:1카톡 단체발송 서비스로<br/>업무 효율 <span className='ver3'>UP</span><br className='mobile-bk'/> 고객 만족 <span className='ver3'>UP</span>
                </h3>
                <Vimeo
                    video='https://player.vimeo.com/video/930911297'
                    className={cx('video-box', 'katalk-video-box')}
                    showTitle={false}
                    controls={false}
                    autoplay={true}
                    muted={true}
                    loop={true}
                    onReady={(player)=> {
                        player.pause();
                        setTimeout(function() {
                            player.play();
                        }, 4000);
                    }}
                />
                <button
                    className={cx('service-guide')}
                    onClick={() => window.open('https://www.copartner.kr/pdf/send_katalk_guide.pdf', "_blank")}
                    type='button'><span>서비스 가이드</span> 보러가기<i></i></button>
            </div>
        </div>
    );
});

export default KatalkServiceGuide;