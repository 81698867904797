import ReportLeft from "../../report-left";
import ReportRight from "../../report-right";
import GrayTxtBox from "../../components/gray-txt-box";
import TableTitle from "../../../../../components/table-title";
import MainReportContainer from "../../main-report-container";
import {usePdfComponents} from "../../../pdf-hooks";
import {StyleSheet} from "@react-pdf/renderer";
import {consultingTypeList, SupportConsulting} from "../../../../../model/co-report/data/support-consulting-list";
import {ReportPageProp} from "../../report-page-prop";

const ConsultingType = ({data, isActive}: {data: SupportConsulting, isActive: boolean}) => {
    const {Image, Text, View, isPdf} = usePdfComponents();

    const styles = StyleSheet.create({
        typeBox: {
            width: 465,
            border: isActive ? '1px solid #5c67ff' : '1px solid #ccc',
            borderRadius: 8
        },
        titleBox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            height: 55,
            paddingLeft: 32,
            borderTopRightRadius: 7,
            borderTopLeftRadius: 7,
            backgroundColor: isActive ? '#5c67ff' : '#eee',
        },
        title: {
            color: isActive ? '#fff' : '#000',
            fontSize: 18,
            fontWeight: 700
        },
        descBox: {
            padding: '16px 32px 18px',
            fontSize: 16,
        },
        desc: {
            paddingBottom: 5
        },
        bold: {
            fontWeight: 700,
            color: isActive ? '#5c67ff' : '#000'
        },
        bottomLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingTop: 9
        },
        img: {
            width: 24,
            marginRight: 2
        },
        txt: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'nowrap'
        },
        keywordBox: {
            borderRadius: 30,
            padding: '3px 9px 5px',
            marginLeft: 8,
            marginTop: 2,
            fontSize: 12,
            backgroundColor: '#eff5ff',
            color: '#5c67ff',
        },
    });

    return(
        <View style={styles.typeBox}>
            <View style={styles.titleBox}><Text style={styles.title}>{data.title}</Text>
                {isActive && <View style={styles.keywordBox}><Text>신청 가능</Text></View>}
            </View>
            <View style={styles.descBox}>
                <Text style={styles.desc}>
                    {data.desc}
                </Text>
                { data.field &&
                    <View style={styles.bottomLine}>
                        <View style={styles.txt}><Image style={styles.img} src={`/images/report/i_field${isActive ? '_on' : ''}.png`}/><Text>{data.field}</Text></View>
                    </View>
                }
                <View style={styles.bottomLine}>
                    <View style={styles.txt}><Image style={styles.img} src={`/images/report/i_company${isActive ? '_on' : ''}.png`}/>
                        <Text style={styles.txt}>{data.laborerSum.split('|')[0]} <Text style={styles.bold}>{isPdf && '∙'}{data.laborerSum.split('|')[1]}</Text>{isPdf && '∙'}{data.laborerSum.split('|')[2]}</Text>
                    </View>
                    <View style={styles.txt}><Image style={styles.img} src={`/images/report/i_cash${isActive ? '_on' : ''}.png`}/><Text>{data.price}</Text></View>
                </View>
            </View>
        </View>
    );
};

const SupportableGovernmentConsulting = ({data}: ReportPageProp) => {
    const {Image, View} = usePdfComponents();

    const styles = StyleSheet.create({
        type: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            paddingBottom: 24
        },
        img: {
            width: '100%',
        },
    });

    const employeeCnt = data.report.lc.employeeCnt ?? 0;
    const period = data.report?.ci.bizperiod ?? 0;

    const isActive = (consulting: SupportConsulting) => {
        let checked = employeeCnt >= consulting.min;

        if (consulting.max && checked) {
            checked = employeeCnt <= consulting.max;
        }

        if (consulting.period && checked) {
            checked = consulting.period <= period;
        }

        return checked;
    }

    return(
        <MainReportContainer reportKey='SupportableGovernmentConsulting'>
            <ReportLeft icon='icon_consulting' title='노무 컨설팅'>
            </ReportLeft>
            <ReportRight reportKey='SupportableGovernmentConsulting' title='근로자 수에 따라 지원 가능한 정부 컨설팅'>
                <GrayTxtBox>
                    정부지원 컨설팅은 기업의 인사관리제도 개선, 노사관계 안정화 등 경영상의 애로사항을 해결하기 위한 노무 컨설팅 비용을 정부에서 일정 부분 지원하는 제도입니다. 다양한 제도가 운영되고 있고, 정부지원금으로 노무컨설팅을 받아볼 수 있는 유용한 제도이지만 의외로 모르는 기업이 많습니다.
                </GrayTxtBox>
                <TableTitle title='주요 정부 지원 컨설팅 유형' type='main' tableStyle={{paddingTop: 40}}>
                    <View style={styles.type}>
                        <ConsultingType data={consultingTypeList[0]} isActive={isActive(consultingTypeList[0])}/>
                        <ConsultingType data={consultingTypeList[1]} isActive={isActive(consultingTypeList[1])}/>
                    </View>
                    <View style={styles.type}>
                        <ConsultingType data={consultingTypeList[2]} isActive={isActive(consultingTypeList[2])}/>
                        <ConsultingType data={consultingTypeList[3]} isActive={isActive(consultingTypeList[3])}/>
                    </View>
                </TableTitle>
                <Image style={styles.img} src='/images/report/government_consulting_img2.png' alt=''/>
            </ReportRight>
        </MainReportContainer>
    );
};

export default SupportableGovernmentConsulting;