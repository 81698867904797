import React from 'react';
import {Helmet} from 'react-helmet-async';

const MetaTag = ({viewportW}: { viewportW?: string }) => {
    if (viewportW == null) {

        if (window.outerWidth > 767 && window.outerWidth <= 1320){
            viewportW = 'width=1320';
        } else if (window.outerWidth > 1320) {
            viewportW = 'width=device-width, initial-scale=1';
        } else {
            viewportW = 'width=device-width, initial-scale=1, maximum-scale=1';
        }
    }

    return (
        <Helmet>
            <meta name='viewport' content={viewportW}/>
        </Helmet>
    );
};

export default MetaTag;