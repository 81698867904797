import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {UploadImgMeta, UploadMeta} from "./dto/upload-meta";
import {ApiResult} from "../api-result";

@injectable()
export class UploadApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    checkAttach = async (fileId: string) => {
        return await this.apiCall.fetch<ApiResult<string>>(`/api/attach/check/${fileId}`);
    }

    uploadSingleImg = async (meta: UploadImgMeta, file: File| Blob) => {
        const form = new FormData();
        form.append("images", file);
        form.append("type", meta.type);
        (meta.searchKey && form.append("searchKey",  meta.searchKey));
        form.append("autoSave",  meta.autoSave.toString());
        form.append("enableDn",  meta.enableDn.toString());
        form.append("thumbnailYn",  meta.thumbnailYn.toString());

        return await this.apiCall.upload('/image/upload/file', form);
    }

    uploadSingleFile = async (meta: UploadMeta, file: File| Blob) => {
        const form = new FormData();
        form.append("files", file);
        form.append("type", meta.type);
        form.append("documentName", meta.documentName ?? "");
        form.append("fileName", meta.fileName ?? "");
        (meta.searchKey && form.append("searchKey",  meta.searchKey));
        form.append("autoSave",  meta.autoSave.toString());

        return await this.apiCall.uploadAttach('/attach/upload/file', form);
    }
}