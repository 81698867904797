import {createContext, Dispatch, SetStateAction, useContext} from "react";
import {CoReportDto} from "../model/co-report/dto/co-report-dto";
import {ReportCanvasController} from "../controller/report-canvas-controller";
import {CreateReportBaseProps, ReportWriterInfo} from "./report/main/main-report-root";
import {ReportPageController} from "../controller/report-page-controller";

export const SetReportContext = createContext<Dispatch<SetStateAction<CoReportDto | undefined>>|undefined>(undefined);
export const useSetReportContext = () => useContext(SetReportContext);

export const ReportCreatePropContext = createContext<CreateReportBaseProps|undefined>(undefined);
export const useReportCreatePropContext = () => useContext(ReportCreatePropContext);

export const ReportCanvasControllerContext = createContext<ReportCanvasController|undefined>(undefined);
export const useReportCanvasControllerContext = () => useContext(ReportCanvasControllerContext);
