import {Form, Formik} from "formik";
import React from "react";
import classNames from "classnames/bind";
import styles from "./emergency-funds-design.module.scss";
import {NumberInputBox} from "./customer/edit-common";
import {useInjection} from "inversify-react";
import {CustomerInputApi} from "../model/customer/customer-input-api";
import usePromise from "../model/common/use-promise";
import {CoReportDto} from "../model/co-report/dto/co-report-dto";
import {CustomerApi} from "../model/customer/customer-api";
import {useMountedState} from "react-use";
import {PrCustomerRawData} from "../model/customer/dto/customer-raw-data";
import {asyncCall} from "../util/util";
import {toast} from "react-toastify";
import {strSaved} from "../data/strings";
import {DialogManager} from "../components/dialog-manager";

const cx = classNames.bind(styles);

const EmergencyFundsDesign = ({data, onClose}: { data: CoReportDto, onClose: VoidFunction }) => {
    const customerApi = useInjection(CustomerApi);
    const customerInputApi = useInjection(CustomerInputApi);
    const isMounted = useMountedState();
    const dialogManager = useInjection(DialogManager);

    const [loading, rowDataResult] = usePromise(() => customerApi.rawData(data.customer.id), [data]);

    if (loading || !rowDataResult) return <div></div>;

    const customer = data.customer;
    const rawData: PrCustomerRawData = rowDataResult?.val!;

    if (!customer) return null;

    return (
        <Formik
            initialValues={rawData.emergencyFundPlan}
            onSubmit={(values) => {
                asyncCall(customerInputApi.saveEmergencyFundPlan, [customer.id, values], result => {
                    toast.success(strSaved);
                }, isMounted, (e) => {
                    dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                });
            }}>
            {({values}) =>
                <Form className={cx('emergency-funds-design')}>
                    <div className={cx('btn-area')}>
                        <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                        <button className='close-btn' type='button' onClick={() => onClose()}>닫기</button>
                    </div>
                    <div className={cx('scroll-box')}>
                        <div className={cx('scroll-area')}>
                            <p className={cx('title')}>비상운영자금 설계</p>
                            <div className={cx('input-area')}>
                                <p className={cx('sub-title')}>부채상환자금</p>
                                <NumberInputBox title='단기차입금 상환률' unit='%' inputWidth='30.3rem' name='stBorrowRepayRate'
                                                inputboxCn={cx('input-box')}/>
                                <NumberInputBox title='장기차입금 상환률' unit='%' inputWidth='30.3rem' name='ltBorrowRepayRate'
                                                inputboxCn={cx('input-box')}/>
                                <p className={cx('sub-title', 'pt')}>운영자금 및 기타</p>
                                <NumberInputBox title='연간 판관비 준비율' unit='%' inputWidth='30.3rem' name='yearSellGaExpRate'
                                                inputboxCn={cx('input-box')}/>
                                <NumberInputBox title='연간 이자비용 준비율' unit='%' inputWidth='30.3rem' name='yearInterestRate'
                                                inputboxCn={cx('input-box')}/>
                            </div>
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    );
};

export default EmergencyFundsDesign;