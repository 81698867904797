import React, {useEffect, useState} from 'react';
import {SmsAuthType} from "../model/intro/dto/sms-auth-req";
import {SmsAuthController} from "../controller/sms-auth-controller";
import {SmsAuthControllerContext} from "../pages/intro/intro-hook";

interface IntroBoxProps {
    smsAuthType?: SmsAuthType;
    children: React.ReactNode;
}

const IntroBox = ({smsAuthType, children}: IntroBoxProps) => {
    const [smsAuthController, setSmsAuthController] = useState<SmsAuthController>();

    useEffect(() => {
        if (smsAuthType) {
            setSmsAuthController(new SmsAuthController());
        }
    }, []);

    return (
        <div className='intro-page'>
            <div className='intro-inner'>
                <div className='grid-box'>
                    <div className='left'>
                        <SmsAuthControllerContext.Provider value={smsAuthController}>
                            {children}
                        </SmsAuthControllerContext.Provider>
                    </div>
                    <div className='right'>
                        <img src='/images/illust_business.png' alt='인트로 이미지'/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroBox;