import {ApiResult} from "../api-result";
import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {StartSubscriptForm} from "./dto/start-subscript-form";
import {Payment, PaymentMethod} from "./dto/payment";
import {PaymentPrepareDto} from "./dto/payment-prepare-dto";
import {Page} from "../common/dto/page";
import {makeSearchDataQs} from "../../util/str-util";
import {PayExtraData} from "./dto/pay-extra-data";
import {SsIdWithPaymentId} from "./dto/ss-id-with-payment-id";

@injectable()
export class PaymentApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    startSubscription = async (form: StartSubscriptForm) => {
        return await this.apiCall.json<ApiResult<SsIdWithPaymentId>>('/api/payment/subscription/start', form);
    }

    preparePayment = async (goodId: string, paymentMethod: PaymentMethod) => {
        return await this.apiCall.fetch<ApiResult<PaymentPrepareDto>>('/api/payment/prepare', {goodId, paymentMethod});
    }

    pagingPayment = async (pageNo: number, searchData: {[key: string]: string|undefined}) => {
        const url = `/api/payment/payments?page=${pageNo}${makeSearchDataQs(searchData)}`;
        return await this.apiCall.fetch<ApiResult<Page<Payment>>>(url);
    }

    getPayment = async (paymentId: string) => {
        const url = `/api/payment/payment/${paymentId}`;
        return await this.apiCall.fetch<ApiResult<Payment>>(url);
    }

    cancelPayment = async (paymentId: string) => {
        return await this.apiCall.fetch<ApiResult<Payment>>('/api/payment/cancel', {paymentId});
    }

    confirmPaymentV2 = async (paymentId: string, repId: string, extraData: PayExtraData) => {
        return await this.apiCall.json<ApiResult<Payment>>(`/api/payment/v2/confirm/${paymentId}/${repId}`, extraData);
    }

    simplePayV2 = async (goodsId: string, billingId: string, extraData: PayExtraData) => {
        return await this.apiCall.json<ApiResult<Payment>>(`/api/payment/v2/simplePay/${goodsId}/${billingId}`, extraData);
    }

    autoPay = async (goodsId: string) => {
        return await this.apiCall.post<ApiResult<string>>(`/api/payment/auto/${goodsId}`, {});
    }

    cashPaymentV2 = async (goodsId: string, extraData: PayExtraData) => {
        return await this.apiCall.json<ApiResult<Payment>>(`/api/payment/v2/cashPay/${goodsId}`, extraData);
    }

}