import React, {CSSProperties, useRef, useState} from 'react';
import styles from './select-button.module.scss';
import classNames from 'classnames/bind';
import {useOutsideAlerter} from '../util/util';
import {SelectItem} from '../model/types';

const cx = classNames.bind(styles);

interface SelectButtonProps {
    children?: React.ReactNode;
    containerStyle?: CSSProperties;
    cn?: string[];
    disabled?: boolean;
    onShowMenu?: () => void;
    noList?: () => void;
}

interface SelectListProps {
    title?: string;
    data: SelectItem[];
    listWidth: string;
    top?: string;
    bottom?: string;
    field?: string;
    addBtnText?: string;
    addList?: () => void;
    onSelect?: (id: any, title: string) => void;
    setFieldValue?: (field: string, value: any, validate?: boolean) => void
    onToggle: () => void;
}

const SelectList = ({
                        title,
                        data,
                        onToggle,
                        listWidth,
                        top,
                        bottom,
                        field,
                        addBtnText,
                        addList,
                        onSelect,
                        setFieldValue
                    }: SelectListProps) => {
    const addListPop = () => {
        onToggle();
        addList?.();
    };

    const style: CSSProperties = {width: listWidth};
    if (bottom) style.bottom = bottom;
    else style.top = top ?? 'calc(100% - .3rem)';

    return (
        <ul className={cx('select-list')} style={style}>
            {title != null && <li className={cx('title')}>{title}</li>}
            {data.map((li, idx) => <li key={idx} title={li.title} onClick={() => {
                onToggle();
                setFieldValue?.(field!, li.id);
                onSelect?.(li.id, li.title);
            }}>{li.title}</li>)}
            {addBtnText && <li className={cx('add-list')} onClick={addListPop}>{addBtnText} +</li>}
        </ul>
    );
};

const SelectButton = ({
                          children,
                          cn,
                          disabled,
                          onShowMenu,
                          containerStyle,
                          noList,
                          ...props
                      }: SelectButtonProps & Omit<SelectListProps, 'onToggle'>) => {

    const wrapperRef = useRef(null);
    const [openList, setOpenList] = useState(false);

    useOutsideAlerter(wrapperRef, setOpenList);

    const openListToggle = () => {
        if (disabled) return;
        if (props.data.length === 0 && noList) noList();
        setOpenList(!openList);
        if (!openList) onShowMenu?.();
    };

    return (
        <div className={cx('select-button')} ref={wrapperRef} style={containerStyle}>
            <div className={`${cx(cn)}`} onClick={openListToggle}>{children}</div>
            {openList && <SelectList onToggle={openListToggle} {...props}/>}
        </div>
    );
};

export default SelectButton;