import Footer from '../home/footer';
import '../../../../scss/home_common.scss'
import {ReviewBox} from "./review";
import styles from './review-detail.module.scss';
import className from 'classnames/bind';
import React, {useEffect, useRef, useState} from "react";
import {useInjection} from "inversify-react";
import {ExtraApi} from "../../../model/extra/extra-api";
import {CoReviewDto} from "../../../model/extra/dto/co-review-dto";
import MetaTag from "../../../components/meta-tag";
import SubHeader from "../home/sub-header";

const cx = className.bind(styles);

const ReviewDetail = () => {
    const reviewDetailRef = useRef<HTMLDivElement>(null);
    const extraApi = useInjection(ExtraApi);
    const [review, setReview] = useState<CoReviewDto[]>();
    const [scrollY, setScrollY] = useState<number>(0);

    useEffect(() => {
        reviewDetailRef.current?.scrollIntoView({behavior: 'auto'});

        (async () => {
            try {
                const result = await extraApi.getCoReview();
                if(result && result.data.val) {
                    let reviewList = result.data.val.filter((review) => review.active === "Y").sort((a, b) => a.priority - b.priority);
                    let reviewList2 = reviewList.sort((a,b) => Date.parse(a.date) - Date.parse(b.date));
                    setReview(reviewList2.reverse());
                }
            } catch (e) {
                console.log(e);
            }
        })();
    },[extraApi, reviewDetailRef]);

    if(!review) return <div></div>;

    return(
        <>
            <MetaTag viewportW='width=device-width, initial-scale=1, maximum-scale=1'/>
            <div className='home_page' onScroll={(e) => { setScrollY(e.currentTarget.scrollTop) }}>
                <SubHeader scrollY={scrollY}/>
                <div className={cx('review-detail')} ref={reviewDetailRef}>
                    <div className={`partners-section-inner ${cx('visual')}`}>
                        <div className={cx('left-area')}>
                            <p className={cx('title')}>확신을 가져다주는<br/>씨오파트너 실제 후기</p>
                            <p className={cx('sub-title')}>먼저 씨오파트너를 경험한<br className={cx('mobile-bk')}/> 실제 사용자들의<br className={cx('pc-bk')}/>만족도 높은<br className={cx('mobile-bk')}/> 후기를 확인해 보세요.</p>
                        </div>
                        <div className={cx('right-area')}>
                            <a href='https://forms.gle/Fsb5D3CVJYvEi91g8' target='_blank' className={cx('write-review-btn')}><span>리뷰작성 </span>제출하기</a>
                        </div>
                    </div>
                    <div className={cx('section2')}>
                        <div className={`partners-section-inner ${cx('grid-box')}`}>
                            <div className={cx('grid-column-box')}>
                                {review.filter((data, i) => i % 2 === 0).map((it, i) =>
                                    <ReviewBox key={i} data={it}/>
                                )}
                            </div>
                            <div className={cx('grid-column-box')}>
                                {review.filter((data, i) => i % 2 !== 0).map((it, i) =>
                                    <ReviewBox key={i} data={it}/>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
        </>
    );
}

export default ReviewDetail;