import React, {useState} from "react";
import classNames from "classnames/bind";
import styles from './price-policy-page.module.scss';
import MetaTag from "../../components/meta-tag";
import SubHeader from "../intro/home/sub-header";
import PricingFooter from "./components/pricing-footer";
import GrayBox from "./components/gray-box";
import MembershipCard from "./components/membership-card";
import PricingTable from "./components/pricing-table";
import usePromise from "../../model/common/use-promise";
import {useInjection} from "inversify-react";
import {GoodOpenApi} from "../../model/intro/good-open-api";
import WholePageLoading from "../../components/whole-page-loading";

const cx = classNames.bind(styles);

export interface MemberType {
    type: string;
    descList: string[];
    img?: string | null;
    btn: string;
}

const memberTypes:MemberType[] = [
    {
        type: '무료 회원',
        descList: [
            "회원가입만해도 간단기업정보 검색 가능!",
            "씨오랩스 전문가 조인웍 신청",
            "씨오포스트(법인컨설팅 칼럼)"
        ],
        img: null,
        btn: "사용문의"
    },
    {
        type: '단체 회원',
        descList: [
            "30인 이상 단체회원은 별도\n문의해 주세요."
        ],
        img: "il_group",
        btn: "도입문의"
    }
];

/*export interface MembershipType {
    subTitle: string;
    title: string;
    type: string;
    createdReportNum: number;
    price: number;
    pricePerCase?: number;
    generable: boolean;
    consultingLabs: boolean;
    katalk: boolean;
    onlineLecture: boolean;
    expertQnA: boolean;
    carryover: boolean;
    period: string;
    refundPolicy: string;
    carryoverPolicy?: string;
    color?: string;
    recommend: boolean;
}

const membershipTypes: MembershipType[] = [
    {
        subTitle: '전문가를 위한 올인원 패키지',
        title: '프리미엄 멤버십',
        type: '연간 멤버십 상품',
        createdReportNum: 100,
        price: 990000,
        pricePerCase: 10000,
        color: '#654CFF',
        recommend: true,
        generable: true,
        consultingLabs: true,
        katalk: true,
        onlineLecture: true,
        expertQnA: true,
        carryover: true,
        period: '구매일로부터 1년간 이용',
        refundPolicy: '14일 이내 환불 (전 메뉴 미사용시)',
        carryoverPolicy: '멤버십 이용기한 만료일\n전후 30일 이내 재연장시\n(단, 만료일 기준 90일 이내 사용 조건)'
    },
    {
        subTitle: '합리적인 비용으로 더 넉넉하게',
        title: '스탠다드 멤버십',
        type: '연간 멤버십 상품',
        createdReportNum: 50,
        price: 660000,
        pricePerCase: 13000,
        color: '#487BFF',
        recommend: false,
        generable: true,
        consultingLabs: true,
        katalk: true,
        onlineLecture: false,
        expertQnA: false,
        carryover: false,
        period: '구매일로부터 1년간 이용',
        refundPolicy: '14일 이내 환불 (전 메뉴 미사용시)',
        carryoverPolicy: '이월 불가'
    },
    {
        subTitle: '법인영업을 위한 최소한의 투자',
        title: '베이직 멤버십',
        type: '연간 멤버십 상품',
        createdReportNum: 25,
        price: 440000,
        pricePerCase: 18000,
        color: '#11B59A',
        recommend: false,
        generable: true,
        consultingLabs: true,
        katalk: true,
        onlineLecture: false,
        expertQnA: false,
        carryover: false,
        period: '구매일로부터 1년간 이용',
        refundPolicy: '14일 이내 환불 (전 메뉴 미사용시)',
        carryoverPolicy: '이월 불가'
    },
    {
        subTitle: '필요할 때 구매하는',
        title: '1회 이용권',
        type: '단건 구매 상품',
        createdReportNum: 1,
        price: 20000,
        recommend: false,
        generable: true,
        consultingLabs: false,
        katalk: false,
        onlineLecture: false,
        expertQnA: false,
        carryover: false,
        period: '구매일로부터 2일간 이용',
        refundPolicy: '환불 불가',
    }
];*/

const PricePolicyPage = () => {
    const [scrollY, setScrollY] = useState<number>(0);

    const goodOpenApi = useInjection(GoodOpenApi);
    const [loading, response] = usePromise(() => goodOpenApi.getPricePolicy(), [goodOpenApi]);

    if (loading || !response) {
        return <WholePageLoading/>
    }

    const goods = response.goods;
    const codes = response.codes;
    const uiView = response.uiView;

    return(
        <>
            <MetaTag viewportW='width=device-width, initial-scale=1, maximum-scale=1'/>
            <div className='home_page' onScroll={(e) => { setScrollY(e.currentTarget.scrollTop) }}>
                <SubHeader scrollY={scrollY}/>
                <div className={cx('content')}>
                    <h2>
                        나에게 맞는 연간 멤버십<br className={cx('mobile-bk')}/> 상품으로 <br className={cx('pc-bk')}/>
                        씨오파트너의 <br className={cx('mobile-bk')}/>다양한 서비스를 경험해 보세요
                    </h2>
                    <div className={cx('flex-box')}>
                        {memberTypes.map((it, i) => <GrayBox key={i} type={it.type} descList={it.descList} img={it.img} btn={it.btn}/>)}
                    </div>
                    <div className={cx('grid-box')}>
                        {goods.map((it, i) =>
                            <MembershipCard key={i} goods={it}/>
                        )}
                    </div>
                    <p className={cx('table-title')}>이용권 요금제 비교</p>
                    <PricingTable goods={goods} codes={codes} uiView={uiView} />
                    <div className={cx('deduct-policy')}>
                        <p className={cx('deduct-title')}>이용권 차감 정책</p>
                        <ul className={cx('deduct-policy-list')}>
                            <li><i></i><span><span className={cx('bold')}>기업 기본현황을 확인</span>하고 고객을 등록할 수 있어요.<br className={cx('mobile-bk')}/> 고객등록(리포트 생성) 전까지는<span className={cx('bold')}> 이용권이 차감되지 않아요.</span></span></li>
                            <li><i></i><span><span className={cx('bold')}>개인기업 리포트</span>는 이용권 차감없이 무제한으로 생성 가능해요.</span></li>
                        </ul>
                    </div>
                </div>
                <PricingFooter/>
            </div>
        </>
    );
}

export default PricePolicyPage;