import React from 'react';
import {useInjection} from 'inversify-react';
import {DialogManager} from './dialog-manager';
import {observer} from 'mobx-react-lite';

const DialogContainer = observer(() => {
    const dialogsManager = useInjection(DialogManager);

    return <div id='dialog'>{
        dialogsManager.dialogs.map(({Comp, props}) => {
            const props2 = Object.assign({}, props);
            props2.onClose = () => {
                dialogsManager.close(props.key);
            }
            props2.onAccept = () => {
                if (props.onAccept) props.onAccept();
                dialogsManager.close(props.key);
            }
            return <Comp {...props2}/>
        })
    }</div>;
});
export default DialogContainer;