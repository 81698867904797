import {usePdfComponents} from "../pdf-hooks";
import {StyleSheet} from '@react-pdf/renderer';
import {getMultiplyValue, getRowT} from "../../../styles/styleSheet";
import {PrCorpRegRoot} from "../../../model/customer/dto/pr-corp-reg-root";
import {
    outputPrCrRegisterStr,
    outputPrCrChangeStr,
    RegisterDateInfo,
    OneInfos,
    MultiInfos
} from "./corporate-regi-copy-common";
import {formatNum, ymdToDateString} from "../../../util/str-util";
import React from "react";
import {SummaryReport} from "../../../model/inquiry/dto/summary-report";
import {useCorporateRegStyle} from "./corporate-regi-context";

const CorporateRegiCopyTable = ({corpReg, summary}: { corpReg: PrCorpRegRoot, summary: SummaryReport }) => {
    const {View, Text, isPdf} = usePdfComponents();
    const rowT = getRowT(isPdf);
    const num = getMultiplyValue(isPdf);
    const depStyles = useCorporateRegStyle();

    const styles = StyleSheet.create({
        copyTable: {
            width: '100%',
            paddingTop: 27 * num
        },
        topLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingBottom: 24 * num
        },
        corporateNum: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 624 * num,
            height: 36 * num,
            border: '1px solid #999',
            borderRadius: 8 * num,
        },
        body: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: `0 ${11 * num}px`,
            color: '#4c4c4c',
            fontSize: 13 * num,
        },
        grayBox: {
            backgroundColor: '#f2f2f2',
            justifyContent: 'flex-start',
            height: '100%',
            padding: `0 0 0 ${22 * num}px`
        },
        inquiryDate: {
            fontSize: 13 * num,
            color: '#808080'
        },
        flexWrap: {
            paddingBottom: 24 * num
        },
        desc: {
            padding: '0 30px 0 26px',
            lineHeight: 1.7 * num
        },
        desc2: {
            maxWidth: 153 * num
        },
        stockList: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 241 * num
        },
        ceoInfo: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 405 * num,
            paddingLeft: 26 * num,
        },
        borderL: {
            borderLeft: `${0.5 * num}px solid #ccc`
        },
        twoLineTitle: {
            display: 'flex',
            flexDirection: 'column',
        },
        cancelLine: {
            textDecoration: 'line-through'
        }
    });

    return (
        <View style={styles.copyTable}>
            <View style={styles.topLine}>
                <View style={styles.corporateNum}>
                    <View style={[styles.body, styles.grayBox, {
                        borderTopLeftRadius: 8,
                        borderBottomLeftRadius: 8
                    }]}><Text>등기번호</Text></View>
                    <View style={styles.body}><Text>{corpReg.resRegistrationNumber}</Text></View>
                    <View style={[styles.body, styles.grayBox]}><Text>등록번호</Text></View>
                    <View style={styles.body}><Text>{corpReg.resRegNumber}</Text></View>
                </View>
                <Text style={styles.inquiryDate}>조회일시 {ymdToDateString(corpReg.searchDate)}</Text>
            </View>
            <View style={[rowT.flexWrap, styles.flexWrap]}>
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>{summary.CS.company}</Text></View>
                    <View style={[rowT.descCover, rowT.borderR]}><Text
                        style={styles.desc}>{corpReg.companyNm.val}</Text></View>
                    <View style={[rowT.descCover, styles.desc2]}><Text
                        style={styles.desc}>{outputPrCrRegisterStr(corpReg.companyNm.registerDate)}</Text></View>
                </View>
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>{summary.CS.address}</Text></View>
                    <View style={[rowT.descCover, rowT.borderR]}><Text
                        style={styles.desc}>{corpReg.userAddr.val}</Text></View>
                    <View style={[rowT.descCover, styles.desc2]}><Text
                        style={styles.desc}>{outputPrCrRegisterStr(corpReg.userAddr.registerDate)}</Text></View>
                </View>

                {corpReg.noticeMethod.val && <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>공고방법</Text></View>
                    <View style={[rowT.descCover, rowT.borderR]}><Text
                        style={styles.desc}>{corpReg.noticeMethod.val}</Text></View>
                    <View style={[rowT.descCover, styles.desc2]}>
                        <Text
                            style={styles.desc}>{outputPrCrChangeStr(corpReg.noticeMethod.changeDate)}</Text>
                        <Text
                            style={styles.desc}>{outputPrCrRegisterStr(corpReg.noticeMethod.registerDate)}</Text>
                    </View>
                </View>}

                {corpReg.oneStockAmt.val && <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}>
                        <Text style={rowT.title}>{summary.CS.oneStock}</Text></View>
                    <View style={[rowT.descCover, rowT.borderR]}><Text
                        style={styles.desc}>금 {formatNum({num: corpReg.oneStockAmt.val})}원</Text></View>
                    <View style={[rowT.descCover, styles.desc2]}>
                        <Text style={styles.desc}>{outputPrCrChangeStr(corpReg.oneStockAmt.changeDate)}</Text>
                        <Text style={styles.desc}>{outputPrCrRegisterStr(corpReg.oneStockAmt.registerDate)}</Text>
                    </View>
                </View>}

                { corpReg.stock.capital &&
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>자본금의 액</Text></View>
                    <View style={[rowT.descCover, rowT.borderR]}><Text
                        style={styles.desc}>금 {formatNum({num: corpReg.stock.capital})}원</Text></View>
                    <View style={[rowT.descCover, styles.desc2, {alignSelf: 'flex-start', paddingTop: 11}]}>
                        <Text style={styles.desc}>{outputPrCrChangeStr(corpReg.stock.changeDate)}</Text>
                        <Text style={styles.desc}>{outputPrCrRegisterStr(corpReg.stock.registerDate)}</Text>
                    </View>
                </View>}

                {corpReg.stock.cntIssuedStock &&
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}>
                        <View style={styles.twoLineTitle}><Text style={[rowT.title, {paddingBottom: 7}]}>발행주식의 총수와</Text><Text style={rowT.title}>그 종류 및 각각의 수</Text></View>
                    </View>
                    <View style={[rowT.descCover, rowT.borderR]}>
                        {corpReg.stock.cntIssuedStock && <View style={[styles.desc, styles.stockList]}><Text>발행주식의
                            총수</Text><Text>{formatNum({num: corpReg.stock.cntIssuedStock})}주</Text></View>}

                        {corpReg.stock.commonStock &&
                            <View style={[styles.desc, styles.stockList]}><Text style={{textIndent: 12}}>보통주식</Text><Text>{formatNum({num: corpReg.stock.commonStock})}주</Text></View>}

                        {(corpReg.stock.preferredStock && corpReg.stock.preferredStock.length > 0) &&
                            corpReg.stock.preferredStock.map((v, idx2) => <View key={idx2} style={[styles.desc, styles.stockList]}><Text
                                style={{textIndent: 12}}>우선주식</Text><Text>{formatNum({num: v})}주</Text></View>)

                        }
                        {(corpReg.stock.convPreferredStock && corpReg.stock.convPreferredStock.length > 0) &&
                            corpReg.stock.convPreferredStock.map((v, idx2) => <View key={idx2} style={[styles.desc, styles.stockList]}><Text
                                style={{textIndent: 12}}>상환전환우선주식</Text><Text>{formatNum({num: v})}주</Text></View>)
                        }
                    </View>
                    <View style={[rowT.descCover, styles.desc2]}><Text style={styles.desc}></Text></View>
                </View>}

                {corpReg.cntStockIssue.val &&
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>발행할 주식의
                        총수</Text></View>
                    <View style={[rowT.descCover, rowT.borderR]}><Text
                        style={styles.desc}>{formatNum({num: corpReg.cntStockIssue.val})}주</Text></View>
                    <View style={[rowT.descCover, styles.desc2]}>
                        <Text style={styles.desc}>{outputPrCrChangeStr(corpReg.cntStockIssue.changeDate)}</Text>
                        <Text style={styles.desc}>{outputPrCrRegisterStr(corpReg.cntStockIssue.registerDate)}</Text>
                    </View>
                </View>}

                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>회사성립연월일</Text></View>
                    <View style={[rowT.descCover, rowT.borderR]}><Text style={styles.desc}>
                        {ymdToDateString(corpReg.corpEstablishDate, 'yyyy년 MM월 dd일')}
                    </Text></View>
                    <View style={[rowT.descCover, styles.desc2]}><Text style={styles.desc}></Text></View>
                </View>
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>관할 등기소</Text></View>
                    <View style={[rowT.descCover, rowT.borderR]}><Text
                        style={styles.desc}>{corpReg.commCompetentRegistryOffice}</Text></View>
                    <View style={[rowT.descCover, styles.desc2]}><Text style={styles.desc}></Text></View>
                </View>
            </View>

            <View style={[rowT.flexWrap, styles.flexWrap]}>
                <OneInfos title='목적' val={corpReg.purpose} rowT={rowT}/>
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>{summary.CS.regHis}</Text></View>
                    <View style={{flex: 1}}>
                        {corpReg.ceos.map((ceo, idx) =>
                            <View
                                style={(idx === (corpReg.ceos.length - 1) && corpReg.boardMembers.length === 0) ? [rowT.flexNoLine] : [rowT.flexLine]}
                                key={idx}>
                                <View style={[rowT.descCover, {maxWidth: 151}]}>
                                    <Text style={[styles.desc]}>{ceo.type}</Text>
                                </View>
                                <View style={[rowT.descCover, styles.borderL]}>
                                    <View
                                        style={[styles.desc, styles.ceoInfo]}><Text>{ceo.name}</Text><Text>{ceo.birth}</Text></View>
                                    <View style={[styles.desc, styles.ceoInfo]}><Text>{ceo.addr}</Text></View>
                                    {ceo.talkDate &&
                                        <View
                                            style={[styles.desc, styles.ceoInfo]}><Text>{ymdToDateString(ceo.talkDate, 'yyyy년 MM월 dd일')} 취임</Text></View>
                                    }
                                    {ceo.reappDate &&
                                        <View
                                            style={[styles.desc, styles.ceoInfo]}><Text>{ymdToDateString(ceo.reappDate, 'yyyy년 MM월 dd일')} 중임</Text>
                                            <Text>{ymdToDateString(ceo.reappRegDate, 'yyyy년 MM월 dd일')} 등기</Text>
                                        </View>
                                    }
                                </View>
                            </View>
                        )}

                        {corpReg.boardMembers.filter((mem) => mem.show).map((mem, idx) =>
                            <View
                                style={(idx === corpReg.boardMembers.length - 1) ? [rowT.flexNoLine] : [rowT.flexLine]}
                                key={idx}>
                                <View style={[rowT.descCover, {maxWidth: 151}]}>
                                    <Text style={[styles.desc]}>{mem.type}</Text>
                                </View>
                                <View style={[rowT.descCover, styles.borderL]}>
                                    <View
                                        style={[styles.desc, styles.ceoInfo]}><Text>{mem.name}</Text><Text>{mem.birth}</Text></View>
                                    {mem.talkDate &&
                                        <View
                                            style={[styles.desc, styles.ceoInfo]}><Text>{ymdToDateString(mem.talkDate, 'yyyy년 MM월 dd일')} 취임</Text></View>
                                    }
                                    {mem.reappDate &&
                                        <View
                                            style={[styles.desc, styles.ceoInfo]}><Text>{ymdToDateString(mem.reappDate, 'yyyy년 MM월 dd일')} 중임</Text>
                                            <Text>{ymdToDateString(mem.reappRegDate, 'yyyy년 MM월 dd일')} 등기</Text>
                                        </View>}

                                    {mem.etc.map((e, i) =>
                                        <View key={i}
                                            style={[styles.desc, styles.ceoInfo]}><Text>{e}</Text>
                                        </View>
                                    )}

                                </View>
                            </View>)}
                    </View>
                </View>
            </View>
        </View>
    );
};

export const OthersInfo = ({corpReg}: { corpReg: PrCorpRegRoot }) => {
    const {View, Text, isPdf} = usePdfComponents();
    const rowT = getRowT(isPdf);
    const num = getMultiplyValue(isPdf);
    const depStyles = useCorporateRegStyle();

    const styles = StyleSheet.create({
        flexWrap: {
            paddingBottom: 24 * num
        },
        desc: {
            padding: `0 ${30 * num}px 0 ${26 * num}px`,
            lineHeight: 1.7 * num
        },
        othersTitle: {
            fontSize: 13 * num,
            fontWeight: 700
        },
        haveTwoCellTitle: {
            flex: 1,
            height: 'auto'
        },
        cellPad: {
            padding: `${8 * num}px 0`
        },
        flexBox: {
            display: 'flex',
            flexDirection: 'row'
        },
        cancelLine: {
            textDecoration: 'line-through'
        }
    });

    return (
        <View style={[rowT.flexWrap, styles.flexWrap]}>
            {corpReg.etc.length > 0 &&
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>기타사항</Text></View>
                    <View style={styles.haveTwoCellTitle}>
                        {
                            corpReg.etc.map((etc, idx) =>
                                <View key={idx}
                                      style={(idx === corpReg.etc.length - 1) ? [styles.cellPad] : [rowT.borderB, styles.cellPad]}>
                                    <Text key={idx} style={[styles.desc, styles.othersTitle]}>{etc.title}</Text>
                                    {
                                        etc.contents.map((etcOne, idx2) => <RegisterDateInfo key={idx2} s={etcOne} rowT={rowT}/>)
                                    }
                                </View>)
                        }
                    </View>
                </View>
            }
            <OneInfos title='지점/분사무소' val={corpReg.branch} rowT={rowT}/>
            <OneInfos title='합작참여자' val={corpReg.joinPartner} rowT={rowT}/>
            <OneInfos title='지배인/대리인' val={corpReg.manager} rowT={rowT}/>
            <MultiInfos title='전환사채' multiVal={corpReg.convertibleBond} rowT={rowT}/>
            <MultiInfos title='신주인수권부사채' multiVal={corpReg.warrantBond} rowT={rowT}/>
            <MultiInfos title='이익참가부사채' multiVal={corpReg.participatingBond} rowT={rowT}/>
            <OneInfos title='주식매수선택권' val={corpReg.stockOption} rowT={rowT}/>
            <MultiInfos title='종류주식의 내용' multiVal={corpReg.typeStockContent} rowT={rowT}/>
            <MultiInfos title='전환형조건부자본증권' multiVal={corpReg.capitalStock} rowT={rowT}/>
        </View>
    );
};

export default CorporateRegiCopyTable;