import {useInjection} from "inversify-react";
import {PaymentApi} from "./payment-api";
import {DialogManager} from "../../components/dialog-manager";
import {requestPayment, requestSubscription} from "./bootpay-connect";
import {CoSubscriptApi} from "./co-subscript-api";
import {PrMemberPayApi} from "./pr-member-pay-api";
import {PayExtraData} from "./dto/pay-extra-data";
import {AuthStore} from "../auth/auth-store";


export const usePayment = () : [
    (goodId: string, goodName: string, onBillingOk: () => void, onComplete: (payId: string) => void, onError: (message: string, error_code: string) => void) => void,
    (goodId: string, goodName: string, payExtraData: PayExtraData, onAuthOk: () => void, onComplete: (payId: string) => void, onError: (message: string, error_code: string) => void) => void,
] => {
    const paymentApi = useInjection(PaymentApi);
    const dialogManager = useInjection(DialogManager);
    const authStore = useInjection(AuthStore);

    // 구독을 시작한다.
    // TODO : 이후 쿠폰 작업시 대응해줘야 한다.
    const startSubscription = async (goodId: string, goodName: string, onBillingOk: () => void, onComplete: (payId: string) => void, onError: (message: string, error_code: string) => void) => {
        try {
            const response = await requestSubscription(goodName);

            if (response.event === 'done') {
                onBillingOk();
                const ssResult = await paymentApi.startSubscription({goodId: goodId, repId: response.data.receipt_id});

                if (ssResult.success) {
                    onComplete(ssResult.val?.paymentId ?? "");
                } else {
                    onError(ssResult.message, "");
                }
            }
        } catch (e: any) {
            dialogManager.alert(e.message);
        }
    }

    const startSinglePayment = async (goodId: string, goodName: string, payExtraData: PayExtraData, onAuthOk: () => void, onComplete: (payId: string) => void, onError: (message: string, error_code: string) => void) => {
        try {
            const payPrepareRes = await paymentApi.preparePayment(goodId, 'CARD');
            const payPrepare = payPrepareRes.val!;

            await requestPayment(goodName, payPrepare.orderId, payPrepare.totalPrice, authStore.authInfo.member?.name ?? "",  async (repId: string) => {
                onAuthOk();

                const paymentRes = await paymentApi.confirmPaymentV2(payPrepare.paymentId, repId, payExtraData);

                const payment = paymentRes.val!;

                if (payment.state == 'COMPLETE') {
                    onComplete(payment.id);
                } else if (payment.state == 'ERROR') {
                    onError(payment.error!.message, payment.error!.errorCode);
                }
            }, onError);
        } catch (e: any) {
            dialogManager.alert(e.message);
        }
    }

    return [startSubscription, startSinglePayment];
}

export const useChangePaymentCard = () => {
    const dialogManager = useInjection(DialogManager);
    const coSubscriptApi = useInjection(CoSubscriptApi);

    const openChangePaymentMethod = async (ssId: string, onBillingOk: () => void, onComplete: () => void, onError: (message: string, error_code: string) => void) => {
        try {
            const response = await requestSubscription('결제 수단 변경');

            if (response.event === 'done') {
                onBillingOk();
                const ssResult = await coSubscriptApi.changePaymentMethod(ssId, response.data.receipt_id);

                if (ssResult.success) {
                    onComplete();
                } else {
                    onError(ssResult.message, "");
                }
            }
        } catch (e: any) {
            dialogManager.alert(e.message);
        }
    }

    return [openChangePaymentMethod]
}

export const useRegisterCard = () => {
    const dialogManager = useInjection(DialogManager);
    const prMemberPayApi = useInjection(PrMemberPayApi);

    const openRegisterCard = async (onBillingOk: () => void, onComplete: () => void, onError: (message: string, error_code: string) => void) => {
        try {
            const response = await requestSubscription('간편 카드 등록');

            if (response.event === 'done') {
                onBillingOk();
                const ssResult = await prMemberPayApi.registerMemberBilling(response.data.receipt_id);

                if (ssResult.success) {
                    onComplete();
                } else {
                    onError(ssResult.message, "");
                }
            }
        } catch (e: any) {
            dialogManager.alert(e.message);
        }
    }

    return [openRegisterCard]
}