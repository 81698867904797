import React from 'react';
import styles from './provisional-payment.module.scss';
import classNames from 'classnames/bind';
import {InputBox, NumberInputBox} from './customer/edit-common';
import {Form, Formik} from 'formik';
import {ReportAddInfoPopProps} from './report-add-info-pop';
import {getFsRefYearV2} from "./report/report-util";
import usePromise from "../model/common/use-promise";
import {useInjection} from "inversify-react";
import {CustomerApi} from "../model/customer/customer-api";
import {PrCustomerRawData} from "../model/customer/dto/customer-raw-data";
import {CustomerInputApi} from "../model/customer/customer-input-api";
import {asyncCall} from "../util/util";
import {toast} from "react-toastify";
import {strSaved} from "../data/strings";
import {useMountedState} from "react-use";
import {DialogManager} from "../components/dialog-manager";
import {useCustomerInputClear} from "../model/customer/customer-input-hook";

const cx = classNames.bind(styles);


const ProvisionalPayment = ({data, setData, onClose}: ReportAddInfoPopProps) => {
    const customerApi = useInjection(CustomerApi);
    const customerInputApi = useInjection(CustomerInputApi);
    const dialogManager = useInjection(DialogManager);
    const [loading, rowDataResult] = usePromise(() => customerApi.rawData(data.customer.id), [data]);
    const isMounted = useMountedState();
    const year = getFsRefYearV2(data);
    const years: number[] = [];
    const yearsForIdx: {[key: number]: number} = {};
    const rawData: PrCustomerRawData = rowDataResult?.val!;
    const {clearInfo} = useCustomerInputClear(setData);

    for (let i = 0; i < 3; i++) {
        years[i] = year - 2 + i;
    }

    const getInputs = () => {
        return years.map((year, i) => {
            let idx = rawData.provPayInfo.provPays.findIndex(v => v.year == year);
            if (idx === -1) {
                idx = rawData.provPayInfo.provPays.length - 1;
                if (yearsForIdx[idx]) idx++;
                yearsForIdx[idx] = year;
            }
            const name = `provPays.${idx}`;

            return <div key={i} className={cx('inner-grid-box')}>
                <p className={cx('year-txt')}>{year}년</p>
                <NumberInputBox name={`${name}.provPay`} unit='원' inputWidth='16rem' readOnly={true}/>
                <NumberInputBox name={`${name}.provPayManual`} unit='원' inputWidth='16rem'/>
                <NumberInputBox name={`${name}.provPayDailyTotal`} unit='원' inputWidth='16rem'/>
                <NumberInputBox name={`${name}.nonBzAssetDailyTotal`} unit='원' inputWidth='16rem'/>
                <NumberInputBox name={`${name}.nonBzAssetInterest`} unit='원' inputWidth='16rem'/>
            </div>
        });
    }

    if (!rawData) {
        return <></>;
    }

    return (
        <Formik
            initialValues={
                {
                    ...rawData.provPayInfo,
                    provPayInterestRate: rawData.provPayInfo.provPayInterestRate * 100
                }
            }
            onSubmit={async (values) => {
                const form = {
                    provPays: [...values.provPays.map((v, i) => {
                        if (!v.year) {
                            v.year = yearsForIdx[i];
                            v.provPay = 0;
                        }
                        return v;
                    })],
                    provPayInterestRate: values.provPayInterestRate * 0.01
                }

                asyncCall(customerInputApi.saveProvPayInfo, [data.customer.id, form], result => {
                    setData(result);
                    data.customer = result.customer;//팝업에서 사용하는 데이터 업데이트
                    toast.success(strSaved);
                }, isMounted, (e) => {
                    dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                });
            }}>
            {({resetForm}) => {
                return <Form className={cx('provisional-payment')}>
                    <div className={cx('btn-area')}>
                        <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                        <button className='close-btn' type='button' onClick={() => {
                            onClose()
                        }}>닫기
                        </button>
                    </div>
                    <div className={cx('scroll-box')}>
                        <div className={cx('scroll-area')}>
                            <p className={cx('title')}>가지급금 정보
                                <button className={cx('reset-btn')} type='button'
                                        onClick={() => clearInfo(data.customer.id, 'provPayInfo', (report, rawData) => resetForm({ values: {
                                                    ...rawData!.provPayInfo,
                                                    provPayInterestRate: rawData!.provPayInfo.provPayInterestRate * 100
                                                }}))}><i></i>초기화
                                </button>
                            </p>

                            <div className={cx('grid-box')}>
                                <div className={cx('inner-grid-box', 'box2')}>
                                    <p></p>
                                    <p>가지급금</p>
                                    <p>가지급금 직접입력<span className='numbering'>1)</span></p>
                                    <p>가지급금적수<span className='numbering'>2)</span></p>
                                    <p>업무무관자산 차입금적수<span className='numbering'>3)</span></p>
                                    <p>업무무관자산 차입금 이자비용</p>
                                </div>
                                {getInputs()}
                            </div>
                            <div className={cx('grid-box2')}>
                                <p>가지급금 인정이자율</p>
                                <InputBox name={'provPayInterestRate'}
                                          inputboxCn={cx('input-box')} unit='%'
                                          type='number' inputWidth='16rem'/>
                                <div></div>
                                <div></div>
                            </div>
                            <p className={`footNote`}>
                                주1) 단기대여금, 주임종단기대여금, 특수관계자단기대여금, 가지급금 등 이외 계정으로 가지급금이 숨어 있을 때 직접 반영할 수 있습니다.<br/>
                                주2) 세무조정계산서 가지급금 및 인정이자 명세서상의 가지급금적수 금액을 입력하면 더욱 정확한 분석이 가능합니다.<br/>
                                주3) 세무조정계산서 업무무관자산 차입금 적수 및 이자비용을 입력하면 더욱 정확한 분석이 가능합니다.
                            </p>
                        </div>
                    </div>
                </Form>;
            }}
        </Formik>
    );
};

export default ProvisionalPayment;