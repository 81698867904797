import {Navigate, Route, Routes} from 'react-router-dom';
import React from 'react';
import {useInjection} from 'inversify-react';
import {AuthStore} from '../model/auth/auth-store';
import {observer} from 'mobx-react-lite';
import loadable from '@loadable/component';
import KatalkPage from "../pages/katalk/katalk-page";
import ReviewDetail from "../pages/intro/home/review-detail";
import PricePolicyPage from "../pages/pricePolicy/price-policy-page";


const NotLoginPage = loadable(() => import('./not-login-page'))
const IntroPage = loadable(() => import('../pages/intro/intro'))
// const EventPage = loadable(() => import('./event-page'))
const LoggedInPage = loadable(() => import('./logged-in-page'))
// const TestPage = loadable(() => import('../pages/test/test-page'))

/**
 * ROOT LAYOUT
 * 하위 컴포넌트들은 Lazy 로딩을 한다.
 */
const RootLayout = observer(() => {
    const authStore = useInjection(AuthStore);
    const isLoggedIn = authStore.isLoggedIn();

    return (
        <Routes>
            {!isLoggedIn && <>
                <Route path="/" element={<Navigate to="/intro" />} />
                <Route path='/intro/:id/*' element={<IntroPage/>}/>
                <Route path='/intro/*' element={<IntroPage/>}/>
                <Route path='/intro/review/review-detail' element={<ReviewDetail/>}/>
                <Route path='*' element={<NotLoginPage/>}/>
            </>}
            {isLoggedIn && <>
                {/*<Route path='test/*' element={<TestPage/>}/>*/}
                {/*<Route path='event/*' element={<EventPage/>}/>*/}
                <Route path='*' element={<LoggedInPage/>}/>
            </>}
            <Route path='/katalk' element={<KatalkPage/>}/>
            <Route path='/pricing' element={<PricePolicyPage/>}/>
            {/*<Route path='/event' element={<FindSupportFundRefund/>}/>*/}

        </Routes>
    );
});

export default RootLayout;