import React, {Key} from 'react';
import cn from 'classnames';

export interface ClosableProps {
    onClose: VoidFunction;
}

export interface DialogProps extends ClosableProps {
    key?: Key;
    children?: React.ReactNode;
    mainBtnText?: string;
    leftBtnText?: string;
    btnCount?: number; // 버튼 갯수
    onAccept?: VoidFunction;
    onClose2?: VoidFunction;
    active?: boolean; // 메인 버튼 활성화, 비활성화
    useOutsideClick?: boolean; // 취소 버튼 없을 경우 외부 클릭시 창 닫힘
}

const Dialog = ({
                    children,
                    mainBtnText = '확인',
                    leftBtnText = '닫기',
                    btnCount = 2,
                    active,
                    onClose,
                    onAccept,
                    onClose2,
                    useOutsideClick
                }: DialogProps) => {
    return (
        <div className='full-background' onClick={() => useOutsideClick && onClose()}>
            <div className='pop-inner dialog-type-a'>
                <div className='text'>
                    {children}
                </div>
                <div className={`btn-area ${cn(btnCount === 1 && 'one-button')}`}>
                    {btnCount === 2 && <button className='close-btn' onClick={() => {
                        onClose2 && onClose2(); 
                        onClose();
                        }}>{leftBtnText ?? '닫기'}</button>}
                    <button className='main-btn' autoFocus
                            disabled={active != null && !active} onClick={onAccept}>{mainBtnText}</button>
                </div>
            </div>
        </div>
    );
};

export default Dialog;