import lottie from 'lottie-web';
import {useEffect, useRef} from 'react';

interface LottieCompProps {
    jsonFile: string
    width: string
    height?: string
    speed?: number
    loop?: boolean
}

const LottieComp = ({jsonFile, width, height, speed, loop=true}:LottieCompProps) => {

    const container = useRef(null);

    useEffect(() => {
        if (container.current) {
            const instance = lottie.loadAnimation({
                container: container.current,
                renderer: 'svg',
                loop: loop,
                autoplay: true,
                animationData: require(`../../json/${jsonFile}.json`),
                rendererSettings: {
                    preserveAspectRatio: 'xMidYMid slice',
                },
            });
            
            speed && instance.setSpeed(speed);

            return () => {
                instance.destroy();
            }
        }
    }, [jsonFile]);

    return (
        <div ref={container} className={`lottie`} style={{width: width, height: height}}></div>
    );
};

export default LottieComp;