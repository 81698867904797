import styles from './payment-system-table-pop.module.scss';
import classNames from 'classnames/bind';
import React from "react";
import {useInjection} from "inversify-react";
import usePromise from "../model/common/use-promise";
import {addComma, calcPerPrice, checkAuthType, formatNum} from "../util/str-util";
import Check from "../pages/pricePolicy/components/check";
import {GoodOpenApi} from "../model/intro/good-open-api";
import LottieComp from "./lottie-comp";

const cx = classNames.bind(styles);

const PaymentSystemTablePop = ({onClose}: { onClose: VoidFunction }) => {
    const goodOpenApi = useInjection(GoodOpenApi);
    const [loading, response] = usePromise(() => goodOpenApi.getPricePolicy(), [goodOpenApi]);

    if (loading || !response) {
        return <div className={cx('loading')}>
            <LottieComp width='10rem' height='10rem' jsonFile='loading'/>
            <p className={cx('sub-title')}>로딩중.</p>
        </div>
    }

    const goods = response.goods;
    const codes = response.codes;
    const uiView = response.uiView;

    console.log(goods);

    return (
        <div className='full-background'>
            <div className={`pop-inner ${cx('payment-sys-table')}`}>
                <div className={cx('top-line')}>
                    <div className={cx('title')}>
                        이용권 요금제 상세비교
                    </div>
                    <button className='close-btn' onClick={() => onClose()}>닫기</button>
                </div>
                <p className={cx('sub-title')}>씨오파트너의 요금제를 비교하고 결제를 진행해 주세요.</p>

                {
                    (loading || !goods) ? <div>Loading</div> :
                        <div className={cx('table')}>
                            <div className={cx('head')}>
                                <p></p>
                                {goods.map((g) => <p key={g.id}>
                                    {g.name}
                                </p>)}
                            </div>
                            <div className={cx('scroll-area')}>
                                <div className={cx('body')}>
                                    <p>상품종류</p>
                                    {goods.map((g) => <p key={g.id}>
                                        {g.type === 'MEMBERSHIP' && <>연간 멤버십 상품</>}
                                        {g.type === 'SINGLE' && <>단건 구매 상품</>}
                                    </p>)}
                                </div>
                                <div className={cx('body')}>
                                    <p>이용권 수</p>

                                    {goods.map((g) => <p key={g.id}>
                                        <span className={cx('bold')}>{g.meta.val2 ?? ""}건</span><span>/

                                        {+(g.meta.val3 ?? "0") >= 365 ? `${+(g.meta.val3 ?? "0") / 365}년` : `${g.meta.val3}일`}
                                </span>
                                    </p>)}
                                </div>
                                <div className={cx('body')}>
                                    <p>금액</p>
                                    {goods.map((g) => <p key={g.id}>
                                        {formatNum({num: g.price, empty: '', type: 'txt'})}원
                                    </p>)}
                                </div>
                                <div className={cx('body')}>
                                    <p>건당 환산 금액</p>
                                    {goods.map((g) => <p key={g.id}>
                                        {addComma(calcPerPrice(g.price, +(g.meta.val2 ?? "0")))}원
                                    </p>)}
                                </div>
                                <div className={cx('menu-title')}>
                                    멤버십 회원 전용 메뉴
                                </div>
                                {
                                    codes
                                        .filter((it, i) => uiView[it.code])
                                        .map((code) => {
                                            return <div key={code.no} className={cx('body')}>
                                                <p>{code.codeVal}</p>
                                                {goods.map((g) => <p key={g.id}>
                                                    {checkAuthType(code.code, g.goodsAuth) ?
                                                        <Check size={24} color={'#5c67ff'}/> : <i
                                                            className={cx('no-use')}></i>}

                                                </p>)}
                                            </div>
                                        })
                                }

                                <div className={cx('body')}>
                                    <p>이용기간</p>
                                    {
                                        goods.map((it, i) => <p
                                            key={i}>구매일로부터 {+(it.meta.val3 ?? "0") >= 365 ? `${+(it.meta.val3 ?? "0") / 365}년` : `${it.meta.val3}일`}간 이용</p>)
                                    }
                                </div>
                                <div className={cx('body')}>
                                    <p>환불정책</p>
                                    {
                                        goods.map((it, i) => <p
                                            key={i}>{it.content.refundPolicy ? '14일 이내 환불 (전 메뉴 미사용시)' : '환불 불가'}</p>)
                                    }
                                </div>
                                <div className={cx('body')}>
                                    <p>잔여이용권<br/>이월정책</p>
                                    {
                                        goods.map((it, i) => <pre key={i}>{it.type === 'MEMBERSHIP' ?
                                            (checkAuthType('CARRY_OVER', it.goodsAuth) ? '멤버십 이용기한 만료일\n전후 30일 이내 재연장시\n(단, 만료일 기준 90일 이내 사용 조건)' : '이월 불가') : ''}</pre>)
                                    }
                                </div>

                            </div>
                        </div>
                }
                <div className={cx('body2')}>
                    <p>이용권 차감 정책</p>
                    <p className={cx('list')}>
                        <span><i></i><span
                            className={cx('bold')}>기업 기본현황을 확인</span>하고 고객을 등록할 수 있어요. 고객등록(리포트 생성) 전까지는 <span
                            className={cx('bold')}>이용권이 차감되지 않아요.</span></span><br/>
                        <span><i></i><span className={cx('bold')}>개인기업 리포트</span>는 이용권 차감없이 무제한으로 생성 가능해요.</span>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PaymentSystemTablePop;