import {Field, FieldArray, Form, Formik} from 'formik';
import styles from './add-list-pop.module.scss';
import classNames from 'classnames/bind';
import {SelectItem} from '../model/types';
import {Dispatch, SetStateAction} from 'react';

const cx = classNames.bind(styles);

interface AddListPopProps {
    title: string;
    width: string;
    list: SelectItem[];
    setList: Dispatch<SetStateAction<SelectItem[]>>;
    placeholder?: string;
    hasUnit?: boolean;
    fieldName: string;
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
    isNum?: boolean;
    onClose: VoidFunction;
}

interface AddListItemProps {
    index: number;
    value: string;
}

const AddListPop = ({title, width, list, setList, placeholder='입력', hasUnit=false, fieldName, setFieldValue, isNum = false, onClose}: AddListPopProps) => {
    //TODO: 중복 아이템 방지

    const items:AddListItemProps[] = [...list.map((v, i) => ({index: i, value: v.id}))];
    items.push({index: items.length, value: ''});

    return (
        <div className='full-background' onClick={onClose}>
            <div className={`pop-inner ${cx('edit-stage-list-pop')}`} style={{width: width}} onClick={(e:any) => e.stopPropagation()}>
                <p className={cx('stage-list-title')}>{title}</p>
                <Formik
                    initialValues={{
                        items: items
                    }}
                    onSubmit={values => {
                        let lastValue = "";

                        for (let i = values.items.length-1; i >= 0; i--)
                        {
                            lastValue = values.items[i].value;
                            if (lastValue) break;
                        }

                        setFieldValue(fieldName, isNum ? parseFloat(lastValue) : lastValue);
                        setList(values.items.filter((item) => item.value !== '').map((val) => ({id: isNum ? parseFloat(val.value) : val.value, title: val.value})));
                        onClose();
                    }}>
                    {({values}) => (
                        <Form>
                            <FieldArray name='items'>
                                {({remove, push}) => ( <>
                                    <ul className={cx('stage-input-list', hasUnit ? 'has-unit' : '')}>
                                        {values.items.map((li, i) => (
                                            <li key={i}>
                                                <Field name={`items.${i}.value`} type='text' maxLength={10}
                                                       placeholder={placeholder}/>
                                                <button className={cx('delete-btn')} type='button'
                                                        onClick={() => remove(i)}></button>            
                                            </li>
                                        ))}
                                    </ul>
                                     <div className={cx('btn-area')}>
                                        <button className={cx('add-btn')} type='button' onClick={() => push({index: items.length, value: ''})
                                            }><i></i>추가</button>
                                        <button className={cx('save-btn')} type='submit'><i></i>적용하기</button>
                                    </div>
                                </>)}
                            </FieldArray>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

export default AddListPop;