import classNames from "classnames/bind";
import styles from "./membership-card.module.scss";
import {addComma, calcPerPrice} from "../../../util/str-util";
import {Goods} from "../../../model/goods/dto/goods";
import {purchaseGoodsKey} from "../../../util/local-storage";
import {useNavigate} from "react-router-dom";

const cx = classNames.bind(styles);

const MembershipCard = ({goods}: {goods:Goods}) => {
    const navigate = useNavigate();

    const onClickPurchaseBtn = () => {
        localStorage.setItem(purchaseGoodsKey, goods.id);
        navigate('/login');
    }

    return(
        <div className={cx('membership-card')} onClick={onClickPurchaseBtn}>
            {goods.content.featuredIcon && <p className={cx('recommend')}></p>}
            <div className={cx('text-area')}>
                <p className={cx('sub-title')}>{goods.info.introduceText ?? ""}</p>
                <div className={cx('title-box')} style={{color: goods.info.goodsColor ?? ""}}>
                    <p className={cx('title')}>{goods.name}</p>
                    <p className={cx('condition')}>{goods.meta.val2}건
                        /{+(goods.meta.val3 ?? "0") >= 365 ? `${+(goods.meta.val3 ?? "0") / 365}년` : `${goods.meta.val3}일`}
                    </p>
                </div>
                <p className={cx('price')}>{addComma(goods.price)} 원</p>
                {goods.type === 'MEMBERSHIP' && <p className={cx('price-per-case')}>건당 {addComma(calcPerPrice(goods.price, +(goods.meta.val2 ?? "0")))}원</p>}
            </div>
            <button className={cx('purchase-btn')} style={{backgroundColor: goods.info.goodsColor}} type='button'>구매하기</button>
        </div>
    );
}

export default MembershipCard;