import MetaTag from "../../components/meta-tag";
import Footer from "../intro/home/footer";
import React, {useState} from "react";
import KatalkVisual from "./katalk-visual";
import SubHeader from "../intro/home/sub-header";
import KatalkManagement from "./katalk-management";
import KatalkServiceGuide from "./katalk-service-guide";
import KatalkReview from "./katalk-review";

const KatalkPage = () => {
    const [scrollY, setScrollY] = useState<number>(0);

    return(
        <>
            <MetaTag viewportW='width=device-width, initial-scale=1, maximum-scale=1'/>
            <div className='home_page' onScroll={(e) => { setScrollY(e.currentTarget.scrollTop) }}>
                <SubHeader scrollY={scrollY} type='katalk'/>
                <KatalkVisual/>
                <KatalkManagement/>
                <KatalkServiceGuide/>
                <KatalkReview/>
                <Footer/>
            </div>
        </>
    );
}

export default KatalkPage;