import {useEffect, useState} from "react";
import classNames from "classnames/bind";
import styles from "../intro/home/visual.module.scss";
import {useNavigate} from "react-router-dom";
import VideoPop from "../../components/video-pop";
import {useInjection} from "inversify-react";
import {DialogManager} from "../../components/dialog-manager";

const cx = classNames.bind(styles);
const KatalkVisual = () => {
    const dialogManager = useInjection(DialogManager);
    const [backgroundH, setBackgroundH] = useState(0);
    const [isPc, setIsPc] = useState(false);
    const navigate = useNavigate();

    const setSize = () => {
        if(window.innerWidth > 1200) {
            setBackgroundH(window.innerHeight + 150);
        } else {
            setBackgroundH(0);
        }
    }

    window.addEventListener("resize", function() {
        setSize();
    });

    useEffect(() => {
        setIsPc(!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)));

        setSize();
    },[])

    return (
        <div className={cx('visual', 'katalk-visual')} style={ window.innerWidth > 1200 ? {height: backgroundH} : {}}>
            <div className={cx('visual-inner')}>
                <h3>씨오파트너 세무사용</h3>
                <h2 className={cx('katalk-h2')}><span>카톡단체발송</span> <span className={cx('color')}>서비스<span className={cx('label')}>PC 전용</span></span></h2>
                <p className={cx('desc', 'katalk-desc')}>클릭 한번으로 내 카톡에 등록되어 있는<br/>고객에게 1:1 단체 메시지를 전송해 보세요.</p>
                <div className={cx('btn-area', 'katalk')}>
                    {
                        isPc &&
                        <button
                            className={cx('download-btn')}
                            onClick={() => /*navigate('/login', {state: {type: 'katalk'}})*/  navigate(`/login/katalk`) }>
                            로그인
                        </button>
                    }
                    <button
                        className={cx('video-btn')}
                        onClick={() => dialogManager.open(VideoPop, {title: '카톡단체발송 서비스 소개영상', url: 'https://player.vimeo.com/video/934629543', channel: 'VIMEO', ratio: 'HD'})}>
                        소개영상</button>
                    <button className={cx('video-btn')} onClick={() => {
                        window.open('https://copartner.channel.io/support-bots/98837', '_blank');
                    }}>사용권한 요청</button>
                </div>
            </div>
        </div>
    );
}

export default KatalkVisual;