import React from 'react';
import TableTitle from '../../../components/table-title';
import {EMPTY, formatNum, formatPercent, ymdToDateString} from '../../../util/str-util';
import {usePdfComponents} from '../pdf-hooks';
import {getColT} from '../../../styles/styleSheet';
import {ShareholderInfo} from '../../../model/customer/dto/shareholder-info';
import {SummaryReport} from "../../../model/inquiry/dto/summary-report";
import {outputReportTitle} from "../report-custom-util";
import {mainT} from "../../../styles/mainStyleSheet";

interface MajorShareholderStatusProps {
    report: SummaryReport;
    data?: ShareholderInfo[];
    refDate?: string;
}

const MajorShareholderStatus = ({report, data, refDate}: MajorShareholderStatusProps) => {
    const {Text, View, Body, isPdf} = usePdfComponents();
    const colT = getColT(isPdf);
    const getShareholderList = () => {
        if (data && data.length !== 0) {
            let totalCount = 0;
            let totalRate = 0;
            const list = data.map((it, index) => {
                if (it.STK_CN) {
                    totalCount += Number(it.STK_CN);
                }

                if (it.STK_RT) {
                    totalRate += Number(it.STK_RT);
                }

                return <View key={index} style={colT.flexLine}>
                    <View style={[colT.flexBody, colT.txtLeft, mainT.txtOverflow, {flex: 1.3, maxWidth: 161}]}>
                        {isPdf ?
                            <Text>{it.STH_NM}</Text>
                            : <Body style={mainT.txtOverflow}>{it.STH_NM}</Body>
                        }
                    </View>
                    <Text style={colT.flexBody}>{formatNum({num: it.STK_CN, unit: report.CS.unit})}</Text>
                    <Text style={colT.flexBody}>{it.STK_RT ? it.STK_RT + '%' : EMPTY}</Text>
                    {(!report.CS.hideNrStock) && <Text style={colT.flexBody}>{it.TYPE ? it.TYPE : '-'}</Text>}

                    <Text style={colT.flexBody}>{it.MDM_REL_CD}</Text>
                    <Text style={colT.flexBody}>{it.ENP_REL_CD}</Text>
                    <Text style={[colT.flexBody, colT.txtRight, {flex: 0.8}]}>{it.BRRW_NM ? '차명주주' : ''}</Text>
                </View>;
            });

            if (totalRate > 100) {
                totalRate = 100;
            }

            list.push(
                <View style={colT.flexLine}>
                    <View style={[colT.flexBody, colT.txtLeft, colT.grayBody, mainT.txtOverflow, {flex: 1.3, maxWidth: 161}]}>
                        <Text>주요 주주 합계</Text>
                    </View>
                    <Text style={[colT.flexBody, colT.grayBody]}>{formatNum({num: totalCount, unit: report.CS.unit})}</Text>
                    <Text style={[colT.flexBody, colT.grayBody]}>{totalRate ? formatPercent(totalRate, 1) : EMPTY}</Text>
                    {(!report.CS.hideNrStock) && <Text style={[colT.flexBody, colT.grayBody]}></Text>}
                    <Text style={[colT.flexBody, colT.grayBody]}></Text>
                    <Text style={[colT.flexBody, colT.grayBody]}></Text>
                    <Text style={[colT.flexBody, colT.grayBody, colT.txtRight, {flex: 0.8}]}></Text>
                </View>
            );

            return list;
        }

        return  <View style={[colT.flexLine]}>
            <Text style={[colT.flexBody]}>-</Text>
        </View>;
    }

    return (
        <>
            <TableTitle title='주요 주주 현황' dateOrUnit={refDate ? '기준일 ' + ymdToDateString(refDate) : undefined}>
                <View style={colT.flexWrap}>
                    <View style={[colT.flexLine, colT.headBox]}>
                        <Text style={[colT.flexHead, colT.txtLeft, {flex: 1.3, maxWidth: 161}]}>주요주주</Text>
                        <Text style={colT.flexHead}>{outputReportTitle('stkCn', report?.KED5002['ENP_FCD'] ?? "")}</Text>
                        <Text style={colT.flexHead}>지분율</Text>
                        {(!report.CS.hideNrStock) && <Text style={colT.flexHead}>주식종류</Text>}

                        <Text style={colT.flexHead}>경영실권자와의 관계</Text>
                        <Text style={colT.flexHead}>회사와의 관계</Text>
                        <Text style={[colT.flexHead, colT.txtRight, {flex: 0.8}]}>차명주주 여부</Text>
                    </View>
                    { getShareholderList() }
                    <Text style={colT.footNote}>※ 자동조회된 과거 주주정보는 최근 주주정보와 다를 수 있습니다. 확인 후 수정해 주세요.</Text>


                </View>
            </TableTitle>
        </>
    );
};

export default MajorShareholderStatus;