import {useInjection} from "inversify-react";
import {DialogManager} from "../../../../components/dialog-manager";
import {CustomerStore} from "../../../../model/customer/customer-store";
import {CustomerApi} from "../../../../model/customer/customer-api";
import {useEffect, useState} from "react";

const BookmarkBtn = ( { reportId } : {reportId: string} ) => {
    const customerStore = useInjection(CustomerStore);
    const customerApi = useInjection(CustomerApi);
    const customer = customerStore.getCustomer();
    // const dialogManager = useInjection(DialogManager);

    // const findReportId = function() {
    //     let findReportId = "";

    //     ReportDb.some((db, i) => {
    //         let isExist = false;

    //         db.sections.some((sec, j) => {
    //             if(sec.title == title) {
    //                 findReportId = sec.reportId;
    //                 isExist = true;
    //                 return true;
    //             } else {
    //                 sec.components.some((com, k) => {
    //                     let comTitle = com.title ?? "";

    //                     if(comTitle == title) {
    //                         findReportId = com.componentId;
    //                         isExist = true;
    //                         return true;
    //                     }
    //                 });
    //             }
    //         });

    //         if(isExist) {
    //             return true;
    //         }
    //     });

    //     return findReportId;
    // }


    const bookmarkList = customer.bookmarkList;
    let initBookmark = !(bookmarkList?.indexOf(reportId)! < 0 || bookmarkList === undefined || bookmarkList === null);

    if(typeof bookmarkList !== "undefined" && bookmarkList != null) {
        if(bookmarkList.indexOf("EmploymentSupportFund") > -1 && reportId.indexOf("EmploymentSupportFund") > -1) {
            initBookmark = true;
        }
    }

    const [bookmark, setBookmark] = useState(initBookmark);

    useEffect(() => {
        setBookmark(initBookmark);
    }, [bookmarkList]);

    const bookmarkStatus = async () => {
        if(reportId != "") {
            // 신청 가능한 고용지원금의 경우, db 에서 가져온 배열의 크기만큼 화면에 출력
            // report db 에는 하나의 컴포넌트만 있지만 실제 출력하는 개수는 다르기에 북마크를 해도 개수가 일치하지 않음
            // 따라서 신청 가능한 고용지원금 은 북마크 시, 모든 컴포넌트를 무조건 포함하도록 작업 
            if(reportId.indexOf("EmploymentSupportFund") > -1) {
                reportId = "EmploymentSupportFund";
            }

            const result = await customerApi.updateBookmark(customer.id, reportId);
        
            if(result.val) {
                customerStore.setCustomer(result.val);

                setBookmark(!bookmark);
            }
        }
    }

    return( 
        <button className={`bookmark ${bookmark && "on"}`}  onClick={() => {bookmarkStatus();}}/>
        // <img alt="북마크 모양" className="bookmark" src={ bookmark ? '/images/i_bookmark_on.png' : '/images/i_bookmark_off.png' }  onClick={() => {bookmarkStatus();}} />
    );
};

export default BookmarkBtn;