import classNames from "classnames/bind";
import { KktAutoFriendForm } from "../../../model/katalk/form/kkt-auto-friend-form";
import styles from "./failure-history-pop.module.scss";

const cx = classNames.bind(styles);

interface KktAddFailureListPopProps {
    list: KktAutoFriendForm[]
    onClose: VoidFunction
} 

const KktAddFailureListPop = ({list, onClose}: KktAddFailureListPopProps) => {
    return(
        <div className='full-background'>
            <div className={cx('failure-history-pop')}>
                <p className={cx('title')}>친구등록 실패내역 리스트</p>
                <div className={cx('info')}>
                    <p className={cx('count')}><span className={cx('sub-title')}>총 실패</span><span className={cx('red')}>{list.length}건</span></p>
                    <p className={cx('date')}><span className={cx('sub-title')}>※</span>친구목록에서 중복된 이름을 변경해 주세요.</p>
                </div>
                <div className={cx('list')}>
                    <div className={cx('head')}>
                        <p>NO.</p>
                        <p>카카오톡 친구목록 이름</p>
                    </div>
                    <div className={cx('body')}>
                        <div>
                            {list.map((li, i) => 
                                <p key={i} className={cx('list-one')}>
                                    <span>{i+1}</span><span className={cx('name')}><img src={`http://localhost:8072/image?name=${encodeURIComponent(li.name)}&dup=${li.nameDup}`} alt='profileImage'/> <span>{li.name}</span></span>
                                </p>
                            )}
                        </div>
                    </div>
                </div>
                <div className={cx('btn-area')}>
                    <button onClick={onClose} className={`close-btn ${cx('close-btn')}`} type='button'>닫기</button>
                </div>
            </div>
        </div>
    );
}

export default KktAddFailureListPop;