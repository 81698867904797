import React, {useRef, useState} from 'react';
import styles from './connect-cotalk.module.scss';
import classNames from 'classnames/bind';
import {useOutsideAlerter} from '../util/util';
import Dialog, {ClosableProps} from './dialog';
import {useInjection} from 'inversify-react';
import {RepInfo} from '../model/customer/dto/rep-info';
import {DialogManager} from './dialog-manager';
import {coTalkConnectInfo} from '../model/customer/cotalk-state';
import {CoTalkConnectResultDto} from "../model/customer/form/member-connect-form";
import {toast} from "react-toastify";
import {AuthStore} from "../model/auth/auth-store";
import {useNavigate} from "react-router-dom";
import CustomerDetailPop from "../pages/customer/customer-detail-pop";
import {CustomerDetailTab} from "../data/const_list";
import {CotalkConnectApi} from "../model/customer/cotalk-connect-api";

const cx = classNames.bind(styles);

type ConnectType = "REQUEST" | "AGAIN" | "CANCEL" | "DISCONNECT";

interface ConnectPopupProps extends ClosableProps {
    customerId: string;
    companyName: string;
    selectedRep: RepInfo;
    connectType: ConnectType;
    refresh: () => Promise<void>;
}

const ConnectDialog = ({customerId, companyName, selectedRep, connectType, refresh, onClose}: ConnectPopupProps) => {
    const dialogManager = useInjection(DialogManager);
    const connectApi = useInjection(CotalkConnectApi);
    const repName = selectedRep.name + (selectedRep.ttl ? ' ' + selectedRep.ttl : '');

    const getBtnTitle = () => {
        switch (connectType) {
            case "REQUEST":
            case "AGAIN":
                return "요청하기";
            case "DISCONNECT":
                return "연결해제";
            case "CANCEL":
                return "요청취소";
        }
    }

    const getContents = () => {
        switch (connectType) {
            case "REQUEST":
                return <>
                    <div className='title'>{repName}님께<br/>씨오톡 연결을 요청하시겠어요?</div>
                    <div className='sub-title'>{repName}님의 휴대폰 번호로<br/>씨오톡 앱 설치 및 씨오톡 연결 URL이 전송됩니다.</div>
                </>;
            case "AGAIN":
                return <>
                    <div className='title'>{repName}님께<br/>씨오톡 연결 URL을 재전송 하시겠어요?</div>
                    <div className='sub-title'>{repName}님의 휴대폰 번호로<br/>씨오톡 앱 설치 및 씨오톡 연결 URL이 전송됩니다.</div>
                </>;
            case "DISCONNECT":
                return <div className='title'>{repName}님과 <br/>씨오톡 연결을 해제하시겠어요?</div>;
            case "CANCEL":
                return <div className='title'>{repName}님에게 요청하신<br/>씨오톡 연결요청을 취소하시겠어요?</div>;
        }
    }

    const onAccept = async () => {
        const form = {
            ceoName: selectedRep.name,
            ceoCompanyName: companyName,
            ceoPhone: selectedRep.phone
        };

        switch (connectType) {
            case "REQUEST":
            case "AGAIN":
                let result = await connectApi.connectCoTalks(customerId, [form]);
                const data = (result as CoTalkConnectResultDto[])[0];
                if (data.request) {
                    dialogManager.alert(
                        `${repName}님께 씨오톡 연결 URL이 전송되었습니다.`,
                        '고객님이 씨오톡 회원가입 후 파트너 연결을 수락하면\n연결이 완료됩니다.'
                    );
                    await refresh();
                } else {
                    dialogManager.alert(
                        `${repName}님은 이미 다른 전문가 파트너와\n씨오톡이 연결되어 있습니다.`,
                        '고객이 씨오톡 앱[전체 메뉴 > 내 전문가 파트너]에서\n기존 전문가 연결을 해제 후 재연결이 가능합니다.'
                    );
                }
                onClose();
                break;
            case "DISCONNECT":
                    await connectApi.disconnectCoTalks(customerId, [form]);
                    toast.success(`씨오톡 연결이 해제되었습니다.`);
                    await refresh();
                    onClose();
                break;
            case "CANCEL":
                await connectApi.cancelConnectCoTalks(customerId, [form]);
                toast.success(`씨오톡 연결 요청이 취소되었습니다.`);
                await refresh();
                onClose();
                break;
        }
    }

    return (
        <Dialog mainBtnText={getBtnTitle()} onClose={onClose} onAccept={onAccept}>
            {getContents()}
        </Dialog>
    );
};

const NoExpertInfoDialog = ({repInfo, onCloseCustomerDetailPop, onClose}: {repInfo: RepInfo, onCloseCustomerDetailPop?:VoidFunction, onClose: VoidFunction}) => {
    const navigate = useNavigate();

    const moveToExpertProfile = () => {
        onClose();
        onCloseCustomerDetailPop?.();
        navigate('/my-page/expert-profile');
    }

    return (
        <Dialog mainBtnText='전문가 프로필 >' onClose={onClose} onAccept={moveToExpertProfile}>
            <div className='title'>{repInfo.name} {repInfo.ttl}씨오톡에 노출될<br/>전문가 프로필 내용이 필요합니다.</div>
            <div className='sub-title'>마이페이지&gt;전문가 프로필 입력 후<br/>씨오톡 연결을 요청해 주세요.</div>
        </Dialog>
    );
}

interface ConnectCoTalkProps {
    customerId: string;
    companyName: string;
    selectedRep?: RepInfo;
    refresh?: () => Promise<void>
    customerState?: string // 고객 리스트 페이지
    onCloseCustomerDetailPop?: VoidFunction
}

const ConnectCoTalk = ({customerId, companyName, refresh, customerState, selectedRep, onCloseCustomerDetailPop}: ConnectCoTalkProps) => {
    const authStore = useInjection(AuthStore);
    const wrapperRef = useRef(null);
    const dialogManager = useInjection(DialogManager);
    const member = authStore.getMember();
    const navigate = useNavigate();
    const [popup, setPopup] = useState(false);
    let connectInfoKey = customerState ? customerState : "NONE";

    if (selectedRep && selectedRep.connectState)
        connectInfoKey = selectedRep.connectState;

    const cotalk = coTalkConnectInfo[connectInfoKey];

    useOutsideAlerter(wrapperRef, setPopup);

    const connectDialog = (type: ConnectType) => {
        setPopup(false);

        console.log(selectedRep?.phone);

        if (type === 'REQUEST' && member?.expertProfile?.mainCareer === undefined) {
            dialogManager.open(
                NoExpertInfoDialog,
                {repInfo: selectedRep!, onCloseCustomerDetailPop: onCloseCustomerDetailPop}
            );
        } else if (type === 'REQUEST' && (typeof selectedRep?.phone === "undefined" || selectedRep?.phone.length !== 11)) {
            dialogManager.alert(
                '씨오톡 연결 URL을 발송할\n연락처를 입력해 주세요.', `${selectedRep?.name}님의 연락처를 입력해 주셔야\n씨오톡 앱 설치 및 연결 URL을 전송할 수 있습니다.`,
                () => {
                    dialogManager.open(CustomerDetailPop, {
                        customerId: customerId,
                        tab: CustomerDetailTab.Personal,
                        repInfo: selectedRep,
                        onDialogClose: refresh!
                    });
                },
            );
        } else if (type === 'REQUEST' && (member?.expertProfile?.mainCareer === undefined || !(member?.expertProfile?.mainCareer.length > 0))) {
            dialogManager.alert(
                '전문가 프로필 등록을\n완료해 주세요.', '',
                () => {
                    if (onCloseCustomerDetailPop) {
                        onCloseCustomerDetailPop();
                    }
                    navigate('/my-page/expert-profile');
                },
            );
        } else {
            dialogManager.open(
                ConnectDialog,
                {
                    customerId: customerId,
                    companyName: companyName,
                    connectType: type,
                    refresh: refresh!,
                    selectedRep: selectedRep!,
                }
            );
        }
    }
    const onClick = () => {
        if (selectedRep) {
            switch (connectInfoKey) {
                case "READY":
                    setPopup(true);
                    break;
                case "CONNECTED":
                    connectDialog("DISCONNECT");
                    break;
                default:
                    connectDialog("REQUEST");
            }
        }
    }

    return (
        <div className={cx('cotalk-state-box')} ref={wrapperRef} key={customerId}>
            <span
                className={cx('co-talk-state', cotalk.cn, customerState ? '' : 'detail')}
                onClick={() => onClick()}>
                    {customerState ? cotalk.name : cotalk.name2}
            </span>
            {popup && <ul className={cx('connect-cotalk-list', customerState ? '' : 'detail')}>
                <li onClick={() => connectDialog("AGAIN")}>
                    <i className={cx('again')}></i>다시 요청하기
                </li>
                <li onClick={() => connectDialog("CANCEL")}>
                    <i className={cx('cancel')}></i>연결요청 취소하기
                </li>
            </ul>}
        </div>
    );
};


/*
********* 대표가 여러명일 경우 케이스 삭제
const ConnectDialog = ({customerId, companyName, repList, selectedRep, connectType, setData, onClose}: ConnectPopupProps) => {
    const dialogManager = useInjection(DialogManager);
    const customerApi = useInjection(CustomerApi);
    const isMounted = useMountedState();
    const isCancel = connectType === "CANCEL";
    const list = selectedRep ?
        [selectedRep] :
        connectType === "REQUEST" ?
            repList.filter(it => it.connectState !== "READY" && it.connectState !== "CONNECTED") :
            repList.filter(it => it.connectState === "READY");
    const [selection, setSelection] = useState<RepInfo[]>(list);

    const toggleSelect = (rep: RepInfo) =>
        setSelection(prev => prev.includes(rep) ? prev.filter(it => it.birthday !== rep.birthday) : [...prev, rep]);

    const getName = (rep: RepInfo) =>
        rep.name + (rep.ttl ? ' ' + rep.ttl : '');

    const onAccept = () => {
        let inValidRep: RepInfo | undefined;

        const list = selection.map(rep => {
            if (rep.phone.length !== 11) inValidRep = rep;

            return {
                ceoName: rep.name,
                ceoCompanyName: companyName,
                ceoPhone: rep.phone
            }
        });

        if (inValidRep && connectType !== "CANCEL") {
            onClose();
            return dialogManager.alert('씨오톡 연결 URL을 발송할\n연락처를 입력해 주세요.', `${getName(inValidRep)}님의 연락처를 입력해 주셔야\n씨오톡 앱 설치 및 연결 URL을 전송할 수 있습니다.`);
        }

        switch (connectType) {
            case "REQUEST":
            case "AGAIN":
                asyncCall(customerApi.connectCoTalks, [customerId, list], result => {
                    onClose();
                    const successList: string[] = [];
                    const failList: string[] = [];

                    (result as CoTalkConnectResultDto[]).forEach(rst => {
                        rst.request ? successList.push(rst.name) : failList.push(rst.name);
                    })

                    dialogManager.alert(
                        `${successList.join(", ")} 님께 씨오톡 연결 URL이 전송되었습니다.`,
                        '고객님이 씨오톡 회원가입 후 파트너 연결을 수락하면\n연결이 완료됩니다.'
                    );
                }, isMounted, () => {
                    onClose();
                });
                break;
            case "DISCONNECT":
                asyncCall(customerApi.disconnectCoTalks, [customerId, list], result => {
                    onClose();
                    dialogManager.alert(selection.map(rep => rep.name).join(', ') + '님께 씨오톡 연결 URL이 전송되었습니다.',
                        '고객님이 씨오톡 회원가입 후 파트너 연결을 수락하면\n연결이 완료됩니다.');
                }, isMounted, () => {
                    onClose();
                });
                break;
            case "CANCEL":
                asyncCall(customerApi.disconnectCoTalks, [customerId, list], result => {
                    onClose();
                    dialogManager.alert(selection.map(rep => rep.name).join(', ') + '님께 씨오톡 연결 URL이 전송되었습니다.',
                        '고객님이 씨오톡 회원가입 후 파트너 연결을 수락하면\n연결이 완료됩니다.');
                }, isMounted, () => {
                    onClose();
                });
                break;
        }
    }

    return (
        <Dialog mainBtnText={isCancel ? '요청취소' : '요청하기'} onClose={onClose} onAccept={onAccept}>
            {list.length === 1 ?
                (isCancel ?
                    <div className='title'>{getName(list[0])}님에게 요청하신<br/>씨오톡 연결요청을 취소하시겠어요?</div>
                    :
                    <>
                        <div className='title'>{getName(list[0])}님께<br/>씨오톡 연결을 요청하시겠어요?</div>
                        <div className='sub-title'>{list[0].name}님의 휴대폰 번호로<br/>씨오톡 앱 설치 및 씨오톡 연결 URL이 전송됩니다.</div>
                    </>)
                :
                <>
                    {isCancel ?
                        <div className='title'>선택한 고객님에게 요청하신<br/>씨오톡 연결요청을 취소하시겠어요?</div>
                        :
                        <>
                            <div className='title'>연결 요청을 보낼 고객을 선택해 주세요.</div>
                            <div className='sub-title'>선택한 고객님의 휴대폰 번호로<br/>씨오톡 앱 설치 및 씨오톡 연결 URL이 전송됩니다.</div>
                        </>}

                    <div className='chkbox-area'>
                        {list.map((it, i) =>
                            <label className='radio-box' key={i}>
                                <input
                                    id={`selectCeo${i}`}
                                    type='checkbox'
                                    checked={selection.includes(it)}
                                    onChange={() => toggleSelect(it)}/>
                                <span>{getName(it)} 공동대표</span>
                            </label>
                        )}
                    </div>
                </>
            }
        </Dialog>
    );
};
 */

export default ConnectCoTalk;
