import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {ApiResult} from '../api-result';
import {CustomerShareForm, PrCustomerShare, PrCustomerShareCont, PrCustomerShareSp} from "./dto/pr-customer-share";
import {Page} from "../common/dto/page";
import {makeSearchDataQs} from "../../util/str-util";

@injectable()
export class CustomerShareApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    /**
     * 고객 공유
     */
    shareAct = async (form: CustomerShareForm) => {
        return await this.apiCall.json<ApiResult<PrCustomerShare>>('/customer/share/shareAct', form);
    }

    paging = async (pageNo: number, size: number, searchData: {[key: string]: string|undefined}) => {
        return await this.apiCall.fetch<ApiResult<Page<PrCustomerShareCont>>>(`/customer/share/paging?page=${pageNo}&size=${size}${makeSearchDataQs(searchData)}`);
    }

    deleteShares = async (customerIds: string[]) => {
        return await this.apiCall.fetch<ApiResult<string>>('/customer/share/del', {customerIds: customerIds});
    }

    /**
     * 특정 고객 공유 회원 리스트
     */
    shareCus = async (customerId: string) => {
        return await this.apiCall.fetch<ApiResult<PrCustomerShareSp[]>>(`/customer/share/shareCus/${customerId}`);
    }

    /**
     * 특정 고객 씨오팀과 공유
     */
    shareLabs = async (customerId: string, loadType: string) => {
        return await this.apiCall.post<ApiResult<string>>(`/customer/share/shareLabs/${customerId}`, {loadType: loadType});
    }
}