import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {ApiResult} from '../api-result';
import {CoTalkConnectResultDto, MemberConnectForm} from "./form/member-connect-form";

@injectable()
export class CotalkConnectApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    connectCoTalks = async (customerId: string, list: MemberConnectForm[]) => {
        return await this.apiCall.json<CoTalkConnectResultDto[]>(`/api/coconnect/connect/${customerId}`, list);
    }

    disconnectCoTalks = async (customerId: string, list: MemberConnectForm[]) => {
        return await this.apiCall.json<ApiResult<undefined>>(`/api/coconnect/disconnect/${customerId}`, list);
    }

    cancelConnectCoTalks = async (customerId: string, list: MemberConnectForm[]) => {
        return await this.apiCall.json<ApiResult<undefined>>(`/api/coconnect/cancel/${customerId}`, list);
    }
}