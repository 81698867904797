import Postcode from "../model/etc/post-code-hook";
import classNames from "classnames/bind";
import styles from "./purchase-materials-pop.module.scss";

const cx = classNames.bind(styles);

const PostCodePop = ({onSelectAddress, onClose}: {
    onSelectAddress: (zipCode: string, addr1: string) => void,
    onClose: VoidFunction}) => {
    return (
        <div className='full-background'>
            <div className={`pop-inner ${cx('purchase-materials-pop')}`}>
                <p className={cx('title')}>배송지 추가</p>
                <Postcode onSelectAddress={onSelectAddress} onClose={onClose}/>
                <div className={cx('btn-area')}>
                    <button className={cx('close-btn')} type='button' onClick={() => {onClose()}}>닫기</button>
                </div>
            </div>
        </div>
    );
};

export default PostCodePop;