import {useInjection} from "inversify-react";
import {DialogManager} from "../../components/dialog-manager";
import {CustomerInputApi, CustomerInputType} from "./customer-input-api";
import {asyncCall} from "../../util/util";
import {toast} from "react-toastify";
import {useMountedState} from "react-use";
import {Dispatch, SetStateAction} from "react";
import {CoReportDto} from "../co-report/dto/co-report-dto";
import {PrCustomerRawData} from "./dto/customer-raw-data";

export const useCustomerInputClear = (setData: Dispatch<SetStateAction<CoReportDto | undefined>>) => {
    const dialogManager = useInjection(DialogManager);
    const customerInputApi = useInjection(CustomerInputApi);
    const isMounted = useMountedState();

    const clearInfo = (customerId: string, type: CustomerInputType, onSuccess?: (report: CoReportDto, rawData?: PrCustomerRawData) => void) => {
        dialogManager.confirm("입력 항목을 초기화 하시겠습니까?", "", () => {
            asyncCall(customerInputApi.clear, [customerId, type], result => {
                setData(result.report);
                toast.success("초기화 되었습니다");

                if (onSuccess != null) onSuccess(result.report, result.rawData);
            }, isMounted);
        })
    };

    return {clearInfo};
}