import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {Page} from "../common/dto/page";
import {CoEmpSubsRefundDto} from "./dto/co-emp-subs-refund-dto";
import {makeSearchDataQs} from "../../util/str-util";

@injectable()
export class EmpSubsRefundApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    pagingEmpSubRefund = async (pageNo: number, size: number, searchData: {[key: string]: string|undefined}) => {
        return await this.apiCall.fetch<Page<CoEmpSubsRefundDto>>(`/customer/esr?page=${pageNo}&size=${size}${makeSearchDataQs(searchData)}`);
    }
}