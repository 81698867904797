import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {ApiResult} from '../api-result';
import {BriefCompanyInfoResult} from './dto/brief-company-info-result';
import {CompanyInquiry} from './dto/company-inquiry';
import {InquiryHistory} from './dto/inquiry-history';
import {SearchCorpRegCont} from "./dto/cf-search-list";

@injectable()
export class InquiryApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    initSearch = async (): Promise<ApiResult<CompanyInquiry>> => {
        return await this.apiCall.fetch<ApiResult<CompanyInquiry>>('/inquiry/init');
    }

    /**
     * 기업검색 V2
     * @param bznoOrName 사업자번호 또는 사업자명
     * @param ceo 대표자명
     */
    public async searchV2(bznoOrName: string, ceo: string): Promise<ApiResult<BriefCompanyInfoResult>> {
        return await this.apiCall.fetch<ApiResult<BriefCompanyInfoResult>>('/inquiry/v2/search', {bznoOrName: bznoOrName, ceo: ceo});
    }

    public async getReport(kedcd?: string): Promise<ApiResult<CompanyInquiry>> {
        return await this.apiCall.fetch<ApiResult<CompanyInquiry>>('/inquiry/report', {
            kedcd: kedcd,
        });
    }

    public async history(): Promise<ApiResult<InquiryHistory[]>> {
        return await this.apiCall.fetch<ApiResult<InquiryHistory[]>>('/inquiry/history');
    }

    /**
     * 등기부 등본 조회
     * @param enpName 회사명
     * @param corpType 사업자 타입
     * @param regOffice 등기소
     */
    public async searchCorpReg(enpName: string, corpType?: string, regOffice?: string): Promise<ApiResult<SearchCorpRegCont>> {
        return await this.apiCall.fetch<ApiResult<SearchCorpRegCont>>('/inquiry/corpReg', {enpName, corpType, regOffice});
    }

    public async searchCorpRegOpt(enpName: string, corpTypeStr?: string, regOfficeStr?: string): Promise<ApiResult<SearchCorpRegCont>> {
        return await this.apiCall.fetch<ApiResult<SearchCorpRegCont>>('/inquiry/corpReg', {enpName, corpTypeStr, regOfficeStr});
    }
}