import {injectable} from 'inversify';
import {v4 as uuidv4 } from 'uuid';

@injectable()
export class AuthToken {
    private _accessToken: string = '';

    private _lastLoginId: string = '';

    private _deviceId: string = '';

    constructor() {
        this._accessToken = localStorage['t0'] ?? '';
        this._lastLoginId = localStorage['l0'] ?? '';

        const localToken = localStorage['di'];

        if (localToken) {
            this._deviceId = localToken;
        }
        else {
            localStorage['di'] = uuidv4();
            this._deviceId = localStorage['di']
        }
    }

    setToken(access_token: string): void {
        this._accessToken = access_token;
        localStorage['t0'] = access_token;
    }

    getDeviceId() : string {
        return this._deviceId;
    }

    getToken(): string {
        return this._accessToken;
    }

    setLastLoginId(loginId: string): void {
        this._lastLoginId = loginId;
        localStorage['l0'] = loginId
    }

    getLastLoginId() : string {
        return this._lastLoginId;
    }

    removeToken() {
        delete localStorage['t0'];
        this._accessToken = '';
    }

    makeHeader(): Record<string, string> {
        return {
            'Authorization': `Bearer ${this._accessToken}`
        };
    }
}
