import ReportLeft from "../../report-left";
import {formatNum, toHundredMillion, toPercent} from "../../../../../util/str-util";
import ReportRight from "../../report-right";
import {mainFinanceT} from "../../../../../styles/mainStyleSheet";
import TableTitle from "../../../../../components/table-title";
import colors from "../../../../../../scss/themes/colors.module.scss";
import MainReportContainer from "../../main-report-container";
import {usePdfComponents} from "../../../pdf-hooks";
import SideNote from "../../components/side-note";
import GrayTxtBox from "../../components/gray-txt-box";
import {StyleSheet} from "@react-pdf/renderer";
import LabelWithImage from "../../components/labelWithImage";
import {EditReportInfoBtnProps} from "../../components/edit-report-info-btn";
import {ReportAddInfoTab} from "../../../../../data/const_list";
import {ReportPageProp} from "../../report-page-prop";
import {chartFont, chartLabelFont} from "../../../../../styles/report-font";

const StockValueSavingsSimulation = ({data}: ReportPageProp) => {
    const {View, Text, Image, Chart, isPdf} = usePdfComponents();

    const styles = StyleSheet.create({
        stressTxt: {
            fontWeight: 700
        },
        flexWrap: {
            flexDirection: 'row',
            flexWrap: 'nowrap',
        },
        top: {
            paddingTop: isPdf ? 2 : 0,
            paddingLeft: 0,
            paddingRight: 0
        },
        cellHeight: {
            height: 32,
            paddingRight: 11,
            fontWeight: 500,
            // fontSize: 14
        },
        cellHeight2: {
            height: 32,
            paddingRight: 20
        },
        FirstCellFlex: {
            flex: 1.95,
            justifyContent: 'flex-start',
            paddingLeft: 22,
            // paddingRight: 22,
        },
        depth2: {
            textIndent: 10
        },
        centertxtCellFirst: {
            flex: 1.9,
        },
        centertxtCellAll: {
            textAlign: 'center'
        },
        txtRight: {
            justifyContent: 'flex-end',
            textAlign: 'right',
            paddingLeft: 9,
            paddingRight: 9
        },
        num: {
            color: colors.main,
        },
        gridBox: {
            display: 'flex',
            flexDirection: 'row',
        },
        blueBox: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            border: '1px solid #b1c6ff',
            borderRadius: 8,
            width: 327,
            height: 158,
            marginRight: 31
        },
        blueBoxImage: {
            width: 106,
            marginRight: 24
        },
        point: {
            paddingTop: 10,
            fontWeight: 500,
            fontSize: 14
        },
        period: {
            fontSize: 17,
            fontWeight: 700,
            paddingBottom: 12,
        },
        effect: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            lineHeight: 1,
            fontSize: 15,
            color: colors.orange,
            fontWeight: 700
        },
        amt: {
            fontSize: 22
        },
        color: {
            color: colors.main
        },
        cellStyle: {
            color: colors.main,
            fontWeight: 700
        },
        chartBox: {

        }
    });

    const format = (val: number) => {

        if(val < 0) {
            return '('+formatNum({num: Math.abs(Math.round(val / 1000))})+')';
        } else {
            return formatNum({num: Math.round(val / 1000)});
        }
    };

    // const age = getRepAge(data.customer);

    //임시로 끌어온 데이터
    const corpValSs = data.report?.ci.corpValSs;
    const thisYear = (corpValSs && corpValSs[0].year) ?? 0;

    // 마지막년도 값
    const lsCv = corpValSs![corpValSs!.length - 1];

    // 절감 금액
    const savePrice = lsCv.cv - (lsCv.cvRp ?? lsCv.cv);

    const retireYear = data.customer.salary.rep[data.customer.salary.repIdx].retireDate ?
        (parseInt(data.customer.salary.rep[data.customer.salary.repIdx].retireDate.substring(0, 4)) - thisYear) : 10;


    const list = [
        ['자본(순자산)', 'equity', 1],
        // ['*미처분이익잉여금', 'unapprtdEarning', 2],
        ['(-)연간 배당금', 'dividend', 2],
        ['당기순이익', 'netIncome_add', 1],
        ['(-)대표이사 퇴직금', 'retirePay', 2],
        ['1주당 순손익가치', 'netincomeValue', 1, 2],
        ['1주당 순자산가치', 'netassetValue', 1, 2],
        ['플랜 적용 전 기업가치', 'cv', 1, 3],
        ['배당 플랜 적용 후 기업가치', 'cvDv', 1, 3],
        ['배당&퇴직금 플랜 적용 후 기업가치', 'cvRp', 1, 3],
    ];

    // 각 사업년도 1주당 순자산가치 값이 음수일 경우, 0 으로 변경
    corpValSs?.map((cvs, i) => {
        if(cvs.netassetValue < 0) {
            cvs.netassetValue = 0;
        }

        return cvs;
    })

    // const formatter = (value: number) => value === 0 || value ? value.toFixed(1).toLocaleString() + '억원' : null;
    const formatter = (value: number) => value === 0 || value ? toHundredMillion({num: value, precision: 1, type:'graph'}) : null;

    const corpValMerge = [lsCv.cv, lsCv.cvDv, lsCv.cvRp];

    const average = (corpValMerge[1] + corpValMerge[2]) / 2;


    const data1 = {
        labels: ['플랜 적용 전 기업가치', '배당 플랜 후 기업가치', '배당&퇴직금 플랜 후 기업가치'],
        datasets: [
            {
                label: '',
                type: 'line' as const,
                data: [null, average + corpValMerge[1], average + corpValMerge[2]],
                backgroundColor: 'transparent',
                borderColor: 'transparent',
                datalabels: {
                    layout: {
                        padding: {
                            left: 40,
                            right: 20
                        }
                    },
                    offset: -13,
                    labels: {
                        title: {display: false},
                        styles: {
                            color: '#fff',
                            font: {
                                weight: 700,
                                size: 18,
                            },
                        }
                    },
                    formatter: (value: number, context: any) => context.dataIndex === 0 ? undefined : Math.round(100 - (corpValMerge[context.dataIndex] / corpValMerge[0] * 100)) + '%'
                }
            },
            {
                label: '자본',
                data: corpValMerge,
                backgroundColor: [colors.main, colors.skyBlue2, colors.skyBlue2],
                datalabels: {
                    offset: 4,
                    backgroundColor: '#fff',
                    labels: {
                        title: {},
                        styles: {
                            color: colors.main,
                            font: {
                                weight: 700,
                                size: chartFont
                            },
                        }
                    }
                }
            },
        ],
    };

    const goEditPop:EditReportInfoBtnProps = {
        title: '배당플랜 정보',
        tab: ReportAddInfoTab.Dividend,
        data: data,
        pageType: 'REPORT'
    };

    return(
        <MainReportContainer reportKey='StockValueSavingsSimulation'>
            <ReportLeft icon='evaluation' title='기업가치평가'>
                <SideNote title='연간 배당금 가정'>
                    <Text>매년 {toHundredMillion({num: data.customer.dvIn?.dividend, unit: '억', precision: 0})}원씩 중간배당으로 현금배당한다고 가정합니다.</Text>
                </SideNote>
                <SideNote title='대표이사 퇴직금 가정'>
                    <Text>임원 퇴직소득 보상플랜에 적용된 대표이사의 퇴직금 가정 값을 적용합니다.</Text>
                </SideNote>
            </ReportLeft>
            <ReportRight reportKey='StockValueSavingsSimulation' title='배당 및 퇴직금 플랜 적용 시 주식가치 절감 효과' state={goEditPop}>
                <GrayTxtBox>
                    배당플랜을 통해 매년 {toHundredMillion({num: data.customer.dvIn?.dividend, unit: '억', precision: 0})}원씩 주주 배당을 꾸준히 실행하여 미처분이익잉여금 증가 속도를 조절하고, 임원퇴직금 플랜을 통해 퇴직급여 {toHundredMillion({num: data.report.eic.rp[0].retirePay, unit: '억', precision: 1})}원을 비<Text>용으로 인식한 결과 </Text><Text style={styles.stressTxt}>{isPdf && '∙'}순손익가치와 순자산가치를 감소시켜 대표이사 퇴직 시점 기존 예상 기업가치보다 {toHundredMillion({num: savePrice, unit: '억', precision: 1})}원 낮추는 효과</Text>{isPdf && '∙'}를 기대할 수 있습니다.
                </GrayTxtBox>
                <TableTitle title='배당 및 퇴직금 플랜 적용 후 기업가치 변동 추이' type='main' tableStyle={{paddingTop: 36, marginBottom: 36}} dateOrUnit='(단위: 천원)'>
                    <View style={mainFinanceT.tableWrap}>
                        <View style={[mainFinanceT.headBox, styles.cellHeight, {paddingRight: 0}]}>
                            <Text style={[mainFinanceT.head2, mainFinanceT.txtLeft, styles.top, styles.FirstCellFlex, styles.centertxtCellFirst]}>사업년도</Text>

                            {corpValSs?.map((it, i) =>
                                <Text key={i} style={[mainFinanceT.head2, styles.top, styles.centertxtCellAll]}>{it.year}년</Text>)}
                        </View>
                        <View
                            style={[mainFinanceT.bodyBox, mainFinanceT.basicInfo, mainFinanceT.bdBWhite, styles.cellHeight, {paddingRight: 0}]}>
                            <Text style={[mainFinanceT.head2, styles.top, styles.FirstCellFlex, styles.centertxtCellFirst]}>경과기간</Text>

                            {corpValSs?.map((it, i) =>
                                <Text key={i} style={[mainFinanceT.head2, styles.top, styles.centertxtCellAll]}>
                                    {thisYear === it.year ? '당해년도' : (it.year - thisYear) + '년 후'}
                                </Text>)}
                        </View>
                        {/*<View style={[mainFinanceT.bodyBox, mainFinanceT.basicInfo, styles.cellHeight, {paddingRight: 0}]}>*/}
                        {/*    <Text style={[mainFinanceT.head2, styles.top, styles.FirstCellFlex, styles.centertxtCellFirst]}>(대표이사 나이)</Text>*/}

                        {/*    {corpValSs?.map((it, i) =>*/}
                        {/*        <Text key={i} style={[mainFinanceT.head2, styles.top, styles.centertxtCellAll]}>({age ? (age + it.year - thisYear) + '세' : EMPTY})</Text>)}*/}
                        {/*</View>*/}

                        {list.map((ar, j) =>
                            <View key={j} style={
                                [
                                    mainFinanceT.bodyBox,
                                    styles.cellHeight2,
                                    {
                                        color: ar[3] === 1 ? colors.main : '#000',
                                        fontWeight: ar[3] === 1 ? 700 : (ar[3] === 3 ? 500 : 400),
                                        backgroundColor: ar[3] === 3 ? '#eee': 'transparent'}
                                ]}>
                                <Text style={[mainFinanceT.head2, styles.top, styles.FirstCellFlex, ar[2] === 2 ? styles.depth2 : {},ar[0].toString().includes('(-)') ? styles.cellStyle : {}]}>{ar[0]}</Text>
                                {corpValSs?.map((it, i) => i < 6 &&
                                    <Text key={i} style={[mainFinanceT.head2, styles.top, styles.txtRight, ar[0].toString().includes('(-)') ? styles.cellStyle : {}]}>
                                        {
                                            ar[3] === 3 ? toHundredMillion({num: it[ar[1]], precision: 1}) : ar[3] === 2 ? formatNum({num: it[ar[1]], unit: '원', empty: '0원'}) : (ar[3] === 1 ? toPercent(it[ar[1]]) : format(it[ar[1]]))
                                        }
                                    </Text>)}
                            </View>)}

                    </View>
                </TableTitle>
                <TableTitle title='대표이사 예상 퇴직 시점 주식가치 절감 효과' type='main' gridStyle={styles.gridBox}>
                    <View style={styles.blueBox}>
                        <Image style={styles.blueBoxImage} src='/images/report/i_apartment.png'/>
                        <View>
                            <View style={styles.point}><Text>대표이사 퇴직 시점</Text></View>
                            <Text style={styles.period}>
                                {retireYear}년 후</Text>
                            <Text style={styles.effect}><Text style={styles.amt}>{toHundredMillion({num: savePrice, unit: '', precision: 1})}</Text> 억원 절감 효과</Text>
                        </View>
                    </View>
                    <View style={styles.chartBox}>
                        <Chart
                            width={580}
                            height={158}
                            type={'bar'}
                            plugins={[LabelWithImage]}
                            options={{
                                indexAxis: 'y',
                                plugins: {
                                    legend: {
                                        display: false,
                                    },
                                    datalabels: {
                                        anchor: 'end',
                                        align: 'end',
                                        formatter: formatter
                                    },
                                    //@ts-ignore
                                    labelWithImage: {
                                        width: 73,
                                        height: 37,
                                        offset: 20
                                    },
                                },
                                datasets: {
                                    bar: {
                                        barThickness: 44,
                                        maxBarThickness: 26,
                                        borderRadius: 8
                                    },
                                },
                                layout: {
                                    padding: {
                                        right: 50,
                                    }
                                },
                                scales: {
                                    y: {
                                        ticks: {
                                            font: {
                                                size: chartLabelFont,
                                                family: 'Noto Sans KR'
                                            },
                                            color: '#000'
                                        },
                                        grid: {
                                            display: false,
                                            borderColor: '#ccc'
                                        },
                                    },
                                    x: {
                                        grid: {
                                            display: false,
                                            borderColor: '#ccc'
                                        },
                                        display: false,
                                    },
                                }
                            }}
                            data={data1}/>
                    </View>
                </TableTitle>
            </ReportRight>
        </MainReportContainer>
    );
};

export default StockValueSavingsSimulation;