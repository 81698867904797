import {inject, injectable} from 'inversify';
import axios from 'axios';
import 'reflect-metadata';
import {AuthToken} from './auth/auth-token';
import {AttachFileInfo, ImageData} from "./upload/dto/image-data";

@injectable()
export class ApiCall {
    @inject(AuthToken)
    private authToken!: AuthToken;

    private readonly _apiUrl: string;

    private readonly _coApiUrl: string;

    private readonly _uploadUrl: string;

    logout!: VoidFunction;

    constructor() {
        this._apiUrl = process.env.REACT_APP_API_URL!;
        this._coApiUrl = process.env.REACT_APP_CO_API_URL!;
        this._uploadUrl = process.env.REACT_APP_IMG_URL!;

        axios.interceptors.response.use(
            (response) => {
                return response;
            },
            (error) => {
                const {response: {status}} = error;
                if (status === 401) {
                    //login 페이지 이동
                    if (this.logout) this.logout();
                }
                return Promise.reject(error)
            });
    }

    async fetch<T>(url: string, param?: Record<string, any> | null, header?: Record<string, string>): Promise<T> {
        const header2 = header ?? this.authToken.makeHeader();

        if (!url.startsWith('http')) url = this._apiUrl + url;
        if (param) {
            let form = new URLSearchParams();
            for (const i in param) {
                if (Array.isArray(param[i])) {
                    param[i].forEach((it: any) => {
                        if (it != null) form.append(i, it);
                    });
                } else {
                    if (param[i] != null) form.append(i, param[i]);
                }
            }
            return (await axios.post<T>(url, form, {headers: header2})).data;
        } else {
            return (await axios.get<T>(url, {headers: header2})).data;
        }
    }

    async post<T>(url: string, param?: Record<string, any> | null, header?: Record<string, string>): Promise<T> {
        const header2 = header ?? this.authToken.makeHeader();

        if (!url.startsWith('http')) url = this._apiUrl + url;

        if (param) {
            let form = new URLSearchParams();
            for (const i in param) {
                if (Array.isArray(param[i])) {
                    param[i].forEach((it: any) => {
                        if (it != null) form.append(i, it);
                    });
                } else {
                    if (param[i] != null) form.append(i, param[i]);
                }
            }
            return (await axios.post<T>(url, form, {headers: header2})).data;
        } else {
            return (await axios.post<T>(url, "", {headers: header2})).data;
        }
    }

    async put<T>(url: string, param?: Record<string, any> | null, header?: Record<string, string>): Promise<T> {
        const header2 = header ?? this.authToken.makeHeader();

        if (!url.startsWith('http')) url = this._apiUrl + url;

        if (param) {
            let form = new URLSearchParams();
            for (const i in param) {
                if (Array.isArray(param[i])) {
                    param[i].forEach((it: any) => {
                        if (it != null) form.append(i, it);
                    });
                } else {
                    if (param[i] != null) form.append(i, param[i]);
                }
            }
            return (await axios.put<T>(url, form, {headers: header2})).data;
        } else {
            return (await axios.put<T>(url, "", {headers: header2})).data;
        }
    }

    async json<T>(url: string, param: any, sendToken: boolean = true) {
        const header = sendToken ? this.authToken.makeHeader() : {};
        header['Content-type'] = 'application/json;charset=utf-8';

        if (!url.startsWith('http')) url = this._apiUrl + url;
        return (await axios.post<T>(url, JSON.stringify(param), {headers: header})).data;
    }

    async multipart<T>(url: string, param: any, fileName: string, file: File | Blob, sendToken: boolean = true) {
        const header = sendToken ? this.authToken.makeHeader() : {};
        header['Content-type'] = 'multipart/form-data';
        if (!url.startsWith('http')) url = this._apiUrl + url;

        const form = new FormData();
        for (const i in param) {
            form.append(i, param[i]);
        }
        if (fileName) {
            if (file instanceof File && fileName.indexOf('.') === -1) {
                fileName += /\.[^.]+$/.exec(file.name);
            }
            form.append('file', file, fileName);
        } else form.append('file', file);
        return (await axios.post<T>(url, form, {headers: header})).data;
    }

    async uploadApiServer<T>(url: string, form: FormData): Promise<T> {
        const header = this.authToken.makeHeader();
        header['Content-type'] = 'multipart/form-data';

        if (!url.startsWith('http')) url = this._apiUrl + url;

        return (await axios.post<T>(url, form, {headers: header})).data;
    }

    async upload(url: string, form: FormData): Promise<ImageData> {
        const header = this.authToken.makeHeader();
        header['Content-type'] = 'multipart/form-data';

        return (await axios.post(`${this._uploadUrl}${url}`, form, {headers: header})).data;
    }

    async uploadAttach(url: string, form: FormData): Promise<AttachFileInfo> {
        const header = this.authToken.makeHeader();
        header['Content-type'] = 'multipart/form-data';

        return (await axios.post(`${this._uploadUrl}${url}`, form, {headers: header})).data;
    }

    async delete<T>(url: string, param?: Record<string, any> | null, header?: Record<string, string>): Promise<T> {
        const header2 = header ?? this.authToken.makeHeader();
        header2['Content-type'] = 'application/json;charset=utf-8';

        if (!url.startsWith('http')) url = this._apiUrl + url;

        return (await axios.delete<T>(url, {data: JSON.stringify(param), headers: header2})).data;

        // return (await axios.delete<T>(url, {headers: header2})).data;
    }

    async coFetch<T>(url: string, param?: Record<string, any> | null, header?: Record<string, string>): Promise<T> {
        const header2 = header ?? this.authToken.makeHeader();

        if (!url.startsWith('http')) url = this._coApiUrl + url;
        if (param) {
            let form = new URLSearchParams();
            for (const i in param) {
                if (Array.isArray(param[i])) {
                    param[i].forEach((it: any) => {
                        if (it != null) form.append(i, it);
                    });
                } else {
                    if (param[i] != null) form.append(i, param[i]);
                }
            }
            return (await axios.post<T>(url, form, {headers: header2})).data;
        } else {
            return (await axios.get<T>(url, {headers: header2})).data;
        }
    }

    getApiUrl (): string {
        return this._apiUrl;
    }
}
