import React, {useEffect, useRef} from 'react';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {
    ArcElement,
    BarController,
    BarElement,
    CategoryScale,
    Chart,
    ChartConfiguration,
    DoughnutController,
    LinearScale,
    LineController,
    LineElement,
    PointElement
} from 'chart.js';

Chart.register(BarController, BarElement, CategoryScale, ChartDataLabels);
Chart.register(LineController, LinearScale, LineElement, PointElement);
Chart.register(DoughnutController, ArcElement);

export interface ChartJsProps extends ChartConfiguration {
    width?: number;
    height?: number;
}

export const ChartJs = ({width, height, ...config}: ChartJsProps) => {
    const ref = useRef<HTMLCanvasElement>(null);
    useEffect(() => {
        config.options = {...config.options, responsive: false};
        const chart = new Chart(ref.current!.getContext('2d', {willReadFrequently: true})!, config);
        return () => {
            chart.destroy();
        }
    }, [config]);
    return <canvas width={width} height={height} ref={ref}/>
}