import {action, computed, makeObservable, observable} from 'mobx';
import {injectable} from 'inversify';
import 'reflect-metadata';

@injectable()
export class ChartManager {
    charts: JSX.Element[] = [];
    images: string[] = [];
    idx: number = 0;

    constructor() {
        makeObservable(this, {
            charts: observable,
            images: observable,
            getId: action,
            addChart: action,
            setData: action,
            clear: action,
            isLoaded: computed,
        });
    }

    addChart = (chart: JSX.Element) => {
        this.charts.push(chart);
    }

    getId = () => {
        this.images.push('');
        return this.images.length - 1;
    }

    getId2 = () => {
        return this.idx++;
    }

    setData = (id: number, data: string) => {
        this.images[id] = data;
        this.images = [...this.images];
    }

    clear = () => {
        this.charts = [];
        this.images = [];
        this.idx = 0;
    }

    get isLoaded() {
        return this.images.length === this.charts.length;
    }
}

