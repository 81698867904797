import styles from './footer.module.scss';
import classNames from 'classnames/bind';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

const Footer = () => {
    return (
        <div className={cx('footer')}>
            <div className={cx('footer-inner')}>
                <div className={cx('left')}>
                    <p className={cx('company')}>주식회사 런인베스트</p>
                    <p className={cx('address')}>
                        대표이사 : 박세라 | 사업자등록번호 : 471-87-00222<br/>
                        주소 : 서울특별시 중구 남대문로5길 47, 903호<br/>
                        대표전화 : 02-3445-8867 | 팩스번호 : 02-3445-8860<br/>
                        통신판매업 신고번호 : 제2019-서울중구-1522호<br/>
                        Copyright© (주) 런인베스트 LearnInvest Co., Ltd. All rights Reserved
                    </p>
                    <Link to='/terms/terms1' className={cx('policy')}>이용약관</Link>
                    <Link to='/terms/terms2' className={cx('policy', 'wall')}>개인정보처리방침</Link>
                </div>
                <div className={cx('right')}>
                    <a href='tel:02-3445-8867' title='call'><img src='/images/partners_home/call.png' alt='call'/></a>
                    {/*<a href='mailto:copartner@learninvest.co.kr' title='mail'><img src='/images/partners_home/mail.png'*/}
                    {/*                                                               alt='mail'/></a>*/}
                    <a href='https://www.facebook.com/cotalkbylearninvest/' title='facebook'><img
                        src='/images/partners_home/facebook.png' alt='facebook'/></a>
                    <a href='https://www.instagram.com/cotalkbylearninvest/' title='insta'><img
                        src='/images/partners_home/insta.png' alt='insta'/></a>
                    <a href='http://pf.kakao.com/_GdUjxj/chat' title='talk'><img src='/images/partners_home/talk.png'
                                                                                 alt='talk'/></a>
                    {/*<a href='https://copartner.channel.io/support-bots/56507' title='channelTalk'><img src='/images/partners_home/channel_talk.png'*/}
                    {/*                                                             alt='talk'/></a>*/}
                </div>
            </div>
        </div>
    );
};

export default Footer;