import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {ApiResult} from "../api-result";
import {BillingKeyWithBankDto, MemberBillingDto} from "./dto/member-billing-dto";

@injectable()
export class PrMemberPayApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    getMemberBilling = async () => {
        const url = `/api/prMember/pay/billingKey`;
        return await this.apiCall.fetch<ApiResult<MemberBillingDto[]>>(url);
    }

    getMemberBillingWithBank = async () => {
        const url = `/api/prMember/pay/billingKeyWithBank`;
        return await this.apiCall.fetch<ApiResult<BillingKeyWithBankDto>>(url);
    }

    deleteMemberBilling = async (billingId: string) => {
        const url = `/api/prMember/pay/billingKey/${billingId}`;
        return await this.apiCall.delete<ApiResult<any[]>>(url);
    }

    registerMemberBilling = async (repKey: string) => {
        const url = `/api/prMember/pay/billingKey`;
        return await this.apiCall.fetch<ApiResult<any[]>>(url, {repKey});
    }
}