import styles from './certificate-status-pop.module.scss';
import classNames from 'classnames/bind';
import {Field, Form, Formik} from 'formik';
import {SelectItem} from '../model/types';

const cx = classNames.bind(styles);

interface CertificateStatusPopProps {
    list: SelectItem[];
    certStat: { [k: string]: boolean };
    setCertStat: (cert: { [k: string]: boolean }) => void;
    onClose: VoidFunction
}

const CertificateStatusPop = ({list, certStat, setCertStat, onClose}: CertificateStatusPopProps) => {
    return (
        <div className='full-background'>
            <Formik
                initialValues={certStat}
                onSubmit={(values) => {
                    setCertStat(values);
                    onClose();
                }}>
                <Form>
                    <div className={`pop-inner ${cx('certificate-status-pop')}`}>
                        <p className={cx('title')}>기업인증현황을 선택해 주세요. (중복 선택 가능)</p>
                        <div className={cx('certificate-list')}>
                            {list.map((it) =>
                                <label key={it.id} className={cx('check-box')}>
                                    <Field type='checkbox' name={it.id}/>{it.title}
                                </label>
                            )}
                        </div>
                        <div className={cx('btn-area')}>
                            <button type='button' className={cx('close-btn')} onClick={onClose}>닫기</button>
                            <button type='submit' className={cx('save-btn')}><i></i>선택</button>
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>
    );
};

export default CertificateStatusPop;