import {Form, Formik} from "formik";
import React from "react";
import classNames from "classnames/bind";
import styles from "./laborer-num-info.module.scss";
import {NumberInputBox} from "./customer/edit-common";
import {ReportAddInfoPopProps} from "./report-add-info-pop";
import usePromise from "../model/common/use-promise";
import {useInjection} from "inversify-react";
import {CustomerApi} from "../model/customer/customer-api";
import {AnnualEmployeeInfo} from "../model/co-report/dto/annual-employee-info";
import {asyncCall} from "../util/util";
import {toast} from "react-toastify";
import {strSaved} from "../data/strings";
import {useMountedState} from "react-use";
import {DialogManager} from "../components/dialog-manager";
import {CustomerInputApi} from "../model/customer/customer-input-api";
import {isSoleReport} from "./report/report-util";

const cx = classNames.bind(styles);

const LaborerNumInfo = ({data, setData, onClose}: ReportAddInfoPopProps) => {
    const customerApi = useInjection(CustomerApi);
    const customerInputApi = useInjection(CustomerInputApi);
    const isMounted = useMountedState();
    const dialogManager = useInjection(DialogManager);
    const [loading, rowDataResult] = usePromise(() => customerApi.rawData(data.customer.id), [data]);
    const emptyList = data.report.corpOperatingExpenses.map(value => {
        return {
            year: Number(value.stDate.substring(0, 4)),
            totalCnt: value.employeeCnt
        } as AnnualEmployeeInfo
    }).reverse();

    if (loading || !rowDataResult) return <div></div>;
    if (!data.customer) return null;

    const employeeInfo = rowDataResult.val?.annualEmployeeInfo ?? [];
    const infoList = emptyList.map((v) => {
        return {
            ...v,
            ...employeeInfo.find(info => v.year === info.year)
        };
    });

    const presentYear = new Date;
    const lastYear = presentYear.getFullYear() - 1;

    return(
        <Formik
            initialValues={infoList}
            onSubmit={(values) => {
                let empInfoApi;

                if(isSoleReport(data)) {
                    empInfoApi = customerInputApi.saveSoleAnnualEmployeeInfo;
                } else {
                    empInfoApi = customerInputApi.saveAnnualEmployeeInfo;
                }

                asyncCall(empInfoApi, [data.customer.id, values as AnnualEmployeeInfo[]], result => {
                    setData(result);
                    data.customer = result.customer;//팝업에서 사용하는 데이터 업데이트
                    toast.success(strSaved);
                }, isMounted, (e) => {
                    dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                });
            }}>
            {() =>
                <Form className={cx('labor-num-info')}>
                    <div className={cx('btn-area')}>
                        <button className={cx('save-btn')} type='submit'><i/>저장하기</button>
                        <button className='close-btn' type='button' onClick={() => {
                            onClose()
                        }}>닫기</button>
                    </div>
                    <div className={cx('scroll-box')}>
                        <div className={cx('scroll-area')}>
                            <p className={cx('title')}>근로자수 정보<button className={cx('reset-btn')} type='button'><i></i>초기화</button></p>
                            <div className={cx('input-area')}>
                                <div className={cx('grid-box')}>
                                    <div className={cx('inner-grid-box')}>
                                        <p/>
                                        <p>상시근로자 합계<span className='numbering'>1)</span></p>
                                        <p>청년</p>
                                        <p>청년 외</p>
                                    </div>
                                    {infoList.map((info, i) =>
                                        <div key={i} className={cx('inner-grid-box')}>
                                            <p className={cx('year-txt')}>{info.year}년</p>
                                            <NumberInputBox name={`${i}.totalCnt`} unit='명' inputWidth='18rem'/>
                                            <NumberInputBox name={`${i}.youngCnt`} unit='명' inputWidth='18rem'/>
                                            <NumberInputBox name={`${i}.otherCnt`} unit='명' inputWidth='18rem'/>
                                        </div>
                                    )}
                                </div>
                                {lastYear === infoList[2].year ?
                                    <p className='footNote'>주1) {infoList[0].year}년, {infoList[1].year}년 말 기준 근로복지공단 상시근로자 수 데이터를 가져옵니다. {infoList[2].year}년은 정보가 제공되지 않아, 최근 조회 상시근로자 수를 소급 적용하였습니다.</p>
                                    : <p className='footNote'>주1) {infoList[0].year}년, {infoList[1].year}년, {infoList[2].year}년 말 기준 근로복지공단 상시근로자 수 데이터를 가져옵니다.</p>
                                }
                            </div>
                        </div>
                    </div>
                </Form>}
        </Formik>
    );
};

export default LaborerNumInfo;