import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {ApiResult} from "../api-result";
import {MemberShipInUseDto, PrMemberShip} from "./dto/pr-member-ship";

@injectable()
export class MemberShipApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    getMemberShipInUse = async () => {
        return await this.apiCall.fetch<ApiResult<MemberShipInUseDto>>('/api/payment/memberShip/inUse');
    }

    getMemberShipRelease = async () => {
        return await this.apiCall.fetch<ApiResult<PrMemberShip[]>>('/api/payment/memberShip/release');
    }
}