import {inject, injectable} from "inversify";
import {AuthToken} from "../auth/auth-token";
import {CorpBizContainerDto, CorpBizDto} from "./dto/corp-biz-dto";
import axios from "axios";
import {PagingList} from "./dto/paging";
import {DownloadKeyDto} from "./dto/download-key-dto";
import {LiLearnDto} from "./dto/learn-dto";
import {LearnContainer} from "./dto/learn-container-dto";
import {QnaBoard, QnaBoardDto} from "./dto/qna-board";
import {makeSearchDataQs} from "../../util/str-util";
import {ApiResult} from "../api-result";

@injectable()
export class LearninvestApi {
    @inject(AuthToken)
    private authToken!: AuthToken;

    learninvestUrl: string = process.env.REACT_APP_LEARN_URL ?? "https://learninvest.co.kr";

    private makeHeader(): Record<string, string> {
        return {
            'auth-token': this.authToken.getToken()
        };
    }

    private makeMultiPartHeader(): Record<string, string> {
        return {
            ...this.makeHeader(),
            'Content-type': 'multipart/form-data'
        };
    }

    public async getCorpBizStart(): Promise<CorpBizContainerDto> {
        try {
            const result = await axios.get(`${this.learninvestUrl}/capi/corpBiz/start`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     * @param page 1부터 시작
     * @param categoryNo 카테고리 번호
     */
    public async pagingCorpBiz(page: number, categoryNo?: number): Promise<PagingList<CorpBizDto>> {
        try {
            const result = await axios.get(`${this.learninvestUrl}/capi/corpBiz/paging?currentPage=${page}&categoryNo=${categoryNo ?? ''}`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async getMakeDownloadKey (articleNo: number): Promise<DownloadKeyDto> {
        try {
            const result = await axios.get(`${this.learninvestUrl}/capi/corpBiz/makeDownloadKey?articleNo=${articleNo}`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async startLearn(): Promise<LiLearnDto[]> {
        try {
            const result = await axios.get(`${this.learninvestUrl}/capi/learn/start`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async getLearnContainer(learnNo: number): Promise<LearnContainer> {
        try {
            const result = await axios.get(`${this.learninvestUrl}/capi/learn/${learnNo}`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    /**
     * @param page 1부터 시작
     * @param size 1페이지에 노출되는 글 수
     * @param searchData 검색 옵션
     */
    public async pagingQna(page: number, size: number, searchData: {[key: string]: string | undefined}): Promise<PagingList<QnaBoard>> {
        try {
            const result = await axios.get(`${this.learninvestUrl}/capi/qna/paging?currentPage=${page}&articlesPerPage=${size}${makeSearchDataQs(searchData)}`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async getQna(articleNo: string): Promise<QnaBoardDto> {
        try {
            const result = await axios.get(`${this.learninvestUrl}/capi/qna/v2/post/${articleNo}`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async countMonthlyQna(memberNo: string): Promise<ApiResult<undefined>> {
        try {
            const result = await axios.get(`${this.learninvestUrl}/capi/qna/count/${memberNo}`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async writeQna(formData: FormData): Promise<QnaBoardDto> {
        try {
            const result = await axios.post(`${this.learninvestUrl}/capi/qna/v2/post`, formData, {headers:  this.makeMultiPartHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async modifyQna(memberNo: string, articleNo: string, formData: FormData): Promise<QnaBoardDto> {
        try {
            const result = await axios.put(`${this.learninvestUrl}/capi/qna/v2/post/${memberNo}/${articleNo}`, formData, {headers: this.makeMultiPartHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }

    public async deleteQna(memberNo: string, articleNo: string): Promise<ApiResult<undefined>> {
        try {
            const result = await axios.delete(`${this.learninvestUrl}/capi/qna/post/${memberNo}/${articleNo}`, {headers: this.makeHeader()});

            return result.data;
        } catch (e) {
            return Promise.reject(e);
        }
    }
}