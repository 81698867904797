import {usePdfComponents} from "../pdf-hooks";
import {StyleSheet} from '@react-pdf/renderer';
import {getMultiplyValue, getRowT} from "../../../styles/styleSheet";
import {
    isCancelStr,
    MultiInfos,
    MultiStrInfos,
    OneInfos,
    outputPrCrChangeStr,
    outputPrCrRegisterStr,
    RegisterDateInfo,
    removeCancelStr
} from "./corporate-regi-copy-common";
import {formatNum, ymdToDateString} from "../../../util/str-util";
import React from "react";
import {SummaryReport} from "../../../model/inquiry/dto/summary-report";
import {useCorporateRegStyle} from "./corporate-regi-context";
import {Customer} from "../../../model/customer/dto/customer";
import {PrCorpRegAll} from "../../../model/customer/dto/pr-corp-reg-all";

/*const CorporateRegiCopyAllCont = ({summary, customer, corpReg}: { summary: SummaryReport, customer: Customer, corpReg?: PrCorpRegAll }) => {
    const customerApi = useInjection(CustomerApi);
    const [loadCorpReg, setLoadCorpReg] = useState(corpReg);
    const [loading, setLoading] = useState(false);
    const [noCorpReg, setNoCorpReg] = useState(false);

    const loadData = async () => {
        setLoading(true);
        const result = await customerApi.corpRegAll(customer.id);
        setLoading(false);

        if (result.success) {
            setLoadCorpReg(result.val!);
        }
        else {
            setNoCorpReg(true);
        }
    }

    // const [loading, data] = usePromise(() => customerApi.corpRegAll(customer.id), [customerApi, customer]);
    useEffect(() => {
        if (corpReg == null) {
            loadData();
        }
    }, [corpReg]);


    if (loading || !loadCorpReg) {
        return <LottieComp width='10.0rem' height='10.0rem' jsonFile='loading'/>
    }

    if (noCorpReg) {
        return <div>말소사항 없음</div>
    }

    return <CorporateRegiCopyAll summary={summary} customer={customer} corpReg={loadCorpReg!}/>
}*/
const CorporateRegiCopyAll = ({summary, customer, corpReg}: { summary: SummaryReport, customer: Customer, corpReg: PrCorpRegAll }) => {
    const {View, Text, isPdf} = usePdfComponents();
    const rowT = getRowT(isPdf);
    const num = getMultiplyValue(isPdf);
    const depStyles = useCorporateRegStyle();

    const styles = StyleSheet.create({
        copyTable: {
            width: '100%',
            paddingTop: 27 * num
        },
        topLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            paddingBottom: 24 * num
        },
        corporateNum: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: 624 * num,
            height: 36 * num,
            border: '1px solid #999',
            borderRadius: 8 * num,
        },
        body: {
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            padding: `0 ${11 * num}px`,
            color: '#4c4c4c',
            fontSize: 13 * num,
        },
        grayBox: {
            backgroundColor: '#f2f2f2',
            justifyContent: 'flex-start',
            height: '100%',
            padding: `0 0 0 ${22 * num}px`
        },
        inquiryDate: {
            fontSize: 13 * num,
            color: '#808080'
        },
        flexWrap: {
            paddingBottom: 24 * num
        },
        desc: {
            padding: '0 30px 0 26px',
            lineHeight: 1.7 * num
        },
        desc2: {
            maxWidth: 153 * num
        },
        stockList: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 241 * num
        },
        ceoInfo: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: 405 * num,
            paddingLeft: 26 * num,
        },
        borderL: {
            borderLeft: `${0.5 * num}px solid #ccc`
        },
        twoLineTitle: {
            display: 'flex',
            flexDirection: 'column',
        },
        cancelLine: {
            textDecoration: 'line-through'
        },
        descList: {
            display: 'flex',
            flexDirection: 'row',
            lineHeight: 1.7 * num,
            paddingLeft: 26,
        }
    });



    return (
        <>
            <View style={styles.copyTable}>
                <View style={styles.topLine}>
                    <View style={styles.corporateNum}>
                        <View style={[styles.body, styles.grayBox, {
                            borderTopLeftRadius: 8,
                            borderBottomLeftRadius: 8
                        }]}><Text>등기번호</Text></View>
                        <View style={styles.body}><Text>{corpReg.resRegistrationNumber}</Text></View>
                        <View style={[styles.body, styles.grayBox]}><Text>등록번호</Text></View>
                        <View style={styles.body}><Text>{corpReg.resRegNumber}</Text></View>
                    </View>
                    <Text style={styles.inquiryDate}>조회일시 {ymdToDateString(corpReg.searchDate)}</Text>
                </View>
                <View style={[rowT.flexWrap, styles.flexWrap]}>

                    <View style={rowT.flexLine}>
                        <View style={[rowT.titleCover, depStyles.titleCover]}><Text
                            style={rowT.title}>{summary.CS.company}</Text></View>
                        <View style={{flex: 1}}>
                            {corpReg.companyNm && corpReg.companyNm.map((val, i) =>
                                <View style={styles.descList}>
                                    <View style={[rowT.borderR, {flex: 1}, i === 0 ? {paddingTop: 8} : {}, i === corpReg.companyNm.length-1 ? {paddingBottom: 8}:{}]}>
                                        <Text style={[isCancelStr(val.val) ? depStyles.cancelLine : {}]}>{removeCancelStr(val.val)}</Text>
                                    </View>
                                    <View style={[styles.desc2, {flex: 1}]}><Text style={styles.desc}>{outputPrCrRegisterStr(val.registerDate)}</Text></View>
                                </View>
                            )}
                        </View>
                    </View>

                    {corpReg.userAddr && <View style={rowT.flexLine}>
                        <View style={[rowT.titleCover, depStyles.titleCover]}><Text
                            style={rowT.title}>{summary.CS.address}</Text></View>
                        <View style={{flex: 1}}>
                            {corpReg.userAddr.map((val, i) =>
                            <View style={styles.descList}>
                                <View style={[rowT.borderR, {flex: 1}, i === 0 ? {paddingTop: 8} : {}, i === corpReg.userAddr.length-1 ? {paddingBottom: 8}:{}]}>
                                    <Text style={[isCancelStr(val.val) ? depStyles.cancelLine : {}]}>{removeCancelStr(val.val)}</Text>
                                </View>
                                <View style={[styles.desc2, {flex: 1}]}><Text style={styles.desc}>{outputPrCrRegisterStr(val.registerDate)}</Text></View>
                            </View>)}
                        </View>
                    </View>
                    }

                    {corpReg.noticeMethod && corpReg.noticeMethod.length > 0 &&
                    <View style={rowT.flexLine}>
                        <View style={[rowT.titleCover, depStyles.titleCover]}>
                            <Text style={rowT.title}>공고방법</Text>
                        </View>
                        <View style={{flex: 1}}>
                            {corpReg.noticeMethod.map((val,i) =>
                            <View style={styles.descList}>
                                <View style={[rowT.borderR, {flex: 1, paddingRight: 26}, i === 0 ? {paddingTop: 8} : {}, i === corpReg.noticeMethod.length-1 ? {paddingBottom: 8}:{}]}>
                                    <Text style={[isCancelStr(val.val) ? depStyles.cancelLine : {}]}>{removeCancelStr(val.val)}</Text>
                                </View>
                                <View style={[styles.desc2, {flex: 1}]}><Text style={styles.desc}>{outputPrCrRegisterStr(val.registerDate)}</Text></View>
                            </View>)}
                        </View>
                    </View>
                    }

                    {corpReg.oneStockAmt && corpReg.oneStockAmt.length > 0 &&
                    <View style={rowT.flexLine}>
                        <View style={[rowT.titleCover, depStyles.titleCover]}>
                            <Text style={rowT.title}>{summary.CS.oneStock}</Text>
                        </View>
                        <View style={{flex: 1}}>
                            {corpReg.oneStockAmt.map((v, idx) =>
                            <View style={styles.descList}>
                                <View style={[rowT.borderR, {flex: 1}, idx === 0 ? {paddingTop: 8} : {}, idx === corpReg.oneStockAmt.length-1 ? {paddingBottom: 8}:{}]}>
                                    <Text style={[isCancelStr(v.val) ? depStyles.cancelLine : {}]}>
                                        금 {formatNum({num: removeCancelStr(v.val)})}원
                                    </Text>
                                </View>
                                <View style={[styles.desc2, {flex: 1}]}>
                                    <Text style={styles.desc}>{outputPrCrChangeStr(v.changeDate)}</Text>
                                    <Text style={styles.desc}>{outputPrCrRegisterStr(v.registerDate)}</Text>
                                </View>
                            </View>
                            )}
                        </View>
                    </View>}


                    {
                        corpReg.stock && corpReg.stock.length > 0 && <View style={rowT.flexLine}>
                            <View style={[rowT.titleCover, depStyles.titleCover]}>
                                <Text style={rowT.title}>자본금의 액</Text>
                            </View>
                            <View style={{flex: 1}}>
                                {corpReg.stock.map((val,i) =>
                                    <View style={[styles.descList, i !== 0 ? rowT.borderT : {}]}>
                                        <View style={[rowT.borderR, {flex: 1, padding: '8px 0'}]}>
                                            <Text style={[val.exp ? depStyles.cancelLine : {}]}>금 {formatNum({num: val.capital})}원</Text>
                                        </View>
                                        <View style={[styles.desc2, {flex: 1, padding: '8px 0'}]}>
                                            <Text style={styles.desc}>{outputPrCrChangeStr(val.changeDate)}</Text>
                                            <Text style={styles.desc}>{outputPrCrRegisterStr(val.registerDate)}</Text>
                                        </View>
                                    </View>)}
                            </View>
                        </View>
                    }


                    {
                        corpReg.stock && corpReg.stock.length > 0 && corpReg.stock.every((stock) => (stock.cntIssuedStock) || (stock.stock && stock.stock.length > 0)) && <View style={rowT.flexLine}>
                        <View style={[rowT.titleCover, depStyles.titleCover]}>
                            <View style={styles.twoLineTitle}>
                                <Text style={[rowT.title, {paddingBottom: 7}]}>발행주식의 총수와</Text><Text style={rowT.title}>그 종류 및 각각의 수</Text>
                            </View>
                        </View>
                        <View style={{flex: 1}}>
                            {corpReg.stock && corpReg.stock.length > 0 &&
                                corpReg.stock.filter((stock) => (stock.cntIssuedStock) || (stock.stock && stock.stock.length > 0))
                                    .map((val, idx) => <>
                                    <View style={[styles.descList, idx !== 0 ? rowT.borderT : {}]}>
                                        <View style={[rowT.descCover, rowT.borderR, idx !== 0 ? rowT.borderT : {}]}>
                                            {val.cntIssuedStock &&
                                                <View style={[styles.desc, styles.stockList]}>
                                                    <Text>발행주식의 총수</Text>
                                                    <Text style={val.exp ? depStyles.cancelLine : {}}>{formatNum({num: val.cntIssuedStock})}주</Text>
                                                </View>
                                            }

                                            {val.stock && val.stock.map((s, idx2) =>
                                                <View style={[styles.desc, styles.stockList]}>
                                                    <Text style={[{textIndent: 12}, val.exp ? depStyles.cancelLine : {}]}>{s.type}</Text>
                                                    <Text style={val.exp ? depStyles.cancelLine : {}}>{formatNum({num: s.val})}주</Text>
                                                </View>
                                            )}
                                        </View>
                                        <View style={[styles.desc2, {flex: 1, padding: '8px 0'}]}>
                                        <Text style={styles.desc}>{outputPrCrChangeStr(val.changeDate)}</Text>
                                        <Text style={styles.desc}>{outputPrCrRegisterStr(val.registerDate)}</Text>
                                        </View>
                                    </View>
                                    </>
                            )}
                        </View>

                    </View>
                    }

                    {
                        corpReg.cntStockIssue && corpReg.cntStockIssue.length > 0 && <View style={rowT.flexLine}>
                            <View style={[rowT.titleCover, depStyles.titleCover]}>
                                <Text style={rowT.title}>발행할 주식의 총수</Text>
                            </View>
                            <View style={{flex: 1}}>
                                {corpReg.cntStockIssue && corpReg.cntStockIssue.map((v, idx) =>
                                    <View style={[styles.descList, idx !== 0 ? rowT.borderT : {}]}>
                                        <View style={[rowT.borderR, {flex: 1, padding: '8px 0'}]}>
                                            <Text style={[isCancelStr(v.val) ? depStyles.cancelLine : {}]}>{formatNum({num: removeCancelStr(v.val)})}주</Text>
                                        </View>
                                        <View style={[styles.desc2, {flex: 1, padding: '8px 0'}]}>
                                            <Text style={styles.desc}>{outputPrCrChangeStr(v.changeDate)}</Text>
                                            <Text style={styles.desc}>{outputPrCrRegisterStr(v.registerDate)}</Text>
                                        </View>
                                    </View>
                                )}
                            </View>
                        </View>
                    }


                    {corpReg.corpEstablishDate &&
                        <View style={rowT.flexLine}>
                            <View style={[rowT.titleCover, depStyles.titleCover]}><Text
                                style={rowT.title}>회사성립연월일</Text></View>
                            <View style={[rowT.descCover, rowT.borderR]}>
                                {corpReg.corpEstablishDate.map((v, idx) =>
                                    <Text key={idx} style={[styles.desc, isCancelStr(v) ? depStyles.cancelLine : {}]}>{ymdToDateString(removeCancelStr(v), 'yyyy년 MM월 dd일')}</Text>)
                                }
                            </View>
                            <View style={[rowT.descCover, styles.desc2]}><Text style={styles.desc}></Text></View>
                        </View>
                    }
                    <View style={rowT.flexLine}>
                        <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>관할
                            등기소</Text></View>
                        <View style={[rowT.descCover, rowT.borderR]}><Text
                            style={styles.desc}>{corpReg.commCompetentRegistryOffice}</Text></View>
                        <View style={[rowT.descCover, styles.desc2]}><Text style={styles.desc}></Text></View>
                    </View>
                </View>

                <View style={[rowT.flexWrap, styles.flexWrap]}>
                    <OneInfos title='목적' val={corpReg.purpose} rowT={rowT}/>
                    <MultiStrInfos title={summary.CS.regHis} multiVal={corpReg.registrationHis} rowT={rowT}/>
                </View>
            </View>
            <OthersInfoAll corpReg={corpReg}/>
        </>
    );
};

const OthersInfoAll = ({corpReg}: { corpReg: PrCorpRegAll }) => {
    const {View, Text, isPdf} = usePdfComponents();
    const rowT = getRowT(isPdf);
    const num = getMultiplyValue(isPdf);
    const depStyles = useCorporateRegStyle();

    const styles = StyleSheet.create({
        flexWrap: {
            paddingBottom: 24 * num
        },
        desc: {
            padding: `0 ${30 * num}px 0 ${26 * num}px`,
            lineHeight: 1.7 * num
        },
        othersTitle: {
            fontSize: 13 * num,
            fontWeight: 700
        },
        haveTwoCellTitle: {
            flex: 1,
            height: 'auto'
        },
        cellPad: {
            padding: `${8 * num}px 0`
        },
        flexBox: {
            display: 'flex',
            flexDirection: 'row'
        },
    });

    return (
        <View style={[rowT.flexWrap, styles.flexWrap]}>
            {corpReg.etc.length > 0 &&
                <View style={rowT.flexLine}>
                    <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>기타사항</Text></View>
                    <View style={styles.haveTwoCellTitle}>
                        {
                            corpReg.etc.map((etc, idx) =>
                                <View key={idx}
                                      style={(idx === corpReg.etc.length - 1) ? [styles.cellPad] : [rowT.borderB, styles.cellPad]}>
                                    <Text key={idx} style={[styles.desc, styles.othersTitle, isCancelStr(etc.title) ? depStyles.cancelLine : {}]}>{removeCancelStr(etc.title)}</Text>
                                    {
                                        etc.contents.map((etcOne, idx2) => <RegisterDateInfo key={idx2} s={etcOne}
                                                                                             rowT={rowT}/>)
                                    }
                                </View>)
                        }
                    </View>
                </View>
            }
            <OneInfos title='지점/분사무소' val={corpReg.branch} rowT={rowT}/>
            <OneInfos title='합작참여자' val={corpReg.joinPartner} rowT={rowT}/>
            <OneInfos title='지배인/대리인' val={corpReg.manager} rowT={rowT}/>
            <MultiInfos title='전환사채' multiVal={corpReg.convertibleBond} rowT={rowT}/>
            <MultiInfos title='신주인수권부사채' multiVal={corpReg.warrantBond} rowT={rowT}/>
            <MultiInfos title='이익참가부사채' multiVal={corpReg.participatingBond} rowT={rowT}/>
            <OneInfos title='주식매수선택권' val={corpReg.stockOption} rowT={rowT}/>
            <MultiInfos title='종류주식의 내용' multiVal={corpReg.typeStockContent} rowT={rowT}/>
            <MultiInfos title='전환형조건부자본증권' multiVal={corpReg.capitalStock} rowT={rowT}/>
        </View>
    );
};

export default CorporateRegiCopyAll;