import {StyleSheet} from '@react-pdf/renderer';
import {usePdfComponents} from '../pdf-hooks';
import colors from '../../../../scss/themes/colors.module.scss';
import EditReportInfoBtn, { EditReportInfoBtnProps } from './components/edit-report-info-btn';
import BookmarkBtn from './components/bookmark-btn';

interface ReportRightProps {
    children: React.ReactNode;
    reportKey: string;
    title: string;
    subTitle?: string;
    subTitleVer2?: string;
    state?: EditReportInfoBtnProps;
}

const ReportRight = ({children, reportKey, title, subTitle, subTitleVer2, state}: ReportRightProps) => {
    const {View, Text, isPdf} = usePdfComponents();

    const styles = StyleSheet.create({
        right: {
            maxWidth: 982,
            height: '100%',
            flex: 0.82,
            borderTop: `1px solid ${colors.main}`
        },
        reportTopLine: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            padding: '6px 0 48px',
        },
        title: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-end',
            flexWrap: 'wrap',
            fontSize: 25,
            fontWeight: 700,
        },
        subTitle: {
            marginLeft: 5,
            marginBottom: 3,
            fontSize: 20
        },
        subTitleVer2: {
            marginLeft: 5,
            fontSize: 25,
            fontWeight: 500
        }
    });

    return (
        <View style={styles.right}>
            <View style={styles.reportTopLine}>
                <Text style={styles.title}>{title}{subTitle &&<Text style={styles.subTitle}>{subTitle}</Text>}{subTitleVer2 && <Text style={styles.subTitleVer2}>{isPdf && '∙'}{subTitleVer2}</Text>}</Text>
                {
                    !isPdf &&
                    <View>
                        {state && <EditReportInfoBtn state={state} ver='ver1'/>}
                        <BookmarkBtn reportId={reportKey} />
                    </View>
                }
            </View>
            {children}
        </View>
    );
};

export default ReportRight;