import {FsCodeDto} from "../../../model/customer/dto/fs-code-dto";
import {PrFrStatCont} from "../../../model/customer/dto/financial-statement";
import {mergeStyle, usePdfComponents} from "../pdf-hooks";
import {StyleSheet} from "@react-pdf/renderer";
import {Style} from "@react-pdf/types";
import {getColT2, getColT2_1} from "../../../styles/styleSheet";
import TableTitle from "../../../components/table-title";
import {formatNum2, ymdToDateString} from "../../../util/str-util";
import React from "react";

const ymd = 'yyyy-MM-dd';

export const FsTableV2 = ({
                              title,
                              data,
                              fsTbl,
                              isBS
                          }: { title: string, fsTbl: FsCodeDto[], data: PrFrStatCont[], isBS: boolean }) => {
    const {View, Text, isPdf} = usePdfComponents();
    const hasData = data && data.length > 0;

    const styles = StyleSheet.create({
        top: {
            paddingTop: isPdf ? 5 : 0
        }
    });

    const labelStyle: { [key: string]: Style } = {};
    const valueStyle: { [key: string]: Style } = {};

    const colT0 = isBS ? getColT2(isPdf) : getColT2_1(isPdf);
    const colT2 = getColT2(isPdf);
    let viewStByDep: Style = StyleSheet.create({});
    let textStByDep: Style = StyleSheet.create({});

    for (let i = 1; i <= 5; i++) {
        if (i === 1) {
            viewStByDep = colT0.dep1;
            textStByDep = colT0.dep1Txt;
        } else if (i === 2) {
            viewStByDep = colT0.dep2;
            textStByDep = colT0.dep2Txt;
        } else if (i === 3) {
            viewStByDep = colT0.dep3;
            textStByDep = colT0.dep3Txt;
        } else if (i === 4) {
            viewStByDep = colT0.dep4;
            textStByDep = colT0.dep4Txt;
        } else {
            viewStByDep = colT0.dep5;
            textStByDep = colT0.dep5Txt;
        }
        labelStyle[i.toString()] = mergeStyle([colT2.flexBody, colT2.body1st, colT2.col1st, viewStByDep, textStByDep, styles.top]);
        valueStyle[i.toString()] = mergeStyle([colT2.flexBody, colT2.txtPadRight, viewStByDep, styles.top]);
    }

    return <TableTitle title={isPdf ? title : undefined} dateOrUnit='(단위: 천원)' gridStyle={colT2.table}>
        <View style={[colT2.flexWrap]}>
            <View style={[colT2.flexLine]}>
                <Text style={[colT2.flexHead, colT2.head1st, colT2.col1st, styles.top]}>계정명</Text>
                <Text
                    style={[colT2.flexHead, colT2.txtPadRight, styles.top]}>{ymdToDateString(data[2].stDate, ymd)}</Text>
                <Text
                    style={[colT2.flexHead, colT2.txtPadRight, styles.top]}>{ymdToDateString(data[1].stDate, ymd)}</Text>
                <Text
                    style={[colT2.flexHead, colT2.txtPadRight, styles.top]}>{ymdToDateString(data[0].stDate, ymd)}</Text>
            </View>
            {hasData ?
                fsTbl.map((it, index) => {
                    const depth = it.kdDepth;

                    if (it.show === 'DEF' && (!data[0].data[it.coNod] && !data[1].data[it.coNod] && !data[2].data[it.coNod])) {
                        return null;
                    }

                    return <View key={index} style={[colT2.flexLine]}>
                        <Text style={labelStyle[depth]}>{it.name}</Text>
                        <Text style={valueStyle[depth]}>{formatNum2({num: data[2].data[it.coNod]})}</Text>
                        <Text style={valueStyle[depth]}>{formatNum2({num: data[1].data[it.coNod]})}</Text>
                        <Text style={valueStyle[depth]}>{formatNum2({num: data[0].data[it.coNod]})}</Text>
                    </View>
                }) :
                <View style={colT2.flexLine}>
                    <Text style={[colT2.flexBody, colT2.head1st, styles.top]}>조회내역이 없습니다.</Text>
                </View>
            }
        </View>
    </TableTitle>
}