export const mngTotalSummaryText: { [key: string]: { [k: string]: string } } = {
    "ANALYSIS": {
        "title": "재무 건전성",
        "titleUnderline": "개선",
        "img": "result_icon_mag",
        "A_C": "최근 {}년간 순이익률 및 ROE가 <b>감소추세</b>에 있습니다.",
        "A1_C": "최근 3년간 순이익률 및 ROE <b>변동성이 확대</b>되었습니다.",
        "A2": "최근 {}년간 순이익률 및 ROE가 증가추세에 있습니다.",
        "A3": "최근 {}년간 순이익률 및 ROE가 유지되고 있습니다.",
        "B_C": "{}순이익률, 영업이익률, ROE 등을 분석했을 때 <b>전반적인 미래 성장을 위한 수익성 개선을 위한 전략 검토가 필요</b>해 보입니다.",
        "B1": "{}순이익률, 영업이익률, ROE 등을 분석했을 때 전반적으로 높은 수익성을 기반으로 기업의 미래 성장이 기대됩니다.",
        "B2": "{}순이익률, 영업이익률, ROE 등을 분석했을 때 빠르진 않지만 비교적 꾸준한 성장을 기대해 볼 수 있습니다.",
        "C_C": "동시에 부족한 현금성 자산, 높은 부채 부담률이라는 측면에서 <b>기업의 유동성 리스크 관리와 차입금상환 계획 등이 필요</b>해 보입니다.",
        "C1": "{}풍부한 유동성과 낮은 부채 부담률이라는 측면에서 매우 안정적인 재무 건전성을 갖추고 있는 것으로 진단됩니다.",
        "C2": "{}유동성과 부채 부담률이라는 측면에서 비교적 안정적인 재무 건전성을 갖추고 있는 것으로 진단됩니다.",
    },
    "EVALUATION": {
        "title": "기업가치평가",
        "img": "result_icon_graph",
        "A_C": "<b>매년 기업가치 평가를 통해 배당, 주식 이동(증여,양도), 차명주식회수, 상속 플랜 등을 실행할 때 예상되는 세금의 증가 속도를 정기적으로 확인</b>하는 것이 좋습니다. 특히 성장하는 기업이라면 경영기간 3년 초과 시 주식가치가 최소 40% 이상 상승할 수 있기 때문에 순자산가치로만 주식평가가 가능한 3년 미만 시점에 가족 간에 지분설계를 적극적으로 검토하는 것이 유리합니다.",
        "B_C": "<b>매년 기업가치 평가를 통해 배당, 주식 이동(증여,양도), 차명주식회수, 상속 플랜 등을 실행할 때 예상되는 세금의 증가 속도를 정기적으로 확인</b>하는 것이 좋습니다. 아울러 매년 적정규모의 비용처리, 정기적인 배당 등을 활용하여 기업가치 증가 속도를 늦추는 방안을 검토해 볼 수 있습니다.",
        "C_C": "<b>미래 잠재 성장성은 높으나 아직은 흑자구조로 전환하지 못한 스타트업의 경우</b> 상증세법상 보충적 평가방법 대신 1)현금흐름법, 2)시장비교기업의 멀티플을 적용하는 방식 등을 활용할 수 있습니다. 다만 시가로 적용해야 하는 주식거래 등이 있을 때, 현실적인 회계상의 기업가치를 파악하고자 할 때는 보충적평가방법에 의한 주식가치평가 내용을 참고할 수 있습니다."
    },
    "ALLOCATION": {
        "title": "배당정책 검토",
        "img": "result_icon_money",
        "A_C": "<b>지난 {}년간 배당한 이력이 없습니다.</b> 현재 기업의 규모, 향후 성장 가능성을 고려했을 때 소득분산, 자금출처, 개인차원 소득세 절감 차원에서 배당정책 수립이 필요합니다. 아울러 정관에 중간배당, 현물 배당 등 배당 관련 규정을 정비하고, 매년 미처분이익잉여금 규모를 파악해 적정수준으로 관리하는 전략을 함께 검토하는 것이 좋습니다.",
        "B": "해당 기업은 이미 배당을 실행하고 있지만 <b>좀 더 효율적인 배당정책을 수립하기 위해서 가족 간에 적절한 범위내에서 지분 분산이 잘 되어 있는지 재검토</b>할 필요가 있습니다. 아울러 정관에 중간배당, 현물 배당 등 배당 관련 규정을 정비하고, 매년 미처분이익잉여금 규모를 파악해 적정수준으로 관리하는 전략을 함께 검토하는 것이 좋습니다."
    },
    "SALARY": {
        "title": "급여 및 퇴직금",
        "titleUnderline": "플랜 수립",
        "img": "result_icon_person",
        "A_C": "<b>임원퇴직금지급규정 재검토(또는 신규 제정)가 필요합니다.</b> 기존에 퇴직금 규정이 있다 하더라도 대표이사가 회사에 기여한 만큼 충분한 퇴직금을 보상받도록 하기 위해서는 희망 퇴직금 규모에 맞는 급여 설계와 규정 정비가 수반되어야 합니다. 또한 매년 퇴직 시점 예상 퇴직금 규모와 세금을 계산해 보고, 퇴직금 재원을 마련할 수 있는 구체적인 방안을 함께 수립해야 합니다."
    },
    "RISK": {
        "title": "CEO 유고 시",
        "titleUnderline": "유동성 리스크",
        "img": "result_icon_chair",
        "A_C": "<b>최근 결산기 말 기준 법인의 비상운영자금이 부족합니다.</b> 갑작스런 CEO 유고 시 기업이 직면할 유동성 리스크에 대비해 부채상환자금 및 필수운영자금을 감당할 수 있는 비상 현금성 자산을 별도로 준비하고 있는지, 이를 확보 할 수 있는 구체적인 대비책을 가지고 있는지, 면밀한 검토가 필요합니다.",
        "B": "<b>최근 결산기 말 기준 법인의 비상운영자금이 충분히 준비되어 있습니다.</b> 비상운영자금은 갑작스런 CEO 유고 시 기업이 직면할 유동성 리스크를 대비해 부채상환자금 및 필수운영자금을 감당할 수 있는 비상 현금성 자산을 의미합니다. 비상운영자금 이외에도 상속세 재원, 회사 안정화 자금 목적의 여유 현금성 자산을 추가로 확보할 수 있는 대비책을 준비하는 것도 좋습니다."
    },
    "BORROWED_STOCK": {
        "title": "차명주식 해결",
        "img": "result_icon_people",
        "A_C": "<b>1996년 9월 이전 설립 법인의 경우 해당 시점 상법상 발기인 요건 때문에 부득이하게 차명주주를 두게 되는 경우</b>가 많습니다. 해당 기업의 경우 차명주주 확인 및 해결방안을 가지고 있는지 확인이 필요해 보입니다. 차명주식은 소유권 분쟁 위험뿐만 아니라 자본거래(배당, 증자)의 제약, 가업승계 시 장애요소라는 점에서 미루지 말고 최대한 빨리 해결방법을 찾아야 합니다.",
        "B_C": "<b>2001년 7월 23일 이전 설립된 법인의 경우 해당 시점 상법상 발기인 요건 때문에 부득이하게 차명주주를 두게 되는 경우</b>가 많습니다. 해당 기업의 경우 차명주주 확인 및 해결방안을 가지고 있는지 확인이 필요해 보입니다. 차명주식은 소유권 분쟁 위험뿐만 아니라 자본거래(배당, 증자)의 제약, 가업승계 시 장애요소라는 점에서 미루지 말고 최대한 빨리 해결방법을 찾아야 합니다.",
        "C": "상법상 발기인 1명 이상이면 법인설립이 가능해진 2002년 이후에 설립된 법인이라 하더라도 잘못된 정보, 과점주주를 피하기 위해서 등의 이유로 여전히 차명주주를 두는 경우가 의외로 많습니다. 현재 해당 법인에 차명주주가 있는지 추가적으로 확인해 볼 필요는 있습니다."
    },
    "PROVISIONAL_PAYMENT": {
        "title": "가지급금 이슈",
        "img": "result_icon_issue",
        "A_C": "<b>최근 결산기 말 재무상태표 가지급금 잔액 {}이 확인</b>됩니다. 연도 중 정확한 가지급금 규모와 이자 부담액은 세무조정계산서상 가지급금인정이자 명세서를 확인해야 알 수 있으나, 일반적으로 가지급금은 오랜 기간에 걸쳐 누적된 결국 대표이사가 갚아야 할 빚이기 때문에, 다각도의 상환방법을 면밀하게 검토할 필요가 있습니다.",
        "B": "최근 결산기 말 재무상태표 가지급금 잔액은 크게 문제가 되지 않는 수준입니다. 다만 연도 중 정확한 가지급금 규모와 이자부담액을  파악하고자 한다면 세무조정계산서상 가지급금인정이자 명세서를 확인해 볼 수 있습니다."
    },
    "INHERITANCE": {
        "title": "상속 및",
        "titleUnderline": "가업승계 검토",
        "img": "result_icon_leave",
        "A_C": "<b>대표이사 나이, 경영기간, 기업가치 등을 고려했을 때 상속, 가업승계 또는 Exit에 대한 고민이 필요한 시점</b>입니다. 기업가치 상승에 따른 예상 상속세를 정기적으로 계산해 보고, 적은 부담으로 상속세 재원을 마련할 수 있는 대비책을 준비할 필요가 있습니다. 또한 해당 기업은 가업승계주식증여 특례, 가업상속공제, 창업자금증여세 과세특례를 활용해 볼 수 있습니다.",
        "B_C": "아직 경영기간이 10년 미만이기 때문에 가업승계나 Exit을 생각할 단계가 아니지만, <b>대표이사의 나이 등을 고려했을 때 사전 계획을 수립해 두는 것이 바람직</b>합니다. 대다수 중소기업 CEO의 예상상속재산의 상당한 부분이 보유지분 가치입니다. 기업가치 상승에 따른 예상 상속세를 정기적으로 계산해 보고, 사전에 적은 부담으로 상속세 재원을 마련할 수 있는 대비책을 준비할 필요가 있습니다.",
        "C": "대다수 중소기업 CEO의 예상상속재산의 상당한 부분이 보유지분 가치입니다. 기업가치 상승에 따른 예상 상속세를 정기적으로 계산해 보고, 사전에 적은 부담으로 상속세 재원을 마련할 수 있는 대비책을 준비하는 것이 좋습니다."
    },
    "CORP_SYSTEM": {
        "title": "기업제도",
        "titleUnderline": "및 인증",
        "img": "result_icon_docu",
        "A_C": "<b>상법내용에 맞는 정관을 잘 갖추고 있는지 재검토</b>하고, 필요 시 정관변경 작업을 진행할 수 있습니다. 아울러 임원퇴직금 및 임원보수규정, 임원유족보상금규정, 복리후생규정도 함께 정비합니다.",
        "B1_C": "해당 기업의 경영기간은 3년 미만으로 <b>기업연구소설립과 함께 벤처기업인증을 진행할 필요</b>가 있습니다. 설립 후 3년 이내 벤처인증을 받아야 5년간 50%의 세감면 혜택을 받을 수 있습니다.",
        "B2_C": "해당 기업의 경영기간은 3년 미만으로 <b>벤처기업인증을 진행할 필요</b>가 있습니다. 설립 후 3년 이내 벤처인증을 받아야 5년간 50%의 세감면 혜택을 받을 수 있습니다.",
        "B3_C": "경영기간 3년 이상 법인이라면 정부시책, 정책자금, 정책참여 등의 다양한 지원 혜택을 누릴 수 있는 <b>메인비즈, 이노비즈 인증을 검토</b>해 볼 수 있습니다. 다만, 그에 앞서 <b>연구소 설립과 특허권 보유는 필수적으로 선행</b>돼야 할 과제입니다.",
        "B4_C": "경영기간 3년 이상 법인이라면 정부시책, 정책자금, 정책참여 등의 다양한 지원 혜택을 누릴 수 있는 <b>메인비즈, 이노비즈 인증을 검토</b>해 볼 수 있습니다. 다만, 그에 앞서 <b>연구소 설립은 필수적으로 선행</b>되야 할 과제입니다.",
        "B5_C": "경영기간 3년 이상 법인이라면 정부시책, 정책자금, 정책참여 등의 다양한 지원 혜택을 누릴 수 있는 <b>메인비즈, 이노비즈 인증을 검토</b>해 볼 수 있습니다. 아울러 <b>기업연구소 사후관리</b>에도 주의를 기울여야 합니다.",
        "B6_C": "경영기간 3년 이상 법인이라면 정부시책, 정책자금, 정책참여 등의 다양한 지원 혜택을 누릴 수 있는 <b>메인비즈, 이노비즈 인증을 검토</b>해 볼 수 있습니다. 다만, 그에 앞서 <b>특허권 보유는 필수적으로 선행</b>돼야 할 과제입니다. 아울러 <b>기업연구소 사후관리</b>에도 주의를 기울여야 합니다.",
        "B7_C": "경영기간 3년 이상 법인이라면 추가적으로 정부시책, 정책자금, 정책참여 등의 다양한 지원 혜택을 누릴 수 있는 <b>메인비즈, 이노비즈 인증을 검토</b>해 볼 수 있습니다.",
        "B8_C": "해당 기업은 메인비즈인증을 보유하고 있습니다. <b>추가적으로 이노비즈인증을 검토</b>해 볼 수 있습니다. 이노비즈인증을 받으면 기업 신인도 상승은 물론 정부시책, 정책자금, 정책참여 등 지원 혜택을 누릴 수 있습니다. 아울러 <b>기업연구소 사후관리</b>에도 주의를 기울여야 합니다.",
        "B9_C": "해당 기업은 <b>메인비즈인증, 이노비즈인증 등 각종 인증과 특허를 잘 준비</b>하고 있습니다. 해당 인증을 통해 누릴 수 있는 각종 지원혜택과 정부정책자금 등에 대한 적극적인 활용 검토가 필요합니다. 아울러 <b>기업연구소 사후관리</b>에도 주의를 기울여야 합니다.",
        "B10_C": "해당 기업은 이미 가장 높은 단계인 <b>이노비즈 인증을 보유</b>하고 있습니다. 해당 인증을 통해 누릴 수 있는 정부시책, 정책자금, 정책참여 등에 대한 적극적인 활용 검토가 필요합니다. 아울러 <b>기업연구소 사후관리</b>에도 주의를 기울여야 합니다.",
        "B11_C": "또한 <b>기업연구소 또는 연구전담부서를 설립</b>하면 세제혜택은 물론 향후 다양한 정부지원사업에서 가점을 받을 수 있습니다.",
        "B12_C": "또한 해당 기업은 <b>기업연구소 사후관리가 필요</b>합니다. 많은 기업들이 세액공제를 받기 위해 연구소를 설립하지만 사후관리 미흡으로 세액공제를 부인 당하는 사례가 많습니다.",
        "C_C": "아울러 <b>해당 사업과 관련된 특허권을 보유</b>하면 연구소설립, 각종 인증, 정부지원사업 등에서 훨씬 유리해집니다.",
    },
    "LABOR": {
        "title": "인사노무검토",
        "img": "result_icon_mark",
        "A_C": "상시근로자 5인 미만 기업의 경우 연장·야간·휴일근로 등에 대한 가산수당이나 연차휴가, 공휴일 유급휴일 등의 의무규정이 적용되진 않지만, <b>임금대장 작성, 근로계약서 및 급여명세서 작성 및 배포 등 모든 사업장에 적용되는 기본 규정은 엄격하게 준수</b>해야 합니다. 해당 사항에 미비한 점은 없는지 정기적인 점검이 필요합니다.",
        "B_C": "<b>5인 이상 사업장부터는 연장·야간·휴일근로 등에 대한 가산수당이나 연차휴가, 공휴일 유급휴일 등의 의무규정이 적용</b>됩니다. 당연히 임금대장 작성, 근로계약서 및 급여명세서 작성 및 배포 등 모든 사업장에 적용되는 기본 규정은 엄격하게 준수해야 합니다. 해당 사항에 미비한 점은 없는지 정기적인 점검이 필요합니다.",
        "C_C": "<b>상시근로자 수 10인 이상부터는 취업 규칙을 작성하여 노동청에 신고</b>할 의무가 있습니다. 이외 연장·야간·휴일근로 등에 대한 가산수당이나 연차휴가, 공휴일 유급휴일 적용 등은 물론 임금대장 작성, 근로계약서 및 급여명세서 작성 및 배포 등 모든 사업장에 적용되는 기본 규정은 엄격하게 준수해야 합니다. 해당 사항에 미비한 점은 없는지 정기적인 점검이 필요합니다.",
        "D_C": "<b>상시근로자 수 30인 이상부터는 노사협의회를 만들고 해당 규정을 노동청에 신고</b>할 의무가 있습니다. 이외 연장·야간·휴일근로 등에 대한 가산수당이나 연차휴가, 공휴일 유급휴일 적용, 취업 규칙 작성 등은 물론 임금대장 작성, 근로계약서 및 급여명세서 작성 및 배포 등 모든 사업장에 적용되는 기본 규정은 엄격하게 준수해야 합니다. 해당 사항에 미비한 점은 없는지 정기적인 점검이 필요합니다."
    }
}
export const mngTotalSummaryTypeList = [
    {
        type: "ANALYSIS",
        name: "재무 건전성 개선"
    },
    {
        type: "EVALUATION",
        name: "기업가치평가"
    },
    {
        type: "ALLOCATION",
        name: "배당정책 검토"
    },
    {
        type: "SALARY",
        name: "급여 및 퇴직금 플랜 수립"
    },
    {
        type: "RISK",
        name: "CEO 유고 시 유동성 리스크"
    },
    {
        type: "BORROWED_STOCK",
        name: "차명주식 해결"
    },
    {
        type: "PROVISIONAL_PAYMENT",
        name: "가지급금 이슈"
    },
    {
        type: "INHERITANCE",
        name: "상속 및 가업승계 검토"
    },
    {
        type: "CORP_SYSTEM",
        name: "기업제도 및 인증"
    },
    {
        type: "LABOR",
        name: "인사노무검토"
    }
];