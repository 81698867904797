import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {PricePolicyInfoDto} from "./dto/price-policy-info-dto";

@injectable()
export class GoodOpenApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    getPricePolicy = async () => {
        return await this.apiCall.fetch<PricePolicyInfoDto>('/oapi/pricePolicy', null, {});
    }
}