import {Document as PdfDocument, Image, Page as PdfPage, StyleSheet, Text, View} from '@react-pdf/renderer';
import {usePdfMode} from '../pdf-hooks';
import {toDateString} from '../../../util/str-util';
import {Provider} from 'inversify-react';
import container from '../../../model/bootstrap-container';
import {PrCorpRegRoot} from "../../../model/customer/dto/pr-corp-reg-root";
import {CorporateRegiCopy} from "./corporate-regi-copy";
import {SummaryReport} from "../../../model/inquiry/dto/summary-report";
import {Customer} from "../../../model/customer/dto/customer";
import {PrCorpRegAll} from "../../../model/customer/dto/pr-corp-reg-all";

const Document: any = PdfDocument //react18 동작을 위해 추가
const Page: any = PdfPage

const styles = StyleSheet.create({
    page: {
        paddingLeft: 40,
        paddingRight: 40,
        paddingBottom: 60,
        letterSpacing: -0.7
    },
    header: {
        left: 0,
        right: 0,
        textAlign: 'center',
        marginTop: 20,
        marginBottom: 15,
        paddingBottom: 5,
        flexDirection: 'row',
        alignItems: 'flex-end',
        fontSize: 13,
        color: '#4c4c4c',
        borderBottom: '1px solid #eee'
    },
    logo: {
        width: 35,
        height: 16,
        marginRight: 16
    },
    chkDate: {
        fontSize: 13,
        color: '#808080'
    },
    footer: {
        position: 'absolute',
        bottom: 10,
        left: 0,
        right: 0,
        flexDirection: 'row',
        paddingTop: 5,
        marginLeft: 40,
        marginRight: 40,
        marginBottom: 20,
        borderTop: '1px solid #eee',
        fontSize: 13,
        color: '#4c4c4c'
    },
    refer: {
        flexGrow: 1
    },
});

export const PdfCorpRegRoot = ({corpReg, summary, customer, corpRegAll}: { corpReg?: PrCorpRegRoot, summary: SummaryReport, customer: Customer, corpRegAll?: PrCorpRegAll }) => {
    usePdfMode(true);

    return <Provider container={container}>
        <Document style={{fontFamily: 'NotoSansKR'}}>
            <Page size='A4' style={styles.page} wrap dpi={72 * 1330 / 842}>
                <View fixed style={styles.header}>
                    <Image style={styles.logo} src='/images/report_logo.png'/>
                    <Text style={{flexGrow: 1}}>법인등기부등본</Text>
                    <Text style={styles.chkDate}>조회일시 {toDateString(new Date())}</Text>
                </View>

                <CorporateRegiCopy corpReg={corpReg} summary={summary} customer={customer} corpRegAll={corpRegAll}/>

                <View fixed style={styles.footer}>
                    <Text style={styles.refer}>데이터 출처: 대법원 인터넷 사이트</Text>
                    <Text render={({pageNumber, totalPages}) => (
                        `${pageNumber} / ${totalPages}`
                    )}/>
                </View>
            </Page>
        </Document>
    </Provider>
};