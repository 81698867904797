import styles from './main-issue-list.module.scss';
import classNames from 'classnames/bind';
import {FieldArray, Form, Formik, FormikErrors} from "formik";
import {InputBox} from "./customer/edit-common";
import React, {MutableRefObject, useRef} from "react";
import {ReportAddInfoPopProps} from "./report-add-info-pop";
import {useInjection} from "inversify-react";
import {CustomerIssue} from "../model/customer/dto/customer-issue";
import {DialogManager} from "../components/dialog-manager";
import { CustomerInputApi } from '../model/customer/customer-input-api';
import { ModifyCustomerIssueForm } from '../model/customer/form/modify-customer-issue-form';
import _ from 'lodash';
import { toast } from 'react-toastify';

const cx = classNames.bind(styles);
const MainIssueList = ({data, setData, onClose}: ReportAddInfoPopProps) => {
    const customerInputApi = useInjection(CustomerInputApi);
    const dialogManager = useInjection(DialogManager);

    const ref = useRef<HTMLDivElement>(null);

    if(data.customer && data.customer.companyInfo && !data.customer.companyInfo.issueList) {
        data.customer.companyInfo.issueList = [{ issue: '', createAt: new Date() }];
    }

    const addIssue = (values: any, setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<any>>, scrollRef: MutableRefObject<HTMLDivElement|null>) => {
        const issue = { content: "", createAt: new Date() };
        setFieldValue('issueList', [...values.issueList, issue]);

        setTimeout(() => {
            if (scrollRef && scrollRef.current) {
                scrollRef.current!.scrollTo({top: scrollRef.current!.scrollHeight, behavior: 'smooth'});
            }
        }, 1)
    }

    const initialValues: any = {...data.customer?.companyInfo};

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={async (values) => {
                const form: ModifyCustomerIssueForm = {
                    issueList: values.issueList
                };

                await customerInputApi.saveMainIssue(data.customer.id, form);
                toast.success("주요이슈가 저장되었습니다.");

                const tempData = _.cloneDeep(data);
                tempData.customer.companyInfo.issueList = values.issueList;
                setData(tempData);
            }}>
            {({values, setFieldValue}) => {
                return <Form className={cx('main-issue-list')}>
                    <div className={cx('btn-area')}>
                        <button className={cx('add-btn')} type='button' onClick={() => { addIssue(values, setFieldValue, ref); }}><i></i>이슈 추가</button>
                        <button
                            className={cx('save-btn')}
                            type='submit'>
                            <i></i>저장하기
                        </button>
                        <button className='close-btn' type='button' onClick={() => onClose()}>닫기</button>
                    </div>
                    <div className={cx('scroll-box')}>
                        <div className={cx('scroll-area')}>
                            <div className={cx('title-line')}>
                                <p className={cx('title')}>
                                    사업경영 주요이슈
                                </p>
                            </div>
                            <div className={cx('info')}>※ 실제 컨설팅을 진행할 영역을 선택하고 리포트에 표시 할 수 있습니다.</div>
                            <>
                            {
                                values.issueList?.map((issue: CustomerIssue, idx: number) => {
                                    return (
                                        <div className={cx('issue-box')}>
                                            <FieldArray name="issueList" render={arrayHelpers => (
                                                <div>
                                                    <InputBox title={`이슈${idx + 1}`} name={`issueList.${idx}.issue`} placeholder='입력 (입력 예시: 성실신고사업자 대상 예상, 법인전환 검토)' inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                                    {
                                                        idx !== 0 ?
                                                            <button
                                                                className={cx('delete-btn')}
                                                                type='button'
                                                                onClick={() => dialogManager.confirm('해당 이슈를 삭제하시겠습니까?', '', () => {
                                                                    arrayHelpers.remove(idx);
                                                                })}>
                                                            </button>
                                                            : null
                                                    }
                                                </div>
                                            )} />
                                        </div>
                                    )
                                })
                            }
                            </>
                        </div>
                    </div>

                </Form>;
            }
            }
        </Formik>
    );
};

export default MainIssueList;