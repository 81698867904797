export type REPORT_TITLE_TYPE = 'totalIssStkCn'|'stkFvalAm'|'stkCn'|'stkFvalAm3'|'totalIssStkCn2';
export function outputReportTitle (type: REPORT_TITLE_TYPE, enpFcd: string) {
    // 좌 모드
    const modeStock2 = enpFcd === "04" || enpFcd === "07";
    switch (type) {
        case "totalIssStkCn":
            return modeStock2 ? "출자 좌수 합계" : "발행주식 합계";
        case "stkFvalAm":
            return modeStock2 ? "출자 1좌의 금액" : "1주의 금액";
        case "stkCn":
            return modeStock2 ? "출자 좌수" : "주식수";
        case "totalIssStkCn2":
            return modeStock2 ? "출자 좌수" : "주식수";
        case "stkFvalAm3":
            return modeStock2 ? "출자 1좌의 금액" : "1주의 금액";

    }
}
