import React, {ForwardedRef, forwardRef, MutableRefObject, useEffect, useRef, useState} from 'react';
import * as Yup from 'yup';
import classNames from 'classnames/bind';
import styles from './edit-info.module.scss';
import {EMPTY, ymdToDateString} from '../../util/str-util';
import {FieldArray, Form, Formik, FormikValues, useField, useFormikContext} from 'formik';
import ReactTextareaAutosize from 'react-textarea-autosize';
import 'react-datepicker/dist/react-datepicker.css';
import {FamInfo, RepInfo} from '../../model/customer/dto/rep-info';
import {asyncCall} from '../../util/util';
import {toast} from 'react-toastify';
import {useMountedState} from 'react-use';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../../model/customer/customer-api';
import {CustomerDetail} from '../../model/customer/dto/customer-detail';
import {ClosableProps} from '../../components/dialog';
import {strSaved} from '../../data/strings';
import {DateInputBox, InputBox, SelectInputBox} from './edit-common';
import {PersonInfo} from '../../model/inquiry/dto/person-info';
import {DialogManager} from '../../components/dialog-manager';

const cx = classNames.bind(styles);

interface EditCeoInfoProps extends ClosableProps {
    customerId: string;
    personInfo: RepInfo;
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>
    orgInfo?: PersonInfo;
    hasTwoDialog?: boolean;
    cusDetailPopClose?: VoidFunction
}

const FamBox = ({index, remove}: { index: number, remove: (index: number) => void }) => {
    const {getFieldProps} = useFormikContext<FormikValues>();
    const dialogManager = useInjection(DialogManager);

    const famList = ['배우자', '성년자녀', '미성년자녀', '성년손주', '미성년손주', '부모', '기타친족'];

    return (
        <div className={cx('fam-box')}>
            <div className={cx('line')}>
                <SelectInputBox name={`fam.${index}.relation`} selectList={famList} inputWidth='12.8rem'
                                selectBoxCn={[cx('fam-select-box')]} placeholder="관계"/>
                <InputBox name={`fam.${index}.name`} placeholder='이름 입력' inputWidth='12.8rem'/>
                <DateInputBox name={`fam.${index}.birthday`} placeholder='생년월일 8자리 입력 (YYYYMMDD)' inputWidth='23.2rem'/>
                {/*<InputBox inputboxCn={cx('date-box2')} inputWidth='23.2rem' placeholder='생년월일 8자리 입력' value='생년월일'>*/}
                {/*    <DatePicker*/}
                {/*        cn={cx('cal-btn')}*/}
                {/*        name={`fam.${index}.birthday`}*/}
                {/*        placeholderText='생년월일 8자리 입력'*/}
                {/*        allowNull={false}/>*/}
                {/*</InputBox>*/}
            </div>
            <div className={cx('line')}>
                <div className={cx('input-box')}>
                    <ReactTextareaAutosize
                        placeholder='특이사항을 입력해 주세요.(예시: 최근 10년 내 **원 증여)' {...getFieldProps(`fam.${index}.note`)} />
                </div>
            </div>
            <button
                className={cx('delete-btn')}
                type='button'
                onClick={() => dialogManager.confirm('해당 가족 정보를 삭제하시겠습니까?', '', () => {
                    remove(index);
                })}></button>
        </div>
    );
}

const AddFamilyBtn = ({scrollRef}: {scrollRef: MutableRefObject<HTMLDivElement|null>}) => {
    const {values, setFieldValue} = useFormikContext<FormikValues>();

    const addFamily = () => {
        const tempFam: FamInfo = {
            relation: undefined,
            name: '',
            birthday: '',
            note: ''
        }

        setFieldValue("fam", [...values.fam, tempFam])

        setTimeout(() => {
            if (scrollRef && scrollRef.current) {
                scrollRef.current!.scrollTo({top: scrollRef.current!.scrollHeight, behavior: 'smooth'});
            }

        }, 1)
    }

    return <button
        type='button'
        className={cx('add-btn')}
        onClick={() => addFamily()}
    ><i></i>가족 추가</button>
}

const EditCeoInfo = ({
                         customerId,
                         personInfo,
                         setData,
                         orgInfo,
                         onClose,
                         hasTwoDialog,
                         cusDetailPopClose
                     }: EditCeoInfoProps) => {
    const isMounted = useMountedState();
    const customerApi = useInjection(CustomerApi);
    // const [female, setFemale] = useState<boolean | undefined>(personInfo.female);
    const dialogManager = useInjection(DialogManager);
    const [requiredField, setRequiredField] = useState(true);
    const scrollRef = useRef<HTMLDivElement>(null);

    const pi = personInfo as any;
    for (let key in pi) {
        if (pi[key] == null) pi[key] = '';
    }

    /* const updateFemale = (id: string) => {
        switch (id) {
            case '0':
                // setFemale(undefined);
                break;
            case '1':
                // setFemale(false);
                setRequiredField(true);
                break;
            case '2':
                // setFemale(true);
                setRequiredField(true);
                break;
        }
    }; */

    return (
        <div className={cx('edit-info')}>
            <Formik
                initialValues={{...personInfo, fam: (personInfo.fam && personInfo.fam.length > 0) ? personInfo.fam : [{
                        relation: undefined,
                        name: '',
                        birthday: '',
                        note: '',
                    }],
                    gender: personInfo.female == null ? null : personInfo.female ? "여자" : "남자",
                }}
                validationSchema={Yup.object({
                    birthday: Yup.string().min(8, '8자로 입력해 주세요.').matches(/^[0-9]+$/, '숫자만 입력해 주세요.'),
                    realBirth: Yup.string().min(8, '8자로 입력해 주세요.').matches(/^[0-9]+$/, '숫자만 입력해 주세요.'),
                    phone: Yup.string().min(11, '휴대폰 번호를 확인해 주세요.').matches(/^[0-9]+$/, '숫자만 입력해 주세요.'),
                    fam: Yup.array().of(
                        Yup.object().shape({
                            birthday: Yup.string()
                                .min(8, "8자로 입력해 주세요.")
                                .matches(/^[0-9]+$/, "숫자만 입력해 주세요."),
                        })
                    ),
                })}
                onSubmit={value => {
                    const newValue: RepInfo = {...value} as RepInfo;
                    // newValue.female = value.gender;
                    newValue.female = value.gender === "여자" ? true : false;

                    if (newValue.female === null) {
                        dialogManager.alert('필수값을 입력해 주세요.');
                        setRequiredField(false);
                        return;
                    }

                    // 기본값에서 더이상 수정안했을 떄 저장 안되게 하기 위한 처리
                    if (newValue.fam && newValue.fam.length === 1 && !newValue.fam[0].name) {
                        newValue.fam = [];
                    }

                    if (newValue.fam && newValue.fam.length > 0) {
                        for (let i = 0; i < value.fam.length; i++) {
                            let famInfo = value.fam[i];

                            if (!famInfo.relation) {
                                dialogManager.alert('가족의 관계를 선택해주세요.');
                                return;
                            } else if (!famInfo.name) {
                                dialogManager.alert('가족의 이름을 입력해주세요.');
                                return;
                            } 
                            // else if (!famInfo.birthday) {
                            //     dialogManager.alert('가족의 생년월일을 입력해주세요.');
                            //     return;
                            // }
                            else {
                                try {
                                    const birthday = new Date(ymdToDateString(famInfo.birthday));
                                    const now = new Date();

                                    if (birthday > now) {
                                        dialogManager.alert('입력하신 가족의 생년월일 날짜가 올바르지 않습니다.');
                                        return;
                                    }
                                } catch (e) {
                                    dialogManager.alert('입력하신 가족의 생년월일 날짜가 올바르지 않습니다.');
                                    return;
                                }
                            }
                        }
                    }

                    asyncCall(customerApi.saveRep, [customerId, newValue], result => {
                        setData(data => {
                            if (data.customer) return {...data, customer: {...data.customer, rep: result}}
                            else return data;
                        });
                        onClose();

                        if (hasTwoDialog) {
                            cusDetailPopClose!();
                        } else {
                            toast.success(strSaved);
                        }
                    }, isMounted);
                }}>
                {({values, getFieldProps, setFieldValue}) =>
                    <Form>
                        <div className={cx('button-area')}>
                            <button className={cx('back')} type='button' onClick={() => {
                                if (hasTwoDialog) {
                                    cusDetailPopClose!();
                                }
                                onClose();
                            }}><i></i>돌아가기
                            </button>
                            <div className={cx('right-btn-area')}>
                                <AddFamilyBtn scrollRef={scrollRef}/>
                                <button className={cx('save')} type='submit'><i></i>저장하기</button>
                            </div>
                        </div>
                        <div className={cx('input-area')} ref={scrollRef}>
                            <div className={cx('scroll')}>
                                <p className={cx('title')}>대표이사 정보</p>
                                <div className={cx('form-box')}>
                                    <InputBox title='대표이사명' name='name' readOnly={orgInfo?.NAME !== ''}
                                              inputWidth='52rem'
                                              inputboxCn={cx('input-box')}/>
                                    <DateInputBox title='생년월일' name='birthday' inputWidth='52rem' maxLength={8}
                                                  inputboxCn={cx('input-box')} placeholder='생년월일 8자리 입력 (YYYYMMDD)'/>
                                    <div className={cx('birthday-box')}>
                                        <DateInputBox title='실제생일' name='realBirth' inputWidth='39.4rem' maxLength={8}
                                                      inputboxCn={cx('input-box')}
                                                      placeholder='생년월일 8자리 입력 (YYYYMMDD)'/>
                                        <div className={cx('calendars-box')}>
                                            <label className={cx('green-chk-box')}
                                                   onClick={() => setFieldValue('lunar', true)}>
                                                <input type='radio' name='lunar'
                                                       checked={values.lunar} readOnly={true}/><span>음력</span></label>
                                            <label className={cx('green-chk-box')}
                                                   onClick={() => setFieldValue('lunar', false)}>
                                                <input type='radio' name='lunar'
                                                       checked={!values.lunar} readOnly={true}/><span>양력</span></label>
                                        </div>
                                    </div>
                                    <SelectInputBox title='성별'
                                                    // value={personInfo.female == null ? '' : (personInfo.female ? '2' : '1')}
                                                    name='gender'
                                                    // disabled={personInfo.female !== null}
                                                    // onChange={updateFemale}
                                                    onChange={
                                                        () => { values.gender === "남자" ? setFieldValue("gender", "여자") : setFieldValue("gender", "남자") }
                                                    }
                                                    inputWidth='52rem'
                                                    inputboxCn={cx('select-input-box', 'no-data-first-list')}
                                                    selectBoxCn={[`${requiredField ? '' : 'error'}`]}
                                                    // selectList={[{id: '0', title: ''}, {id: '1', title: '남자'}, {id: '2', title: '여자'}]}
                                                    selectList={['', '남자', '여자']}
                                    />
                                    <InputBox title='휴대폰 번호' name='phone' placeholder='숫자만 입력' minLength={11}
                                              maxLength={11} inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                    <InputBox title='이메일' name='email' maxLength={40} placeholder='이메일을 입력해 주세요.'
                                              inputWidth='52rem' inputboxCn={cx('input-box')}/>
                                    <div className={cx('input-box')}>
                                        <label>경력 및<br/>학력사항</label>
                                        <ReactTextareaAutosize maxLength={300} placeholder='내용을 입력해 주세요.'
                                                               {...getFieldProps('career')}/>
                                    </div>
                                    <div className={cx('input-box')}>
                                        <label>특이사항</label>
                                        <ReactTextareaAutosize maxLength={300} placeholder='내용을 입력해 주세요.'
                                                               {...getFieldProps('remark')}/>
                                    </div>
                                    <div className={cx('input-box')}>
                                        <label>가족정보</label>

                                        <FieldArray
                                            name="fam"
                                            render={arrayHelpers => (
                                                <div>
                                                    {
                                                        values.fam && values.fam.map((fam, index) =>
                                                            <FamBox index={index} key={index}
                                                                    remove={(i) => arrayHelpers.remove(i)}/>
                                                        )
                                                    }
                                                </div>
                                            )}/>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    );
};

export default EditCeoInfo;