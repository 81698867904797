import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {ApiResult} from "../api-result";
import {Goods} from "./dto/goods";

@injectable()
export class GoodApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    currentSubscription = async (): Promise<ApiResult<Goods>> => {
        return await this.apiCall.fetch<ApiResult<Goods>>('/api/goods/subscription/current');
    }

    currentGoods = async (): Promise<ApiResult<Goods[]>> => {
        return await this.apiCall.fetch<ApiResult<Goods[]>>('/api/goods/current');
    }

    getGoods = async (goodsId: string): Promise<ApiResult<Goods>> => {
        return await this.apiCall.fetch<ApiResult<Goods>>(`/api/goods/${goodsId}`);
    }
}