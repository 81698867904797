import styles from './family-share-plan.module.scss';
import classNames from 'classnames/bind';
import {FieldArray, Form, Formik, FormikValues, useFormikContext} from 'formik';
import {familyList, InputBox, NumberInputBox, SelectInputBox} from './customer/edit-common';
import {ReportAddInfoPopProps} from './report-add-info-pop';
import {formatNum, round, toNum} from '../util/str-util';
import SelectButton from '../components/select-button';
import React, {useEffect, useRef, useState} from 'react';
import {SelectItem} from '../model/types';
import {useInjection} from 'inversify-react';
import {DialogManager} from '../components/dialog-manager';
import {CorpValue} from '../model/co-report/dto/corp-value';
import {Donee} from '../model/co-report/dto/donee';
import {ShareholderInfo} from '../model/customer/dto/shareholder-info';
import {stkRtPrecision} from '../data/const';
import {asyncCall} from '../util/util';
import {toast} from 'react-toastify';
import {strSaved} from '../data/strings';
import {useMountedState} from 'react-use';
import {CustomerDetailTab} from '../data/const_list';
import {CustomerInputApi} from "../model/customer/customer-input-api";
import {useCustomerInputClear} from "../model/customer/customer-input-hook";
import {Customer} from "../model/customer/dto/customer";
import CustomerDetailPop from './customer/customer-detail-pop';
import {CoReportApi} from '../model/co-report/co-report-api';
import DirectInputPop from '../components/direct-input-pop';
import {FamilyShareInfo} from "../model/co-report/dto/family-share-info";
import {DividendInputDto} from "../model/co-report/dto/dividend-input-dto";
import {getGiverList, getSelectedCorpValue} from "./report/report-util";

const cx = classNames.bind(styles);

interface DoneeBoxProps {
    data: Donee;
    fsIdx: number;
    index: number;
    remove: (index: number) => any;
    issStkCn: number;
    marketValue: number;
    giverList: SelectItem[];
    validate: boolean;
    shareholder: ShareholderInfo[];
    prefix: string;
}

const DoneeBox = ({
                      data,
                      fsIdx,
                      index,
                      remove,
                      issStkCn,
                      marketValue,
                      giverList,
                      validate,
                      shareholder,
                      prefix
                  }: DoneeBoxProps) => {

    const { values, setFieldValue } = useFormikContext<FormikValues>();
    const field = `${prefix}.receiver.${index}.`;
    const dialogManager = useInjection(DialogManager);

    const donee = (values.familyShares[fsIdx].receiver[index]) as Donee;
    const healthInsType: SelectItem[] = [{id: "REGION", title: "지역가입자"}, {id: "COMPANY", title: "직장가입자"}];

    const calc = (amount: number, cnBf: number) => {
        const cnt = round(amount / marketValue);
        setFieldValue(field + 'cnGift', cnt);
        const sum = Number(cnBf) + cnt;
        setFieldValue(field + 'cnAf', sum);
        setFieldValue(field + 'rtAf', round(sum / issStkCn * 100, stkRtPrecision));
    }

    useEffect(() => {
        calc(donee.gift, donee.cnBf);
    }, [donee.gift]);

    const showAddGroup = () => {
        dialogManager.open(
            DirectInputPop, {
                title: '수증자 직접 입력',
                width: '22.4rem',
                placeholder: '수증자 입력',
                fieldName: field + 'name',
                setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => onChangeName(value, value)
            }
        );
    }

    const onChangeName = (id: string, title: string) => {
        const holder = shareholder.find(it => it.STH_NM === title);
        const cnBf = holder ? holder.STK_CN : "0";

        setFieldValue(field + 'name', title);
        setFieldValue(field + 'cnBf', cnBf);
        setFieldValue(field + 'rtBf', holder ? holder.STK_RT : "0");

        calc(data.gift, Number(cnBf));
    }

    return (
        <div className={cx('donee-box')}>
            <div className={cx('input-area')}>
                <div className={cx('line1')}>
                    <SelectButton
                        listWidth='15.2rem'
                        title='수증자 선택'
                        addBtnText='직접 입력'
                        data={giverList}
                        addList={showAddGroup}
                        onSelect={onChangeName}
                        cn={[cx('select-btn')]}>
                        <InputBox title={`수증자${index + 1}`} name={field + 'name'} inputWidth='15.2rem'
                                  inputboxCn={cx('select-btn-input')} readOnly/>
                    </SelectButton>
                    <SelectInputBox title='관계' name={field + 'relation'} selectList={familyList} inputWidth='15.2rem'
                                    inputboxCn={cx((validate && data.name.length > 0 && data.relation.length === 0) ? 'error' : '')}/>
                    <NumberInputBox
                        name={field + 'gift'}
                        title='증여가액' unit='원'
                        inputWidth='15.2rem'
                        inputboxCn={cx((validate && data.name.length > 0 && data.gift === undefined) ? 'error' : '')}
                    />
                </div>
                <div className={cx('line2')}>
                    <NumberInputBox
                        name={field + 'giftBf'}
                        title='10년 이내 증여자산가액'
                        unit='원' inputWidth='20rem'
                    />
                    <NumberInputBox
                        name={field + 'giftTaxBf'}
                        title='기납부증여세'
                        unit='원' inputWidth='20rem'/>
                </div>
                <div className={cx('line3')}>
                    <SelectInputBox title='건강보험가입' name={field + 'healthInsType'} selectList={healthInsType} inputWidth='15.2rem'/>
                </div>
            </div>
            {data.name && data.relation && data.gift ?
                <div className={cx('result-table')}>
                    <div className={cx('cell', 'head-box')}>
                        <p className={cx('head')}>증여전 주식수</p>
                        <p className={cx('head')}>증여전 지분율</p>
                        <p className={cx('head')}>증여 주식수</p>
                        <p className={cx('head')}>증여후 주식수</p>
                        <p className={cx('head')}>증여후 지분율</p>
                    </div>
                    <div className={cx('cell', 'body-box')}>
                        <p className={cx('body')}>{formatNum({num: data.cnBf, unit: '주', type: 'txt'})}</p>
                        <p className={cx('body')}>{formatNum({num: data.rtBf, unit: '%', type: 'txt'})}</p>
                        <p className={cx('body')}>{formatNum({num: data.cnGift, unit: '주', type: 'txt'})}</p>
                        <p className={cx('body')}>{formatNum({num: data.cnAf, unit: '주', type: 'txt'})}</p>
                        <p className={cx('body')}>{formatNum({num: data.rtAf, unit: '%', type: 'txt'})}</p>
                    </div>
                </div>
                : null
            }
            <button className={cx('remove-btn')} onClick={() => remove(index)} type='button'></button>
        </div>
    );
};

const FamilyShareInfoInput = ({customer, corpVal, idx, shareholder, validate, moveScroll}:  // fsi,
                                  {customer: Customer, corpVal: CorpValue, idx: number, shareholder: ShareholderInfo[], validate: boolean,moveScroll: VoidFunction,}) => {
    const { values, setFieldValue } = useFormikContext<FormikValues>();
    const fsi = (values.familyShares[idx]) as FamilyShareInfo;
    const dialogManager = useInjection(DialogManager);
    const prefix = `familyShares.${idx}`;

    const findShareholder = (name: string) => shareholder.find(it => it.STH_NM === name);
    const [giver, setGiver] = useState(fsi.giverName ?? '');
    const issStkCn = Number(customer.companyInfo.issStkCn);

    const setGiverList2 = (values: Donee[]) => {
        setGiverList(getGiverList(shareholder, values));
    }

    useEffect(() => {
        calcShare(fsi.cnBf ?? 0);
    }, [fsi.receiver]);

    useEffect(() => {
        setGiverList(getGiverList(shareholder, fsi.receiver ?? []));

        shareholder.forEach((sh) => {

            if (sh.STH_NM === fsi.giverName) {

                setFieldValue(`${prefix}.cnBf`, sh.STK_CN);
                setFieldValue(`${prefix}.rtBf`, sh.STK_RT);

                calcShare(toNum(sh.STK_CN) ?? 0);
            }
        })
    }, [shareholder]);

    const calcShare = (cnBf: number, setGiver?: boolean) => {
        const sum = fsi.receiver.reduce((prev: number, cur: Donee) => prev + (cur.cnGift ? cur.cnGift : 0), 0);

        setFieldValue(`${prefix}.rtAf`, round(((cnBf ?? 0) - sum) * 100 / issStkCn, stkRtPrecision));

        if (setGiver) {
            setGiverList2(fsi.receiver);
        }
    };

    const selectGiver = (name: string) => {
        setFieldValue(`${prefix}.giverName`, name);
        const sh = findShareholder(name);

        setFieldValue(`${prefix}.giverRel`, sh?.ENP_REL_CD);
        setFieldValue(`${prefix}.giverMdm`, sh?.MDM_REL_CD);
        setFieldValue(`${prefix}.cnBf`, toNum(sh?.STK_CN));
        setFieldValue(`${prefix}.rtBf`, toNum(sh?.STK_RT));
        setGiver(name);

        // fsi.cnBf
        calcShare(toNum(sh?.STK_CN) ?? 0);
    }

    const addDonee = (push: (obj: any) => void) => {
        if (fsi.receiver.length >= 3) {
            dialogManager.alert('수증자 리포트 분석 목적으로 최대 3명까지만 추가가 가능합니다.');
        } else {
            push({name: '', relation: '', cnBf: 0, rtBf: 0} as Donee);
            moveScroll();
        }
    }
    // 수증자
    const [giverList, setGiverList] = useState<SelectItem[]>(getGiverList(shareholder, fsi.receiver ?? []));

    return (
        <>
            {/*<p className={cx('sub-title')}>1. 가족지분설계</p>*/}
            <div className={cx('total-box')}>

                <NumberInputBox value={customer.companyInfo.issStkCn} title='발행주식 수' unit='주'
                                readOnly
                                inputWidth='15.2rem'/>
                <NumberInputBox value={corpVal.corpValue} title='기업가치' unit='원' readOnly
                                inputWidth='15.2rem'/>
                <NumberInputBox value={corpVal.marketValue} title='1주당 주식가액' unit='원' readOnly
                                inputWidth='15.2rem'/>

                <SelectInputBox name={`${prefix}.giverName`} title='증여자'
                                selectList={giverList}
                                onChange={id => selectGiver(id)}
                                inputWidth='15.2rem'/>

                <NumberInputBox name={`${prefix}.rtBf`} title='증여전 지분율' unit='%' readOnly
                                inputWidth='15.2rem'/>
                <NumberInputBox name={`${prefix}.rtAf`} title='증여후 지분율' unit='%' readOnly placeholder=''
                                inputWidth='15.2rem'/>
            </div>


            <FieldArray name={`${prefix}.receiver`}>
                {({push, remove}) => <>
                    <div className={cx('sub-title-btn-area')}>
                        <p className={cx('sub-title')}>수증자를 추가해 주세요. (최대 3명)</p>
                        <button
                            onClick={() => addDonee(push)}
                            className={cx('add-btn')} type='button'>
                            <i></i>수증자 추가
                        </button>
                    </div>
                    {
                        fsi.receiver?.map((it, i) => {
                                return <DoneeBox key={i} index={i} data={it}
                                                 fsIdx={idx}
                                                 remove={idx => remove(idx)}
                                                 issStkCn={issStkCn}
                                                 marketValue={corpVal.marketValue}
                                                 giverList={giverList}
                                                 validate={validate}
                                                 shareholder={shareholder}
                                                 prefix={prefix}
                                />
                            }
                        )
                    }
                </>}
            </FieldArray>
        </>
    );
};

const FamilySharePlanV2 = ({data, setData, onClose}: ReportAddInfoPopProps) => {
    const customerInputApi = useInjection(CustomerInputApi);
    const reportApi = useInjection(CoReportApi);
    const isMounted = useMountedState();
    const dialogManager = useInjection(DialogManager);
    const [validate, setValidate] = useState(false);
    const {clearInfo} = useCustomerInputClear(setData);
    const scrollRef = useRef<HTMLDivElement>(null);
    const customer = data.customer;
    const report = data.report;
    const [shareholder, setShareholder] = useState(customer.shareholder.list ?? []);

    // 증여자1, 2 여부
    const [selectIdx, setSelectIdx] = useState(0);

    if (!customer || !report) return null;
    const corpVal = getSelectedCorpValue(report.ci.corpVal, customer.eval.selectedYear);

    // 초기화를 한다.
    const makeInit = (customer: Customer) : DividendInputDto => {
        if (customer.dvIn == null) {
            return {
                dividend: 0, dividend_ceo: 0, evenlyDv: false,
                familyShares: [{rtAf: 0, giverName: '', giverRel: '', receiver: [{name: '', relation: '', cnBf: 0, rtBf: 0, healthInsType: "REGION"} as Donee]}]
            };
        }

        const copyDvIn = {...customer.dvIn};

        if (copyDvIn.familyShares.length === 1) {
            copyDvIn.familyShares.push({rtAf: 0, giverName: '', giverRel: '', receiver: [{name: '', relation: '', cnBf: 0, rtBf: 0, healthInsType: "REGION"} as Donee]});
        }

        // 기본 수증자 추가
        copyDvIn.familyShares.forEach((f) => {
            if (f.receiver == null || f.receiver.length === 0) {
                f.receiver = [{name: '',relation: '',cnBf: 0,rtBf: 0, healthInsType: "REGION"} as Donee];
            }
        })

        return copyDvIn;
    }

    // 스크롤 이동
    const moveScroll = () => {
        setTimeout(() => {
            scrollRef?.current?.scrollTo({
                top: scrollRef?.current?.scrollHeight - scrollRef?.current?.offsetHeight - 50,
                behavior: 'smooth'
            });
        }, 1)
    }

    return (
        <Formik
            initialValues={makeInit(customer)}
            onSubmit={(values, formikHelpers) => {
                setValidate(true);

                if (values.familyShares[0].receiver.some((info) => info.name && (!info.relation || !info.gift))) {
                    dialogManager.alert('수증자 미입력 정보를 확인해 주세요.');
                    return;
                }

                if (Number(values.familyShares[0].rtAf) < 0) {
                    dialogManager.alert('증여자가 보유한 지분가치를\n초과하여 증여할 수 없습니다.');
                    return;
                }

                if (values.familyShares[0].receiver.some((info) => info.name) && !values.familyShares[0].giverName) {
                    dialogManager.confirm('증여자 정보가 없습니다.', '먼저 주주 정보를 입력해 주세요.', () => {
                        dialogManager.open(CustomerDetailPop, {
                            customerId: data.customer.id,
                            tab: CustomerDetailTab.Member,
                            onDialogClose: async () => {
                                const updateResult = await reportApi.coReportV2(data.customer.id!);
                                if (updateResult.val) {
                                    data.customer = updateResult.val!.customer;

                                    setShareholder(updateResult.val!.customer.shareholder.list ?? []);
                                    setData(updateResult.val!);
                                }
                            }
                        });
                    }, '주주현황으로 이동');
                    return;
                }

                asyncCall(customerInputApi.saveFamilySharePlan, [customer.id, values as DividendInputDto], result => {
                    setData(result);
                    data.customer = result.customer;//팝업에서 사용하는 데이터 업데이트
                    toast.success(strSaved);
                }, isMounted, (e) => {
                    dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                });
            }}>
            {({values, setFieldValue, resetForm}) => <Form className={cx('inheritance-tax-info')}>
                <div className={cx('btn-area')}>
                    <button
                        className={cx('save-btn')}
                        type='submit'>
                        <i></i>저장하기
                    </button>
                    <button className='close-btn' type='button' onClick={() => onClose()}>닫기</button>
                </div>
                <div className={cx('scroll-box')}>
                    <div className={cx('scroll-area')} ref={scrollRef}>
                        <div className={cx('title-line')}>
                            <p className={cx('title')}>
                                가족지분설계
                                <button className={cx('reset-btn')} type='button'
                                        onClick={() => clearInfo(customer.id, 'familySharePlan',
                                            (report) => resetForm({values: makeInit(report.customer)})
                                        )}><i></i>초기화
                                </button>
                            </p>
                            <button className={cx('status-btn')} type='button' onClick={() => {
                                dialogManager.open(CustomerDetailPop, {
                                    customerId: data.customer.id,
                                    tab: CustomerDetailTab.Member,
                                    onDialogClose: async () => {
                                        const updateResult = await reportApi.coReportV2(data.customer.id!);
                                        if (updateResult.val) {
                                            data.customer = updateResult.val!.customer;

                                            setShareholder(updateResult.val!.customer.shareholder.list ?? []);
                                            setData(updateResult.val!);
                                        }
                                    }
                                });
                            }}>주요 주주현황<i></i></button>
                        </div>

                        <div className={cx('tab-area')}>
                            <button type='button' className={cx(selectIdx === 0 && 'on')} onClick={() => setSelectIdx(0)}>증여자 1
                            </button>
                            <button type='button' className={cx(selectIdx === 1 && 'on')} onClick={() => setSelectIdx(1)}>증여자 2
                            </button>
                        </div>

                        {values.familyShares?.map((it, i) => (
                            selectIdx == i && <FamilyShareInfoInput key={i} corpVal={corpVal}
                                                                    customer={customer} idx={i}
                                                                    validate={validate}
                                                                    moveScroll={moveScroll}
                                                                    shareholder={shareholder}/>
                        ))}
                    </div>
                </div>
            </Form>
            }
        </Formik>
    );
};

export default FamilySharePlanV2;