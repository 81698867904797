import React, {useState} from 'react';
import styles from './add-financial-data.module.scss';
import classnames from 'classnames/bind';
import {Field, Form, Formik} from 'formik';
import {ClosableProps} from '../../components/dialog';
import {useInjection} from 'inversify-react';
import Dropzone from 'react-dropzone';
import {DialogManager} from "../../components/dialog-manager";
import LoadingDialog from "../../components/loading-dialog";
import {PrFrStat} from "../../model/customer/dto/financial-statement";
import {CustomerInputApi, PrFrSrcType} from "../../model/customer/customer-input-api";
import {asyncCall} from "../../util/util";
import {toast} from "react-toastify";
import {strSaved} from "../../data/strings";
import {useMountedState} from "react-use";

const cx = classnames.bind(styles);

interface Props extends ClosableProps {
    customerId: string;
    workYear: number;
    initApplyYear?: number;
    onComplete: (fsData: PrFrStat) => void;
}

const getPrFrSrcTypeStr = (type: PrFrSrcType): string[] => {
    switch (type) {
        case "HOMETAX":
            return ["표준재무제표증명원PDF"]
        case "TAX_ACC":
        case "TAX_ACC_2":
            return ["표준재무상태표PDF", "표준손익계산서PDF"];
        case "CRETOP_XLSX":
            return ["크레탑 재무상태표 엑셀", "크레탑 손익계산서 엑셀"];

    }
}

const AddFinancialData = ({workYear, onComplete, customerId, initApplyYear, onClose}: Props) => {
    const isMounted = useMountedState();
    const customerInputApi = useInjection(CustomerInputApi);
    const dialogManager = useInjection(DialogManager);
    // const yearList: SelectItem[] = [workYear, workYear - 1, workYear - 2].map((it, i) => ({
    //     id: it.toString(),
    //     title: it + '년'
    // })) ?? [];
    const [applyYear, setApplyYear] = useState((initApplyYear ?? workYear).toString());
    const [selectFiles, setSelectFiles] = useState<File[]>([]);


    const initialValues: { files: File[], type: PrFrSrcType } = {files: [], type: 'TAX_ACC'}
    return (
        <div className={`${cx('add-financial-data')} full-background`}>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    if (!selectFiles) {
                        dialogManager.alert("표준재무제표 PDF파일을 선택해주세요")
                        return;
                    }

                    const key = dialogManager.open(LoadingDialog, {
                        aniW: '10.0rem',
                        aniH: '10.0rem',
                        jsonFile: 'loading',
                        title: '잠시만 기다려 주세요.'
                    });

                    asyncCall(customerInputApi.uploadFsPdf, [customerId, values.type, workYear, parseInt(applyYear), selectFiles], (result) => {
                        dialogManager.close(key);

                        onComplete(result as PrFrStat);
                        toast.success(strSaved);
                        onClose();
                    }, isMounted, (errorMsg) => {
                        dialogManager.close(key);

                        toast.error(errorMsg);
                    });
                }}>
                {({values, setFieldValue}) => {
                    const fileCheckCnt = getPrFrSrcTypeStr(values.type).length;
                    const validate = selectFiles.length == fileCheckCnt;

                    return <Form>
                        <div className={`pop-inner ${cx('add-dialog')}`}>
                            <p className={cx('title')}>표준재무제표 업로드</p>
                            <div className={cx('input-box')}>
                                <span className={cx('select-box-title')}>결산연도</span>
                                <div className={cx('read-only-select-box')}>{applyYear}년</div>
                            </div>
                            <div className={cx('input-box')}>
                                <span className={cx('select-box-title', 'ver2')}>파일구분</span>
                                <div className={cx('grid-box')}>
                                    <label className={cx('radio-btn')}><Field type='radio' name='type'
                                                                              value='TAX_ACC'
                                                                              onClick={() => setFieldValue('files', [])}/>표준재무제표(세무사랑)</label>

                                    <label className={cx('radio-btn')}><Field type='radio' name='type'
                                                                              value='TAX_ACC_2'
                                                                              onClick={() => setFieldValue('files', [])}/>표준재무제표(위하고)</label>

                                    <label className={cx('radio-btn')}><Field type='radio' name='type'
                                                                              value='HOMETAX'
                                                                              onClick={() => setFieldValue('files', [])}/>표준재무제표증명원(홈택스)</label>

                                    <label className={cx('radio-btn')}><Field type='radio' name='type'
                                                                              value='CRETOP_XLSX'
                                                                              onClick={() => setFieldValue('files', [])}/>재무제표(엑셀)</label>

                                </div>
                            </div>
                            <Dropzone onDrop={files => setSelectFiles([...selectFiles, ...files])} noClick={true}
                                      accept={values.type === 'CRETOP_XLSX' ? {'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx', '.xls']} : {'application/pdf': ['.pdf']}}>
                                {({getRootProps, getInputProps, isDragActive, open}) =>
                                    <div {...getRootProps({className: cx('add-file', isDragActive && 'drag')})}>
                                        <div className={cx('add-file-btn-area')} onClick={() => open()}>

                                            {getPrFrSrcTypeStr(values.type).map((v, idx) => <label key={v}
                                                className={cx('add-file-btn', idx == 0 ? 'mr' : '')}>
                                                <i></i>{v}
                                            </label>)}
                                        </div>
                                        <p className={cx('file-name', (isDragActive || !selectFiles) && 'no-file')}>
                                            <span></span>
                                            {isDragActive ? '파일을 여기에 놓으세요.'
                                                : (!selectFiles || selectFiles.length == 0 ? '파일을 선택하거나 끌어오세요.'
                                                    : selectFiles.map((f, idx) => <span
                                                        key={idx} onClick={(e) => {
                                                        setSelectFiles((sv) => {
                                                            const copySv = [...sv];
                                                            copySv.splice(idx, 1);
                                                            return copySv;
                                                        });
                                                        e.stopPropagation();
                                                    }}>{f.name}<i></i></span>))}
                                        </p>
                                    </div>
                                }</Dropzone>
                            <div className={cx('btn-area')}>
                                <button className={cx('close-btn')} type='button' onClick={onClose}>닫기</button>
                                <button className={cx('save-btn')} type='submit' disabled={!validate}><i></i>저장하기
                                </button>
                            </div>
                        </div>
                    </Form>;
                }
                }
            </Formik>
        </div>
    );
};

export default AddFinancialData;