import styles from './basic-select-box.module.scss';
import classNames from 'classnames/bind';
import {useOutsideAlerter} from '../util/util';
import {SelectItem} from "../model/types";
import React, {useRef, useState} from "react";

const cx = classNames.bind(styles);

interface SelectListProps extends SelectBoxVer2Props{
    innerOnChange: (item:SelectItem) => void;
}

const SelectList = ({selectList, innerOnChange, selected}: SelectListProps) => {

    return (
        <div className={cx('select-list')}>
            <ul>
                {selectList.map((li, i) =>
                    <li key={i} className={cx(li.id === selected && 'selected')}
                        onClick={() => {
                            if (innerOnChange) {
                                innerOnChange(li);
                            }
                        }}>{li.title}</li>
                )}
            </ul>
        </div>
    );
}

interface SelectBoxVer2Props {
    selectList: SelectItem[];
    selected?: number | string;
    selectBoxCn?: string;
    inputWidth?: string;
    unit?: string;
    onChange?: (id:number|string) => void;
}

const BasicSelectBox = ({selectList, selected, selectBoxCn, inputWidth, unit, onChange}: SelectBoxVer2Props) => {
    const wrapperRef = useRef(null);
    const [showSelectBox, setShowSelectBox] = useState(false);
    const [selectedId, setSelectedId] = useState<number | string>(selected ?? 0);

    useOutsideAlerter(wrapperRef, setShowSelectBox);

    const innerOnChange = (item: SelectItem) => {
        setSelectedId(item.id);
        onChange?.(item.id);
    }

    let value = selectList.find((select) => select.id === selectedId)?.title ?? (selectList.length > 0 ? selectList[0].title : '-');

    return(
        <div className={cx('select-box', selectBoxCn)} onClick={() => setShowSelectBox(!showSelectBox)} ref={wrapperRef}>
            <input className={`input-txt`} value={`${value}${unit}`} style={{width: inputWidth}} readOnly/>
            <i className={cx('arrow')}></i>
            {showSelectBox &&
                <SelectList innerOnChange={innerOnChange} selected={selectedId ?? selected} selectList={selectList}/>
            }
        </div>
    );
}

export default BasicSelectBox;