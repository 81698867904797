import {StyleSheet} from '@react-pdf/renderer';
import {mergeStyle, usePdfComponents} from '../../pdf-hooks';
import colors from '../../../../../scss/themes/colors.module.scss';
import {mLeftDesc} from '../../../../styles/report-font';
import {Style} from '@react-pdf/types';

const SideNote = ({title, style, line2Title = false, children, titleFontSize}: { title: string, style?: Style, line2Title?: boolean, children: React.ReactNode, titleFontSize?: number}) => {
    const {View, Text} = usePdfComponents();

    const styles = StyleSheet.create({
        noteBox: {
            paddingBottom: 58,
        },
        sideNote: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: line2Title ? 'flex-start' : 'center',
            paddingBottom: 6,
        },
        icon: {
            marginTop: line2Title ? 4 : 0,
            marginRight: 5.5,
            fontSize: 9,
            color: colors.main
        },
        title: {
            fontSize: titleFontSize ? titleFontSize : 16,
            color: colors.main,
            fontWeight: 700
        },
        sideDesc: {
            fontSize: mLeftDesc,
            lineHeight: 1.63,
        },
    });

    return (
        <View style={mergeStyle([styles.noteBox, style])}>
            {title.length !== 0 &&
                <View style={styles.sideNote}>
                    <Text style={styles.icon}>◆</Text>
                    <Text style={styles.title}>{title}</Text>
                </View>
            }
            <View style={styles.sideDesc}>
                {children}
            </View>
        </View>
    );
};

export default SideNote;