import {injectable} from 'inversify';
import {action, makeObservable, observable} from 'mobx';
import Dialog from './dialog';
import React, {FunctionComponent, ReactNode} from 'react';
import 'reflect-metadata';
@injectable()
export class DialogManager {

    wrapRef: HTMLDivElement | null = null;
    dialogs: { Comp: FunctionComponent<any>, props: { [k: string]: any } }[] = [];

    constructor() {
        makeObservable(this, {
            dialogs: observable,
            open: action,
            close: action
        });
    }

    alert(title: string, content?: string, onAccept?: VoidFunction, mainBtnText?: string, child?: ReactNode, useOutsideClick?: boolean) {
        return this.open(Dialog, {
            btnCount: 1,
            children: <>
                <div className='title'>{title}</div>
                <div className='sub-title'>{content}</div>
                {child}</>,
            onAccept: onAccept,
            mainBtnText: mainBtnText,
            useOutsideClick: useOutsideClick,
        });
    }

    alert2(title: string, content?: string, onAccept?: VoidFunction, mainBtnText?: string, onClose2?:VoidFunction, leftBtnText?: string, child?: ReactNode, useOutsideClick?: boolean) {
        return this.open(Dialog, {
            btnCount: 2,
            children: <>
                <div className='title'>{title}</div>
                <div className='sub-title'>{content}</div>
                {child}</>,
            onAccept: onAccept,
            mainBtnText: mainBtnText,
            onClose2: onClose2,
            leftBtnText: leftBtnText,
            useOutsideClick: useOutsideClick,
        });
    }

    confirm(title: string, content?: string, onAccept?: VoidFunction, mainBtnText?: string, child?: ReactNode, title2?: string, leftBtnText?: string) {
        return this.open(Dialog, {
            children: <>
                <div className='title'>{title}</div>
                <div className='title2'>{title2}</div>
                <div className='sub-title'>{content}</div>
                {child}</>,
            onAccept: onAccept,
            mainBtnText: mainBtnText,
            leftBtnText: leftBtnText,
        });
    }

    open<P>(Comp: FunctionComponent<P>, props: Omit<P, 'onClose'> & { key?: number } & {onDialogClose?: VoidFunction}, closeAll: boolean = false) {
        if (closeAll) this.dialogs = [];
        props.key = this.dialogs.length > 0 ? (+(this.dialogs[this.dialogs.length - 1].props.key ?? 0)) + 1 : 0;
        this.dialogs.push({Comp: Comp, props});
        this.setScroll();
        return props.key;
    }

    close(key: any) {
        const closeDialog = this.dialogs.find(item => item.props.key === key);
        this.dialogs = this.dialogs.filter(item => item.props.key !== key);
        this.setScroll();

        if (closeDialog && closeDialog.props.onDialogClose) {
            closeDialog.props.onDialogClose();
        }

    }

    setScroll() {
        const classList = document.querySelector('body')?.classList
        if (this.dialogs.length > 0) classList?.add('modal-open');
        else classList?.remove('modal-open');
    }

    getScale() {
        return this.wrapRef ? this.wrapRef.getBoundingClientRect().width / this.wrapRef.clientWidth : 1;
    }
}

