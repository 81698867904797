import Bootpay from "@bootpay/client-js";

export function requestSubscription (goodName: string): Promise<any> {
    // TODO 사용자 연결 작업등은 이후에 보강한다.
    // TODO 먼저 결제 흐름 먼저 만든다.
    return Bootpay.requestSubscription({
        application_id: process.env.REACT_APP_BOOTPAY!,
        pg: 'nicepay',
        price: 0,
        tax_free: 0,
        method: "카드자동",
        order_name: goodName,
        subscription_id: (new Date()).getTime().toString(),
        user: {
        },
        extra: {
            // subscription_comment: '매월 1,000원이 결제됩니다',
            subscribe_test_payment: false
        }
    });
}

export async function requestPayment (goodName: string, orderId: string, price: number, userName: string, onConfirm: (receiptId: string) => void, onError: (message: string, error_code: string) => void): Promise<any> {
    // TODO 사용자 연결 작업등은 이후에 보강한다.
    // TODO 먼저 결제 흐름 먼저 만든다.
    try {
        const response = await Bootpay.requestPayment({
            application_id: process.env.REACT_APP_BOOTPAY!,
            pg: 'nicepay',
            price: price,
            tax_free: 0,
            method: "card",
            order_name: goodName,
            order_id: orderId,
            user: {
                username: userName
            },
            extra: {
                separately_confirmed: true,
                // subscription_comment: '매월 1,000원이 결제됩니다',
                // subscribe_test_payment: false
            }
        });

        switch (response.event) {
            case 'confirm': //payload.extra.separately_confirmed = true; 일 경우 승인 전 해당 이벤트가 호출됨
                await onConfirm(response.receipt_id);
                Bootpay.destroy();
                break;
        }
    }
    catch (e: any) {
        onError(e.message, e.error_code);
    }
}