import styles from '../intro/home/review.module.scss';
import classNames from 'classnames/bind';
import React from "react";

const cx = classNames.bind(styles);

const KatalkReview = ( ) => {

    return(
        <div className={cx('review')}>
            <div className='partners-section-inner'>
                <p className='partners-category ver2'>이용 후기</p>
                <h3 className='partners-section-title gap1'>
                    고객이 읽지 않는<br/> 일괄단체문자는 이제 그만!
                </h3>
                <picture>
                    <source media='(max-width: 500px)' srcSet='/images/partners_home/illust_katalk_screen_m.png'/>
                    <img className={cx('katalk-img')} src='/images/partners_home/illust_katalk_screen.png' alt=''/>
                </picture>
            </div>
        </div>
    );
};

export default KatalkReview;