import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/styles.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from 'react-router-dom';
import 'reflect-metadata'
import {Provider} from 'inversify-react';
import container from './apps/model/bootstrap-container';
import {HelmetProvider} from 'react-helmet-async';
// import registerServiceWorker from "./serviceWorker";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Provider container={container}>
                <HelmetProvider>
                    <App/>
                </HelmetProvider>
            </Provider>
        </BrowserRouter>
    </React.StrictMode>
);

// ServiceWorker
// registerServiceWorker();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
