import styles from './share-customer-pop.module.scss';
import classNames from 'classnames/bind';
import React, {useRef, useState} from "react";
import {useInjection} from "inversify-react";
import {DialogManager} from "./dialog-manager";
import {PrEtcApi} from "../model/etc/pr-etc-api";
import {PrSearchMemberDto} from "../model/etc/dto/pr-search-member-dto";
import {useMountedState} from "react-use";
import {CustomerShareApi} from "../model/customer/customer-share-api";
import { LoadType } from '../model/customer/dto/customer';

const cx = classNames.bind(styles);

const SearchResultBox = ({
                             searchList,
                             setSelected,
                             setResultLi
                         }: { searchList: PrSearchMemberDto[],  setSelected: React.Dispatch<React.SetStateAction<PrSearchMemberDto>>, setResultLi: React.Dispatch<React.SetStateAction<PrSearchMemberDto[]|null>>}) => {

    const selectedVal = (result:PrSearchMemberDto) => {
        setSelected(result);
        setResultLi?.(null);
    };

    return (
        <div className={cx('search-result-box')}>
            <ul className={cx('scroll')}>
                {
                    searchList.map((result) =>
                        <li key={result.memberId} onClick={() => selectedVal(result)}>
                            <p>{result.name}&nbsp;&nbsp;&nbsp;&nbsp;{result.position}</p>
                            <p>{result.phone}</p>
                        </li>
                    )
                }
            </ul>
        </div>
    );
};

const ShareCustomerPop = ({customerId, loadType, onClose}: {customerId: string, loadType: LoadType | undefined, onClose:VoidFunction}) => {
    const prEtcApi = useInjection(PrEtcApi);
    const customerShareApi = useInjection(CustomerShareApi);
    const dialogManager = useInjection(DialogManager);
    const isMounted = useMountedState();
    const [searchVal, setSearchVal] = useState<string | null>(null);
    const [resultLi, setResultLi] = useState<PrSearchMemberDto[] | null>(null);
    const [selected, setSelected] = useState<PrSearchMemberDto>({userNo: '', memberId: '', name: '', phone: ''});
    const wrapperRef = useRef(null);

    const searchResult = () => {

        if(searchVal) {
            if (searchVal.length < 2) {

            } else {
                (async () => {
                    try {
                        const result = await prEtcApi.searchMember(searchVal);
                        if (isMounted()) {
                            setResultLi(result.filter((li) => li.name === searchVal));
                        }
                    } catch (e) {}
                })();
            }
        }
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        setResultLi(null);
        setSelected({userNo: '', memberId: '', name: '', phone: ''});

        if (!e.nativeEvent.isComposing && !e.repeat && e.key === 'Enter') {
            searchResult();
        }
    };

    const resetBtn = () => {
        setSearchVal(null);
        setSelected({userNo: '', memberId: '', name: '', phone: ''});
    };

    const shareBtn = async (name: string, position: string) => {
        try {
            const result = await customerShareApi.shareAct({customerId: customerId, targetMemberNo: selected.userNo, loadType: loadType});

            if(isMounted()) {
                if(result.success) {
                    dialogManager.alert(`해당 고객정보가\n${name} ${position}님에게 공유되었습니다.`);
                    onClose();
                } else {
                    dialogManager.alert('이미 고객정보를 공유한 회원입니다.');
                }
            }
        } catch (e:any) {
           console.log(e);
        }
    };

    const shareLabs = async () => {
        try {
            const result = await customerShareApi.shareLabs(customerId, loadType!);

            if(result.success) {
                dialogManager.alert('씨오팀과 공유되었습니다.');
                onClose();
            } else {
                dialogManager.alert('씨오팀과 공유에 실패했습니다.');
            }
        } catch (e) {
            dialogManager.alert('오류가 발생했습니다.');
        }
    }

    return(
        <div className='full-background'>
            <div className={`pop-inner ${cx('share-customer-pop')}`}>
                <p className={cx('title')}>고객정보를 공유할 대상을 선택해 주세요.</p>
                <p className={cx('desc')}>고객정보 공유는 씨오파트너 회원간에만 가능합니다.</p>
                <div className={cx('search-box')} ref={wrapperRef}>
                    <div className={cx('search-input')}>
                        <input
                            type='text'
                            value={searchVal ?? ''}
                            onChange={(e) => setSearchVal(e.target.value)}
                            onKeyDown={handleKeyDown}
                            placeholder='공유할 회원 이름을 정확하게 입력해 주세요.'/>
                        <button type='button' className={cx('reset-txt-btn')} onClick={resetBtn}></button>
                    </div>
                    <button className={cx('search-btn')} type='button' onClick={searchResult}></button>
                    {resultLi && resultLi.length > 0 && <SearchResultBox searchList={resultLi} setSelected={setSelected} setResultLi={setResultLi}/>}
                </div>
                <div className={cx('space')}>
                    {resultLi && resultLi.length === 0 && <p className={cx('no-data')}>해당 검색어와 일치하는 회원이 없습니다.</p>}
                    {searchVal && searchVal.length < 2 && <p className={cx('no-data')}>회원명은 2자 이상 입력해 주세요.</p>}
                    {selected.memberId && <div className={cx('search-result')}><i></i><p>{selected.name} {selected.position}</p><p>{selected.phone}</p></div>}
                </div>
                <div className={cx('btn-area')}>
                    <button className={cx('share-btn')} type='button' onClick={shareLabs}><i></i>씨오팀과 공유하기</button>
                    <div className={cx('right-btn-area')}>
                        <button className={cx('close-btn')} type='button' onClick={onClose}>닫기</button>
                        <button className={cx('save-btn')} disabled={!selected.memberId} type='button' onClick={() => shareBtn(selected.name, selected.position ?? '')}><i></i>공유하기</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShareCustomerPop;