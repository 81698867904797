import {StyleSheet} from '@react-pdf/renderer';
import {usePdfComponents} from '../pdf-hooks';
import colors from '../../../../scss/themes/colors.module.scss';
import {ReactNode} from 'react';

interface ReportLeftProps {
    children?: ReactNode;
    icon: string;
    title: string;
}

const ReportLeft = ({children, icon, title}: ReportLeftProps) => {
    const {View, Text, Image} = usePdfComponents();

    const styles = StyleSheet.create({
        left: {
            position: 'relative',
            maxWidth: 220,
            flex: 0.18,
            borderTop: `1px solid ${colors.main}`
        },
        title: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '10px 0 82px',
        },
        icon: {
            width: 22,
            margin: '5px 10px 0 0',
        },
        text: {
            fontSize: 17,
            fontWeight: 700,
        },
    });

    return (
        <View style={styles.left}>
            <View style={styles.title}>
                <Image style={styles.icon} src={`/images/report/${icon}.png`}/>
                <Text style={styles.text}>{title}</Text>
            </View>
            {children}
        </View>
    );
};

export default ReportLeft;