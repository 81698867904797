import React from 'react';
import TableTitle from '../../../components/table-title';
import {SummaryReport} from '../../../model/inquiry/dto/summary-report';
import {
    EMPTY,
    hyphen,
    toBirthAndAgeEmptyMask,
    toBirthAndSex,
    toDateString,
    ymdToDateString
} from '../../../util/str-util';
import {usePdfComponents} from '../pdf-hooks';
import {getColT, getMultiplyValue, getRowT} from '../../../styles/styleSheet';
import {StyleSheet} from '@react-pdf/renderer';
import {MngInfo} from '../../../model/customer/dto/mng-info';
import {PrCorpRegRoot} from "../../../model/customer/dto/pr-corp-reg-root";

interface ManagementStatusProps {
    data: SummaryReport;
    ked5008?: MngInfo[];
    refDate?: string;

    detail: boolean;

    corpReg?: PrCorpRegRoot;
    mask?: boolean;
}

const ManagementStatus = ({data, ked5008, refDate, detail, corpReg, mask}: ManagementStatusProps) => {
    const {Text, View, isPdf} = usePdfComponents();
    const rowT = getRowT(isPdf);
    const colT = getColT(isPdf);
    const num = getMultiplyValue(isPdf);

    const convertPid = (it: MngInfo) => {
        const result = toBirthAndSex(it.PID)[0];

        if(result === null || result?.toString() === "Invalid Date") {
            return "-";
        } else {
            return toDateString(result!);
        }
    }

    const getOpinion = (type: string) => {
        const str = data.KED5031?.find(it => it['OPNN_CCD'] === type)?.['CTT']
        if (str == null) return EMPTY;
        // eslint-disable-next-line no-control-regex
        return str.replaceAll(/\x0b/g, '\n');
    }

    const styles = StyleSheet.create({
        flexDateBox: {
            flex: 1.2 * num
        },
        txtRight: {
            flex: 0.9 * num,
            paddingRight: 30 * num,
            textAlign: 'right'
        }
    });

    let stDate = "";

    if (corpReg != null) {
        stDate = '법인등기부등본 조회 (기준일 ' + ymdToDateString(corpReg.searchDate) + ')';
    }
    else if (refDate) {
        stDate = '기준일 ' + ymdToDateString(refDate);
    }

    return (
        <>
            <TableTitle title='임원 현황' dateOrUnit={stDate}>
                <View style={colT.flexWrap}>
                    <View style={[colT.flexLine, colT.headBox]}>
                        <Text style={[colT.flexHead, colT.txtLeft]}>직위</Text>
                        <Text style={colT.flexHead}>성명</Text>
                        <Text style={colT.flexHead}>관계</Text>
                        <Text style={colT.flexHead}>생년월일</Text>
                        <Text style={[colT.flexHead, styles.flexDateBox]}>취임일</Text>
                        <Text style={[colT.flexHead, styles.flexDateBox]}>재선임일</Text>
                        <Text style={[colT.flexHead, styles.flexDateBox]}>최근 등기일</Text>
                    </View>
                    {ked5008 ? ked5008.map((it, index) => 
                            <View key={index} style={[colT.flexLine]}>
                                {/* <Text
                                    style={[colT.flexBody, colT.txtLeft]}>{hyphen(it.MNGR_CCD)}</Text> */}
                                <Text style={[colT.flexBody, colT.txtLeft]}>{hyphen(it.TTL)}</Text>
                                <Text style={colT.flexBody}>{hyphen(it.NAME)}</Text>
                                <Text style={colT.flexBody}>{hyphen(it.MDM_REL_CD)}</Text>
                                {/* <Text style={colT.flexBody}>{toBirthAndSex(it.PID)[0] ? toDateString(toBirthAndSex(it.PID)[0]!) : '-'}</Text> */}
                                <Text style={colT.flexBody}>{convertPid(it)}</Text>
                                <Text style={[colT.flexBody, styles.flexDateBox]}>{ymdToDateString(it.ASMP_DT)}</Text>
                                <Text style={[colT.flexBody, styles.flexDateBox]}>{ymdToDateString(it.REAPP_DT)}</Text>
                                <Text style={[colT.flexBody, styles.flexDateBox]}>{ymdToDateString(it.REAPP_REG_DT)}</Text>
                            </View>) :
                        <View style={colT.flexLine}>
                            <Text style={colT.flexBody}>-</Text>
                        </View>
                    }
                </View>
            </TableTitle>
            {detail &&
            <>
            <TableTitle title='대표이사 주요 경력 사항'>
                {data.REP_INFO.map((pi, i) => {
                        const [birth, female] = toBirthAndSex(pi.PID);
                        const career = [
                            ['성명/직위', (pi.NAME ?? '') + (pi.TTL ? '/' + pi.TTL : ''),
                                '생년월일', birth ? toBirthAndAgeEmptyMask(pi.PID, mask ?? false) + (female != null ? '(' + (female ? '여' : '남') + ')' : '') : '****-**-**'],
                            ['거주지(도로명)', hyphen(pi.KED5005?.['REG_RDNM_ADDRA'] ? (pi.KED5005?.['REG_RDNM_ADDRA'] + ' ' + (pi.KED5005?.['REG_RDNM_ADDRB'].split(',')[0] ?? '')) : ''),
                                '출신학교', '']
                        ];

                        const ked5007 = pi.KED5007
                        if (ked5007 && ked5007.length > 0) {
                            if (ked5007[0]['SCH_CCD']) career[1][3] = '[' + ked5007[0]['SCH_CCD'] + '] ' + ked5007[0]['SCH_NM'];
                        }
                        career.forEach(value => {
                            value[1] = hyphen(value[1]);
                            value[3] = hyphen(value[3]);
                        });

                        const ked5006 = pi.KED5006;

                        const EachGap = () => {
                            if (i + 1 === data.REP_INFO.length) {
                                return null;
                            } else {
                                return <Text style={{height: 30 * num}}/>;
                            }
                        };

                        return <div key={i}>
                            <View style={[rowT.flexWrap]}>
                                {career.map((it, index) =>
                                    <View key={index} style={rowT.flexLine}>
                                        <View style={rowT.titleCover}><Text
                                            style={rowT.title}>{it[0]}</Text></View>
                                        <View style={rowT.descCover}><Text style={rowT.desc}>{it[1]}</Text></View>
                                        <View style={rowT.titleCover}><Text
                                            style={rowT.title}>{it[2]}</Text></View>
                                        <View style={[rowT.descCover, rowT.desc2Cover]}>
                                            <Text style={rowT.desc}>{it[3]}</Text></View>
                                    </View>)}
                            </View>
                            <Text style={{height: 20}}></Text>
                            <View style={colT.flexWrap}>
                                <View style={[colT.flexLine, colT.headBox]}>
                                    <Text style={[colT.flexHead, colT.txtLeft]}>기간</Text>
                                    <Text style={colT.flexHead}>근무기업</Text>
                                    <Text style={colT.flexHead}>근무업종</Text>
                                    <Text style={colT.flexHead}>최종직위</Text>
                                    <Text style={[colT.flexHead, colT.txtRight]}>담당업무</Text>
                                </View>
                                {ked5006?.length > 0 ? ked5006?.map((it, index) =>
                                        <View key={index} style={colT.flexLine}>
                                            <Text
                                                style={[colT.flexBody, colT.txtLeft]}>
                                                {ymdToDateString(it['WK_STDT']) + ' ~ ' + ymdToDateString(it['RTIR_DT'])}</Text>
                                            <Text style={colT.flexBody}>{hyphen(it['WKPL'])}</Text>
                                            <Text
                                                style={colT.flexBody}>{hyphen(it['WKPL_BZC_CD'])}</Text>
                                            <Text style={colT.flexBody}>{hyphen(it['LST_TTL'])}</Text>
                                            <Text
                                                style={[colT.flexBody, colT.txtRight]}>{hyphen(it['CHRG_BZ'])}</Text>
                                        </View>) :
                                    <View style={colT.flexLine}>
                                        <Text style={colT.flexBody}>-</Text>
                                    </View>
                                }
                            </View>
                            <EachGap/>
                        </div>
                    }
                )}
            </TableTitle>
            <TableTitle title='종합의견'>
                <View style={rowT.flexWrap}>
                    <View style={rowT.flexLine}>
                        <View style={rowT.titleCover}><Text style={rowT.title}>기업체
                            개요</Text></View>
                        <View style={rowT.descCover}>
                            <Text style={rowT.desc}>{getOpinion('기업체개요')}</Text></View>
                    </View>
                    <View style={rowT.flexLine}>
                        <View style={rowT.titleCover}><Text style={rowT.title}>경영진</Text></View>
                        <View style={rowT.descCover}><Text
                            style={rowT.desc}>{getOpinion('경영진')}</Text></View>
                    </View>
                    <View style={rowT.flexLine}>
                        <View style={rowT.titleCover}><Text style={rowT.title}>영업상황</Text></View>
                        <View style={rowT.descCover}><Text
                            style={rowT.desc}>{getOpinion('영업상황')}</Text></View>
                    </View>
                </View>
            </TableTitle>
            <View break/>
            <TableTitle title='연혁'>
                <View style={colT.flexWrap}>
                    <View style={[colT.flexLine, colT.headBox]}>
                        <View style={colT.flex02}><Text style={colT.flexHead}>연혁일자</Text></View>
                        <Text style={colT.flexHead}>내용</Text>
                    </View>
                    {data.KED5009?.length > 0 ? data.KED5009?.map((it, index) =>
                            <View key={index} style={colT.flexLine} wrap={false}>
                                <View style={colT.flex02}><Text
                                    style={colT.flexBody}>{ymdToDateString(it['HIS_DT'])}</Text></View>
                                <Text
                                    style={[colT.flexBody, colT.txtLeft]}>{it['CTT']}</Text>
                            </View>) :
                        <View style={colT.flexLine} wrap={false}>
                            <Text style={colT.flexBody}>-</Text>
                        </View>
                    }
                </View>
            </TableTitle>
            <TableTitle title='사업목적'>
                <View style={colT.flexWrap}>
                    <View style={[colT.flexLine, colT.headBox]}>
                        <Text style={colT.flexHead}>내용</Text>
                    </View>
                    {data.KED5010 ? data.KED5010?.map((it, index) =>
                            <View key={index} style={colT.flexLine}>
                                <Text
                                    style={[colT.flexBody, colT.txtLeft]}>{it['PPOS_BZ']}</Text>
                            </View>) :
                        <View style={colT.flexLine}>
                            <Text style={colT.flexBody}>-</Text>
                        </View>
                    }
                </View>
            </TableTitle>
            </>
            }
        </>
    );
};

export default ManagementStatus;
