import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {MentorProfileCont} from "./dto/mentor-profile-cont";
import {MentorProfile} from "./dto/mentor-profile";
import {ConsultingExpert} from "./dto/consulting-expert";
import {NeedsRequestForm} from "./dto/needs-request-form";
import {ApiResult} from "../api-result";
import {CoCategory} from "./dto/co-category";
import {Page} from "../common/dto/page";
import {CoPostDto} from "../co-post/dto/co-post-dto";
import {makeSearchDataQs} from "../../util/str-util";
import {CommonCode} from "../common/dto/common-code";

@injectable()
export class ConsultingExApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    getCategories = async (type: string) => {
        return await this.apiCall.coFetch<CoCategory[]>(`/api/co/cm/categories/${type}`);
    }

    getCoPostPaging = async (pageNo: number, searchData: {[key: string]: string | undefined}) => {
        return await this.apiCall.coFetch<Page<CoPostDto>>(`/api/co/post/paging?page=${pageNo}&size=8${makeSearchDataQs(searchData)}`);
    }

    getCoPost = async (postId: string) => {
        return await this.apiCall.coFetch<CoPostDto>(`/api/co/post/${postId}`);
    }

    getExpertCoLabCode = async () => {
        return await this.apiCall.fetch<CommonCode[]>(`/api/consulting/needs/expert/codes`);
    }

    getExpertForNeeds = async (categoryNo: string) => {
        return await this.apiCall.fetch<ConsultingExpert[]>(`/api/consulting/needs/expert/category/${categoryNo}`);
    }

    getExpertForNeedsAll = async () => {
        return await this.apiCall.fetch<ConsultingExpert[]>(`/api/consulting/needs/expert`);
    }

    getMentorProfileCont = async (searchName?: string) => {
        return await this.apiCall.fetch<MentorProfileCont>(`/api/consulting/expert` + (searchName ? `?name=${encodeURIComponent(searchName)}` : ""));
    }

    /**
     * 프로필 수정
     * @param categoryId 카테고리 아이디
     * @param searchName 검색
     */
    getMentorProfile = async (categoryId: string, searchName?: string) => {
        const url = `/api/consulting/expert/${categoryId}` + (searchName ? `?name=${encodeURIComponent(searchName)}` : "");

        return await this.apiCall.fetch<MentorProfile[]>(url);
    }

    postNeedsRequest = async (form: NeedsRequestForm) => {
        return await this.apiCall.json<ApiResult<string>>(`/api/consulting/needs/startExpert`, form);
    }
}