export const mFont = 16;

export const mFontOld = 15;

export const mLeftDesc = 14;

export const mGraphLegend = 15;

export const summaryFont = 13;

export const summaryColT2Font = 13;

export const chartFont = 18;

export const chartLabelFont = 16;