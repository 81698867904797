import {Container} from 'inversify';
import {ApiCall} from './api-call';
import {AuthStore} from './auth/auth-store';
import {InquiryApi} from './inquiry/inquiry-api';
import {AuthToken} from './auth/auth-token';
import {DialogManager} from '../components/dialog-manager';
import {CustomerApi} from './customer/customer-api';
import {MemberApi} from './intro/member-api';
import {ChartManager} from '../components/chart-manager';
import {CoReportApi} from './co-report/co-report-api';
import {GoodApi} from "./goods/good-api";
import {PaymentApi} from "./payment/payment-api";
import {CoSubscriptApi} from "./payment/co-subscript-api";
import {UploadApi} from "./upload/upload-api";
import {CustomerInputApi} from "./customer/customer-input-api";
import {PrEtcApi} from "./etc/pr-etc-api";
import {PrMemberPayApi} from "./payment/pr-member-pay-api";
import {LearninvestApi} from "./learninvest/learninvest-api";
import {LectureApi} from "./extra/lecture-api";
import {CustomerShareApi} from "./customer/customer-share-api";
import {ConsultingExApi} from "./talk/consulting-ex-api";
import {ExtraApi} from "./extra/extra-api";
import { FaqApi } from './faq/faq-api';
import { CommonCodeApi } from './common/common-code-api';
import { CustomerStore } from './customer/customer-store';
import {CotalkConnectApi} from "./customer/cotalk-connect-api";
import { KatalkApi } from './katalk/katalk-api';
import {CallKktAutoApi} from "./katalk/call-kkt-auto-api";
import {EmpSubsRefundApi} from "./event/emp-subs-refund-api";
import {LectureProgressApi} from "./lectureProgress/lecture-progress-api";
import {GoodOpenApi} from "./intro/good-open-api";
import {MemberShipApi} from "./payment/member-ship-api";

const container = new Container();

container.bind(DialogManager).toSelf().inSingletonScope();
container.bind(ApiCall).toSelf().inSingletonScope();
container.bind(AuthToken).toSelf().inSingletonScope();
container.bind(AuthStore).toSelf().inSingletonScope();
container.bind(MemberApi).toSelf().inSingletonScope();
container.bind(InquiryApi).toSelf().inSingletonScope();
container.bind(CustomerApi).toSelf().inSingletonScope();
container.bind(CustomerInputApi).toSelf().inSingletonScope();
container.bind(CoReportApi).toSelf().inSingletonScope();
container.bind(UploadApi).toSelf().inSingletonScope();
container.bind(ChartManager).toSelf().inSingletonScope();
container.bind(GoodApi).toSelf().inSingletonScope();
container.bind(PaymentApi).toSelf().inSingletonScope();
container.bind(CoSubscriptApi).toSelf().inSingletonScope();
container.bind(PrEtcApi).toSelf().inSingletonScope();
container.bind(PrMemberPayApi).toSelf().inSingletonScope();
container.bind(LearninvestApi).toSelf().inSingletonScope();
container.bind(LectureApi).toSelf().inSingletonScope();
container.bind(CustomerShareApi).toSelf().inSingletonScope();
container.bind(ConsultingExApi).toSelf().inSingletonScope();
container.bind(ExtraApi).toSelf().inSingletonScope();
container.bind(FaqApi).toSelf().inSingletonScope();
container.bind(CommonCodeApi).toSelf().inSingletonScope();
container.bind(CustomerStore).toSelf().inSingletonScope();
container.bind(CotalkConnectApi).toSelf().inSingletonScope();
container.bind(KatalkApi).toSelf().inSingletonScope();
container.bind(CallKktAutoApi).toSelf().inSingletonScope();
container.bind(EmpSubsRefundApi).toSelf().inSingletonScope();
container.bind(LectureProgressApi).toSelf().inSingletonScope();
container.bind(MemberShipApi).toSelf().inSingletonScope();
container.bind(GoodOpenApi).toSelf().inSingletonScope();

container.get(AuthStore).init();

export default container;