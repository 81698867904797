import React from 'react';
import styles from './add-consult-data.module.scss';
import classnames from 'classnames/bind';
import {Field, Form, Formik} from 'formik';
import {ClosableProps} from '../../components/dialog';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../../model/customer/customer-api';
import Dropzone from 'react-dropzone';
import {UploadApi} from "../../model/upload/upload-api";
import {toast} from "react-toastify";
import {strSaved} from "../../data/strings";
import {DialogManager} from "../../components/dialog-manager";
import LoadingDialog from "../../components/loading-dialog";

const cx = classnames.bind(styles);

interface AddConsultDataProps extends ClosableProps {
    customerId: string;
    counselingLogId: string;
    refresh: () => Promise<void>;
}

const AddConsultData = ({counselingLogId, refresh, customerId, onClose}: AddConsultDataProps) => {
    const customerApi = useInjection(CustomerApi);
    const uploadApi = useInjection(UploadApi);
    const dialogManager = useInjection(DialogManager);

    const initialValues: { filename: string, file: any } = {filename: '', file: ''}
    return (
        <div className={`${cx('add-consult-data')} full-background`}>
            <Formik
                initialValues={initialValues}
                onSubmit={async (values) => {
                    const filetitle = values.filename ? values.filename : values.file.name;

                    if(filetitle.indexOf(",") > -1) {
                        dialogManager.alert("파일명에 쉼표(,) 문자는 제거해주시기 바랍니다.");
                    } else {
                        const key = dialogManager.open(LoadingDialog, {aniW: '10.0rem', aniH: '10.0rem', jsonFile: 'loading', title: '잠시만 기다려 주세요.'});

                        const fileInfo = await uploadApi.uploadSingleFile({'type': 'PR_COUNSEL', 'autoSave':false}, values.file);
                        await customerApi.saveCounselingData(customerId, counselingLogId, fileInfo.fileID, filetitle);
                        dialogManager.close(key);
                        toast.success(strSaved);
                        await refresh();
                        onClose();
                    }                    
                }}>
                {({values, setFieldValue}) =>
                    <Form>
                        <div className={`pop-inner ${cx('add-dialog')}`}>
                            <p className={cx('title')}>상담자료 추가</p>
                            <div className={cx('input-box')}>
                                <Field name='filename' placeholder={values.file ? values.file.name : '문서 이름 입력'}/>
                            </div>
                            <Dropzone onDrop={files => setFieldValue('file', files[0])} noClick={false}>
                                {({getRootProps, getInputProps, isDragActive}) =>
                                    <div {...getRootProps({className: cx('add-file', isDragActive && 'drag')})}>
                                        <div className={cx('add-file-btn-area')}>
                                            <input
                                                {...getInputProps({
                                                    id: 'file', name: 'file', type: 'file', multiple: false,
                                                    onChange: (e: any) => {
                                                        setFieldValue('file', e.target.files[0]);
                                                    }
                                                })}/>
                                            <label className={cx('add-file-btn')}><i></i>파일 선택하기</label>
                                        </div>
                                        <p className={cx('file-name', (isDragActive || !values.file) && 'no-file')}>
                                            {isDragActive ? '파일을 여기에 놓으세요.' : (!values.file ? '파일을 선택하거나 끌어오세요.' : values.file.name)}
                                        </p>
                                    </div>
                                }</Dropzone>
                            <div className={cx('btn-area')}>
                                <button className={cx('close-btn')} type='button' onClick={onClose}>닫기</button>
                                <button className={cx('save-btn')} type='submit'><i></i>저장하기</button>
                            </div>
                        </div>
                    </Form>
                }
            </Formik>
        </div>
    );
};

export default AddConsultData;