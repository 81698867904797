import {SmsAuthReq} from "../model/intro/dto/sms-auth-req";
import {SmsAuthDto} from "../model/intro/dto/sms-auth-dto";

export class SmsAuthController {
    private _req?: SmsAuthReq;
    private _rst?: SmsAuthDto;

    setData(req: SmsAuthReq, rst: SmsAuthDto) {
        this._req = req;
        this._rst = rst;
    }

    get request(): SmsAuthReq {
        return this._req!
    }

    get result(): SmsAuthDto {
        return this._rst!
    }

}