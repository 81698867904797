import React, {createContext, useEffect, useState} from 'react';
import {Route, Routes} from 'react-router-dom';
import RootLayout from './apps/layout/root-layout';
import {toast, ToastContainer} from 'react-toastify';
import {Font} from '@react-pdf/renderer';
import {useInjection} from 'inversify-react';
import {AuthStore} from './apps/model/auth/auth-store';
import DialogContainer from './apps/components/dialog-container';
import LottieComp from './apps/components/lottie-comp';
import { RequireAuth } from './apps/model/auth/require-auth';
import MainReportTest from './apps/pages/report/main/main-report-test';
import {FullScreen, FullScreenHandle, useFullScreenHandle} from "react-full-screen";
import {KatalkControllerContext} from "./apps/pages/katalk/katalk-context";
import {KatalkController} from "./apps/controller/katalk-controller";

export const PdfContext = React.createContext({isPdf: false, isChart: false});
export const FullScreenContext = createContext<FullScreenHandle|undefined>(undefined);
//Font.register({family: 'NanumGothic", src: "https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf'});
//NotoSansKR-Regular.otf(v27)에서 일부 알파벳 표시 안 됨
//현재 폰트 출처 : https://fontmeme.com/ktype/noto-sans-kr-font/
Font.register({
    family: 'NotoSansKR', fonts: [
        {src: '/fonts/NotoSansKR-Regular.otf'},
        {src: '/fonts/NotoSansKR-Bold.otf', fontWeight: 700},
        {src: '/fonts/NotoSansKR-Medium.otf', fontWeight: 500}]
});

/*
Font.register({
    family: 'NotoSansKR',
    fonts: [
        { src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Regular.ttf' },
        { src: 'https://fonts.gstatic.com/ea/nanumgothic/v5/NanumGothic-Bold.ttf', fontWeight: 700 }
    ]
});*/

function App() {
    const authStore = useInjection(AuthStore);
    const fullScreenHandle = useFullScreenHandle();
    // const katalkController = useKatalkControllerContext();
    const [katalkController, setKatalkController] = useState<KatalkController>();
    const [loaded, setLoaded] = useState(false);
    // const handle = useFullScreenHandle();

    useEffect(() => {
        setKatalkController(new KatalkController());

        (async () => {
            await authStore.initApp();
            setLoaded(true);
        })(); 
    }, [authStore]);


    return (!loaded ? <div className='loading-box'><LottieComp width='10.0rem' height='10.0rem' jsonFile='loading'/></div>
                    :
                    <FullScreen className={`full-screen ${fullScreenHandle.active ? 'active' : ''}`} handle={fullScreenHandle}>
                        <FullScreenContext.Provider value={fullScreenHandle}>
                            <PdfContext.Provider value={{isPdf: false, isChart: false}}>
                                <KatalkControllerContext.Provider value={katalkController}>
                                    <Routes>
                                        {/*<Route path='/pdf/summary/:id' element={<RequireAuth><PdfReportTest/></RequireAuth>}/>*/}
                                        <Route path='/pdf/co-report/:id/' element={<RequireAuth><MainReportTest/></RequireAuth>}/>
                                        {/*<Route path='/pdf/co-report/:id/:memberNo'*/}
                                        {/*       element={<MainReportTest/>}/>*/}
                                        <Route path='*' element={<RootLayout/>}/>
                                    </Routes>

                                    <DialogContainer/>
                                </KatalkControllerContext.Provider>
                                <ToastContainer autoClose={1000} position={toast.POSITION.BOTTOM_LEFT}/>
                            </PdfContext.Provider>
                        </FullScreenContext.Provider>
                    </FullScreen>
    );
}

export default App;