import styles from './hometax-search.module.scss';
import classnames from 'classnames/bind';
import TitleBox from "../../components/title-box";
import { useInjection } from 'inversify-react';
import { CustomerApi } from '../../model/customer/customer-api';
import { Customer } from '../../model/customer/dto/customer';
import { useEffect, useState } from 'react';
import {CustomerDetail} from "../../model/customer/dto/customer-detail";

const cx = classnames.bind(styles);

const DownloadDataBox = ({ title, type, data }: {title: string, type: string, data: Customer}) => {
    const customerApi = useInjection(CustomerApi);
    const [settleYear, setSettleYear] = useState<string>("");

    useEffect(() => {
        loadSettleYear();
    }, []);


    const loadSettleYear = async () => {
        const result = await customerApi.getSettleYear(data.connectId!, type);
        let tempSettleYear = "";

        result.val?.sort();
        result.val?.map((sy, i) => {
            tempSettleYear += (sy + "년" + ", ");
        });

        tempSettleYear = tempSettleYear.substring(0, tempSettleYear.length - 2);

        setSettleYear(tempSettleYear);
    }

    const excelDown = async () => {
        let result = false;
        let resultData = "";

        if(type === "BS" || type === "IS") {
            const loadResult = await customerApi.hometaxFrStatExcel(data.connectId!, type);
            result = loadResult.success;
            resultData = loadResult.data!;
        } else {
            const loadResult = await customerApi.hometaxStockTableExcel(data.connectId!);
            result = loadResult.success;
            resultData = loadResult.data!;
        }

        if (result) {
            window.location.href = customerApi.makeDownloadFile(resultData);
        }
    }

    return(
        <div className={cx('download-data-box')} onClick={() => { excelDown() }}>
            <div className={cx('title-area')}>
                <p className={cx('title')}><i></i>{title}</p>
                <p className={cx('year')} style={{ letterSpacing: "0rem" }}>{settleYear}</p>
            </div>
            <i className={cx('download-icon')}></i>
        </div>
    );
}

const HomeTaxSearch = ({data} : {data: CustomerDetail}) => {
    return(
        <>
            {
                data.customer?.connectionState === "CONNECTED" && data.customer?.connectId && data.ownerHometaxData ?
                    <div className={cx('home-tax-page')}>
                        <TitleBox title='홈택스 조회자료 다운로드' paddingTop='4.0rem'></TitleBox>
                        <div className={cx('data-list')}>
                            <DownloadDataBox title={"표준재무상태표"} type={"BS"} data={data.customer} />
                            <DownloadDataBox title={"표준손익계산서"} type={"IS"} data={data.customer} />
                            <DownloadDataBox title={"주주변동명세서"} type={"ST"} data={data.customer} />
                        </div>
                    </div>
                    :
                    <div className={cx('policy-fund-tab')}>
                        <TitleBox title='홈택스 조회자료 다운로드' paddingTop='4.0rem'></TitleBox>
                        <div className={cx('content')}>
                            <p className={cx('illust')}></p>
                            <p className={cx('sub-title')}>CEO고객에게 <span className={cx('color')}>씨오톡 연결</span>을 요청해 보세요</p>
                            <p className={cx('text')}>
                                CEO고객이 씨오톡 앱 회원가입 후 <span className={cx('color')}>[전문가파트너 연결 &gt; 경영진단 &gt; 홈택스 재무정보 조회하기]</span> 메뉴에서<br/>공동인증서를 등록하고 홈택스 표준재무제표 정보를 조회하면, 전문가 파트너가 해당 정보를 공유 받을 수 있습니다.
                            </p>
                        </div>
                    </div>
            }
        </>
    );
}

export default HomeTaxSearch;