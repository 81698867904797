import {DependencyList, useEffect, useState} from "react";

export default function usePromise<T>(promiseCreator: () => Promise<T>, deps: DependencyList) : [boolean, T|null, Error|null, VoidFunction] {
    const [loading, setLoading] = useState(false);
    const [resolved, setResolved] = useState<T|null>(null);
    const [error, setError] = useState<Error|null>(null);

    const process = async (loadingOn: boolean) => {
        if (loadingOn) setLoading(true);

        try {
            const resolved = await promiseCreator();

            setResolved(resolved);
        } catch (e) {
            if (e instanceof Error)
                setError(e);
        }

        if (loadingOn) setLoading(false);
    }

    const refresh = () => {
        process(false);
    }

    useEffect(() => {
        process(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);

    return [loading, resolved, error, refresh];
}
