import styles from './family-share-plan.module.scss';
import classNames from 'classnames/bind';
import {Field, Form, Formik, useFormikContext} from "formik";
import CustomerDetailPop from "./customer/customer-detail-pop";
import {CustomerDetailTab} from "../data/const_list";
import {NumberInputBox} from "./customer/edit-common";
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import {useInjection} from "inversify-react";
import {DialogManager} from "../components/dialog-manager";
import {useCustomerInputClear} from "../model/customer/customer-input-hook";
import {CoReportDto} from "../model/co-report/dto/co-report-dto";
import {CoReportApi} from "../model/co-report/co-report-api";
import {formatNum} from "../util/str-util";
import {asyncCall} from "../util/util";
import {toast} from "react-toastify";
import {strSaved} from "../data/strings";
import {CustomerInputApi} from "../model/customer/customer-input-api";
import {ModifyDividend} from "../model/customer/form/modify-form";
import {useMountedState} from "react-use";
import {Customer} from "../model/customer/dto/customer";

const cx = classNames.bind(styles);

interface DividendPlanProps {
    data: CoReportDto
    setData: Dispatch<SetStateAction<CoReportDto | undefined>>
    onClose: VoidFunction
}

const DividendPlanPrice = ({customer}: { customer: Customer }) => {
    const {values, setFieldValue} = useFormikContext<ModifyDividend>();

    useEffect(() => {
        if (values.dividend.evenlyDv) {
            const companyStock = customer.shareholder.list?.filter((s) => {
                const sthNm = s.STH_NM.replaceAll("(주)", "");
                const enpNm = customer.enpNm.replaceAll("(주)", "");
                return sthNm === enpNm;
            });

            const companyEqrt = parseFloat((companyStock?.reduce((prev, current) => prev + parseFloat(current.STK_RT), 0) ?? 0).toFixed(4));

            // customer.shareholder.list.find
            if (!customer.rep || customer.rep.length === 0)
                return;

            const rep = customer.rep[0];
            const sh = customer.shareholder.list?.find((sh) => sh.STH_NM === rep.name);

            if (sh) {
                setFieldValue(`dividend.dividend_ceo`, values.dividend.dividend *
                    parseFloat((parseFloat(sh.STK_RT) / (100 - companyEqrt)).toFixed(5))
                );
            }
        }
    }, [values.dividend.evenlyDv]);

    return (
        <div className={cx('dividend-box')}>
            <NumberInputBox name='dividend.dividend' title='연간 총 배당액' unit='원'
                            inputWidth='52rem'/>
            <NumberInputBox name='dividend.dividend_ceo' title='대표자 급여 외 배당' unit='원'
                            inputWidth='52rem'/>
            <label className={cx('check-box')}>
                <Field
                    type='checkbox'
                    name='dividend.evenlyDv'/>
                배당설계에 따른 대표이사 균등배당액 적용
            </label>
        </div>
    );
};

const DividendPlanInput = ({eqrtSum, customer, lastUpdate}: { eqrtSum: string, customer: Customer, lastUpdate: number }) => {
    const {values, setFieldValue} = useFormikContext<ModifyDividend>();
    const [diffDvSum, setDiffDvSum] = useState(0);
    const [eqDvSum, setEqDvSum] = useState(0);

    // 회사 주식
    const companyStock = customer.shareholder.list?.filter((s) => {
        const sthNm = s.STH_NM.replaceAll("(주)", "");
        const enpNm = customer.enpNm.replaceAll("(주)", "");
        return sthNm === enpNm;
    });

    // 회사 주식
    const companyTv = (companyStock?.reduce((prev, current) => prev + (+current.STK_CN), 0) ?? 0);

    useEffect(() => {
        setDiffDvSum(values.shareholderDv.reduce((prev, current) => current.diffDividend ? prev + parseInt((current.diffDividend.toString())) : prev, 0));
        setEqDvSum(values.shareholderDv.reduce((prev, current) => current.eqDividend ? prev + current.eqDividend : prev, 0));
    }, [values.shareholderDv]);

    useEffect(() => {
        customer.shareholder.list?.forEach((sh, idx) => {
            const sthNm = sh.STH_NM.replaceAll("(주)", "");
            const enpNm = customer.enpNm.replaceAll("(주)", "");
            if (sthNm === enpNm) {
                setFieldValue(`shareholderDv.${idx}.eqDividend`, 0);
            }
            else {
                setFieldValue(`shareholderDv.${idx}.eqDividend`,
                    Math.round((values.dividend.dividend * (+sh.STK_CN)) / ((+customer.companyInfo.issStkCn) - companyTv) / 100) * 100
                );
            }

        });
    }, [lastUpdate, values.dividend.dividend]);

    return (
        <div className={cx('result-table')}>
            <div className={cx('cell', 'head-box')}>
                <p className={cx('head')}>주요주주</p>
                <p className={cx('head')}>관계</p>
                <p className={cx('head')}>지분율</p>
                <p className={cx('head')}>균등배당액</p>
                <p className={cx('head')}>차등배당액</p>
            </div>
            {values.shareholderDv.map((shdv, idx) => {
                const sh = customer.shareholder.list![idx];

                return (
                    <div key={idx} className={cx('cell', 'body-box')}>
                        <p className={cx('body')}>{shdv.name}</p>
                        <p className={cx('body')}>{sh.MDM_REL_CD}</p>
                        <p className={cx('body')}>{sh.STK_RT}%</p>
                        <p className={cx('body')}>
                            {formatNum({num: shdv.eqDividend, unit: '원'})}
                        </p>
                        <div className={cx('body')}>
                            <NumberInputBox title={``}
                                            name={`shareholderDv.${idx}.diffDividend`}
                                            unit='원'
                                            inputboxCn={cx('input-box')}/>

                            {/*<InputBox inputboxCn={cx('input-box')} unit='원'*/}
                            {/*          name={`shareholderDv.${idx}.diffDividend`}/>*/}
                        </div>
                    </div>
                );
            })}


            <div className={cx('cell', 'body-box')}>
                <p className={cx('body')}>합계</p>
                <p className={cx('body')}></p>
                <p className={cx('body')}>{eqrtSum}%</p>
                <p className={cx('body')}>{formatNum({num: eqDvSum, unit: '원'})}</p>
                <p className={cx('body')}>{formatNum({num: diffDvSum, unit: '원'})}</p>
            </div>
        </div>);
};

const DividendPlan = ({data, setData, onClose}: DividendPlanProps) => {
    const reportApi = useInjection(CoReportApi);
    const customerInputApi = useInjection(CustomerInputApi);
    const dialogManager = useInjection(DialogManager);
    const isMounted = useMountedState();
    const {clearInfo} = useCustomerInputClear(setData);

    // const [customer, setCustomer] = useState(data.customer);
    const customer = data.customer;
    // const report = data.report;

    const [eqrtSum, setEqrtSum] = useState("-");
    const [lastUpdate, setLastUpdate] = useState(1);

    useEffect(() => {
        const v = (customer.shareholder.list?.reduce((p, sh) => p + parseFloat(sh.STK_RT), 0) ?? 0);
        setEqrtSum((v > 100 ? 100 : v).toFixed(1));
    }, [customer]);

    const makeInit = (customer: Customer): ModifyDividend => {
        return {
            dividend: customer.dvIn, shareholderDv: customer.shareholder.list?.map((sh) => ({
                name: sh.STH_NM,
                diffDividend: sh.DIFF_DIVIDEND,
            }))
        } as ModifyDividend;
    }

    return (
        <Formik
            initialValues={makeInit(customer)}
            onSubmit={(values) => {
                const totalDiffDiv = values.shareholderDv.reduce((acc, dv) => acc + (Number(dv.diffDividend ?? "0")), 0);

                if (totalDiffDiv > 0 && (totalDiffDiv !== values.dividend.dividend)) {
                    dialogManager.alert("차등배당액합계액은 연간 총배당액과\n동일하게 적용해야 합니다.");
                    return;
                }

                asyncCall(customerInputApi.saveDividend, [customer.id, values as ModifyDividend], result => {
                    setData(result);
                    data.customer = result.customer;//팝업에서 사용하는 데이터 업데이트
                    toast.success(strSaved);
                }, isMounted, () => {
                    dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                });
            }}>
            {({resetForm}) => {
                return <Form className={cx('inheritance-tax-info')}>
                    <div className={cx('btn-area')}>
                        <button
                            className={cx('save-btn')}
                            type='submit'>
                            <i></i>저장하기
                        </button>
                        <button className='close-btn' type='button' onClick={() => onClose()}>닫기</button>
                    </div>
                    <div className={cx('scroll-box')}>
                        <div className={cx('scroll-area')}>
                            <div className={cx('title-line')}>
                                <p className={cx('title')}>
                                    배당설계
                                    <button className={cx('reset-btn')} type='button'
                                            onClick={() => clearInfo(customer.id, 'dividend',
                                                (report) => {
                                                    resetForm({values: makeInit(report.customer)});
                                                    setLastUpdate(lastUpdate + 1);
                                                }
                                            )}><i></i>초기화
                                    </button>
                                </p>
                                <button className={cx('status-btn')} type='button' onClick={() => {
                                    dialogManager.open(CustomerDetailPop, {
                                        customerId: data.customer.id,
                                        tab: CustomerDetailTab.Member,
                                        onDialogClose: async () => {
                                            const updateResult = await reportApi.coReportV2(data.customer.id!);
                                            if (updateResult.val) {
                                                data.customer = updateResult.val!.customer;

                                                setData(updateResult.val!);
                                                resetForm({values: makeInit(updateResult.val.customer)});
                                            }
                                        }
                                    });
                                }}>주요 주주현황<i></i></button>
                            </div>

                            <DividendPlanPrice customer={customer}/>

                            <p className={cx('sub-title')}>주주별 배당액</p>

                            <DividendPlanInput eqrtSum={eqrtSum} customer={customer} lastUpdate={lastUpdate}/>
                        </div>
                    </div>

                </Form>;
            }
            }
        </Formik>
    );
};

export default DividendPlan;