import styles from '../intro/home/consulting-package.module.scss';
import classNames from 'classnames/bind';
import React from 'react';

const cx = classNames.bind(styles);

const KatalkManagement = () => {

    return (
        <div className={cx('consulting-package')}>
            {/*<div className='partners-section-inner'>*/}
                <h3 className='partners-section-title gap2'>
                    고객관리가 쉬워지는<br/> <span>새로운 방법</span>
                </h3>
            {/*</div>*/}
            <div className={cx('katalk-img-area')}>
                <picture>
                    <source media='(max-width: 500px)' srcSet='/images/partners_home/illust_katalk_connection_m.png'/>
                    <source media='(max-width: 1200px)' srcSet='/images/partners_home/illust_katalk_connection_tablet.png'/>
                    <img src='/images/partners_home/illust_katalk_connection.png' alt=''/>
                </picture>
            </div>
        </div>
    );
};

export default KatalkManagement;