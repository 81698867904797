import {Document as PdfDocument, Page as PdfPage, PDFViewer} from '@react-pdf/renderer';
import {useFont, usePdfMode} from '../pdf-hooks';
import {CoReportDto} from '../../../model/co-report/dto/co-report-dto';
import {useParams} from 'react-router-dom';
import {Provider, useInjection} from 'inversify-react';
import {useInit} from '../../../util/util';
import container from '../../../model/bootstrap-container';
import {mFont} from '../../../styles/report-font';
import {CoReportApi} from '../../../model/co-report/co-report-api';
import {observer} from 'mobx-react-lite';
import {ChartManager} from '../../../components/chart-manager';
import React from "react";
import usePromise from '../../../model/common/use-promise';
import {ApiResult} from '../../../model/api-result';
import {CoReportExtraInfoDto} from '../../../model/co-report/dto/co-report-extra-info-dto';
import EmploymentSupportFund from "./page/laborConsulting/employment-support-fund";
import ManagementResult from "./page/finance/management-result";
import StockTransferCost from "./page/evaluation/stock-transfer-cost";
import TransferUnlistedStockTax from "./page/evaluation/transfer-unlisted-stock-tax";
import TaxComparisonByStockTransferType from "./page/evaluation/tax-comparison-by-stock-transfer-type";
import CheckSeverancePay from "./page/salaryCare/check-severance-pay";
import AppropriateSalaryAnalysis from "./page/salaryCare/appropriate-salary-analysis";
import CeoSalaryDividendDesign from "./page/salaryCare/ceo-salary-dividend-design";
import SurplusAnalysis from "./page/dividendPlan/surplus-analysis";
import TaxSavingEffect from "./page/dividendPlan/tax-saving-effect";
import DividendTaxComparison from "./page/dividendPlan/dividend-tax-comparison";
import CompareTaxByMethod from "./page/borrowedNMStock/compare-tax-by-method";
import ProvisionalPaymentAnalysis from "./page/provPaySolution/provisional-payment-analysis";
import IssueByLifeCycle from "./page/finance/issue-by-life-cycle";
import SeverancePayDetail from "./page/salaryCare/severance-pay-detail";
import FourMajorInsurancePremium from "./page/salaryCare/four-major-insurance-premium";
import DebtSolvencyAnalysis from "./page/riskManagement/debt-solvency-analysis";
import TreasuryStockRetirementC from "./page/treasuryStockUseSolution/treasury-stock-retirement-c";
import InheritanceTaxDetail from "./page/inheritance/inheritance-tax-detail";
import SupportableGovernmentConsulting from "./page/laborConsulting/supportable-government-consulting";
import StockValueSavingsSimulation from "./page/evaluation/stock-value-savings-simulation";

const ReportWrapper = ({data, isPdf, isChart}: { data: CoReportDto, isPdf: boolean, isChart?: boolean }) => {
    usePdfMode(isPdf, isChart);
    // const inhr = data.report.inhr;
    // const brStk = data.report.brStk;
    const reportApi = useInjection(CoReportApi);
    const [loading, extra] = usePromise<ApiResult<CoReportExtraInfoDto>>(() => reportApi.getCoReportExtraInfo(), []);
    const element = <>
        {/* <ReportCover type='cover6' info={{company: '(주)런인베스트', name: '정혜련', position: 'FP', phone: '01075847664'}} logo={extra?.val} company={'서울냉열'}/> */}
        {/*<ContentsType1 reportList={tempList}/>*/}F
        {/* <ContentCover1_1 title='tkdthr'/> */}
        {/* 리포트 목차 */}
        {/*<ContentCover2 title='상속 및 가업승계'/>*/}
        {/* <ContentCover1 title='상속 및 가업승계'/>*/}
        {/* 기업재무분석 */}
        {/* <CorporateOverview data={data}/>*/}
        {/* <ShareholderManagement data={data}/> */}
        {/*<FinanceOverview data={data}/>*/}
        {/*<FinanceAnalysis data={data}/>*/}
        {/*<FinanceAnalysis2 data={data}/>*/}
        {/*<SummaryBalanceSheet data={data}/>*/}
        {/*<SummaryIncomeStatement data={data}/>*/}
        {/*<IssueByLifeCycle data={data}/>*/}
        {/*<ManagementResult data={data} idx={0}/>*/}
        {/*<ManagementResult data={data} idx={1}/>*/}

        {/*<NeedEvaluation/>*/}
        {/* <EvalUnlistedStock data={data}/> */}
        {/*<UnlistedStockDetail data={data}/>*/}
        {/*<FutureValueSimulation data={data}/>*/}
        {/*<TaxComparisonByStockTransferType data={data} idx={0}/>*/}
        <StockValueSavingsSimulation data={data}/>
        {/*<StockTransferCost data={data}/>*/}
        {/*<TransferUnlistedStockTax data={data}/>*/}

        {/*<AssetManagementPlan/>*/}
        {/*<SurplusAnalysis data={data}/>*/}
        {/*<TaxSavingEffect data={data}/>*/}
        {/*<FamilyShareHolderDesign data={data}/>*/}
        {/* <UseExample data={data} idx={0}/>*/}
        {/*<DividendUtilizationStrategy/>*/}
        {/*<DividendTaxComparison data={data}/>*/}

        {/*<NeedSalaryCarePlan/>*/}
        {/*<CheckSeverancePay data={data} isRp2="true"/>*/}
        {/*<SeverancePayDetail data={data}/>*/}
        {/*<CheckSeverancePay data={data} isRef={true}/>*/}
        {/*<SeverancePayTaxSaving data={data}/>*/}
        {/*<AppropriateSalaryAnalysis data={data}/>*/}

        {/*<RiskManagement/>*/}
        {/*<EmergencyFundsAnalysis data={data}/>*/}
        {/*<DebtSolvencyAnalysis data={data}/>*/}
        {/*<EmergencyFundsAnalysis data={data}/>*/}
        {/*<MinimumSafeguards data={data}/>*/}
        {/*<RegularInsuranceCost/>*/}
        {/*<LifeInsuranceCost/>*/}
        {/*<EmergencyFundsAnalysis data={data}/>*/}

        {/*<CheckBorrowedNameStock data={data}/>*/}
        {/*<ChkListForBorrowedNameStock/>*/}
        {/*{<CompareTaxByMethod data={data}/>}*/}
        {/*{!brStk.example && brStk.nmSh.map((nominee, i) =>*/}
        {/*    <>*/}
        {/*        <SwiperSlide key={`0${nominee.name}${i}`}>*/}
        {/*            <StockRecoveryPlanCheck data={data} idx={i}/>*/}
        {/*        </SwiperSlide>*/}
        {/*        <SwiperSlide key={`1${nominee.name}${i}`}>*/}
        {/*            <EstimatedTaxDetail data={data} idx={i}/>*/}
        {/*        </SwiperSlide>*/}
        {/*    </>*/}
        {/*)}*/}
        {/*{brStk.example && <EstimatedTaxDetail data={data} idx={0}/>}*/}
        {/*<TreasuryStockRetirementC data={data}/>*/}
        {/*<LowCostTransferTaxBasis data={data}/>*/}
        {/*    <ProvisionalPaymentRisk/>*/}
        {/*    <ProvisionalPaymentAnalysis data={data}/>*/}
        {/*    <ProvisionalPaymentSolution1/>*/}
        {/*    <ProvisionalPaymentSolution2/>*/}
        {/*    <TreasuryStockUseExample/>*/}
        {/*    <TreasuryStockRetirement/>*/}
        {/*    <NewCapitalReduction/>*/}
        {/*    <InheritanceTaxAnalysis data={data}/>*/}
        {/*    <InheritanceTaxDetail data={data}/>*/}
            {/*{inhr.table2 &&*/}
            {/*    <SecondInheritanceTaxAnalysis data={data}/>}*/}
            {/*{inhr.table2 &&
                <SecondInheritanceTaxDetail data={data}/>}*/}
        {/*    <InheritancePortionCheck data={data}/>*/}
        {/*    <ReducingInheritanceTaxEffect data={data}/>*/}
        {/*    <FamilyBusinessSuccessionPlan data={data}/>*/}
        {/*    <FamBusinessSucSystemUse/>*/}
        {/*    <CoRullBasicPackage/>*/}
        {/*    <EmployeeWelfareFund/>*/}
        {/*    <EmployeeRewardSystem/>*/}
        {/*    <CompanyResearchInstitute/>*/}
        {/*    <VentureCertification/>*/}
        {/*    <ISOBizCertification/>*/}
        {/*    <CertificationStrategyByIndustry/>*/}
        {/*    {*/}
        {/*        (data.report.lc.employeeCnt ?? 50 >= 5) ?*/}
        {/*            <>*/}
        {/*                <LaborIssueByScale data={data} idx={0}/>*/}
        {/*                <LaborIssueByScale data={data} idx={1}/>*/}
        {/*            </>*/}
        {/*            : <LaborIssueByScale data={data}/>*/}
        {/*    }*/}
        {/*    <TightenWorkerProtectionRule/>*/}
        {/*    <HowToRespondCrackdown/>*/}
        {/*    <CorrectionRequestConsulting/>*/}


        {/* <FinanceOverview data={data}/>*/}
        {/* <FinanceAnalysis data={data}/>*/}
        {/* <FinanceAnalysis2 data={data}/>*/}
        {/* <SummaryBalanceSheet data={data}/>*/}
        {/* <SummaryIncomeStatement data={data}/>*/}
        {/* <IssueByLifeCycle data={data}/>*/}
        {/* <ManagementResult/>*/}
        {/* <ManagementResultContinue/>*/}
        {/*/!* 기업가치평가 *!/*/}
        {/*/!* <NeedEvaluation/> *!/*/}
        {/*<EvalUnlistedStock data={data}/>*/}
        {/* <UnlistedStockDetail data={data}/>*/}
        {/* <FutureValueSimulation data={data}/>*/}
        {/* <StockTransferCost data={data}/>*/}
        {/* <TransferUnlistedStockTax data={data}/>*/}
        {/*/!* 배당플랜 *!/*/}
        {/* <AssetManagementPlan/>*/}
        {/* <SurplusAnalysis data={data}/>*/}
        {/* <TaxSavingEffect data={data}/>*/}
        {/* <FamilyShareHolderDesign data={data}/>*/}
        {/* <UseExample data={data}/>*/}
        {/* <DividendUtilizationStrategy/>*/}
        {/*/!* 임원소득보상플랜 *!/*/}
        {/* <NeedSalaryCarePlan/>*/}
        {/* <CheckSeverancePay data={data}/>*/}
        {/* <SeverancePayDetail data={data}/>*/}
        {/* <CheckSeverancePay data={data} isRef={true}/>*/}
        {/* <SeverancePayTaxSaving data={data}/>*/}
        {/*<RetirementIncomeTaxCalMethod/>*/}
        {/*<TaxComparison/>*/}
        {/*<FourMajorInsurancePremium data={data}/>*/}
        {/*<AppropriateSalaryAnalysis data={data}/>*/}
        {/*<CeoSalaryDividendDesign data={data}/>*/}
        {/*/!* CEO유고시 리스크 관리 *!/*/}
        {/* <RiskManagement/>*/}
        {/* <EmergencyFundsAnalysis data={data}/>*/}
        {/* <MinimumSafeguards data={data}/>*/}
        {/* <RegularInsuranceCost/>*/}
        {/* <LifeInsuranceCost/>*/}
        {/*/!* 차명주식 솔루션 *!/*/}
        {/* <CheckBorrowedNameStock data={data}/>*/}
        {/* <ChkListForBorrowedNameStock/>*/}
        {/* <CompareTaxByMethod data={data}/>*/}
        {/* <StockRecoveryPlanCheck data={data} idx={0}/>*/}
        {/* /!*<StockRecoveryPlanCheck2 data={data}/>*!/*/}
        {/* <LowCostTransferTaxBasis data={data}/>*/}
        {/*<EstimatedTaxDetail data={data} idx={0}/>*/}
        {/*/!* 가지급금 솔루션 *!/*/}
        {/* <ProvisionalPaymentRisk />*/}
        {/* <ProvisionalPaymentAnalysis data={data}/>*/}
        {/* <ProvisionalPaymentSolution1/>*/}
        {/* <ProvisionalPaymentSolution2/>*/}
        {/*/!* 자기주식활용 솔루션 *!/*/}
        {/* <TreasuryStockUseExample/>*/}
        {/* <TreasuryStockRetirement/>*/}
        {/* <NewCapitalReduction/>*/}
        {/* 상속 및 가업승계 */}
        {/* <InheritanceTaxAnalysis data={data}/>*/}
        {/*<InheritanceTaxDetail data={data}/>*/}
        {/* <SecondInheritanceTaxAnalysis/>*/}
        {/*<SecondInheritanceTaxDetail data={data}/>*/}
        {/*<InheritancePortionCheck data={data}/>*/}
        {/* <ReducingInheritanceTaxEffect data={data}/>*/}
        {/* <FamilyBusinessSuccessionPlan/>*/}
        {/* <FamBusinessSucSystemUse/>*/}
        {/* 기업제도 정비 */}
        {/* <CorporateSystemReorganization/>*/}
        {/* 노무 컨설팅 */}
        {/* <LaborIssueByScale/>*/}
        {/* <LaborIssueByScale2/>*/}
        {/* <TightenWorkerProtectionRule/>*/}
        {/* <HowToRespondCrackdown/>*/}
        {/*<EmploymentSupportFund data={data} idx={0}/>*/}
        {/*<EmploymentSupportFund data={data} idx={1}/>*/}
        <SupportableGovernmentConsulting data={data}/>
        {/* 경정청구 컨설팅 */}
        {/* <CorrectionRequestConsulting/>*/}
    </>;
    return element;
}
const Document: any = PdfDocument
const Page: any = PdfPage

const PdfReport = ({data}: { data: CoReportDto }) => {
    return <Provider container={container}>
        <Document style={{fontFamily: 'NotoSansKR', fontSize: mFont}}>
            <Page size='A4' orientation='landscape' dpi={72 * 1330 / 842}>
                <ReportWrapper data={data} isPdf={true}/>
            </Page>
        </Document>
    </Provider>
}

const MainReportTest = observer(() => {
    const {loaded} = useFont('NotoSansKR');
    const {id, memberNo} = useParams();
    const coReportApi = useInjection(CoReportApi);
    const [data] = useInit<CoReportDto | null>(coReportApi.coReportV2, [id ?? "", memberNo ?? ""], null);
    const chartManager = useInjection(ChartManager);

    if (!loaded || !data) return <div>Loading...</div>;

    return <div >
        <div style={{
            width: '100%', backgroundColor: '#fff',
            zIndex: 100,
        }}>
            <PDFViewer height='1000px' className='app' style={{width: '90%'}} showToolbar={false}>
                <PdfReport data={data}/>
            </PDFViewer>
            {/* <Page size='A4' orientation='landscape' dpi={72 * 1330 / 842}>
            <ReportWrapper data={data} isPdf={false}/>
            </Page> */}
        </div>
        {/* {option !== '1' && <div style={{width: '100%'}}> */}
        {/* <div style={{width: '100%'}}>
           <ChartContainer document={<ReportWrapper data={data} isPdf={true} isChart={true}/>}/>
           {chartManager.isLoaded && <PdfReport data={data}/>}

           {chartManager.isLoaded && <PDFViewer height='1000px' className='app' style={{width: '100%'}} showToolbar={false}>
               <PdfReport data={data}/>
           </PDFViewer>}

        </div> */}
    </div>
})

export default MainReportTest