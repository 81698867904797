import {useInjection} from "inversify-react";
import {DialogManager} from "../../components/dialog-manager";
import PaymentWindowPop from "../../components/payment-window-pop";
import ProductListPop from "../../components/product-list-pop";

export const useOpenPaymentDialog = () => {
    const dialogManager = useInjection(DialogManager);

    const openRegularPayment = () => {
        dialogManager.open(ProductListPop, {});
    }

    const openAddonPayment = () => {
        dialogManager.open(PaymentWindowPop, {id:'ADD_CREDIT'});
    }

    return [openRegularPayment, openAddonPayment];
}
