import React from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import {Address} from "react-daum-postcode/lib/loadPostcode";

interface Props {
    onSelectAddress: (zipCode: string, addr1: string) => void;
    onClose: VoidFunction;
}

const Postcode = ({onSelectAddress, onClose}:Props) => {

    const handleComplete = (data:Address) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        onSelectAddress(data.zonecode, fullAddress);
    };

    const onCloseState = (state: string) => {
        if(state === 'COMPLETE_CLOSE') onClose();
    };

    return (
        <DaumPostcodeEmbed onComplete={handleComplete} onClose={onCloseState} style={{height: 600}}/>
    );
};

export default Postcode;