import styles from './payment-window-pop.module.scss';
import classNames from 'classnames/bind';
import {Field, Form, Formik} from "formik";
import usePromise from "../model/common/use-promise";
import {useInjection} from "inversify-react";
import {PrMemberPayApi} from "../model/payment/pr-member-pay-api";
import LottieComp from "./lottie-comp";
import React, {useEffect, useState} from "react";
import {useOpenRegisterCardDialog} from "../model/payment/open-register-card-hook";
import {formatNum} from "../util/str-util";
import {InputBox} from "../pages/customer/edit-common";
import {AuthStore} from "../model/auth/auth-store";
import {CashPaymentInfo, PubReceiptType} from "../model/payment/dto/cash-payment-info";
import {DialogManager} from "./dialog-manager";

const cx = classNames.bind(styles);

export type SelectCardType = 'SIMPLE' | 'NORMAL';

export type SelectCardMode = 'PAYMENT' | 'CHANGE';

type Prop = {
    selectMode: SelectCardMode;
    onClose: VoidFunction;
    onSelectCard: (cardType: SelectCardType, billingId?: string) => void;
    onSelectCashPay?: (cashPayInfo: CashPaymentInfo) => void;
    goodName?: string;
    price?: number;
    nextDate?: string;
    hideCash?: boolean;
}

const ChoosePayCardPop = ({
                              selectMode,
                              onClose,
                              onSelectCard,
                              onSelectCashPay,
                              goodName,
                              price,
                              nextDate,
                              hideCash
                          }: Prop) => {

    const prMemberPayApi = useInjection(PrMemberPayApi);
    const [loading, response, error, refresh] = usePromise(() => prMemberPayApi.getMemberBillingWithBank(), [prMemberPayApi]);
    const [openRegisterCardDialog] = useOpenRegisterCardDialog();
    const dialogManager = useInjection(DialogManager);
    const authStore = useInjection(AuthStore);
    const [selectBilling, setSelectBilling] = useState('');

    const showCashPayment = hideCash == null || !hideCash;

    useEffect(() => {
        if (response && response.val && response.val.billings) {
            if (response.val.billings.length > 0) {
                setSelectBilling(response.val.billings[0].billingId);
            }
        }

    }, [response]);

    if (loading || !response) {
        return <div className='loading-box'><LottieComp width='10.0rem' height='10.0rem' jsonFile='loading'/></div>
    }

    const onCompleteRegister = () => {
        refresh();
    }

    const billings = response.val!.billings;
    const bankInfo = response.val!.bank;

    // 우리은행 1005-783-818632 (주)런인베스트
    const bankStr = `${bankInfo.bankName} ${bankInfo.bankNum} ${bankInfo.bankHolder}`;

    return (
        <Formik initialValues={{
            cardType: 'SIMPLE',
            receiptType: 'CASHST',
            bankHolder: authStore.getMember()?.name ?? "",
            phoneNum: authStore.getMember()?.phone ?? "",
            targetBank: bankInfo,
            companyNum: '',
            email: authStore.getMember()?.email ?? ""
        }}
                onSubmit={(values) => {
                    if (values.cardType === "DEPOSIT") {
                        // 현금영수증 발급을 위한 휴대폰 번호를 입력해 주세요
                        const receiptType = values.receiptType as PubReceiptType

                        if (receiptType === "CASHST") {
                            if (values.phoneNum.length < 10) {
                                // 현금영수증 발급을 위한 휴대폰 번호를 입력해 주세요
                                dialogManager.alert("현금영수증 발급을 위한 휴대폰 번호를 입력해 주세요", '', () => {

                                });
                                return;
                            }
                        }

                        const cashPay: CashPaymentInfo = {
                            holder: values.bankHolder,
                            receiptType: values.receiptType as PubReceiptType,
                            idInfo: values.phoneNum,
                            companyNum: values.companyNum,
                            email: values.email,
                            targetBank: values.targetBank
                        };

                        if (onSelectCashPay) {
                            onSelectCashPay(cashPay);
                        }
                    } else {
                        if (values.cardType === 'SIMPLE' && !selectBilling) {
                            dialogManager.alert("간편 결제 카드를 선택해주세요", '', () => {
                            });
                            return;
                        }
                        onSelectCard(values.cardType as SelectCardType, selectBilling);
                    }
                    onClose();
                }}>
            {({values}) =>
                <Form>
                    <div className='full-background'>
                        <div className={`pop-inner ${cx('payment-window-pop', 'choose-pay-card-pop')}`}>

                            {selectMode === 'PAYMENT' &&
                                <p className={cx('title')}>결제방식 선택</p>
                            }

                            {selectMode === 'CHANGE' &&
                                <>
                                    <p className={cx('title')}>결제방식 변경</p>
                                    <p className={cx('sub-title')}>결제상품</p>
                                    <div className={cx('product-box')}>
                                        {goodName && <span>{goodName}</span>}
                                        {price && <span className={cx('bold')}>
                                            {formatNum({num: price, empty: '', type: 'txt'})}원</span>}

                                        {nextDate && <p>다음결제일 : {nextDate}</p>}
                                    </div>

                                    <p className={cx('sub-title')}>결제수단 선택</p>
                                </>

                            }

                            {selectMode === 'PAYMENT' &&
                                <div className={cx('radio-txt', values.cardType === 'SIMPLE' ? '' : 'bd-bt')}>
                                    <label className={cx('radio-btn')}><Field name='cardType' type='radio'
                                                                              value='SIMPLE'/>카드
                                        간편결제</label>
                                </div>
                            }
                            {selectMode === 'CHANGE' &&
                                <div className={cx('radio-txt')}></div>
                            }


                            {values.cardType === 'SIMPLE' &&
                                <div className={cx('add-card-area', selectMode === 'CHANGE' ? 'no-left-padding' : '')}>
                                    <div className={cx('regi-card-list')}>
                                        <div className={cx('scroll')}>
                                            {billings.map((billing) =>
                                                <div key={billing.billingId}
                                                     className={cx('card-box', 'card-registered', (selectBilling === billing.billingId ? 'on' : ''))}
                                                     onClick={(e) => setSelectBilling(billing.billingId)}>
                                                    <span>{billing.cardName}</span><span>**** **** **** **** {billing.cardNo}</span>
                                                </div>
                                                )}
                                        </div>
                                    </div>
                                    <div className={cx('card-box', 'add-btn')}
                                         onClick={() => openRegisterCardDialog(onCompleteRegister)}>
                                        <i></i><span>간편결제 카드 추가</span>
                                    </div>
                                    {billings.length === 0 &&
                                        <p className={cx('noti')}>※ 카드를 등록해두면, 다음 결제 시 간편하게 이용하실 수 있습니다.</p>}
                                </div>
                            }


                            {selectMode === 'PAYMENT' &&
                                <>
                                    <div className={cx('radio-txt', 'bd-bt')}>
                                        <label className={cx('radio-btn')}><Field name='cardType' type='radio'
                                                                                  value='NORMAL'/>일반결제</label>
                                    </div>
                                    {showCashPayment && <div className={cx('radio-txt')}>
                                        <label className={cx('radio-btn')}><Field name='cardType' type='radio'
                                                                                  value='DEPOSIT'/>무통장입금</label>
                                    </div>}

                                    {values.cardType === 'DEPOSIT' && <div className={cx('deposit')}>
                                        <InputBox title='입금계좌' inputWidth='28rem' value={bankStr}
                                                  inputboxCn={cx('input-box')} readOnly/>
                                        <InputBox title='입금자명' inputWidth='28rem' name='bankHolder'
                                                  inputboxCn={cx('input-box')}/>
                                        <div className={cx('receipt')}>
                                            <p className={cx('receipt-title')}>현금영수증</p>
                                            <label className={cx('radio-btn')}><Field name='receiptType' type='radio'
                                                                                      value='CASHST'/>개인소득공제용</label>
                                            <label className={cx('radio-btn')}><Field name='receiptType' type='radio'
                                                                                      value='TAX_BILL'/>세금계산서</label>
                                        </div>
                                        <div className={cx('input-num-area')}>
                                            {values.receiptType === 'CASHST' ?
                                                <InputBox title='휴대폰번호' name='phoneNum' inputWidth='21rem'
                                                          inputboxCn={cx('input-box')}/>
                                                : <><InputBox title='사업자번호' name='companyNum' inputWidth='21rem'
                                                            placeholder='-없이 사업자번호를 입력해 주세요'
                                                            inputboxCn={cx('input-box')}/>
                                                    <InputBox title='이메일 주소' name='email' inputWidth='21rem'
                                                              placeholder='세금계산서 발급용 이메일 주소 입력'
                                                              inputboxCn={cx('input-box')}/>
                                                </>
                                            }
                                        </div>
                                    </div>}
                                </>
                            }

                            <div className={cx('btn-area')}>
                                <button className={cx('close-btn')} type='button' onClick={() => {
                                    onClose()
                                }}>닫기
                                </button>
                                <button
                                    className={cx('payment-btn')}
                                    type='submit'>
                                    {selectMode === 'PAYMENT' && <><i></i>결제하기</>}
                                    {selectMode === 'CHANGE' && <>변경하기</>}

                                </button>
                            </div>
                        </div>
                    </div>
                </Form>
            }
        </Formik>
    );
}

export default ChoosePayCardPop;