import styles from './header.module.scss';
import classNames from 'classnames/bind';
import {Link} from 'react-router-dom';
import React, {useState} from 'react';
import {useInjection} from "inversify-react";
import {AuthStore} from "../../../model/auth/auth-store";

const cx = classNames.bind(styles);

const SubHeader = ({scrollY, type}: {scrollY: number, type?: string}) => {
    const [mobNavOpen, setMobNavOpen] = useState(false);
    const authStore = useInjection(AuthStore);

    const isLoggedIn = authStore.isLoggedIn();

    return (
        <div className={cx('header', scrollY > 0 && 'white')}>
            <div className={cx('header-inner')}>
                <h1 className={cx('logo')}><a href='/' title='home'><img src='/images/partners_home/logo.png'
                                                                         alt=''/></a></h1>
                <p className={cx('mobile-btn')} onClick={() => setMobNavOpen(true)}></p>
                <div className={cx('nav-flex-box', mobNavOpen && 'show')} onClick={() => setMobNavOpen(false)}>
                    <ul className={cx('nav-bar')}>
                        <li className={cx('mobile-nav-header')}>
                            <p className={cx('logo')}><img src='/images/partners_home/logo.png' alt=''/></p>
                            <button className={cx('close-btn')}></button>
                        </li>
                        <li className={cx('mobile-menu')}>
                            {   isLoggedIn ?
                                <Link className={cx('login-btn')} to='/partner-main' style={{color: '#5c67ff', fontWeight: 700}}>마이 페이지</Link>
                                : <Link className={cx('login-btn')} to={`/login/${type ?? ''}`} style={{color: '#5c67ff', fontWeight: 700}}>로그인</Link>
                            }
                        </li>
                        {/*<li className={cx('katalk-btn')}>*/}
                        {/*    <a href='/katalk'><i></i>카톡 단체발송 서비스</a>*/}
                        {/*</li>*/}
                        <li>
                            <Link to={{pathname: '/intro'}}>씨오파트너</Link>
                        </li>
                        <li>
                            <a href='/pricing'>가격정책</a>
                        </li>
                        <li>
                            <Link to={{pathname: '/intro/review/review-detail'}}>이용후기</Link>
                        </li>
                        <li>
                            <a href='https://www.learninvest.co.kr/u/exp/company' target='_blank' rel='noreferrer'
                               title='회사소개'>회사소개</a>
                        </li>
                        <li>
                            <a href='/cotalk/' target='_blank' title='씨오톡'>씨오톡</a>
                        </li>
                    </ul>
                    <div className={cx('btn-area')}>
                        {isLoggedIn && <Link className={cx('login-btn')} to='/partner-main'>마이 페이지</Link>}
                        {!isLoggedIn && <Link className={cx('login-btn')} to={`/login/${type ?? ''}`}>로그인</Link>}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SubHeader;