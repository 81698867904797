import React from 'react';
import TableTitle from '../../../components/table-title';
import {usePdfComponents} from '../pdf-hooks';
import {SummaryReport} from '../../../model/inquiry/dto/summary-report';
import {
    formatBzno,
    formatNum,
    formatPhoneNum,
    formatPid,
    hyphen,
    toBirthAndAgeEmptyMask,
    ymdToDateString
} from '../../../util/str-util';
import {getColT, getRowT} from '../../../styles/styleSheet';
import {getCompanyInfo} from '../report-util';
import {outputReportTitle} from "../report-custom-util";
import {PrCorpRegRoot} from "../../../model/customer/dto/pr-corp-reg-root";
import { PrCompanyInfo } from '../../../model/customer/dto/pr-company-info';
import { RepInfo } from '../../../model/customer/dto/rep-info';

const CompanyOverview = ({summary, mask, corpReg, companyInfo, rep, enpTyp}: { summary: SummaryReport, mask?: boolean, corpReg?: PrCorpRegRoot, companyInfo?: PrCompanyInfo, rep?: RepInfo, enpTyp?: string }) => {
    const list0 = [
        ['사업자번호', 'BZNO', '법인(주민)번호', 'CONO_PID'],
        ['설립일자', 'ESTB_DT', '기업규모', 'ENP_SZE'],
        //['대표자명', '', '대표이사 생년월일(나이)', ''],
        ['설립형태', 'ESTB_FCD', '여성기업 여부', ''],
        ['기업형태', 'IPO_CD', '결산월', 'ACCT_EDDT'],
        ['상시근로자수', 'LABORER_SUM', '성실신고확인대상', 'ENP_SCD'],
        ['팩스번호', 'FAX_NO', '전화번호', 'TEL_NO'],
        ['홈페이지', 'HPAGE_URL', '이메일', 'EMAIL']
    ];

    list0.forEach((value) => {
        if (value[1]) {
            if (value[1] === "IPO_CD") {
                let str = summary.KED5002[value[1]];
                if (summary.KED5002["GOPUB"]) {
                    str += `, ${summary.KED5002["GOPUB"]}`;
                }
                value[1] = str;
            }
            else {
                value[1] = summary.KED5002[value[1]];
            }
        }

        if (value[3]) {
            value[3] = summary.KED5002[value[3]];
        }
    });

    if (companyInfo?.bno) list0[0][1] = companyInfo?.bno;

    if (companyInfo?.homepage && companyInfo?.homepage.length > 0) list0[6][1] = companyInfo?.homepage;
    
    if (companyInfo?.sincereReportYn === "Y") {
        list0[4][3] = "해당";
    } else {
        list0[4][3] = "";
    }

    if (companyInfo?.estbFcd) {
        list0[2][1] = companyInfo?.estbFcd;
    }

    list0[0][1] = formatBzno(list0[0][1]);
    list0[0][3] = formatPid(list0[0][3]);
    list0[1][1] = ymdToDateString(list0[1][1]);

    let femaleCompany = companyInfo?.femaleCompany;

    if(rep !== null && rep) {
        if(rep?.female !== null && typeof rep?.female !== "undefined" && femaleCompany !== rep?.female) {
            femaleCompany = rep.female;
        }
    }

    let femaleCompanyCertInfo = "";

    if(femaleCompany) {
        if(companyInfo?.femaleCompanyCertYn) {
            femaleCompanyCertInfo = "여성기업(인증)";
        } else {
            femaleCompanyCertInfo = "여성기업(미인증)";
        }
    } else {
        if(companyInfo?.femaleCompanyCertYn) {
            femaleCompanyCertInfo = "여성기업(인증)";
        }
    }

    list0[2][3] = femaleCompanyCertInfo;

    if(companyInfo?.corpType) list0[3][1] = companyInfo.corpType;
    if(companyInfo?.settleMonth) list0[3][3] = companyInfo.settleMonth;

    // if (summary.REP_INFO.length > 0) {
    //     const [birth0, female] = toBirthAndSex(summary.REP_INFO[0].PID);
    //     if (birth0) {
    //         list0[2][3] = female ? '여성기업' : '';
    //     }
    // }

    if (list0[3][3]) list0[3][3] = parseInt(list0[3][3].substring(0, 2)) + '월';
    if (list0[4][1]) list0[4][1] = list0[4][1] + '명';
    list0[4][3] = list0[4][3] === '정상' ? '' : list0[4][3];
    list0[5][1] = formatPhoneNum(list0[5][1]);
    list0[5][3] = formatPhoneNum(list0[5][3]);



    //대표자 정보 삽입
    summary.REP_INFO.forEach((pi, index) => {
        list0.splice(2 + index, 0,
            ['대표자명', (pi.NAME ?? '') + (pi.TTL ? '/' + pi.TTL : ''),
                '대표이사 생년월일', toBirthAndAgeEmptyMask(pi.PID ? pi.PID : rep?.birthday ?? "", mask ?? false)]);
    });
    list0.forEach((value) => {
        value[1] = hyphen(value[1]);
        value[3] = hyphen(value[3]);
    });

    const list1 = [
        ['업종', 'BZC_CD', ''],
        ['주요제품', 'PD_NM', ''],
        ['주소(도로명)', 'LOC_RDNM_ADDRA', ''],
        ['기업인증현황', '', ''],
        ['산업재산권현황', '', '']
    ];
    const list2 = getCompanyInfo(list1, 2, summary);

    if(companyInfo?.mainProduct) list1[1][1] = companyInfo?.mainProduct;
    if(companyInfo?.addr) list1[2][1] = companyInfo?.addr!;

    const {View, Text, isPdf} = usePdfComponents();
    const rowT = getRowT(isPdf);
    const colT = getColT(isPdf);

    let stDate = "";

    if (corpReg != null) {
        stDate = '법인등기부등본 조회 (기준일 ' + ymdToDateString(corpReg.searchDate) + ')';
    }
    else if (summary.KED5012) {
        stDate = '기준일 ' + ymdToDateString(summary.KED5012?.['STD_DT']);
    }

    return (
        <>
            <TableTitle title='기업일반'>
                <View style={[rowT.flexWrap]}>
                    {list0.map((it, index) =>
                        <View key={index} style={rowT.flexLine}>
                            <View style={rowT.titleCover}><Text style={rowT.title}>{it[0]}</Text></View>
                            <View style={rowT.descCover}><Text style={rowT.desc}>{it[1]}</Text></View>
                            <View style={[rowT.titleCover, rowT.title2Cover]}>
                                <Text style={rowT.title}>{it[2]}</Text></View>
                            <View style={rowT.descCover}><Text style={rowT.desc}>{it[3]}</Text></View>
                        </View>)}
                    {list1.map((it, index) =>
                        <View key={index} style={rowT.flexLine}>
                            <View style={rowT.titleCover}><Text style={rowT.title}>{it[0]}</Text></View>
                            <View style={rowT.descCover}><Text style={rowT.desc}>{it[1]}</Text></View>
                        </View>)}
                </View>
            </TableTitle>
            {
                enpTyp !== "2" ?
                <TableTitle title='자본금 및 주식 발행사항' dateOrUnit={stDate}>
                    <View style={[colT.flexWrap]}>
                        <View style={[colT.flexLine, colT.headBox]}>
                            <Text style={colT.flexHead}>자본금</Text>
                            <Text style={colT.flexHead}>{outputReportTitle('totalIssStkCn', summary.KED5002['ENP_FCD'])}</Text>
                            <Text style={colT.flexHead}>보통주</Text>
                            <Text style={colT.flexHead}>우선주</Text>
                            <Text style={colT.flexHead}>상환전환우선주</Text>
                            <Text style={colT.flexHead}>{outputReportTitle('stkFvalAm', summary.KED5002['ENP_FCD'])}</Text>
                        </View>
                        <View style={colT.flexLine}>
                            <Text style={colT.flexBody}>{formatNum({num: list2[0], unit: '원'})}</Text>
                            <Text style={colT.flexBody}>{formatNum({num: list2[1], unit: summary.CS.unit})}</Text>
                            <Text style={colT.flexBody}>{formatNum({num: list2[2], unit: summary.CS.unit})}</Text>
                            <Text style={colT.flexBody}>{formatNum({num: list2[3], unit: summary.CS.unit})}</Text>
                            <Text style={colT.flexBody}>{formatNum({num: list2[4], unit: summary.CS.unit})}</Text>
                            <Text style={colT.flexBody}>{formatNum({num: list2[5], unit: '원'})}</Text>
                        </View>
                    </View>
                </TableTitle>
                : null
            }
            {
               (companyInfo?.investStage || companyInfo?.investAmount) &&
                <TableTitle title='투자유치 현황'>
                    <View style={[rowT.flexWrap]}>
                        {companyInfo?.investStage &&
                            <View style={rowT.flexLine}>
                                <View style={rowT.titleCover}><Text style={rowT.title}>투자단계</Text></View>
                                <View style={rowT.descCover}><Text style={rowT.desc}>{companyInfo?.investStage}</Text></View>
                            </View>
                        }
                        {companyInfo?.investAmount &&
                            <View style={rowT.flexLine}>
                                <View style={rowT.titleCover}><Text style={rowT.title}>누적투자유치액</Text></View>
                                <View style={rowT.descCover}><Text style={rowT.desc}>{formatNum({num: companyInfo?.investAmount, unit: '원'})}</Text></View>
                            </View>
                        }
                    </View>
                </TableTitle>
            }
        </>
    );
};

export default CompanyOverview