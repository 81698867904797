import {injectable} from 'inversify';
import {Customer} from './dto/customer';

@injectable()
export class CustomerStore {
  private customer!: Customer;

  // private selectReportId!: string;

  setCustomer(data: Customer) {
    this.customer = data;
  }

  getCustomer() {
    return this.customer;
  }
}