import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {ApiResult} from '../api-result';
import {CoReportDto} from './dto/co-report-dto';
import {Attachment} from '../customer/dto/attachment';
import {CoReportExtraInfoDto} from "./dto/co-report-extra-info-dto";
import {PrMemberOptDtl} from "../prMember/dto/pr-member-opt";

@injectable()
export class CoReportApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    coReportV2 = async (customerId: string) : Promise<ApiResult<CoReportDto>> => {
        return await this.apiCall.fetch<ApiResult<CoReportDto>>(`/customer/coReport/v2/${customerId}`);
    }

    coSoleReport = async (customerId: string) : Promise<ApiResult<CoReportDto>> => {
        return await this.apiCall.fetch<ApiResult<CoReportDto>>(`/customer/coReport/sole/${customerId}`);
    }

    saveCoReport = async (customerId: string, fileId: string) => {
        return await this.apiCall.post<ApiResult<Attachment[]>>(`/customer/coReport/${customerId}/${fileId}`);
    }

    deleteCoReport = async (customerId: string, fileId: string) => {
        return await this.apiCall.delete<ApiResult<Attachment[]>>(`/customer/coReport/${customerId}/${fileId}`);
    }

    getCoReportExtraInfo = async () => {
        return await this.apiCall.fetch<ApiResult<CoReportExtraInfoDto>>(`/customer/coReport/extra`);
    }

    updateReportOutput = async (reportOutput: {[key: string]: PrMemberOptDtl}, enpTyp: string) => {
        return await this.apiCall.json<ApiResult<string>>(`/customer/coReport/extra/reportOutput/${enpTyp}`, reportOutput);
    }
}