import React, {useEffect, useState} from 'react';
import {useInjection} from 'inversify-react';
import {ChartManager} from './chart-manager';
import {ChartJs, ChartJsProps} from './chart-js';
import {mergeObject} from '../util/util';

export const ChartImageProducer = ({options, ...props}: ChartJsProps) => {
    const chartManager = useInjection(ChartManager);
    const [id, setId] = useState(-1);

    useEffect(() => {
        if (id === -1) {
            const id2 = chartManager.getId();
            const chart = <ChartJs key={id2} {...props}
                                   options={mergeObject(options, {
                                       devicePixelRatio: 3,
                                       animation: {
                                           duration: 0,
                                           onComplete: (event: any) => {
                                               chartManager.setData(id2, event.chart.toBase64Image());
                                               setId(id2);
                                           }
                                       }
                                   })}/>
            chartManager.addChart(chart);
        }
    }, [id, props, chartManager, options]);

    return <>{id}</>
}
