import classnames from "classnames/bind";
import styles from "./mna-introduction-fee-policy-pop.module.scss";

const cx = classnames.bind(styles);

const MnaIntroductionFeePolicyPop = ({onClose}: {onClose: VoidFunction}) => {
    return(
        <div className={`full-background`}>
            <div className={`pop-inner ${cx('fee-policy-pop')}`}>
                <div className={cx('title-box')}>
                    M&A중개 소개수수료 정책
                </div>
                <div className={cx('content')}>
                    <p className={cx('top')}>
                        씨오파트너 M&A플랫폼을 통해 매각희망 고객사를 등록(소개)하면,<br/>해당 기업의 M&A거래 체결시, <span>중개수수료에 연동된 소개수수료</span>를 지급합니다.<br/><br/>

                        소개자의 역할은 씨오파트너 사이트에 M&A니즈를 등록하고, 고객사 대표와 M&A 어드바이저와의 1차 인터뷰 미팅을 연결해 주시면 완료됩니다.
                    </p>
                    <p className={cx('bulb-title')}>💡 소개 수수료는 얼마인가요?</p>
                    <table className={cx('table')}>
                        <thead>
                            <tr>
                                <th>씨오파트너 회원유형</th>
                                <th>소개수수료율<span>M&A중개수수료 기준</span></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>M&A니즈 등록 (검토가능기업)</td>
                                <td>10%</td>
                            </tr>
                            <tr>
                                <td>M&A니즈 등록 (매각희망기업)</td>
                                <td>15%</td>
                            </tr>
                            <tr>
                                <td>M&A파트너 선정시</td>
                                <td>20~30%</td>
                            </tr>
                        </tbody>
                    </table>
                    <div className={cx('example')}>
                        <p>예시</p>
                        <p>씨오파트너 회원이 매각희망 고객을 소개할 경우, 딜 사이즈 100억짜리 M&A 거래가 체결된다면 중개수수료 3%의 15%에 해당하는 4,500만원을 소개수수료로 지급받게 됩니다. (M&A파트너의 경우 소개수수료 6,000만원 지급)<br/><br/></p>
                    </div>
                    <p className={cx('bulb-title')}>💡 M&A파트너가 되려면 어떻게 해야 하나요?</p>
                    <div className={cx('how-to-do')}>
                        <p className={cx('list')}><i>•</i>씨오파트너 멤버쉽 회원 중 분기별 1회 진행되는 M&A파트너 양성과정을 이수하고, 일정 자격요건을 갖춘 경우 M&A파트너 자격을 부여합니다.</p>
                        <p className={cx('list')}><i>•</i>M&A파트너가 되면  더 높은 소개수수료율 적용은 물론  M&A 실무특강, 전문가 네트워크, 퀄리티 높은 M&A 영업지원 서비스 등 멤버쉽 혜택을 집중적으로 제공받게 됩니다.</p>
                        <p className={cx('list','info')}><i></i>※ 자세한 내용은 런인베스트 아카데미 &gt; M&A파트너 양성과정을 참조해 주세요.</p>
                    </div>
                </div>
                <div className={cx('btn-area')}>
                    <button onClick={onClose} className={`close-btn`} type='button'>닫기</button>
                </div>
            </div>
        </div>
    );
}

export default MnaIntroductionFeePolicyPop;