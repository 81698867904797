import styles from './review.module.scss';
import classNames from 'classnames/bind';
import React, {forwardRef} from "react";
import {Link} from "react-router-dom";
import {CoReviewDto} from "../../../model/extra/dto/co-review-dto";

const cx = classNames.bind(styles);

export const ReviewBox = ({data, isHome = false}: {data: CoReviewDto, isHome?: boolean}) => {

    return(
        <div className={cx('review-box')}>
            <p className={cx('title' , isHome ? 'titleH' : '')}>{data.title}</p>
            <p className={cx('star', data.color)}>
                {[...Array(5)].map((_, i) =>
                    i + 1 <= data.starNum ?
                    <span key={i} className={cx('full')}></span>
                        : i !== data.starNum && i < data.starNum ?
                          <span key={i} className={cx('half')}></span> : <span key={i}></span>
                )}
            </p>
            <p className={cx('customer-info', data.color)}>
                <i className={cx('cus-img', data.color)} style={{backgroundImage: `url(/images/partners_home/i_${data.img}.png)`}}></i>
                <span className={cx('txt-area')}>
                    <span className={cx('bold')}>{data.companyName}</span>
                    <span>{data.customerName}</span>
                </span>
            </p>
            <p className={cx('txt', isHome ? 'txt-control' : '')}>
                {data.review.split('|').map((txt, i) =>
                    i % 2 === 0 ?
                        <span key={i}>{txt}</span>
                        : <span key={i} className={cx('bold')}>{txt}</span>
                )}
            </p>
            <p className={cx('date')}>{data.date}</p>
        </div>
    );
}

export interface testProps {
    review: CoReviewDto[]
}

const Review = forwardRef<HTMLDivElement, testProps>(({ review }: { review: CoReviewDto[] }, ref) => {
    // const extraApi = useInjection(ExtraApi);
    // const [review, setReview] = useState<CoReviewDto[]>();

    // useEffect(() => {
    //     (async () => {
    //         try {
    //             const result = await extraApi.getCoReview();
    //             if(result && result.data.val) {
    //                 let reviewList = result.data.val.filter((review) => review.active === "Y").sort((a, b) => a.priority - b.priority);
    //                 let reviewList2 = reviewList.sort((a,b) => Date.parse(a.date) - Date.parse(b.date));
    //                 setReview(reviewList2.reverse());

    //             }
    //         } catch (e) {
    //             console.log(e);
    //         }
    //     })();
    // },[extraApi]);

    if(!review) return <div></div>;

    return(
        <div className={cx('review')} ref={ref}>
            <div className='partners-section-inner'>
                <p className='partners-category ver2'>이용 후기</p>
                <h3 className='partners-section-title gap2'>
                    확신을 가져다주는<br/>씨오파트너 실제 후기
                </h3>
                <p className={`partners-sub-desc underBtn`}>
                    먼저 씨오파트너를 경험한<br className={cx('mobile-bk')}/> 실제 사용자들의 만족도 높은<br className={cx('mobile-bk')}/> 후기를 확인해 보세요.
                </p>
                <Link to='/intro/review/review-detail' className={cx('review-all')}>
                    <span>전체 후기</span> 보러가기
                </Link>
                <div className={cx('grid-box')}>
                    {
                        review && review.slice(0, 4).map((it) =>
                            <ReviewBox key={it.no} data={it} isHome={true}/>
                        )
                    }
                </div>
            </div>
        </div>
    );
});

export default Review;