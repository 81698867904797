import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {CommonCode} from "./dto/common-code";

@injectable()
export class CommonCodeApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    getCodes = async (type: string) => {
        return await this.apiCall.fetch<CommonCode[]>(`/api/cm/code/code/${type}`);
    }

    getChildCodes = async (type: string, parentCode: string) => {
        return await this.apiCall.fetch<CommonCode[]>(`/api/cm/code/code/${type}/${parentCode}`);
    }
}