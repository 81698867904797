import LottieComp from "./lottie-comp";
import React from "react";

const WholePageLoading = () => {
    return(
        <div className={`full-background`} style={{display: "flex", alignItems: 'center', justifyContent: 'center', backgroundColor: "#fff"}}>
            <LottieComp width='10rem' height='10rem' jsonFile='loading'/>
        </div>
    );
}

export default WholePageLoading;