import {StyleSheet} from '@react-pdf/renderer';
import React from 'react';
import {usePdfComponents} from '../../pdf-hooks';

interface GrayTxtBoxProps {
    children: React.ReactNode;
    halfW?: boolean;
}

const GrayTxtBox = ({children, halfW = false}: GrayTxtBoxProps) => {
    const {View, Text} = usePdfComponents();

    const styles = StyleSheet.create({
        txtBox: {
            width: '100%',
            padding: halfW ? '20px 19px 20px' : '20px 26px 20px',
            backgroundColor: '#eee',
            borderRadius: 8,
            lineHeight: 1.42857
        },
        txt: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            fontSize: 16,
        }
    });

    return (
        <View style={styles.txtBox}>
            <Text style={styles.txt}>{children}</Text>
        </View>
    );
};

export default GrayTxtBox;