import classNames from "classnames/bind";
import styles from "./pricing-footer.module.scss";
import {Link} from "react-router-dom";

const cx = classNames.bind(styles);

const PricingFooter = () => {
    return(
        <div className={cx('pricing-footer')}>
            <div className={cx('footer-inner')}>
                <div className={cx('top')}>
                    <img className={cx('logo')} src='/images/logo_learninvest.png' alt='런인베스트 로고'/>
                    <p className={cx('policy')}>
                        <a href=''>고객센터</a>
                        <a href=''>블로그</a>
                        <Link to='/terms/terms2'>개인정보 처리방침</Link>
                    </p>
                </div>
                <div className={cx('address')}>
                    <span>주식회사 런인베스트</span>|<span></span><span>대표이사 박세라</span><br/>
                    <span>서울특별시 중구 남대문로5길 47, 903호</span><span>|</span><span>전화번호: 02-3445-8867</span><span>|</span><span>팩스번호 : 02-3445-8860</span><br/>
                    <span>사업자등록번호: 471-87-00222</span><span>|</span><span>통신판매번호: 제2019-서울중구-1522호</span>
                </div>
                <div className={cx('bottom')}>
                    <p>Copyright© (주)런인베스트 LearnInvest Co., Ltd. All rights Reserved</p>
                    <p className={cx('sns-box')}>
                        <a href='https://www.instagram.com/cotalkbylearninvest/' className={cx('sns','insta')}></a>
                        <a href='https://www.facebook.com/cotalkbylearninvest/' className={cx('sns','facebook')}></a>
                        <a href='' className={cx('sns','appstore')}></a>
                        <a href='' className={cx('sns','playstore')}></a>
                    </p>
                </div>
            </div>
        </div>
    );
}

export default PricingFooter;