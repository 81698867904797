import {useInjection} from "inversify-react";
import {DialogManager} from "../../components/dialog-manager";
import {useRef} from "react";
import LoadingDialog from "../../components/loading-dialog";
import {useRegisterCard} from "./payment-hook";

export const useOpenRegisterCardDialog = () => {
    const dialogManager = useInjection(DialogManager);
    const [openRegisterCard] = useRegisterCard()

    const loadingKey = useRef(0);

    const openLoading = () => {
        loadingKey.current = dialogManager.open(LoadingDialog, {aniW: '10.0rem', aniH: '10.0rem', jsonFile: 'loading', title: '잠시만 기다려 주세요.'});
    }

    const onPaymentError = (message: string, errorCode: string) => {
        dialogManager.close(loadingKey.current);
        dialogManager.alert('등록실패', message);
    }

    const openRegisterCardDialog = (onComplete: VoidFunction) => {
        // 결제 수단 변경 작업 시작
        openRegisterCard(openLoading, () => {
            dialogManager.close(loadingKey.current);
            onComplete();
        },onPaymentError);

    };

    return [openRegisterCardDialog];
}