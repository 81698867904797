export interface SupportConsulting {
    title: string;
    desc: string;
    laborerSum: string;
    min: number;
    max?: number;
    period?: number;
    field?: string;
    price: string;
}

export const consultingTypeList: SupportConsulting[] = [
    {
        title: '안전보건 관리체계 구축 컨설팅',
        desc: '중대재해처벌법 시행을 앞두고 중소기업의 안전 보건관리체계 구축•이행을 위한 컨설팅 지원 (50인 미만 사업장 중심)',
        laborerSum: '근로자 수 |5~299인|만 가능',
        min: 5,
        max: 299,
        period: 3,
        price: '30인 미만 전액 무료'
    },
    {
        title: '유연근무 종합컨설팅',
        desc: '유연근무(재택, 시차, 선택근무 등)가 일상적인 근로형태로 정착될 수 있도록 제도화를 위한 컨설팅 지원',
        laborerSum: '근로자 수 |10인 이상|만 가능',
        min: 10,
        price: '전액 무료'
    },
    {
        title: '일터혁신 컨설팅 - 통합패키지형',
        desc: '기업의 일터에서 발생하는 주요 이슈(HR·ER)를 해소하기 위한 맞춤형 컨설팅을 지원함으로써 사업장의 경쟁력을 강화하고자 함',
        field: '컨설팅 분야: 총 10개 영역 중 3개까지 선택 가능',
        laborerSum: '근로자 수 |30 ~ 49인|만 가능',
        min: 30,
        max: 49,
        price: '전액 무료'
    },
    {
        title: '일터혁신 컨설팅 - 일반컨설팅',
        desc: '기업의 일터에서 발생하는 주요 이슈(HR·ER)를 해소하기 위한 맞춤형 컨설팅을 지원함으로써 사업장의 경쟁력을 강화하고자 함',
        field: '컨설팅 분야: 총 10개 영역 중 3개까지 선택 가능',
        laborerSum: '근로자 수 |50인 이상|만 가능',
        min: 50,
        price: '전액 무료'
    },
];