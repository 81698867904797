import {
    PrCrChangeDate,
    PrCrMultiVal,
    PrCrRegisterDate
} from "../../../model/customer/dto/corpReg/corp-reg-common";
import {ymdToDateString} from "../../../util/str-util";
import ReactPDF from "@react-pdf/renderer";
import {useCorporateRegStyle} from "./corporate-regi-context";
import {usePdfComponents} from "../pdf-hooks";
import React from "react";

export function outputPrCrRegisterStr(registerDate?: PrCrRegisterDate): string {
    if (!registerDate || !registerDate!.registrationDate) return '';

    return `${ymdToDateString(removeCancelStr(registerDate.registrationDate))} ${registerDate.note}`;
}

export function outputPrCrChangeStr(registerDate?: PrCrChangeDate): string {
    if (!registerDate || !registerDate!.changeDate) return '';

    return `${ymdToDateString(removeCancelStr(registerDate.changeDate))} ${registerDate.note}`;
}

export const getTextIndent = (s: string, depStyles: ReactPDF.Styles) => {
    if (!s) return {};
    if (s.substring(0, 1) !== ' ') return {};

    const findSpace = /(^\s*)/
    const result = s.match(findSpace);

    if (!result) return {};

    switch (Math.ceil(result[0].length / 2)) {
        case 0:
            return {};
        case 1:
            return depStyles.dep1;
        case 2:
            return depStyles.dep2;
        case 3:
            return depStyles.dep3;
        default:
            return depStyles.dep4;
    }
}

export const isCancelStr = (s?: string): boolean => {
    if (!s) return false;
    return (s.startsWith("!"));
}

export const removeCancelStr = (s?: string): string => {
    if (isCancelStr(s)) {
        return s!.replaceAll("!", "");
    }
    return s ?? "";
}


export const RegisterDateInfo = ({s, rowT}:{s:string, rowT: ReactPDF.Styles}) => {
    const depStyles = useCorporateRegStyle();
    const {View, Text} = usePdfComponents();
    const chkChar0 = /\<[0-9]{4}\.[0-9]{2}\.[0-9]{2}\s+[가-힣]{2,6}\s+[0-9]{4}\.[0-9]{2}\.[0-9]{2}\s+[가-힣]{2,6}\>/g;
    const chkChar1 = /[0-9]{4}\.[0-9]{2}\.[0-9]{2}\s+[가-힣]{2,6}/g;
    const chkChar2 = /[0-9]{4}[\s년\s]+[0-9]{2}[\s월\s]+[0-9]{2}[\s일\s]+[가-힣]{2,6}/g;

    if (chkChar2.test(s)) {
        const text = s.match(chkChar2)?.[0].trim();

        return <View style={[rowT.desc, {display: 'flex', flexDirection: 'row'}]}>
            <Text style={[getTextIndent(s, depStyles), isCancelStr(text) ? depStyles.cancelLine : {}]}>{removeCancelStr(text)}</Text>
            <Text style={depStyles.dep3}>{s.match(chkChar2)?.[1]}</Text>
        </View>
    }
    else if (chkChar0.test(s)) {
        const text = s.split('<')[0].trim();

        return <View style={[rowT.desc, {display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}]}>
            <Text style={[getTextIndent(s, depStyles), isCancelStr(text) ? depStyles.cancelLine : {}]}>{removeCancelStr(text)}</Text>
            <Text>&lt; {s.match(chkChar0)?.[0].match(chkChar1)?.[0]}&nbsp;&nbsp;&nbsp;&nbsp;{s.match(chkChar0)?.[0].match(chkChar1)?.[1]} &gt;</Text>
        </View>
    }

    return <Text style={[rowT.desc, getTextIndent(s, depStyles), isCancelStr(s) ? depStyles.cancelLine : {}]}>{removeCancelStr(s)}</Text>;
};

export const OneInfos = ({title, val, rowT}: { title: string, val: string[] | undefined, rowT: ReactPDF.Styles}) => {
    const {View, Text} = usePdfComponents();
    const depStyles = useCorporateRegStyle();

    if (!val || val!.length === 0) return null;

    return (
        <View style={rowT.flexLine}>
            <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>{title}</Text></View>
            <View style={rowT.descCover}>
                {val!.map((s, idx) => <RegisterDateInfo key={idx} s={s} rowT={rowT}/>)}
            </View>
        </View>
    );
};

export const MultiInfos = ({title, multiVal, rowT}: { title: string, multiVal: PrCrMultiVal[] | undefined, rowT: ReactPDF.Styles}) => {
    const {View, Text} = usePdfComponents();
    const depStyles = useCorporateRegStyle();

    if (!multiVal || multiVal!.length === 0) return null;

    return (
        <View style={rowT.flexLine}>
            <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>{title}</Text></View>
            <View style={[rowT.descCover]}>
                {multiVal.map(({values}, idx) =>
                    <React.Fragment key={idx}>
                        {idx > 0 && <Text>&nbsp;</Text>}
                        {values?.map((s, idx) => <RegisterDateInfo key={idx} s={s} rowT={rowT}/>)}
                    </React.Fragment>)}
            </View>
        </View>
    );
};

export const MultiStrInfos = ({title, multiVal, rowT}: { title: string, multiVal: string[] | undefined, rowT: ReactPDF.Styles}) => {
    const {View, Text} = usePdfComponents();
    const depStyles = useCorporateRegStyle();

    if (!multiVal || multiVal!.length === 0) return null;

    return (
        <View style={rowT.flexLine}>
            <View style={[rowT.titleCover, depStyles.titleCover]}><Text style={rowT.title}>{title}</Text></View>
            <View style={[rowT.descCover]}>
                {multiVal.map((s, idx) =>
                    <Text key={idx} style={[rowT.desc, getTextIndent(s, depStyles), isCancelStr(s) ? depStyles.cancelLine : {}]}>{removeCancelStr(s)}</Text>
                )}
            </View>
        </View>
    );
};

