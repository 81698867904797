import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {makeSearchDataQs} from "../../util/str-util";
import axios from "axios";
import { KktSendMsgDto } from "./dto/kkt-send-msg-dto";
import {useInjection} from "inversify-react";
import {DialogManager} from "../../components/dialog-manager";
import { log } from "console";

@injectable()
export class CallKktAutoApi {
    @inject(DialogManager)
    private _dialogManager!: DialogManager;

    private _instance = axios.create();

    constructor() {
        this._instance.interceptors.response.use(function (response) {
            return response;
        }, (error) => {
            if (!error.response.data) {
                this._dialogManager.alert('카카오톡 자동 발송 프로그램을\n실행 후 이용해 주세요.');
            } else {
                console.log(error);
                if(error.response.status === 404) {
                    this._dialogManager.alert('새로운 업데이트 버전이 있습니다.\n새로 설치 후 이용 가능합니다.', '',  () => {
                        window.location.href = '/katalk/KktAutoSetup.msi';
                    },'최신 버전 다운로드');
                }
            }
            return Promise.reject(error);
        });
    }

    callKktAutoState = async ()=> {
       return await this._instance.post(process.env.REACT_APP_KKT_URL + '/state');
    }

    callKktFriends = async (keyword?:string, start?:string, count?:string) => {
        const data = {
            c: "?",
            s: "?",
            name: keyword,
            start: start,
            count: count
        };

        return await this._instance.post(process.env.REACT_APP_KKT_URL + "/contacts/start", makeSearchDataQs(data));
    }

    callCurrentFriends = async () => {
        return await this._instance.post(process.env.REACT_APP_KKT_URL + '/contacts/current', "c=1&s=1");
    }

    getKktFriends = async () => {
        return await this._instance.post(process.env.REACT_APP_KKT_URL + '/contacts/get', "c=1&s=1");
    }

    stopKktFriends =async () => {
        return await this._instance.post(process.env.REACT_APP_KKT_URL + '/contacts/stop', "c=1&s=1");
    }

    sendStartMessage = async (sendMsgList: KktSendMsgDto[], filename: string, filedata: string) => {
        const data = {
            c: "1",
            s: "1",
            msg: JSON.stringify(sendMsgList),
            filename: filename,
            filedata: filedata
        };

        let searchData = makeSearchDataQs(data);

        if(filename === "") {
            searchData += "&filename=";
        }

        if(filedata === "") {
            searchData += "&filedata=";
        }

        return await this._instance.post(process.env.REACT_APP_KKT_URL + '/send/start', searchData);
    }

    saveFailedLog = async () => {
        return await this._instance.post(process.env.REACT_APP_KKT_URL + '/failed', "c=1&s=1");
    }
}