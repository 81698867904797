import {StyleSheet} from '@react-pdf/renderer';
import {mFont} from '../../../styles/report-font';
import {usePdfComponents} from '../pdf-hooks';
import {ReactNode} from 'react';
import {useReportCanvasControllerContext, useReportCreatePropContext} from "../../co-report-hook";

interface MainReportContainerProps {
    children: ReactNode;
    reportKey: string
}

const MainReportContainer = ({children, reportKey}: MainReportContainerProps) => {
    const {View, Text, Body, Image, isPdf} = usePdfComponents();
    const reportCreateProp = useReportCreatePropContext();
    const reportCanvasController = useReportCanvasControllerContext();

    const styles = StyleSheet.create({
        wrapper: {
            position: 'relative',
            width: 1330,
            height: 940
        },
        container: {
            position: 'relative',
            width: 1242, //1330
            height: 851, //940
            margin: '48px 44px 40px',
            borderBottom: '1px solid #eee',
            fontSize: mFont,
        },
        flexBox: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            height: '100%',
            width: '100%',
        },
        footer: {
            position: 'absolute',
            left: 0,
            bottom: isPdf ? -31 : -28,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'space-between',
            width: '100%',
            fontSize: 13,
            color: '#808080'
        },
        canvasImg: {
            position: 'absolute',
            top: 95,
            left: 0,
            width: '100%'
        }
    });

    const getCanvasImage = () => {
        const data = reportCanvasController?.getCanvasData(reportKey);

        if (data) {
            return <Image style={styles.canvasImg} src={`${data.dataUrl}`}/>
        }
    }

    return (
        <Body id={reportKey} style={styles.wrapper}>
            <Body style={styles.container}>
                <View style={styles.flexBox}>
                    {children}
                </View>
                <View style={styles.footer}>
                    {
                        reportCreateProp?.showWriter && <Text>{reportCreateProp?.info && `작성자: ${reportCreateProp?.info.name}`}</Text>
                    }

                    {
                        reportCreateProp?.printPageNo && <Text render={({pageNumber, totalPages}) => (
                        `씨오리포트 ${pageNumber} / ${totalPages}`
                        )}/>
                    }

                </View>
            </Body>
            { (isPdf && reportCanvasController?.printCanvas) && getCanvasImage() }
        </Body>
    );
};

export default MainReportContainer;