import classNames from "classnames/bind";
import commonStyles from "./laborer-num-info.module.scss";
import styles from "./edit-result-overview-chkbox.module.scss";
import {Field, Form, Formik} from "formik";
import React, {useEffect, useState} from "react";
import {ReportAddInfoPopProps} from "./report-add-info-pop";
import {mngTotalSummaryTypeList} from "../model/co-report/data/mng-total-summary-text";
import {useInjection} from "inversify-react";
import {CustomerInputApi} from "../model/customer/customer-input-api";
import usePromise from "../model/common/use-promise";
import {CustomerApi} from "../model/customer/customer-api";
import {toast} from "react-toastify";
import {strSaved} from "../data/strings";
import {useCustomerInputClear} from "../model/customer/customer-input-hook";
import {asyncCall} from "../util/util";
import {useMountedState} from "react-use";
import {DialogManager} from "../components/dialog-manager";

const cx = classNames.bind(commonStyles);
const cn = classNames.bind(styles);

const EditResultOverviewChkbox = ({data, setData, onClose}: ReportAddInfoPopProps) => {
    const customerApi = useInjection(CustomerApi);
    const customerInputApi = useInjection(CustomerInputApi);
    const dialogManager = useInjection(DialogManager);
    const [loading, rowDataResult] = usePromise(() => customerApi.rawData(data.customer.id), [data]);
    const isMounted = useMountedState();
    const {clearInfo} = useCustomerInputClear(setData);
    const [editMode, setEditMode] = useState(false);
    const [initialValues, setInitialValues] = useState(data.report.mngTotalSummaryList.map(it => {
        return {
            type: it.type,
            checked: it.checked
        }
    }));

    useEffect(()=> {
        if (rowDataResult) {
            setEditMode(rowDataResult.val?.mngSummaryCheck?.active ?? false);
        }
    }, [rowDataResult]);

    const doReset = () => {
        clearInfo(
            data.customer.id,
            'mngSummaryCheck',
            (report) => {
                setData(report);
                data.report = report.report;
                setEditMode(false);
                setInitialValues(data.report.mngTotalSummaryList.map(it => {
                    return {
                        type: it.type,
                        checked: it.checked
                    }
                }));
            }
        );
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values) => {
                asyncCall(customerInputApi.saveMngSummaryList, [data.customer.id, values], result => {
                    setData(result);
                    data.customer = result.customer;
                    data.report = result.report;
                    toast.success(strSaved);
                }, isMounted, (e) => {
                    dialogManager.alert("입력값에 문제가 발생하여 리포트 생성에 오류가 발생하였습니다. 값을 다시 확인해 주세요.");
                });
            }}
            enableReinitialize
        >
            <Form className={cx('labor-num-info')}>
                <div className={cx('btn-area')}>
                    {
                        editMode ?
                            <button className={cx('save-btn')} type='submit'><i/>저장하기</button> :
                                <button className={cx('save-btn')} type='button'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setEditMode(true)
                                        }}><i/>편집하기</button>
                    }
                    <button className='close-btn' type='button' onClick={() => {
                        onClose()
                    }}>닫기</button>
                </div>
                <div className={cx('scroll-box')}>
                    <div className={cx('scroll-area')}>
                        <p className={cx('title')}>
                            경영진단결과 종합개요 체크박스 편집
                            { editMode &&
                                <button className={cx('reset-btn')} type='button' onClick={doReset}><i></i>초기화</button>
                            }
                        </p>
                        <div className={cn('info')}>※ 실제 컨설팅을 진행할 영역을 선택하고 리포트에 표시 할 수 있습니다.</div>
                        <div className={cn('chk-box-area')}>
                            {mngTotalSummaryTypeList.map((it, i) =>
                                <label key={i} className={cn('blue-chkbox')}>
                                    <Field name={`${i}.type`} value={it.type} type='hidden'/>
                                    <Field name={`${i}.checked`} type='checkbox' disabled={!editMode}/>
                                    <span>{it.name}</span>
                                </label>
                            )}
                        </div>
                    </div>
                </div>
            </Form>
        </Formik>
    );
};

export default EditResultOverviewChkbox;