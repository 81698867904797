import { inject, injectable } from "inversify";
import { ApiCall } from "../api-call";
import { ApiResult } from '../api-result';
import { Page } from "../common/dto/page";
import {KktAutoFriendDto} from "./dto/kkt-auto-friend-dto";
import {KktAutoFriendForm} from "./form/kkt-auto-friend-form";
import { KktAutoPresetDto } from "./dto/kkt-auto-preset-dto";
import { KktAutoGroupDto } from "./dto/kkt-auto-group-dto";
import { makeSearchDataQs } from "../../util/str-util";
import { KktAutoLogDto } from "./dto/kkt-auto-log-dto";
import { KktAutoPresetType } from "./dto/kkt-auto-preset-type";

@injectable()
export class KatalkApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    programDownload = () => {
        return this.apiCall.getApiUrl() + `/api/kkt/program/download`;
    }

    getPaging = async (searchData: {[key: string]: string|undefined}) => {
        return await this.apiCall.fetch<Page<KktAutoFriendDto>>(`/api/kkt/friends?${makeSearchDataQs(searchData)}`);
    }

    getAllList = async () => {
        return await this.apiCall.fetch<KktAutoFriendDto[]>(`/api/kkt/friends/all`);
    }

    getIdListInGroup = async (groupId:string) => {
        return await this.apiCall.fetch<String[]>(`/api/kkt/friends/group/ids/${groupId}`);
    }

    getPreset = async () => {
        return await this.apiCall.fetch<KktAutoPresetDto[]>(`/api/kkt/preset`);
    }

    getPresetFromCode = async (type: KktAutoPresetType, code: string) => {
        return await this.apiCall.fetch<KktAutoPresetDto>(`/api/kkt/preset/${type}/${code}`);
    }

    addFriends = async (data: KktAutoFriendForm[]) => {
        return await this.apiCall.json<KktAutoFriendDto[]>(`/api/kkt/friends/add`, data);
    }

    delFriends = async (friendsIds: string[]) => {
        return await this.apiCall.delete<ApiResult<string>>(`/api/kkt/friends`, friendsIds);
    }

    changeNickName = async (id:string, nickName:string) => {
        return await this.apiCall.post<ApiResult<string>>(`/api/kkt/change/nickname/${id}`, {nickName: nickName});
    }

    addSendList = async (friendIds: string[]) => {
        return await this.apiCall.json<ApiResult<string>>(`/api/kkt/send`, friendIds);
    }

    outSendList = async (friendIds: string[]) => {
        return await this.apiCall.json<ApiResult<string>>(`/api/kkt/outsend`, friendIds);
    }

    changeGroup = async (friendsIds: string[], groupId?: string) => {
        return await this.apiCall.json<ApiResult<string>>(`/api/kkt/change/group?groupId=${groupId}`, friendsIds);
    }

    getGroupList = async () => {
        return await this.apiCall.fetch<KktAutoGroupDto[]>(`/api/kkt/group`);
    }

    addGroup = async (groupName:string) => {
        return await this.apiCall.post<KktAutoGroupDto>(`/api/kkt/group`, {groupName: groupName});
    }

    modifyGroup = async (groupId: string, groupName: string) => {
        return await this.apiCall.put<ApiResult<string>>(`/api/kkt/group/${groupId}`, {groupName: groupName});
    }

    deleteGroup = async (groupId: string) => {
        return await this.apiCall.delete<ApiResult<string>>(`/api/kkt/group/${groupId}`);
    }

    saveFailedLog = async (data: string[]) => {
        return await this.apiCall.json<ApiResult<string>>(`/api/kkt/log/failed`, data);
    }

    getFailedLog = async () => {
        return await this.apiCall.fetch<KktAutoLogDto>(`/api/kkt/log/failed`);
    }

    getCouponLink = async () => {
        return await this.apiCall.fetch<ApiResult<string>>('/api/kkt/link/coupon');
    }
}