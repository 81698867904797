import {inject, injectable} from 'inversify';
import {action, computed, makeObservable, observable, toJS} from 'mobx';
import {ApiCall} from '../api-call';
import {ApiResult} from '../api-result';
import {AuthToken} from './auth-token';
import {AuthDto} from './auth-dto';
import {SmsAuthDto} from '../intro/dto/sms-auth-dto';
import {MemberDto} from '../intro/dto/member-dto';
import {CreditDto, PrSubscriptionType} from '../customer/dto/credit-dto';

export interface AuthInfoType {
    auth: boolean;
    member: MemberDto | null
}

@injectable()
export class AuthStore {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    @inject(AuthToken)
    private authToken!: AuthToken;

    authInfo: AuthInfoType = {
        auth: false,
        member: null
    }

    init() {
        makeObservable(this, {
            authInfo: observable,
            logout: action,
            directLogin: action,
            setLoginInfo: action,
            setCredit: action,

            isSubscription: computed,
            subscriptionType: computed,
            isRegularSubscription: computed,
            expDate: computed,
            isLogoManager: computed,
            isShowLecture: computed,
            isShowQna: computed,
            isModifyReportAuth: computed,
            isIncludeCorpMake: computed,
            isShowKktAuto: computed
        });

        this.apiCall.logout = () => {
            this.logout();
        };
    }

    login = async (username: string, password: string, deviceId: string) => {
        const rs = await this.apiCall.fetch<ApiResult<AuthDto>>('/login', {
            username: username,
            password: password,
            deviceId: deviceId,
        }, {});
        if (rs.success && rs.val) {
            this.authToken.setToken(rs.val.at ?? '');
            if (rs.val.member != null) {
                this.setLoginInfo(rs.val.at != null, rs.val.member);
            }
        }
        return rs;
    }

    directLogin = async (authDio: AuthDto) => {
        this.authToken.setToken(authDio.at ?? '');
        if (authDio.member != null) {
            this.setLoginInfo(authDio.at != null, authDio.member);
        }

    }

    logout() {
        this.authToken.removeToken();
        this.authInfo.auth = false;
        this.authInfo.member = null;
    }

    isLoggedIn() {
        return this.authInfo.auth;
    }

    setLoginInfo(auth: boolean, member: MemberDto | null = null) {
        this.authInfo.auth = auth;
        if (member != null) {
            this.authInfo.member = member;
            this.authToken.setLastLoginId(member.userId);
        }
    }

    setCredit(credit: CreditDto) {
        const member = this.authInfo.member;
        if (member != null) {
            member.subscriptionType = credit.subscriptionType;
            member.corpMakeType = credit.corpMakeType;
            member.subCredit = credit.subCredit;
            member.cotalkCredit = credit.cotalkCredit;
            member.purCredit = credit.purCredit;
            member.expDt = credit.expDt;
        }
    }

    getCredit = () => {
        const member = this.authInfo.member;
        if (member == null) return 0;
        return member.subCredit + member.cotalkCredit + member.purCredit;
    }

    getMember = () => toJS(this.authInfo.member);

    /**
     * 최초 로그인을 시도한다.
     */
    async initApp() {
        if (!this.authToken.getToken()) return;
        try {
            const rs = await this.apiCall.fetch<ApiResult<MemberDto>>('/init');
            if (rs.success) {
                this.setLoginInfo(true, rs.val);
            }
        } catch (e) {
            console.log('error', e);
            this.logout();
        }
    }

    /**
     * 토큰을 설정한다.
     * @param token 토큰
     */
    setOnlyToken(token: string | undefined) {
        this.authToken.setToken(token ?? '');
    }

    setToken(token: string | undefined, member?: MemberDto) {
        this.authToken.setToken(token ?? '');
        this.setLoginInfo(!!token, member);

    }

    loginReqCert = async (token: string) => {
        return await this.apiCall.fetch<ApiResult<string>>('/login/cert/req', null, {'Authorization': `Bearer ${token}`});
    }

    loginCheckCert = async (seq: string, authNum: string, token: string, deviceId: string) => {
        return await this.apiCall.fetch<ApiResult<AuthDto>>('/login/cert/check', {
            seq: seq,
            authNum: authNum,
            deviceId: deviceId
        }, {'Authorization': `Bearer ${token}`});
    }

    requestCert = async (type: string, name: string, phone: string, username?: string) => {
        return await this.apiCall.fetch<ApiResult<string>>('/cert/req', {
            type: type,
            name: name,
            phone: phone,
            username: username
        }, {});
    }

    checkCert = async (type: string, seq: string, authNum: string) => {
        return await this.apiCall.fetch<ApiResult<SmsAuthDto>>('/cert/check', {
            type: type,
            seq: seq,
            authNum: authNum
        }, {});
    }

    /**
     * 최종 로그인된 아이디를 리턴한다.
     */
    lastLoginId = () => {
        return this.authToken.getLastLoginId();
    }

    get subscriptionType(): PrSubscriptionType {
        if (!this.authInfo.member) return "NONE";

        return this.authInfo.member?.subscriptionType ?? "NONE";
    }

    get isPause(): boolean {
        if (!this.authInfo.member) return false;
        return (this.authInfo.member.subscriptionType === "PAUSE");
    }

    /**
     * 구독중 여부 확인
     */
    get isSubscription(): boolean {
        if (!this.authInfo.member) return false;
        if (this.authInfo.member.subscriptionType === "NONE") return false;

        return (this.authInfo.member?.subscriptionType === "MEMBER"
            || this.authInfo.member?.subscriptionType === "GROUP"
            || this.authInfo.member?.subscriptionType === "FREE_PASS"
            || this.authInfo.member?.subscriptionType === "ONE_TIME"
            || this.authInfo.member?.subscriptionType === "ANNUAL_PASS"
        );
    }

    /**
     * 정규 구독 여부
     */
    get isRegularSubscription(): boolean {
        if (!this.authInfo.member) return false;

        return (this.authInfo.member?.subscriptionType === "MEMBER"
            || this.authInfo.member?.subscriptionType === "GROUP"
            || this.authInfo.member?.subscriptionType === "ANNUAL_PASS"
        );
    }

    /**
     * 만료일
     */
    get expDate(): string {
        if (!this.authInfo.member) return "";

        return this.authInfo.member!.expDt;
    }

    /**
     * 등기부 등본 생성 권한
     */
    get isIncludeCorpMake(): boolean {
        return !(this.authInfo.member?.corpMakeType === "EXCEPT");
    }

    /**
     * 컨설팅 권한
     */
    get isConsulting(): boolean {
        if (!this.authInfo.member) return false;

        return this.authInfo.member.authType["CONSULTING"];
    }

    /**
     * 온라인강의 권한
     */
    get isShowLecture(): boolean {
        if (!this.authInfo.member) return false;

        return this.authInfo.member?.authType["ONLINE_LECTURE"];
    }

    /**
     * 온라인강의 권한
     */
    get isShowQna(): boolean {
        if (!this.authInfo.member) return false;

        return this.authInfo.member?.authType["EXPERT_QNA"];
    }

    /**
     * 로고 관리 권한
     */
    get isLogoManager(): boolean {
        if (!this.authInfo.member) return false;

        return this.authInfo.member.authType["LOGO_MANAGE"];
    }

    /**
     * 리포트 수정 권한
     */
    get isModifyReportAuth(): boolean {
        if (!this.authInfo.member) return false;

        return this.authInfo.member.authType["COREPORT"];
    }

    /**
     * 카카오톡 자동발송 권한
     */
    get isShowKktAuto(): boolean {
        if (!this.authInfo.member) return false;

        return this.authInfo.member.authType["KATALK_SEND"];
    }

    /**
     * 장비 아이디
     */
    getDeviceId = () => {
        return this.authToken.getDeviceId()
    }

    get groupId(): string | undefined {
        if (!this.authInfo.member) return undefined;
        if (!this.authInfo.member.group) return undefined;

        return this.authInfo.member.group.id;
    }
}