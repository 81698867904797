import React from 'react';
import {Field, FieldAttributes} from 'formik';
import FormikErrorMsg from './formik-error-msg';

const FormikField = ({name, ...props}: FieldAttributes<any>) => {
    return <span className='formik-field' id={name}>
        <Field name={name} {...props}/>
        <FormikErrorMsg name={name}/>
    </span>
};

export default FormikField;