import classnames from "classnames/bind";
import styles from "../mna-info.module.scss";
import React from "react";

const cx = classnames.bind(styles);

interface ConnectChannelTalkProps {
    children: React.ReactNode
    btnText: string
}

const ConnectChannelTalk = ({children, btnText}:ConnectChannelTalkProps) => {
    const linkChannelTalk = () => {
        window.open('https://copartner.channel.io/workflows/97690', '_blank');
    }

    return(
        <div className={cx('connect-channel-talk')}>
            <img className={cx('img')} src='/images/illust_consulting.png' alt='mna'/>
            {children}
            <button type="button" className={cx('channel-talk-btn')} onClick={linkChannelTalk}>{btnText}</button>
        </div>
    );
}

export default ConnectChannelTalk;