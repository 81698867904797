import {inject, injectable} from "inversify";
import {ApiCall} from "../api-call";
import {ApiResult} from "../api-result";
import {makeSearchDataQs} from "../../util/str-util";
import {CoSubscription} from "./dto/co-subscript";
import {Page} from "../common/dto/page";
import {SubscriptionActDto} from "./dto/subscription-act-dto";
import {RetryBillingData} from "./dto/retry-billing-data";
import {SubscriptionCurrent} from "./dto/subscription-current";

@injectable()
export class CoSubscriptApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;

    /**
     * 현재 구독을 리턴한다.
     */
    currentSubscription = async () => {
        const url = `/api/payment/subscription/current`;
        return await this.apiCall.fetch<ApiResult<SubscriptionCurrent>>(url);
    }

    /**
     * 구독 페이징
     */
    pagingSubscription = async (pageNo: number, searchData: {[key: string]: string|undefined}) => {
        const url = `/api/payment/subscriptions?page=${pageNo}${makeSearchDataQs(searchData)}`;
        return await this.apiCall.fetch<ApiResult<Page<CoSubscription>>>(url);
    }

    /**
     * 구독 정보 리턴
     * @param ssId 구독 아이디
     */
    getSubscription = async (ssId: string) => {
        const url = `/api/payment/subscription/${ssId}`;
        return await this.apiCall.fetch<ApiResult<CoSubscription>>(url);
    }

    /**
     * 구독 취소
     * @param ssId 구독 아이디
     */
    cancelSubscription = async (ssId: string) => {
        return await this.apiCall.fetch<ApiResult<string>>('/api/payment/subscription/cancel', {ssId});
    }

    retryInfo = async () => {
        const url = `/api/payment/subscription/current/retry/billing`;
        return await this.apiCall.fetch<ApiResult<SubscriptionActDto>>(url);
    }

    retryInfoAct = async (data: RetryBillingData) => {
        const url = `/api/payment/subscription/current/retry/billing`;
        return await this.apiCall.fetch<ApiResult<any>>(url, data);
    }

    changePaymentMethod = async (ssId: string, repId: string) => {
        return await this.apiCall.fetch<ApiResult<string>>('/api/payment/subscription/changePayMethod', {ssId, repId});
    }

    changeBilling = async (ssId: string, billingId: string) => {
        return await this.apiCall.fetch<ApiResult<string>>('/api/payment/subscription/changeBilling', {ssId, billingId});
    }
}