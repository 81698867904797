import {StyleSheet} from '@react-pdf/renderer';
import {mFont} from './report-font';
import colors from '../../scss/themes/colors.module.scss';

export const mainT = StyleSheet.create({
    tableWrap: {
        width: '100%',
        fontSize: mFont,
        textAlign: 'center',
    },
    headBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: 36,
        backgroundColor: colors.main,
        fontWeight: 700,
        color: '#fff',
    },
    headBox2: {
        borderTop: `1px solid ${colors.main}`,
        backgroundColor: colors.skyBlue,
        fontWeight: 400,
        color: '#4c4c4c',
    },
    headBox3: {
        height: 32,
        backgroundColor: colors.mainBack2
    },
    headBox4: {
        height: 27,
    },
    head: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
    },
    bodyBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 36,
        borderBottom: '0.5px solid #ccc',
    },
    bodyBox3: {
        height: 32
    },
    bodyTitleBox: {
        flex: 1,
        display: 'flex',
        height: '100%',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'left',
        backgroundColor: colors.skyBlue
    },
    bodyTitle: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textIndent: 24,
    },
    bodyInbody: {
        width: '100%',
        lineHeight: 1.4,
        paddingTop: 6,
        paddingBottom: 6,
        textIndent: 24,
    },
    additionalInfo: {
        textIndent: 0,
        color: '#666'
    },
    bodyDesc: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    },
    rate: {
        width: 20,
        height: 20,
        marginLeft: 4,
        marginRight: -24,
        vertialAlgin: 'bottom'
    },
    txtCenter: {
        justifyContent: 'center',
        textAlign: 'center',
    },
    txtLeft: {
        justifyContent: 'flex-start',
        textAlign: 'left',
        textIndent: 24
    },
    txtRight: {
        justifyContent: 'flex-end',
        textAlign: 'right',
    },
    borderR: {
        borderRight: '0.5px solid #ccc',
    },
    borderB: {
        borderBottom: '0.5px solid #ccc',
    },
    annotationNum: {
        fontSize: 11,
        fontWeight: 400,
        textIndent: 2,
    },
    annotationNum2: {
        marginTop: -4,
        fontSize: 11,
        fontWeight: 400,
        textIndent: 2,
    },
    numbering: {
        textIndent: 3,
        color: colors.main,
        fontWeight: 400
    },
    txtOverflow: {
        overflow:'hidden', 
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
});

export const mainRowT = StyleSheet.create({
    flexWrap: {
        width: '100%',
        borderTop: `1px solid ${colors.main}`,
        fontSize: mFont,
    },
    flexLine: {
        display: 'flex',
        flexDirection: 'row',
        borderBottom: '0.5px solid #ccc',
        minHeight: 36,
    },
    titleCover: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.skyBlue,
        width: 137,
    },
    title2Cover: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: colors.skyBlue,
        width: 180,
    },
    title: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        textIndent: 24,
        color: '#4c4c4c',
    },
    descCover: {
        flex: 1,
        alignSelf: 'center',
        paddingBottom: 8,
        paddingTop: 8,

        desc2: {
            flex: 0.8
        }
    },
    desc: {
        paddingRight: 24,
        paddingLeft: 24,
        lineHeight: 1.7
    },
    annotationNum: {
        marginLeft: 1,
        fontSize: 11,
        textIndent: 3
    },
    txtRight: {
        textAlign: 'right'
    }
});

export const indicatorT = StyleSheet.create({
    tableWrap: {
        fontSize: mFont,
        borderBottom: '0.5px solid #ccc'
    },
    headBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 32,
        backgroundColor: colors.main,
        fontWeight: 700,
        color: '#fff'
    },
    head: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        borderRight: '0.5px solid #ccc',
    },
    headTitle: {
        flex: 1.25,
    },
    bodyBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        textAlign: 'center',
        height: 128,
        borderBottom: '0.5px solid #ccc',
    },
    bodyTitle: {
        flex: 1.25,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        borderRight: '0.5px solid #ccc',
        fontWeight: 500,
    },
    analysisBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        borderRight: '0.5px solid #ccc',
    },
    analysisTop: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        borderBottom: '0.5px solid #ccc',
        fontSize: 16,
        fontWeight: 700
    },
    analysisBottom: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    averageBox: {
        flex: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
    },
    averageTop: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        borderBottom: '0.5px solid #ccc',
    },
    averageBottom: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    rowBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    columnBox: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    img: {
        width: 26,
        margin: '0 auto'
    },
    bad: {color: '#f53f50'},
    normal: {color: '#ffbe5b'},
    good: {color: '#5cbf39'},
    bdR: {
        borderRight: '0.5px solid #ccc',
    },
    bdB: {
        borderBottom: '0.5px solid #ccc',
    }
});

export const mainFinanceT = StyleSheet.create({
    tableWrap: {
        width: '100%',
        fontSize: mFont
    },
    headBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 32,
        backgroundColor: colors.main,
        color: '#fff',
        fontWeight: 700,
    },
    headBox2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 36,
        backgroundColor: colors.main,
        color: '#fff',
        fontWeight: 700,
    },
    head: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        textAlign: 'center',
        padding: '0 8px',
        borderRight: '0.5px solid #ccc',
    },
    head2: {
        flex: 0.9,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        padding: '0 22px',
        // textAlign: 'center'
    },
    head1st: {
        flex: 1.3,
        padding: '0 12px'
    },
    headLast: {
        borderRight: 'none'
    },
    bodyBox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        height: 32,
        borderBottom: '0.5px solid #ccc',
    },
    body: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        textAlign: 'right',
        borderRight: '0.5px solid #ccc',
        paddingRight: 16
    },
    hasNoColorCell: {
        fontSize: 14
    },
    body2: {
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
        textAlign: 'right',
        height: '100%',
        paddingRight: 22
    },
    body1st: {
        flex: 1.3,
        justifyContent: 'flex-start',
        paddingLeft: 24,
        paddingRight: 0,
        textAlign: 'left'
    },
    bodyLast: {
        borderRight: 'none'
    },
    dep1: {
        backgroundColor: '#D1DEFF',
        fontWeight: 500
    },
    dep2: {
        backgroundColor: '#eee',
        fontWeight: 500
    },
    dep3: {},
    dep3Txt: {
        fontWeight: 500
    },
    dep4Txt: {
        textIndent: 7
    },
    dep5Txt: {
        textIndent: 17
    },
    number: {
        textIndent: 0,
        color: '#4752E4',
        fontWeight: 500,
    },
    basicInfo: {
        backgroundColor: colors.skyBlue
    },
    basicInfoTitle: {
        fontSize: 12,
    },
    bdRn: {
        borderRight: 'none'
    },
    bdBWhite: {
        borderBottom: '0.5px solid #fff'
    },
    txtLeft: {
        justifyContent: 'flex-start',
        textAlign: 'left',
    },
    txtRight: {
        justifyContent: 'flex-end',
        textAlign: 'right',
    },
    annotationNum: {
        marginTop: -3,
        textIndent: 2,
        fontSize: 11,
        fontWeight: 400,
        color: colors.main,
    }
});

export const blueBox = StyleSheet.create({
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        border: `1px solid ${colors.main}`,
        backgroundColor: colors.skyBlue,
        borderRadius: '8px',
    }
});

export const orangeBox = StyleSheet.create({
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        border: `1px solid ${colors.orange}`,
        backgroundColor: colors.orangeLight,
        borderRadius: '8px',
    }
});

export const grayBorderBox = StyleSheet.create({
    box: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        border: '2px solid #E5E5E5',
        backgroundColor: '#fff',
        borderRadius: '8px',
    }
});