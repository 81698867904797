import React, {useEffect, useState} from 'react';
import styles from './consult-log.module.scss';
import classnames from 'classnames/bind';
import NoInfo from '../../components/no-info';
import {useInjection} from 'inversify-react';
import {DialogManager} from '../../components/dialog-manager';
import EditConsultLogPop from './edit-consult-log-pop';
import AddConsultData from './add-consult-data';
import {toast} from 'react-toastify';
import {usePagination} from '../../util/util';
import {CustomerApi} from '../../model/customer/customer-api';
import {toDateString, YMD, ymdToDateString} from '../../util/str-util';
import {strDeleted} from '../../data/strings';
import {CounselingLog} from "../../model/customer/dto/counseling-log";
import Pagination from "../../components/pagination";
import {Page} from "../../model/common/dto/page";
import {CounselingStage} from "../../model/customer/dto/counseling-stage";
import { PrCustomerShareSp } from '../../model/customer/dto/pr-customer-share';
import { CustomerShareApi } from '../../model/customer/customer-share-api';
import { CommonCode } from '../../model/common/dto/common-code';
import _ from 'lodash';

const cx = classnames.bind(styles);

const ConsultLog = ({customerId, flags}: { customerId: string, flags: CommonCode[] }) => {
    const customerApi = useInjection(CustomerApi);
    const customerShareApi = useInjection(CustomerShareApi);
    const [list, setList] = useState<Page<CounselingLog>>();
    const [stageList, setStageList] = useState<CounselingStage[]>([]);
    const [shareCus, setShareCus] = useState<PrCustomerShareSp[]>([]);
    const [currentPage, setCurrentPage] = usePagination();
    const [labsShare, setLabsShare] = useState(false);
    const [otherMemberExist, setOtherMemberExist] = useState(false);
    const dialogManager = useInjection(DialogManager);

    const doRefresh = async () => {
        let pageNo = 0;
        setCurrentPage(pageNo);
        setList(undefined);

        const result = await customerApi.listCounselingLog(pageNo, customerId);
        const shareCusResult = await customerShareApi.shareCus(customerId);

        if (result.val) {
            setList(result.val?.paging);
            setStageList(result.val?.stages);
        }

        if(shareCusResult.val) {
            let cnt = 0;

            shareCusResult.val.map((sc, idx) => {
                sc.flags?.some((flag) => {
                    if(flag === "LABS") {
                        cnt++;
                        return true;
                    }   
                })
            });

            if(shareCusResult.val.length > cnt) {
                setOtherMemberExist(true);
            }

            setShareCus(shareCusResult.val);
        }
    }

    const paging = async () => {
        const result = await customerApi.pagingCounselingLog(currentPage, customerId);
        const shareCusResult = await customerShareApi.shareCus(customerId);

        if (result.val) {
            setList(result.val);
        }

        if(shareCusResult.val) {
            let cnt = 0;

            shareCus.map((sc, idx) => {
                sc.flags?.some((flag) => {
                    if(flag === "LABS") {
                        cnt++;
                        return true;
                    }   
                })
            });

            if(shareCusResult.val.length > cnt) {
                setOtherMemberExist(true);
            }

            setShareCus(shareCusResult.val);
        }
    }

    const openEditPop = (e: React.MouseEvent, i?: number) => {
        const maxRound = list!.content.reduce((prev, cur) => cur.round > prev ? cur.round : prev, 0);
        const value = i !== undefined ? list!.content[i!] : {
            customerId: customerId,
            round: maxRound + 1,
            stage: '',
            content: '',
            date: toDateString(new Date(), YMD)
        }

        dialogManager.open(EditConsultLogPop, {customerId, counselingLog: value, stages: stageList, refresh: doRefresh});
    };

    const onDelete = (counselingLogId: string) => {
        dialogManager.confirm(
            '상담일지를 삭제하시겠어요?',
            '해당 차수에 추가한 상담자료도 함께 삭제됩니다.',
            async () => {
                await customerApi.deleteCounselingLog(customerId, counselingLogId);
                doRefresh().then();
                toast.success(strDeleted);
            }
        );
    };

    const addData = (counselingLogId: string) => {
        dialogManager.open(AddConsultData, {counselingLogId: counselingLogId, customerId, refresh: paging});
    };

    const deleteData = (counselingLogId: string, fileId: string) => {
        dialogManager.confirm(
            '첨부된 상담자료를 삭제하시겠어요?',
            '',
            async () => {
                await customerApi.deleteCounselingData(customerId, counselingLogId, fileId);
                await paging().then();
                toast.success(strDeleted);
            }
        );
    };

    const checkLabs = () => {
        flags?.map((flag, idx) => {
            if(flag.codeVal === "LABS") {
                setLabsShare(true);
            }
        });
    }

    useEffect(() => {
        if (list) {
            paging().then();
        } else {
            doRefresh().then();
        }

        checkLabs();
    }, [currentPage]);

    if (!list)
        return <></>;

    const attachCount = list.content.reduce((prev, cur) => prev + (cur.attachment?.length ?? 0), 0);

    return (
        <div className={cx('advice-journal')}>
            <div className={cx('record')}>
                <div className={cx('title-area')}>
                    <p className={cx('title')}>
                        상담일지
                    </p>
                    <button className={cx('add-btn')} type='button' onClick={openEditPop}><i></i>상담일정 추가</button>
                </div>
                <ul className={cx('list-area')}>
                {
                        list.content.length > 0 ? list.content.map((it, i) => <li key={i} onClick={e => openEditPop(e, i)}>
                            <span><i className={cx('icon-calendar-list')}></i></span>
                            <span>{it.round}차</span>
                            <span>{ymdToDateString(it.date)}</span>
                            <span>{it.stage}</span>
                            <span>{it.content}</span>
                            <span><button className={cx('edit-btn')} type='button'></button></span>
                            <span onClick={(e) => e.stopPropagation()}>
                                <button className={cx('upload-file-btn')} type='button'
                                        onClick={() => addData(it.id!)}></button>
                            </span>
                            <span onClick={(e) => e.stopPropagation()}>
                                <button className={cx('delete-btn')} type='button'
                                        onClick={() => onDelete(it.id!)}></button></span>
                        </li>) :
                        // <li className={cx('no-info-li')}><NoInfo/></li>
                        <li>
                            <span><i className={cx('icon-calendar-list')}></i></span>
                            <span>1차</span>
                            <span>날짜입력</span>
                            <span>상담단계</span>
                            <span>내용을 입력해 주세요.</span>
                            <span><button className={cx('edit-btn')} type='button' onClick={openEditPop}></button></span>
                            <span onClick={(e) => e.stopPropagation()}>
                                <button className={cx('upload-file-btn')} type='button'></button>
                            </span>
                            <span onClick={(e) => e.stopPropagation()}>
                                <button className={cx('delete-btn')} type='button'></button></span>
                        </li>
                    }
                </ul>
                {
                    list.totalElements > 5 && <Pagination listLen={list.totalElements} listsPerPage={5} setCurrentPage={setCurrentPage}
                                                          currentPage={currentPage}/>
                }
            </div>
            {/* <div className={cx('share-cus-member')}>
                <p className={cx('share-cus-title')}><i></i>고객공유</p>
                {
                    labsShare ? <p>씨오랩스{otherMemberExist ? "," : ""}</p> : null
                }
                {
                    shareCus.map((sc, idx) => {
                        console.log(sc);

                        if(!labsShare) {
                            return (
                                <p>{sc.srcMember.name} {sc.srcMember.position}{idx === shareCus.length - 1 ? "" : ","}</p>
                            )
                        } else {
                            let isLabsMember = false;

                            if(sc.flags != null) {
                                sc.flags.some((flag, k) => {
                                    if(flag.codeVal === "LABS") {
                                        isLabsMember = true;
                                        return true;
                                    }
                                });
                            }

                            if(!isLabsMember) {
                                return (
                                    <p>{sc.srcMember.name} {sc.srcMember.position}{idx === shareCus.length - 1 ? "" : ","}</p>
                                )
                            } else {
                                return (null)
                            }
                        }
                    })
                }
            </div> */}
            <div className={cx('data')}>
                <div className={cx('title-area')}>
                    <p className={cx('title')}>
                        상담자료
                    </p>
                </div>
                <ul className={cx('list-area')}>
                    {attachCount > 0 ? list.content.map(it => it.attachment?.map(att =>
                            <li key={att.id}>
                                <a href={att.url} download>
                                    <span><i className={cx('icon-document-list')}></i></span>
                                    <span>{it.round}차</span>
                                    <span>{att.documentName}</span>
                                    <span>
                                <button className={cx('download-btn')} type='button'></button>
                            </span>
                                </a>
                                <span>
                            <button className={cx('delete-btn')} onClick={() => deleteData(it.id!, att.id)}
                                    type='button'></button>
                        </span>
                            </li>)) :
                        <li className={cx('no-info-li')}><NoInfo/></li>}
                </ul>
            </div>
        </div>
    );
};

export default ConsultLog;