import styles from './noti-bot-pop.module.scss';
import classNames from 'classnames/bind';
import React from "react";
import Iframe from "react-iframe";
import { Customer } from '../model/customer/dto/customer';

const cx = classNames.bind(styles);

const NotiBotPop = ({data, onClose}: {data: Customer, onClose: VoidFunction}) => {
    const cotalkWebUrl = process.env.REACT_APP_COTALK_WEB_URL;
    const url = cotalkWebUrl + "/noAuthNotiBot/noti_bot/" + data?.connectId;

    return(
        <div className='full-background'>
            <div className={`pop-inner ${cx('noti-bot-pop')}`}>
                <div className={cx('top-line')}>
                    <div className={cx('flex-box')}>
                        <p className={cx('title')}>맞춤형 정책자금</p>
                        <button className={cx('close-btn')} type='button' onClick={async () => {
                            onClose();
                        }}>닫기</button>
                    </div>
                </div>
                <div className={cx('iframe-box')}>
                    <Iframe url={url} width='100%' height='100%'/>
                </div>
            </div>
        </div>
    );
}

export default NotiBotPop;