export const strSaved = '저장 완료되었습니다.';
export const strDeleted = '삭제가 완료되었습니다.';
export const strError = '오류가 발생했습니다.';

export const strEnterId = '아이디를 입력해 주세요.';
export const strEnterPwd = '비밀번호를 입력해 주세요.';
export const strEnterName = '이름을 입력해 주세요.';
export const strEnterEmail = '이메일 주소를 입력해 주세요.';
export const strEnterDomain = '도메인을 입력해 주세요.';
export const strSelectDomain = '도메인을 선택해 주세요.';
export const strEnterAuthNum = '인증번호를 입력해 주세요.';
export const strWrongAuthNum = '인증번호를 잘못 입력하였습니다.\n다시 확인해 주세요.';
export const strNumberOnly = '숫자만 입력 가능합니다.';

export const errExist = 'Exist';
export const strIdExist = '이미 가입된 회원입니다.\n로그인 화면으로 이동하시겠어요?';
export const errNoCredit = 'NoCredit';