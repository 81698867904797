import React from 'react';
import classNames from "classnames/bind";
import styles from "./custom-tooltip.module.scss";

const cx = classNames.bind(styles);

interface CustomTooltipProps {
    title: string;
    position?: string;
}

const CustomTooltip = ({title, position = 'center'}: CustomTooltipProps) => {
    return (
        <p className={cx('custom-tooltip', position)}>
            {title}
        </p>
    )
};

export default CustomTooltip;