import {inject, injectable} from 'inversify';
import {ApiCall} from '../api-call';
import {ApiResult} from '../api-result';
import {SignupDto} from './dto/signup-dto';
import {PrGroupSpDto} from './dto/pr-group-sp-dto';
import {PrManageModifyForm, PrMemberModifyForm} from "./form/pr-member-modify-form";
import {SignResultDto} from "./dto/sign-result-dto";
import {PrManagerInfo} from "./dto/member-dto";
import {CommonCode} from "../common/dto/common-code";
import {PrGroupMemberSpDto} from "./dto/pr-group-member-sp-dto";
import { PrExpertProfile } from './embed/pr-expert-profile';
import {AuthDto} from "../auth/auth-dto";

@injectable()
export class MemberApi {
    @inject(ApiCall)
    private apiCall!: ApiCall;


    resetPassword = async (password: string, token: string) => {
        return await this.apiCall.fetch<ApiResult<string>>('/cert/resetPwd', {password: password, tempToken: token},
            {});
    }

    checkUsername = async (username: string) => {
        return await this.apiCall.fetch<ApiResult<boolean>>('/signup/check/username', {username: username}, {});
    }

    signup = async (data: SignupDto) => {
        return await this.apiCall.json<ApiResult<SignResultDto>>('/signup', data, false);
    }

    tempKey = async (tempKey: string, deviceId: string) => {
        return await this.apiCall.json<ApiResult<AuthDto>>(`/oapi/tempLoginKey/${tempKey}`, {val: deviceId}, false);
    }

    groupList = async () => {
        return await this.apiCall.fetch<ApiResult<PrGroupSpDto[]>>('/signup/groups', null, {});
    }

    specializedList = async () => {
        return await this.apiCall.fetch<ApiResult<CommonCode[]>>('/signup/specialized', null, {});
    }

    checkPromotion = async (promotionCode: string) => {
        return await this.apiCall.fetch<ApiResult<boolean>>('/signup/check/promotion', {promotionCode: promotionCode}, {});
    }

    checkEmployeeId = async (groupId: string, employeeId: string) => {
        return await this.apiCall.fetch<ApiResult<PrGroupMemberSpDto>>('/signup/check/employeeId', {
            groupId: groupId,
            employeeId: employeeId
        }, {});
    }

    checkPassword = async (password: string) => {
        return await this.apiCall.fetch<ApiResult<boolean>>(`/api/myInfo/check`, {passwd: password});
    }

    editMyInfo = async (form: PrMemberModifyForm) => {
        return await this.apiCall.json<ApiResult<boolean>>(`/api/myInfo/modify`, form);
    }

    editPasswd = async (password: string, newPassword: string) => {
        return await this.apiCall.fetch<ApiResult<boolean>>(`/api/myInfo/modifyPasswd`, {
            passwd: password,
            newPasswd: newPassword
        });
    }

    public async editManageInfo (form: PrManageModifyForm): Promise<ApiResult<any>> {
        return await this.apiCall.json<ApiResult<any>>(`/api/myInfo/modify/manage`, form);
    }

    getManageInfo = async () => {
        return await this.apiCall.fetch<ApiResult<PrManagerInfo>>(`/api/myInfo/manage`);
    }

    withdraw = async (password: string) => {
        return await this.apiCall.fetch<ApiResult<boolean>>(`/api/myInfo/out`, {passwd: password});
    }

    updateExpertProfile = async (data: PrExpertProfile) => {
        return await this.apiCall.json<ApiResult<boolean>>(`/api/expert`, data);
    }
}