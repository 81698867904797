import React, {useRef} from 'react';
import {useInjection} from 'inversify-react';
import {ChartManager} from './chart-manager';
import {ChartJsProps} from './chart-js';
import {Image} from '@react-pdf/renderer';

export const ChartImage = ({options, ...props}: ChartJsProps) => {
    const chartManager = useInjection(ChartManager);
    const idRef = useRef(-1);

    if (idRef.current === -1) {
        idRef.current = chartManager.getId2();
    }

    const id = idRef.current;
    return id < 0 || id >= chartManager.images.length || !chartManager.images[id] ? null :
        <Image src={chartManager.images[id]} style={{width: props.width, height: props.height}}/>
}
