import React, {useRef} from 'react';
import classNames from 'classnames/bind';
import {FieldArray, Form, Formik} from 'formik';
import styles from './edit-shareholder-info.module.scss'
import * as Yup from 'yup';
import {asyncCall} from '../../util/util';
import {toast} from 'react-toastify';
import {useMountedState} from 'react-use';
import {useInjection} from 'inversify-react';
import {CustomerApi} from '../../model/customer/customer-api';
import {CustomerDetail} from '../../model/customer/dto/customer-detail';
import {ApiResult} from '../../model/api-result';
import {DateInputBox, DatePicker, InputBox, mdmRelList, SelectInputBox} from './edit-common';
import {MngInfoList} from '../../model/customer/dto/mng-info-list';
import {MngInfo} from '../../model/customer/dto/mng-info';
import {DialogManager} from "../../components/dialog-manager";

const cx = classNames.bind(styles);


interface ManagementBoxProps {
    index: number;

    mng: MngInfo;

    isConnectedCorpReg: boolean;

    remove: (index: number) => MngInfo | undefined
}

const ManagementBox = ({index, mng, isConnectedCorpReg, remove}: ManagementBoxProps) => {
    // const mngrList = ['대표자', '대표이사및사원', '공동대표이사및사원', '동업자', '과점주주이사', '경영실권자', '무한책임사원', '법정관리인', '사내이사', '사외이사', '감사', '기타'];
    const field = `list.${index}.`;
    const isCeo = !!(mng.NO && mng.NO.startsWith("C"));
    const dialogManager = useInjection(DialogManager);

    return (
        <div className={cx('shareholder-box')}>
            <div className={cx('line')}>
                {/*<SelectInputBox title='직위' name={field + 'MNGR_CCD'} selectList={mngrList} inputboxCn='flex'/>*/}
                <InputBox title='직위' name={field + 'TTL'} />
                <DateInputBox title='주민번호앞자리' name={field + 'PID'} placeholder='YYMMDD' />
                <InputBox title='성명' name={field + 'NAME'} disabled={isCeo}/>
                {/* <InputBox title='직위' name={field + 'TTL'}/> */}

            </div>
            <div className={cx('line')}>
                <DateInputBox title='취임일' name={field + 'ASMP_DT'} placeholder='YYYYMMDD' disabled={isCeo && isConnectedCorpReg}/>
                <DateInputBox title='재선임일' name={field + 'REAPP_DT'} placeholder='YYYYMMDD' />
                <DateInputBox title='최근 등기일' name={field + 'REAPP_REG_DT'} placeholder='YYYYMMDD' />
            </div>
            <div className={cx('line')}>
                <SelectInputBox title='관계' name={field + 'MDM_REL_CD'} selectList={mdmRelList} inputboxCn='flex'/>
            </div>
            {!isCeo && <button className={cx('delete-btn')} type='button' onClick={() => dialogManager.confirm('해당 임원정보를 삭제하시겠어요?\n삭제 후에는 복구가 불가능합니다.', '', () => {
                remove(index);
            })}>
            </button>}

        </div>
    );
}

interface EditManagementInfoProps {
    customerId: string;
    data: MngInfoList;
    isConnectedCorpReg: boolean;
    setData: React.Dispatch<React.SetStateAction<CustomerDetail>>;
    onClose: () => void;
}

const EditManagementInfo = ({customerId, data, isConnectedCorpReg, setData, onClose}: EditManagementInfoProps) => {
    const isMounted = useMountedState();
    const customerApi = useInjection(CustomerApi);
    const scrollRef = useRef<HTMLDivElement>(null);
    // const dialogManager = useInjection(DialogManager);

    const doAction = (api: (...args: any) => Promise<ApiResult<CustomerDetail>>, args: any[], action: string) => {
        asyncCall(api, args, result => {
            setData(data  => {
                if (data.customer && data.summary) return {...data
                    , customer: {...data.customer, ...result.customer}
                    , summary: {...data.summary, ...result.summary}}
                else return data;
            });
            onClose();
            toast.success(action + ' 완료되었습니다.');
        }, isMounted);
    }
    // const onDelete = (values: FormikValues) => {
    //     dialogManager.confirm(ymdToDateString(values.refDt) + ' 기준 경영진 현황 정보를 삭제하시겠어요?', '', () => {
    //         doAction(customerApi.delMngInfoList, [customerId, values.orgDt], '삭제가');
    //     });
    // }

    const onAddRow = (insertAction: VoidFunction) => {
        insertAction();
        setTimeout(() => {
            scrollRef?.current?.scrollTo({ top: scrollRef?.current?.scrollHeight, behavior: 'smooth' });
        }, 1)
    }

    return (
        <div className={cx('edit-info')}>
            <Formik
                initialValues={{...data, orgDt: data.refDt}}
                validationSchema={Yup.object({
                    list: Yup.array().of(
                        Yup.object({
                            NAME: Yup.string().required('이름을 입력해주세요.')
                        }))
                })}
                onSubmit={(value) => {
                    doAction(customerApi.saveMngInfoList, [customerId, value], '저장');
                }}>
                {({values}) =>
                    <Form>
                        <FieldArray name='list'>
                            {({push, remove}) => (<>
                                <div className={cx('button-area')}>
                                    <button className={cx('back')} type='button' onClick={() => onClose()}><i></i>돌아가기
                                    </button>
                                    <div>
                                        <button className={cx('add-btn')} type='button'
                                                onClick={() => onAddRow(() => push( {NAME: ''}))}><i></i>임원 추가
                                        </button>
                                        <button className={cx('save')} type='submit'><i></i>저장하기</button>
                                    </div>
                                </div>
                                <div className={cx('input-area')} ref={scrollRef}>
                                    <div className={cx('scroll')}>
                                        <p className={cx('title')}>임원 현황</p>
                                        <div className={cx('total-box')}>
                                            <InputBox title='기준일' name='refDt' inputboxCn='date-box'>
                                                <DatePicker
                                                    name='refDt'
                                                    allowNull={false}/>
                                            </InputBox>
                                            {/* <DateInputBox title='기준일' name='refDt' readOnly/> */}
                                        </div>
                                        {values.list?.map((it, i) => <ManagementBox key={i} index={i} mng={it} isConnectedCorpReg={isConnectedCorpReg} remove={remove}/>)}
                                    </div>
                                </div>
                            </>)}
                        </FieldArray>
                    </Form>}
            </Formik>
        </div>
    );
};

export default EditManagementInfo;