import ReportAddInfoPop from "../../../report-add-info-pop";
import {useInjection} from "inversify-react";
import {DialogManager} from "../../../../components/dialog-manager";
import {CoReportDto} from "../../../../model/co-report/dto/co-report-dto";
import {useSetReportContext} from "../../../co-report-hook";
import CustomerDetailPop from "../../../customer/customer-detail-pop";
import {CoReportApi} from "../../../../model/co-report/co-report-api";
import {ReportAddInfoTab} from "../../../../data/const_list";

type ModifyOpenPageType = 'REPORT'|'CUSTOMER';

export interface EditReportInfoBtnProps {
    customerId?: string
    title: string
    tab: string

    secondTab?: string

    edit?: boolean,

    data?: CoReportDto;

    pageType?: ModifyOpenPageType,
}

const EditReportInfoBtn = ({state, ver}:{state:EditReportInfoBtnProps, ver: string}) => {
    const dialogManager = useInjection(DialogManager);
    const setData = useSetReportContext();
    const reportApi = useInjection(CoReportApi);

    return( 
        <button onClick={() => {
            if (state.pageType === 'REPORT') {
                dialogManager.open(ReportAddInfoPop, {
                    data: state.data!,
                    setData: setData!,
                    tab: state.tab,
                    secondTab: state.secondTab,
                    onDialogClose: async () => {
                        if (state.tab === ReportAddInfoTab.Emergency) {
                            const updateResult = await reportApi.coReportV2(state.customerId!);
                            if (updateResult.val) {
                                setData!(updateResult.val!);
                            }
                        }
                    }
                });
            }
            if (state.pageType === 'CUSTOMER') {
                dialogManager.open(CustomerDetailPop, {
                    customerId: state.customerId!,
                    tab: state.tab,
                    edit: state.edit,
                    secondTab: state.secondTab,
                    onDialogClose: async () => {
                        let updateResult;


                        if(state.data?.customer.enpTyp && state.data?.customer.enpTyp === "2") {
                            updateResult = await reportApi.coSoleReport(state.customerId!);
                        } else {
                            updateResult = await reportApi.coReportV2(state.customerId!);
                        }

                        if (updateResult.val) {
                            setData!(updateResult.val!);
                        }
                    }
                });
            }
        }} className={`${ver === 'ver1' ? "edit-report-info-btn" : "edit-btn"}`}>{ver === 'ver2' && <i></i>}{state.title}{ver === 'ver1' && <i></i>}</button>
    );
};

export default EditReportInfoBtn;